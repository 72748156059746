import axios from "axios";
import { endpoint } from "../../../constants";
import { DocumentActionModel, FilterModel, FilterUnlocatedPaymentModel, PaymentAllocationModel } from "./paymentAllocation.model";

export default class PaymentAllocationApi {

  static getListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/PaymentAllocation/GetAllList", model)
      .then((res) => res.data);
  }

  static getAllUnallocatedPayment(model: FilterUnlocatedPaymentModel) {
    return axios
      .post<any>(endpoint + "/api/PaymentAllocation/GetAllUnAllocatedPayments", model)
      .then((res) => res.data);
  }

  static getAllUnallocatedInvoices(model: FilterUnlocatedPaymentModel) {
    return axios
      .post<any>(endpoint + "/api/PaymentAllocation/GetAllUnAllocatedInvoices", model)
      .then((res) => res.data);
  }

  static createPaymentAllocation(model: PaymentAllocationModel) {
    return axios
      .post<any>(endpoint + "/api/PaymentAllocation/CreatePaymentAllocation", model)
      .then((res) => res.data);
  }

  static getSingleItemWithDetail(id: string) {
    return axios
      .get<any>(endpoint + "/api/PaymentAllocation/GetSingleWithDetail?paymentAllocationId=" + id)
      .then((res) => res.data);
  }

  static docCompleteAction(model: DocumentActionModel) {
    return axios
      .put<any>(endpoint + "/api/PaymentAllocation/DocCompleteAction?id=" + model.id + "&status=" +model.status)
      .then((res) => res.data);
  }


}
