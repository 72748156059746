export interface PointRewardSettingModel {
    id: string;
    partnerType: number;
    isPointReward: boolean;
    pointType : number;
    shoppingAmountToPoint: number;
    qty: number;
    point: number;
    pointToShoppingAmount: number;
}

export enum PartnerTypeEnum
{
    Customer,
    Stockist,
    Reseller,
    Admin,
    AdminStockist,
    StockistReseller=101
}