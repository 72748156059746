import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "../../../../../app/store";
import {
  getListExpedition,
  getListPartner,
  getListPaymentMethod,
  getProvince,
} from "../../../../../global/global.reducer";
import { PaymentConfirmationModel, ShipmentModel } from "../../model";
import JatuhTempoDropshipForm from "../components/JatuhTempoDropshipForm";
import { SalesOrderModel } from "../jatuhTempoDropship.model";
import {
  getJatuhTempoDropshipSingleItem,
  getPaymentConfirmationSingle,
  getSalesOrderSingle,
} from "../jatuhTempoDropship.reducer";

const JatuhTempoDropshipPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  let history = useHistory();

  const singleDataState = useSelector(
    (state: RootState) => state.jatuhTempoDropship.single ?? ({} as ShipmentModel)
  );

  const singleSoDataState = useSelector(
    (state: RootState) => state.jatuhTempoDropship.salesOrderSingle ?? ({} as SalesOrderModel)
  );

  const singlePaymentConfirmationDataState = useSelector(
    (state: RootState) =>
      state.jatuhTempoDropship.paymentConfirmationSingle ?? ({} as PaymentConfirmationModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getJatuhTempoDropshipSingleItem(id));
    dispatch(getSalesOrderSingle(id));
    dispatch(getPaymentConfirmationSingle(id));
    dispatch(getProvince());
    dispatch(getListExpedition());
    dispatch(getListPaymentMethod());
    dispatch(getListPartner());
    setIsEdit(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <div onClick={() => history.goBack()} className="form-title">
          <FaChevronLeft />
          {"            "}
          Edit Jatuh Tempo (Dropship)
        </div>
      </Form.Group>
      <JatuhTempoDropshipForm
        data={singleDataState}
        dataSo={singleSoDataState}
        dataPaymentConfirm={singlePaymentConfirmationDataState}
        isEdit={isEdit}
      />
    </Container>
  );
};

export default JatuhTempoDropshipPage;
