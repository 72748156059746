import axios from "axios";
import {endpoint} from "../../../constants";
import {AdditionalPageSetting, AdditionalPageSettingEnum} from "./models";

export default class FooterSettingApi {

    static getSingleAdditionalPageSetting(type: AdditionalPageSettingEnum) {
        return axios
            .get<any>(endpoint + "/api/AdditionalPageSetting/GetSingleItem", {
                params: {
                    Type: type
                }
            })
            .then((res) => res.data);
    }

    static updateAdditionalPageSetting(args: AdditionalPageSetting) {
        return axios
            .post<any>(
                endpoint + "/api/AdditionalPageSetting/Update",
                args
            )
            .then((res) => res.data);
    }
}