import { Container, Form } from "react-bootstrap";
import PesananNonCashlessTable from "../components/pesananNonCashlessTable";

const PesananNonCashlessIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan Non Cashless</Form.Label>
      </Form.Group>
      <PesananNonCashlessTable />
    </Container>
  );
};

export default PesananNonCashlessIndex;
