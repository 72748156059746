import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import Form from '../components/ConfirmPasswordForm';
import {  } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { ConfirmPasswordModel } from '../ConfirmPasswordModel';
import { changePassword } from '../confirmPassword.reducer';


const ConfirmPasswordPage = () => {
  const dispatch = useDispatch();
  // let { user } = useParams<{ user: string }>();
  let { id } = useParams<{ id: string }>();

  const authResult = new URLSearchParams(window.location.search); 
  const code = authResult.get('id')

  
  // const singleDataState = useSelector(
  //   (state: RootState) =>
  //     state.changePassword.data ?? ({} as ConfirmPasswordModel)
  // );

  const onSubmit = ( password: string, confirmPassword: string) => {
    // console.log("userId : "+code);
      dispatch(
        changePassword({
          id: code,
          password,
          confirmPassword
        } as ConfirmPasswordModel)
      );
    
  };
  return (
    <div>
        <Form 
        onSubmit={onSubmit}
        // data={singleDataState}
        />
    </div>
  );
};

export default ConfirmPasswordPage;
