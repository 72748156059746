import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import RichTextEditor from "../../../../components/rich-text/RichTextEditor";
import {useDispatch, useSelector} from "react-redux";
import {getSingleAdditionalPageSetting, resetStatus, updateAdditionalPageSetting} from "../../footerSetting/footerSetting.reducer";
import {AdditionalPageSetting, AdditionalPageSettingEnum} from "../../footerSetting/models";
import {RootState} from "../../../../app/store";
import Swal from "sweetalert2";

export interface PageSettingFormProps {
    pageTitle: string;
    type: AdditionalPageSettingEnum;
}

const PageSettingForm = ({pageTitle = "", type = AdditionalPageSettingEnum.Address}: PageSettingFormProps) => {
    const dispatch = useDispatch();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState<string>("");

    const additionalPageSetting = useSelector(
        (state: RootState) =>
            state.footerSetting.singleData ?? ({} as AdditionalPageSetting)
    );
    const status = useSelector(
        (state: RootState) =>
            state.footerSetting.status ?? "pending"
    );
    const isLoading = useSelector(
        (state: RootState) =>
            state.footerSetting.isLoading ?? false
    );

    const onContentChangeHandler = (val: string) => {
        setContent(val);
    };

    useEffect(() => {
        if (additionalPageSetting) {
            setContent(additionalPageSetting.content);
            setTitle(additionalPageSetting.contentTitle);
        }
    }, [additionalPageSetting]);

    useEffect(() => {
        if ( status !== 'pending') {
            if (status === 'success') {
                Swal.fire({
                    icon: 'success',
                    text: 'Web setting updated',
                }).then(() => {
                    dispatch(getSingleAdditionalPageSetting(type));
                    dispatch(resetStatus());
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    text: 'Web setting cannot updated!',
                }).then(() => {
                    dispatch(resetStatus());
                })
            }
        }
    }, [status]);

    const handleSubmit = (e:any)=>{
        e.preventDefault();
        dispatch(updateAdditionalPageSetting({
            id: additionalPageSetting.id,
            contentTitle: title,
            content: content,
            type: type
        }));
    }

    const onTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(e.target.value);
    };

    return (
        <div>
            <h5 className={"mx-3"}>{pageTitle}</h5>
            <form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="input-padding mt-4 mx-2">
                    <Form.Label column={"sm"} sm={3}>
                        Title
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="text"
                            placeholder="Fill title page here"
                            required
                            onChange={onTitleChange}
                            value={title}></Form.Control>
                    </Col>
                </Form.Group>
                <Row className='forms mt-4 mx-3'>
                    <RichTextEditor value={content} onValueChange={onContentChangeHandler}/>
                </Row>
                <div className="wrap-buttons mx-3 my-3">
                    <Button variant="info" type='submit' disabled={isLoading}>Save</Button>
                </div>
            </form>
        </div>
    );
};

export default PageSettingForm;
