export interface WalletModel {
 id: string;
 partnerId : string;
 partnerName: string,
 partnerType: number,
 status: number;
 nominal: number;
 approveDate: string;
 createdDate: string;
 note: string;
 type: WalletTransactionType;
}

export interface FilterModel {
 startCreatedDate: string;
 endCreatedDate: string;
 startApprovalDate: string;
 endApprovalDate: string;
 status: number|null;
 partnerId: string|null;
 partnerType: number|null;
 skip: number;
 take: number;
}

export interface CreateRequestWalletModel {
 partnerId : string;
 status: number;
 nominal: number;
 note: string;
 type: WalletTransactionType;
} 

export interface ChangeWalletStatusModel {
 id: string;
 status: number;
}

export enum WalletTransactionType {
 Wallet,
 Point
}