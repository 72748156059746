import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button, Modal, Container, Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getAllWarehouse, getReturDetail, updateRetur, getListProduct, getListVariationByProduct } from "../Retur.api";
import { useHistory } from "react-router-dom";
import noImage from "../../../assets/no-image.png";
import { unwrapResult } from '@reduxjs/toolkit';
import { FaTrash } from "react-icons/fa";
import { endpoint } from "../../../constants";

const ReturForm = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  const { warehouseList, returDetail, listProduct, listVariationByProduct } = useSelector((state: RootState) => state.retur)

  const [values, setValues] = useState<any>({})
  const [statusModalAddProduct, setStastusModalAddProduct] = useState(false)
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [selectedVariantName, setSelectedVariantName] = useState("");
  const [selectedProductQty, setSelectedProductQty] = useState(0);

  useEffect(() => {
    let path = window.location.pathname
    let id = path.split('/')[3]
    dispatch(getReturDetail(id))
    dispatch(getAllWarehouse())
    dispatch(getListProduct())
  }, [])

  useEffect(() => {
    setValues(returDetail)
  }, [returDetail])

  const handleChange = (e: any) => {
    let { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const handleDeleteList = (id: string) => {
    setValues({
      ...values,
      returDetail: values.returDetail.filter((item: any) => item.id !== id)
    })
  }

  const handleChangeQtyList = (e: any, data: any) => {
    let newReturDetail: any = []
    values.returDetail.map((item: any) => {
      let newItem = JSON.parse(JSON.stringify(item))
      if (newItem.id === data.id) {
        newItem.quantity = e.target.value
      }
      newReturDetail.push(newItem)
    })
    setValues({
      ...values,
      returDetail: newReturDetail
    })
  }

  const handleSave = () => {
    dispatch<any>(updateRetur(values))
      .then(unwrapResult)
      .then((res: any) => {
        history.goBack()
      })
  }

  const columns = [
    {
      name: "KODE PRODUK",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "PRODUCT NAME",
      selector: "productName",
      sortable: true,
    },
    {
      name: "COLOR/VARIANT",
      selector: "variantName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: (d: any) => (
        <Form.Control
          type="number"
          placeholder="-"
          value={d.quantity}
          onChange={(e) => handleChangeQtyList(e, d)}
        ></Form.Control>
      ),
      sortable: true,
    },
    // {
    //   name: "ACTION",
    //   selector: (d: any) => (
    //     <div className="retur-table-button">
    //       <FaTrash onClick={() => handleDeleteList(d.id)} />
    //     </div>
    //   ),
    //   sortable: true,
    // },
  ];

  const onProductChange = (e: any) => {
    let selected = listProduct.filter((item: any) => item.id === e.target.value)[0]
    if (selected) {
      setSelectedVariantId("")
      setSelectedProductCode(selected.productCode)
      setSelectedProductName(selected.name)
      dispatch(getListVariationByProduct(e.target.value));
    }
  }

  const onVariationChange = (e: any) => {
    let selected = listVariationByProduct.filter((item: any) => item.id === e.target.value)[0]
    if (selected) {
      setSelectedVariantId(e.target.value)
      setSelectedVariantName(selected.name)
    }
  }

  const handleAddProduct = () => {
    let data = {
      variationId: selectedVariantId,
      quantity: selectedProductQty,
      productCode: selectedProductCode,
      productName: selectedProductName,
      variantName: selectedVariantName,
    }
    setValues({
      ...values,
      returDetail: [...values.returDetail, data]
    })
    setStastusModalAddProduct(false)
  }

  return (
    <Container fluid>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={statusModalAddProduct}
        onHide={() => setStastusModalAddProduct(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Product</Form.Label>
              <Form.Control as="select" onChange={onProductChange}>
                <option>select</option>
                {listProduct.map((Item: any) => {
                  return (
                    <option value={Item.id}>
                      {Item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Variant</Form.Label>
              <Form.Control as="select" onChange={onVariationChange}>
                <option>select</option>
                {listVariationByProduct.map((item: any) => (
                  <option value={item.id}>
                    {item.customProductAttributeList.length >= 1
                      ? item.customProductAttributeList[0].name
                      : ""
                    }
                    {item.customProductAttributeList.length >= 2
                      ? " - " + item.customProductAttributeList[1].name
                      : ""
                    }
                    {item.customProductAttributeList.length == 3
                      ? " - " + item.customProductAttributeList[2].name
                      : ""
                    }
                    {" (" + item.stockQty + ")"}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Qty</Form.Label>
              <Form.Control
                type="number"
                value={selectedProductQty}
                onChange={(e) => setSelectedProductQty(parseInt(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={() => setStastusModalAddProduct(false)}>
            Cancel
          </Button>
          <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={handleAddProduct}
            disabled={selectedVariantId === ""}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        className="float-right"
        style={{ width: 160, marginLeft: 10 }}
        variant="info"
        type="submit"
        form="internalUseForm"
        onClick={handleSave}>
        Save
      </Button>{" "}
      <Button
        className="float-right"
        style={{ width: 160, marginLeft: 10 }}
        variant="outline-info"
        type="submit"
        form="internalUseForm"
        onClick={() => history.goBack()}>
        Cancel
      </Button>
      <Form.Group>
        <Form.Label className="title">Edit Retur</Form.Label>
      </Form.Group>
      <Grid textAlign="center">
        <Card>
          <Card.Body>

            <Form>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  SO Number
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    placeholder="-"
                    required
                    disabled
                    name="soNumber"
                    value={values.soNumber}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Retur Number
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    placeholder="-"
                    required
                    disabled
                    name="number"
                    value={values.number}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Date
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    required
                    disabled
                    value={moment(new Date()).format('DD/MM/YYYY')}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Status
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    name="isActive"
                    value={values.isActive ? 1 : 0}
                    onChange={(e: any) => setValues({ ...values, isActive: e.target.value === '1' ? true : false })}
                  >
                    <option>-</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    name="warehouseId"
                    value={values.warehouseId}
                    onChange={handleChange}
                  >
                    <option>-</option>
                    {warehouseList.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.code} - {item.name} - {item.city}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    name="note"
                    value={values.note}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal Terima Pesanan
                </Form.Label>
                
                
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    required
                    disabled
                    value={values.recipientDate?moment(new Date(values.recipientDate)).format('DD/MM/YYYY HH:mm'):"-"}
                  ></Form.Control>
                </Col>


              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Keluhan
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    disabled
                    name="note"
                    value={values.description}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>


              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Bukti Pembelian
                </Form.Label>
                <Col sm={4}>
                  {values.photoOfReceipt !== '' && (<Image fluid thumbnail src={endpoint + values.photoOfReceipt} />)}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Foto Barang
                </Form.Label>
                <Col sm={4}>
                  {values.photoOfItem !== '' && (<Image fluid thumbnail src={endpoint + values.photoOfItem} />)}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Video Unboxing
                </Form.Label>
                <Col sm={4}>
                  {values.videoUnboxing !== '' && (<iframe title="video unboxing" allowFullScreen style={{ width: "100%", height: "300px" }} src={endpoint + values.videoUnboxing} />)}
                </Col>
              </Form.Group>
              <hr />

            </Form>
          </Card.Body>
        </Card>
      </Grid>
      <br />
      <Grid>
        <Card>
          <Card.Body>
            {/* <Button
              variant="info"
              type="submit"
              onClick={() => setStastusModalAddProduct(true)}>
              Tambah Produk
            </Button> */}
            <DataTable
              noHeader
              customStyles={TableCustomStyles}
              columns={columns}
              data={values.returDetail}
              pagination
              paginationServer
            // onChangeRowsPerPage={handlePerRowChange}
            // onChangePage={handlePageChange}
            // paginationTotalRows={totalRowState}
            />
          </Card.Body>
        </Card>
      </Grid>
    </Container>
  );
};

export default ReturForm;
