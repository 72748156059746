import moment from 'moment';
import React from 'react'
import { Doughnut } from 'react-chartjs-2';
var randomColor = require('randomcolor'); // import the script
// var color = randomColor(); // a hex code for an attractive color


export default function DiagramLaporan({listData, label}:any) {
    const data= {
        labels: listData.map((res:any)=> moment(res.createdDate).format('DD MMM YYYY')),
        datasets: [
            {
                id: 1,
                label: 'Statistik Laporan',
                data:  listData.map((res:any)=> res.totalPrice),
                // data: [22222, 22422, 33232, 43434,39394, 12123],
                backgroundColor: listData.map((res:any)=> randomColor({
                    luminosity: 'bright',
                    format: 'rgba',
                    alpha: 0.5 // e.g. 'rgba(9, 1, 107, 0.5)',
                 }))
            },
        // {
        //     id: 2,
        //     label: '',
        //     data: [3, 2, 1],
        // },
        ],
    }
    return (
        <div className="container d-flex justify-content-center text-center p-4">
            <div className='' style={{width: '30%'}}>
                <Doughnut
                    type='doughnut'
                    data={data}
                    // height={100}
                    // width={30}
                    // options={{ maintainAspectRatio: false }}
                />
                <h3 className='my-3'>
                Diagram Penjualan <br /> {label()}
                </h3>
            </div>
        </div>
    )
}
