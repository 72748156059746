import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import logo from "../../../assets/logo.png";
import moment from "moment";
import {
  SalesOrderDetailModel,
  SalesOrderWithDetailModel,
} from "../customer.model";
import { getSingleSalesOrderWithDetail } from "../customer.reducer";
import "../../../assets/styles/style-travel-doc-print.css";

const TravelDocumentPrint = () => {
  const dispatch = useDispatch();

  const [total, setTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalProductCount, setTotalProductCount] = useState(0);

  const salesOrderSingleDetail = useSelector(
    (state: RootState) =>
      state.customer.singleSalesOrderWithDetail ??
      ({} as SalesOrderWithDetailModel)
  );

  const countTotal = () => {
    let subTotalPerItem = 0;
    let total = 0;

    salesOrderSingleDetail?.salesOrderDetail?.map((item) => {
      total += item.quantity;

      setTotal(total);
    });
  };

  // const countGrantTotal = () => {
  //   setGrandTotal(
  //     total + SalesOrderWithDetailState?.shipment?.totalShippingCost
  //   );
  // };

  useEffect(() => {
    if (salesOrderSingleDetail?.salesOrderDetail?.length !== 0) {
      countTotal();
      setShippingCost(salesOrderSingleDetail?.shipment?.totalShippingCost);
    }
  }, [salesOrderSingleDetail]);

  useEffect(() => {
    setGrandTotal(total + shippingCost);
  }, [total, shippingCost]);

  useEffect(() => {
    let path = window.location.pathname;
    let id = path.split("/")[3];
    dispatch(getSingleSalesOrderWithDetail(id));

    setTimeout(() => {
      window.print();
    }, 2000);
  }, []);

  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp " + result + ",-";
    }
    return value;
  };

  return (
    <div className="travel-doc-print-container p-3">
      {/* <img src={logo} />
          <br />
          <br /> */}

      <br />
      <h1>SURAT JALAN</h1>
      <br />

      <Table className="m-0" size="sm" borderless>
        <tr>
          <td>Customer : {salesOrderSingleDetail?.salesOrder?.customerName}</td>
          <td align="right">
            Tanggal :{" "}
            {moment(
              new Date(salesOrderSingleDetail?.salesOrder?.orderDate)
            ).format("DD/MM/YYYY")}
          </td>
        </tr>
        <tr>
          <td>SO : {salesOrderSingleDetail?.salesOrder?.soNumber}</td>
        </tr>
      </Table>

      <br />

      <Table className="m-0" size="sm" bordered>
        <thead>
          <tr>
            <th>SKU</th>
            <th>PRODUCT</th>
            <th>VARIANT</th>
            <th>QTY</th>
            {/* <th>QTY PRODUCT</th> */}
            {salesOrderSingleDetail?.salesOrder?.soNumber
              .split("-")[2]
              .includes("ST") ? (
              <>
                {" "}
                <th>NOTE</th>{" "}
              </>
            ) : (
              <>
                {" "}
                <th>NOTE</th>{" "}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {salesOrderSingleDetail?.salesOrderDetail?.map(
            (item: any, index: number) => (
              <tr>
                <td>{item?.inventorySKU}</td>
                <td>{item?.productName}</td>
                <td>{item?.variationName}</td>
                <td>{item?.quantity}</td>

                {/* <td>
                  {salesOrderSingleDetail?.salesOrderDetail?.reduce(function (
                    prev,
                    current
                  ) {
                    if (current.inventorySKU === item.inventorySKU.toString()) {
                      return prev + +current.quantity;
                    } else {
                      return prev + +0;
                    }
                  },
                  0)}
                </td> */}

                {salesOrderSingleDetail?.salesOrder?.soNumber
                  .split("-")[2]
                  .includes("ST") ? (
                  <>
                    {" "}
                    <td>{item?.note==""? "-" : item?.note}</td>
                  </>
                ) : (
                  <>
                    {" "}
                    <td>{item?.note==""? "-" : item?.note}</td>
                  </>
                )}
              </tr>
            )
          )}
        </tbody>
        <tbody>
          <tr>
            <td colSpan={3} align={"right"}>
              <b>TOTAL</b>
            </td>
            <td>
              <b>{total}</b>
            </td>
          </tr>
        </tbody>
      </Table>

      <br />

      {salesOrderSingleDetail?.salesOrder?.note == "" ||
      salesOrderSingleDetail?.salesOrder?.note == null ||
      salesOrderSingleDetail?.salesOrder?.note == undefined ? (
        <></>
      ) : (
        <>
          <b>Catatan Order :</b>
          <p>{salesOrderSingleDetail?.salesOrder?.note}</p>
        </>
      )}

      <br />

      <Table className="m-0" size="sm" borderless>
        <tr>
          <td align="center">TANDA TERIMA</td>
          <td align="center">GUDANG</td>
          <td align="center">LOGISTIK</td>
        </tr>
        <br />
        <br />
        <tr>
          <td align="center">_____________</td>
          <td align="center">_____________</td>
          <td align="center">_____________</td>
        </tr>
      </Table>
    </div>
  );
};
export default TravelDocumentPrint;
