import { createSlice } from '@reduxjs/toolkit'
import { deleteRetur, getAllWarehouse, getListProduct, getListVariationByProduct, getReturDetail, getReturList, updateRetur } from './Retur.api'

export interface ReturState {
  loading: boolean,
  warehouseList: any,
  returList: any,
  returDetail: any,
  listProduct: any,
  listVariationByProduct: any
}

const initialState: ReturState = {
  loading: false,
  warehouseList: [],
  returList: [],
  returDetail: [],
  listProduct: [],
  listVariationByProduct: []
}

export const ReturSlice = createSlice({
    name: 'retur',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {

        builder.addCase(getAllWarehouse.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getAllWarehouse.fulfilled, (state, action) => {
            state.loading = false
            state.warehouseList = action.payload.data
        })
        builder.addCase(getAllWarehouse.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getReturList.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getReturList.fulfilled, (state, action) => {
            state.loading = false
            state.returList = action.payload.data
        })
        builder.addCase(getReturList.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getReturDetail.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getReturDetail.fulfilled, (state, action) => {
            state.loading = false
            state.returDetail = action.payload.data
        })
        builder.addCase(getReturDetail.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(updateRetur.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(updateRetur.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateRetur.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteRetur.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(deleteRetur.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRetur.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getListVariationByProduct.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getListVariationByProduct.fulfilled, (state, action) => {
            state.loading = false
            state.listVariationByProduct = action.payload.data
        })
        builder.addCase(getListVariationByProduct.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getListProduct.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getListProduct.fulfilled, (state, action) => {
            state.loading = false
            state.listProduct = action.payload.data
        })
        builder.addCase(getListProduct.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default ReturSlice.reducer