import React, { Fragment, useEffect, useState } from "react";
import { Card, Form, Col, Row, Button, Modal } from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../app/store";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../constants";
import { getListWithPaging } from "../printList.reducer";
import DataTable from "react-data-table-component";
import Viewer from "react-viewer";
import moment from "moment";

const PrintListTable = () => {
  const dispatch = useDispatch();

  const PrintListState = useSelector(
    (state: RootState) => state.printList.list ?? []
  );

  const TotalRowState = useSelector(
    (state: RootState) => state.printList.totalRow ?? 0
  );

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //filter
  const [filterSoNumber, setFilterSoNumber] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterPartnerType, setFilterPartnerType] = useState<number | null>(
    null
  );
  const [filterTempPartnerType, setFilterTempPartnerType] = useState(3);
  const [filterNoRek, setFilterNoRek] = useState("");
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterPaymentStatus, setfilterPaymentStatus] = useState<number | null>(
    null
  );
  const [filterTempPaymentStatus, setFilterTempPaymentStatus] = useState(2);
  const [filterStatus, setFilterStatus] = useState<number | null>(1);
  const [filterTempStatus, setFilterTempStatus] = useState(5);
  const [filterTransactionStatus, setFilterTransactionStatus] = useState<
    number | null
  >(null);
  const [filterTempTransactionStatus, setFilterTempTransactionStatus] =
    useState(99);

  const [filterShipmentStatus, setFilterShipmentStatus] = useState<
    number | null
  >(null);
  const [filterTempShipmentStatus, setFilterTempShipmentStatus] = useState(9);

  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterSoNumber("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterPartnerType(null);
    setFilterNoRek("");
    setFilterCustomerName("");
    setfilterPaymentStatus(null);
    setFilterStatus(null);
    setFilterTempPartnerType(3);
    setFilterTempPaymentStatus(2);
    setFilterTempStatus(5);
    setFilterTransactionStatus(null);
    setFilterTempTransactionStatus(99);
    setFilterTempShipmentStatus(9);
    setFilterShipmentStatus(null);

    dispatch(
      getListWithPaging({
        soNumber: "",
        startDate: "",
        endDate: "",
        partnerType: null,
        noRek: "",
        customerName: "",
        paymentStatus: null,
        transactionStatus: null,
        shippingStatus: null,
        status: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        soNumber: filterSoNumber,
        startDate: filterStartDate,
        endDate: filterEndDate,
        partnerType: filterPartnerType,
        noRek: filterNoRek,
        customerName: filterCustomerName,
        paymentStatus: filterPaymentStatus,
        transactionStatus: filterTransactionStatus,
        shippingStatus: filterShipmentStatus,
        status: filterStatus,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [PrintListState]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  const columns = [
    {
      name: "SO NUMBER",
      // selector: "soNumber",
      cell: (row: any) => (
        <Fragment>
          <Link
            to={{
              pathname: `/print-list/${row.salesOrderId}`,
            }}
          >
            {row.soNumber}
          </Link>
        </Fragment>
      ),
      sortable: true,
    },
    {
      name: "TANGGAL",
      selector: (d: { orderDate: string }) => d.orderDate,
      sortable: true,
      format: (row: any) => moment(row.orderDate).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      name: "CUSTOMER",
      selector: "partnerName",
      sortable: true,
    },
    {
      name: "TIPE CUSTOMER",
      selector: (d: { partnerType: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.partnerType === 0 && "End Customer"}
            {d.partnerType === 1 && "Stockist"}
            {d.partnerType === 2 && "Reseller"}
            {d.partnerType === 4 && "Admin Stockist"}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "TANGGAL APPROVE",
      selector: (d: { approveDate: string }) => d.approveDate,
      sortable: true,
      format: (row: any) => moment(row.approveDate).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      name: "APPROVE BY",
      selector: "approveBy",
      sortable: true,
    },
    // {
    //   name: "NO. REKENING",
    //   selector: "accountNo",
    //   sortable: true,
    // },
    // {
    //   name: "BANK ACCOUNT",
    //   selector: "accountName",
    //   sortable: true,
    // },
    // {
    //   name: "TOTAL",
    //   selector: (d: { grandTotal: number }) => (
    //     <>
    //       <NumberFormat
    //         value={d.grandTotal}
    //         displayType="text"
    //         thousandSeparator={true}
    //         prefix="Rp. "
    //       />
    //     </>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: "ATTACHMENT",
    //   selector: (d: { image: string }) => (
    //     <p style={{ margin: 5 }}>
    //       {d.image !== "" && d.image !== null ? (
    //         <img
    //           src={d.image !== "" ? endpoint + d.image : "none"}
    //           style={{ width: 50 }}
    //           onClick={() => {
    //             setSelectedImage(endpoint + d.image);
    //             setVisible(true);
    //           }}
    //         />
    //       ) : (
    //         <b>-</b>
    //         // <img src={noImage} style={{ width: 50 }} />
    //       )}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "PAYMENT STATUS",
      selector: (d: { paymentStatus: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.paymentStatus === 0 ? "UNPAID" : "PAID"}
          </p>
        </>
      ),
      sortable: true,
    },

    // {
    //   name: "DELIVERY STATUS",
    //   selector: (d: { shippingStatus: number }) => (
    //     <p style={{ margin: 10 }}>
    //       {d.shippingStatus === 0 && <span>Pending</span>}
    //       {d.shippingStatus === 1 && <span>Print Request</span>}
    //       {d.shippingStatus === 2 && <span>On Progress</span>}
    //       {d.shippingStatus === 3 && <span>On Delivery</span>}
    //       {d.shippingStatus === 4 && <span>Delivered</span>}
    //       {d.shippingStatus === 5 && <span>Completed</span>}
    //     </p>
    //   ),
    //   sortable: true,
    // },

    {
      name: "TRANSACTION STATUS",
      selector: (d: { transactionStatus: number }) => (
        <p style={{ margin: 10 }}>
          {d.transactionStatus === 0 && <span>Waiting For Payment</span>}
          {d.transactionStatus === 1 && <span>Payment Confirmed</span>}
          {d.transactionStatus === 2 && <span>Payment Received</span>}
          {d.transactionStatus === 3 && <span>Process</span>}
          {d.transactionStatus === 4 && <span>Delivery</span>}
          {d.transactionStatus === 5 && <span>Received</span>}
          {d.transactionStatus === 6 && <span>Delivered</span>}
          {d.transactionStatus === 11 && <span>Complain</span>}
          {d.transactionStatus === 12 && <span>Cancel</span>}
          {d.transactionStatus === 13 && <span>Completed</span>}
          {d.transactionStatus === 14 && <span>Rejected</span>}
          {d.transactionStatus === 15 && <span>Complain Process</span>}
          {d.transactionStatus === 16 && <span>Complain Reject</span>}
          {d.transactionStatus === 17 && <span>Complain Complete</span>}
          {d.transactionStatus === 18 && <span>Retur Process</span>}
          {d.transactionStatus === 19 && <span>Retur Complete</span>}
          {d.transactionStatus === 20 && <span>Retur Reject</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Delivery Status",
      selector: (d: { shippingStatus: number }) => (
        <p style={{ margin: 10 }}>
          {d.shippingStatus === 0 && (
            <span>
              {" "}
              <p style={{ color: "red" }}>Pending</p>
            </span>
          )}
          {/* {d.shippingStatus === 1 && <span>Print Request</span>} */}
          {d.shippingStatus === 2 && <span>On Progress</span>}
          {d.shippingStatus === 3 && <span>On Delivery</span>}
          {d.shippingStatus === 4 && <span>Delivered</span>}
          {d.shippingStatus === 5 && <span>Completed</span>}
          {d.shippingStatus === 6 && <span>Print A Request</span>}
          {d.shippingStatus === 7 && <span>Print B Request</span>}
          {d.shippingStatus === 8 && <span>Waiting For Pickup</span>}
        </p>
      ),
      sortable: true,
    },

    {
      name: "STATUS",
      selector: (d: { status: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.status === 0 && "Pending"}
            {d.status === 1 && "Approve"}
            {d.status === 2 && "Reject"}
            {d.status === 3 && "Complete"}
            {d.status === 4 && "Waiting"}
          </p>
        </>
      ),
      sortable: true,
    },
    // {
    //   name: "ACTION",
    //   selector: (d: {
    //     salesOrderId: string;
    //     paymentStatus: number;
    //     status: number;
    //   }) => (
    //     <>
    //       {d.status === 4 || d.status === 0 ? (
    //         <>
    //           <button
    //             style={{
    //               margin: 3,
    //               width: 70,
    //               height: 23,
    //               background: "#F52B3D",
    //               borderRadius: 5,
    //               color: "white",
    //             }}
    //             disabled={d.paymentStatus !== 0 && true}
    //             onClick={() => {
    //               handleChangeStatus(2, d.salesOrderId, 0);
    //               // setShowModal(true)
    //             }}
    //           >
    //             Reject
    //           </button>
    //           <br />
    //           <button
    //             style={{
    //               margin: 3,
    //               width: 70,
    //               height: 23,
    //               background: "#2ECC71",
    //               borderRadius: 5,
    //               color: "white",
    //             }}
    //             disabled={d.paymentStatus !== 0 && true}
    //             onClick={() => {
    //               setShowModal(true);
    //               setSelectedSalesOrderId(d.salesOrderId)
    //               // handleChangeStatus(1, d.salesOrderId)
    //             }}
    //           >
    //             Approve
    //           </button>
    //         </>
    //       ) : (
    //         <> </>
    //       )}
    //     </>
    //   ),
    // },
  ];

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    SO Number
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterSoNumber}
                      onChange={(e) => {
                        setFilterSoNumber(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Tanggal
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="date"
                      // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                      onChange={(e) =>
                        setFilterStartDate(
                          new Date(e.target.value).toISOString()
                        )
                      }
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="date"
                      // value={filterEndDate.substr(0, 10)}
                      onChange={(e) =>
                        setFilterEndDate(new Date(e.target.value).toISOString())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Customer Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterCustomerName}
                      onChange={(e) => {
                        setFilterCustomerName(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    No. Rekening
                  </Form.Label>
                  <Col>
                    <Form.Control
                      placeholder="Search"
                      type="text"
                      value={filterNoRek}
                      onChange={(e) => {
                        setFilterNoRek(e.target.value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col> */}
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Partner Type
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempPartnerType}
                      onChange={(e) => {
                        if (e.target.value.toString() === "3") {
                          setFilterPartnerType(null);
                          setFilterTempPartnerType(parseInt(e.target.value));
                        } else {
                          setFilterTempPartnerType(parseInt(e.target.value));
                          setFilterPartnerType(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={3}>All</option>
                      <option value={0}>End Customer</option>
                      <option value={1}>Stockist</option>
                      <option value={2}>Reseller</option>
                      <option value={4}>Admin Stockist</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Payment Status
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempPaymentStatus}
                      onChange={(e) => {
                        if (e.target.value.toString() === "2") {
                          setfilterPaymentStatus(null);
                          setFilterTempPaymentStatus(parseInt(e.target.value));
                        } else {
                          setFilterTempPaymentStatus(parseInt(e.target.value));
                          setfilterPaymentStatus(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={2}>All</option>
                      <option value={0}>UNPAID</option>
                      <option value={1}>PAID</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {/* <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Status
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempStatus}
                      onChange={(e) => {
                        if (e.target.value.toString() === "3") {
                          setFilterStatus(null);
                          setFilterTempStatus(parseInt(e.target.value));
                        } else {
                          setFilterTempStatus(parseInt(e.target.value));
                          setFilterStatus(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={5}>All</option>
                      <option value={0}>Pending</option>
                      <option value={1}>Approved</option>
                      <option value={2}>Rejected</option>
                      <option value={3}>Complete</option>
                      <option value={4}>waiting</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col> */}

              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Transaction Status
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempTransactionStatus}
                      onChange={(e) => {
                        if (e.target.value.toString() === "99") {
                          setFilterTransactionStatus(null);
                          setFilterTempTransactionStatus(
                            parseInt(e.target.value)
                          );
                        } else {
                          setFilterTempTransactionStatus(
                            parseInt(e.target.value)
                          );
                          setFilterTransactionStatus(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={99}>All</option>
                      <option value={0}>Waiting For Payment</option>
                      <option value={1}>Payment Confirmed</option>
                      <option value={2}>Payment Received</option>
                      <option value={3}>Process</option>
                      <option value={4}>Delivery</option>
                      <option value={5}>Received</option>
                      <option value={6}>Delivered</option>
                      <option value={11}>Complain</option>
                      <option value={15}>Complain Process</option>
                      <option value={16}>Complain Reject</option>
                      <option value={17}>Complain Completed</option>
                      <option value={12}>Cancel</option>
                      <option value={13}>Completed</option>
                      <option value={14}>Rejected</option>
                      <option value={18}>Retur Process</option>
                      <option value={19}>Retur Reject</option>
                      <option value={20}>Retur Completed</option>
                      
                     
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Delivery Status
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="select"
                      value={filterTempShipmentStatus}
                      onChange={(e) => {
                        if (e.target.value.toString() === "9") {
                          setFilterShipmentStatus(null);
                          setFilterTempShipmentStatus(parseInt(e.target.value));
                        } else {
                          setFilterShipmentStatus(parseInt(e.target.value));
                          setFilterTempShipmentStatus(parseInt(e.target.value));
                        }
                      }}
                    >
                      <option value={"9"}>All</option>
                      <option value={"0"}>Pending</option>
                      <option value={"1"}>Print Request</option>
                      <option value={"2"}>On Progress</option>
                      <option value={"8"}>Waiting For Pickup</option>
                      <option value={"3"}>On Delivery</option>
                      <option value={"4"}>Delivered</option>
                      <option value={"5"}>Completed</option>
                      <option value={"6"}>Print A Request</option>
                      <option value={"7"}>Print B Request</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Button
                  variant="outline-info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterResetClick}
                >
                  Reset Filter
                </Button>
                <Button
                  variant="info"
                  style={{ width: 130, height: 45, marginLeft: 10 }}
                  type="submit"
                  onClick={onFilterClick}
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Card.Body>

          <hr />

          <DataTable
            columns={columns}
            data={PrintListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={TotalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            style={{}}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        images={[{ src: selectedImage, alt: selectedImage }]}
      />
    </div>
  );
};

export default PrintListTable;
