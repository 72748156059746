import React, { useEffect, useState } from 'react'
import '../AutoCancelMasterReport.scss'
import { Container, Form, Button } from "react-bootstrap";
import { FiPrinter } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaFileExport } from 'react-icons/fa';
import { postExportAutoCancelMaster, postAutoCancelMasterReport, getAllCustomerFilterAutoCancelMaster, getAllProductFilterAutoCancelMaster } from '../AutoCancelMasterReport.api';
import moment from 'moment';
import { DatePicker, Space } from 'antd';
import Pagination from "react-js-pagination";
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';

export default function AutoCancelMasterReport() {

    const dispatch = useDispatch()

    const { listCustomerFilterAutoCancelMaster, listProductFilterAutoCancelMaster } = useSelector((state: RootState) => state.autoCancelMasterReport)

    const { RangePicker } = DatePicker;

    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [qtyTotal, setQtyTotal] = useState(0)
    const [sumTotal, setSumTotal] = useState(0)

    const initialValues = {
        customerName: "",
        reportType: '1',
        filterMonthly: moment(new Date()).format('YYYY-MM'),
        startFilterDate: "",
        endFilterDate: "",
        productName: "",
        skip: 0,
        take: "10",
        page: 1,
    }

    const [values, setValues] = useState({
        customerName: "",
        reportType: '1',
        filterMonthly: moment(new Date()).format('YYYY-MM'),
        startFilterDate: "",
        endFilterDate: "",
        productName: "",
        skip: 0,
        take: "10",
        page: 1,
    })

    useEffect(() => {
        dispatch(getAllCustomerFilterAutoCancelMaster())
        dispatch(getAllProductFilterAutoCancelMaster())
    }, [])

    useEffect(() => {
        getData()
    }, [values.page, values.take, values.skip])

    const handlePerPage = (e: any) => {
        setValues({
            ...values,
            take: e.target.value,
            skip: 0,
            page: 1
        })
    }

    const handleRangePicker = (event: any, picker: any) => {
        setValues({
            ...values,
            startFilterDate: picker[0],
            endFilterDate: picker[1],
        })
    }

    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    const handleReset = () => {
        setValues(initialValues)
    }

    const nameFile = () => {
        return `${moment(new Date(values.startFilterDate)).format('DD-MMM-YYYY')} - ${moment(new Date(values.endFilterDate)).format('DD-MMM-YYYY')}`
    }

    const handlePagination = (page: any) => {
        setValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        })
    }

    const handleExportExcel = (e: any) => {
        const data = {
            customerName: values.customerName,
            "reportType": parseInt(values.reportType),
            filterMonthly: values.filterMonthly.length !== 0 ? new Date(values.filterMonthly).toISOString() : new Date().toISOString(),
            startFilterDate: values.startFilterDate === "" ? new Date().toISOString() : new Date(values.startFilterDate).toISOString(),
            endFilterDate: values.endFilterDate === "" ? new Date().toISOString() : new Date(values.endFilterDate).toISOString(),
            productName: values.productName,
            skip: values.skip,
            take: parseInt(values.take)
        }
        dispatch<any>(postExportAutoCancelMaster(data))
            .then(unwrapResult)
            .then((res: any) => {
                const url = URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Laporan Master Auto Cancel - ${nameFile()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err: any) => console.log(err))
    }

    const handlePrint = () => {
        let params = "?productName=" + values.productName +
            "&reportType=" + values.reportType +
            "&filterMonthly=" + values.filterMonthly +
            "&startFilterDate=" + values.startFilterDate +
            "&endFilterDate=" + values.endFilterDate +
            "&customerName=" + values.customerName +
            "&skip=" + values.skip +
            "&take=" + values.take
        const newWindow = window.open('/print/autocancelmasterreport' + params)
        if (newWindow) newWindow.opener = null
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if ((values.startFilterDate && values.endFilterDate && values.reportType === '0') || values.reportType === '1' || values.reportType === '2') {
            setValues({
                ...values,
                skip: 0,
                page: 1,
            })
            getData()
        }
        else {
            Swal.fire({
                text: "Lengkapi rentang waktu",
                icon: "error",
                timer: 1500
            })
        }
    }

    const getData = () => {
        const data = {
            "productName": values.productName,
            "reportType": parseInt(values.reportType),
            "filterMonthly": values.filterMonthly.length !== 0 ? new Date(values.filterMonthly).toISOString() : new Date().toISOString(),
            "startFilterDate": values.startFilterDate === "" ? new Date().toISOString() : new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? new Date().toISOString() : new Date(values.endFilterDate).toISOString(),
            "customerName": values.customerName,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(postAutoCancelMasterReport(data))
            .then(unwrapResult)
            .then((res: any) => {
                setList(res.data.list)
                setTotal(res.data.total)
                setQtyTotal(res.data.qtyTotal)
                setSumTotal(res.data.sumTotal)
            })
            .catch((err: any) => console.log(err))
    }

    const moneyParser = (value: any) => {
        let valueString = value.toString()
        let valueList = valueString.split('')
        let resultList: any = []
        valueList.reverse().map((c: any, i: number) => {
            resultList.push(c)
            if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
                resultList.push('.')
            }
        })
        let result = resultList.reverse().join('')
        return "Rp" + result + ",00"
    }

    return (
        <Container className="content-body autocancel-report" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                Laporan Master Auto Cancel
                <div>
                    <Button className='addor' onClick={handleExportExcel}><FaFileExport /> Export to Excel</Button>
                    <Button className='addor' onClick={handlePrint}><FiPrinter /> Print</Button>
                </div>
            </div>
            <div className="box">
                <div className="head w-100">
                    <form onSubmit={handleSubmit}>
                        <div className="row w-100">
                            <div className="col-md-2 mb-3 ">
                                <label htmlFor="title">Nama Pembeli</label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <input className='custext' name="customerName" list='customerName' value={values.customerName} onChange={handleChange} />
                                <datalist id="customerName">
                                    {listCustomerFilterAutoCancelMaster.map((res: any, i: number) =>
                                        <option key={i} value={res.fullName}>{res.fullName}</option>
                                    )}
                                </datalist>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-2 ">
                                <label htmlFor="title">Waktu</label>
                            </div>
                            <div className="col-md-4 ">
                                <div className="d-flex align-items-center mb-3 justify-content-between">
                                    <Form.Check
                                        className='radiocustom m-0 '
                                        style={{ marginBottom: 13 }}
                                        inline
                                        type='radio'
                                        id='user4'
                                        label='Tanggal'
                                        name='reportType'
                                        checked={values.reportType === '0'}
                                        value={'0'}
                                        onClick={handleChange}
                                    />
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            className='custext'
                                            onChange={handleRangePicker}
                                            value={values.startFilterDate !== "" && values.endFilterDate !== "" ? [moment(values.startFilterDate, 'YYYY-MM-DD'), moment(values.endFilterDate, 'YYYY-MM-DD')] : undefined}
                                            format="YYYY-MM-DD"
                                        />
                                    </Space>
                                </div>
                                <div className="d-flex align-items-center mb-3 justify-content-between">
                                    <Form.Check
                                        className='radiocustom m-0 '
                                        style={{ marginBottom: 13 }}
                                        inline
                                        type='radio'
                                        id='user4'
                                        label='Bulan'
                                        name='reportType'
                                        checked={values.reportType === '1'}
                                        value={'1'}
                                        onClick={handleChange}
                                    />
                                    <input className='custext' type="month" name="filterMonthly" value={values.filterMonthly} onChange={handleChange} />
                                </div>
                                <div className="d-flex align-items-center mb-3 justify-content-between">
                                    <Form.Check
                                        className='radiocustom m-0 '
                                        style={{ marginBottom: 13 }}
                                        inline
                                        type='radio'
                                        id='user4'
                                        label='Seluruh Data'
                                        name='reportType'
                                        checked={values.reportType === '2'}
                                        value={'2'}
                                        onClick={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-2 mb-3 ">
                                <label htmlFor="title">Nama Produk</label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <input className='custext' name="productName" list='productName' value={values.productName} onChange={handleChange} />
                                <datalist id="productName">
                                    {listProductFilterAutoCancelMaster.map((res: any, i: number) =>
                                        <option key={i} value={res.name} />
                                    )}
                                </datalist>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="wrapbutfil col-md-12">
                                <Button className='addor' type='button' onClick={handleReset}>Reset</Button>
                                <Button className='addorrev' type='submit'>Filter</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="con">
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing {list.length} of {total} results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span>
                            <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>

                    <table className="table">
                        <thead className="">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">{qtyTotal}</th>
                                <th scope="col"></th>
                                <th scope="col">{moneyParser(sumTotal)}</th>
                            </tr>
                        </thead>
                        <thead className="">
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Tanggal Pesanan</th>
                                <th scope="col">Pembeli</th>
                                <th scope="col">Kode Produk</th>
                                <th scope="col">Produk</th>
                                <th scope="col">Varian</th>
                                <th scope="col">QTY</th>
                                <th scope="col">Harga</th>
                                <th scope="col">Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((res: any, i: number) => (
                                    <tr key={i}>
                                        <td>
                                            {
                                                values.skip === 0 ?
                                                    i + 1
                                                    :
                                                    i + 1 + (values.page - 1) * parseInt(values.take)
                                            }
                                        </td>
                                        <td>{moment(new Date(res.orderDate)).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{res.customerName}</td>
                                        <td>{res.productCode}</td>
                                        <td>{res.product}</td>
                                        <td>{res.variant}</td>
                                        <td>{res.qty}</td>
                                        <td>{moneyParser(res.price)}</td>
                                        <td>{moneyParser(res.totalPrice)}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                        <Pagination
                            activePage={values.page}
                            itemClass='item'
                            itemsCountPerPage={parseInt(values.take)}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePagination}
                            prevPageText='⟨ PREVIOUS'
                            itemClassPrev='prev'
                            itemClassNext='next'
                            nextPageText='NEXT ⟩'
                            hideFirstLastPages
                        />
                    </div>

                </div>
            </div>
        </Container>
    )
}
