import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";

import {
  createData,
  getMenuAccessList,
  getMenuList,
  getSingleItemRole,
  updateData,
} from "../role.reducer";
import { CreateRoleModel, RoleModel } from "../role.model";
import RoleForm from "../components/RoleForm";

const RolePage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector((state: RootState) => state.role.single ?? ({} as RoleModel));

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItemRole(id));
      dispatch(getMenuAccessList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getMenuList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (roleName: string, roleId: string, isAll: boolean, menuId: string[]) => {
    if (isEdit) {
      dispatch(
        updateData({
          roleData: {
            roleId: id,
            roleName,
          },
          menuAccessData: {
            roleId,
            isAll,
            menuId,
          },
        } as CreateRoleModel)
      );
    } else {
      dispatch(
        createData({
          roleData: {
            roleName,
          },
          menuAccessData: {
            roleId,
            isAll,
            menuId,
          },
        } as CreateRoleModel)
      );
    }
  };
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <RoleForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RolePage;
