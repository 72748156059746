import { createData, getList } from "../province.reducer";
import ProvinceTable from "../components/provinceTable";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { RootState } from "../../../../app/store";
import { ProvinceModel } from "../province.model";

const ProvinceTablePage = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const State = useSelector((state: RootState) => state.province.name);

  const singleDataState = useSelector(
    (state: RootState) => state.province.single ?? ({} as ProvinceModel)
  );

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  const onSubmit = (name: string, no: number, countryId: string, isActive: boolean) => {
    dispatch(
      createData({
        name,
        no,
        countryId,
        isActive,
      } as ProvinceModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <ProvinceTable data={singleDataState} onSubmit={onSubmit} isEdit={isEdit} />
        </Col>
      </Row>
    </Container>
  );
};

export default ProvinceTablePage;
