import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'
export const getAutoCancelKeepList = createAsyncThunk('post/autoCancelKeep', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportAutoCancelKeep`, body)
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

