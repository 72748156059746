import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getPartnerTypeEnum, setSelectedRoleList } from "../voucher.reducer";
import { VoucherModel } from "../voucher.model";

const RoleForm = (  ) => {
  const dispatch = useDispatch();

  const roleListState = useSelector(
    (state: RootState) => state.global.listRole ?? []
  );

  const partnerTypeEnumListState = useSelector(
    (state: RootState) => state.voucher.partnerTypeEnum ?? []
  );

  const singleDataState = useSelector(
    (state: RootState) => state.voucher.single ?? ({} as VoucherModel)
  );

  const [userId, setUserId] = useState<Array<string>>([]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      console.log(singleDataState)
      var array = singleDataState.partnerType.split(",");
      setUserId(array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getPartnerTypeEnum());
  }, []);

  console.log(userId)

  const onCheck = (item: string) => {
    let newArr = [];
    if (!userId.includes(item)) {
      newArr = [...userId, item];
    } else {
      newArr = userId.filter((a) => a !== item);
    }
    setUserId(newArr);
    // dispatch(setSelectedRoleList(newArr.toString()));
  };

  useEffect(() => {
    if(userId.length !== 0){
      dispatch(setSelectedRoleList(userId.toString()));
    }
  }, [userId])
  

  console.log(singleDataState)
  console.log(partnerTypeEnumListState)

  return (
    <div>
      <Grid textAlign="center">
        <Card>
          <Card.Body>
            <Form>
              <section>
                <h6 className="input-padding">Pengguna / Role</h6>
                <hr />
                <Form.Group as={Row} className="input-padding">
                  <Col sm={8}>
                    {partnerTypeEnumListState.map((item) => {
                      return (
                        <Form.Check
                          label={item.name}
                          name="group1"
                          type="checkbox"
                          value={item.id.toString()}
                          onClick={() => onCheck(item.id.toString())}
                          checked={
                            userId.some((val) => val === item.id.toString())
                          }
                          
                          // defaultChecked
                        />
                      );

                      // {roleListState.map((item) => {
                      // if (userId?.some((i) => i === item.id)) {
                      //   return (
                      //     <Form.Check
                      //       label={item.roleName}
                      //       name="group1"
                      //       type="checkbox"
                      //       value={item.id}
                      //       onClick={() => onCheck(item.id)}
                      //       defaultChecked
                      //     />
                      //   );
                      // } else {
                      //   return (
                      //     <Form.Check
                      //       label={item.roleName}
                      //       name="group1"
                      //       type="checkbox"
                      //       value={item.id}
                      //       onClick={() => onCheck(item.id)}
                      //     />
                      //   );
                      // }
                    })}
                  </Col>
                </Form.Group>
              </section>
            </Form>
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export default RoleForm;
