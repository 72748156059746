import React, { ChangeEvent, useEffect, useState } from "react";
import { Form, Button, Col, Jumbotron, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../app/store";
import { ConfirmPasswordModel } from "../ConfirmPasswordModel";
import checklist from "./../../../assets/checklist.png";
import logo from "./../../../assets/logo.png";
import AlertMessage from "../../../components/alertMessage";
import loginPanel from './../../../assets/login-panel.png';

import "./style.css";
import { resetStatus } from "../confirmPassword.reducer";

const ConfirmPasswordForm = ({
  // data = {} as ConfirmPasswordModel,
  ...props
}: ConfirmPasswordFormProps) => {
  let history = useHistory();

  const dispatch = useDispatch();

  const [confirmPassword, setconfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const statusState = useSelector(
    (state: RootState) => state.changePassword.status ?? ""
  );

  const onAlertClose = () => {
    
    if (statusState === "success") {
      dispatch(resetStatus());
      history.replace("/login");
    } else {
      dispatch(resetStatus());
    }

    setShowAlert(false);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setconfirmPassword(e.target.value);
  };

  const onConfirmPasswordClick = () => {
    if (password === confirmPassword && password !== "") {
      props.onSubmit(password, confirmPassword);
      setPassword("");
      setconfirmPassword("");
    } else {
      setAlertProps({
        variant: "failed",
        title: "",
        content: "Password dan Confirm Password tidak sesuai",
      });
      setShowAlert(true);
    }
  };

  // useEffect(() => {
  //   setPassword(data.password);
  //   setconfirmPassword(data.confirmPassword);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  useEffect(() => {
    if (statusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil merubah password",
        content: " ",
      });
      setShowAlert(true);
    }else {

    }
  }, [statusState]);

  return (
    <Form
      // noValidate
      // validated={validated}
      // onSubmit={(e) => {
        // const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //   e.preventDefault();
        //  e.stopPropagation();
        // } else {
        //   e.preventDefault();
        //   props.onSubmit(password, confirmPassword);
        //   setPassword("");
        //   setconfirmPassword("");
        //   // history.replace("/login");
        // }
        // setValidated(true);
      // }}
    > 
      <Row style={{ marginRight: 0 }}>
        {/* <Col>
          <Jumbotron fluid className="login-container"></Jumbotron>
        </Col> */}
        <Col className="background-login">
          <div className="center-image" >
            <img src={loginPanel} alt="image" style={{ width:"60vh" }}></img>   
          </div> 
        </Col>
        <Col>
          <div className="vertical-center">
            <img src={logo} alt="Logo" className="logo-img" />

            <Form.Group>
              <Form.Label className="title-text">Reset Password</Form.Label>
            </Form.Group>

            <Form.Group>
              <Form.Label className="subtitle-text">
                Your new password must be different from previous used
                passwords.
              </Form.Label>
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                onChange={onPasswordChange}
                value={password}
                required
                placeholder="Enter password"
                autoComplete="off"
              />
              <Form.Label className="notif-text">
                <img
                  src={checklist}
                  alt="Logo"
                  className="checklist-icon"
                ></img>{" "}
                Must be at least 8 characters.
              </Form.Label>
              <br />
              <Form.Label className="notif-text">
                <img
                  src={checklist}
                  alt="Logo"
                  className="checklist-icon"
                ></img>{" "}
                Must contain 1 Uppercase letter and numeric
              </Form.Label>
            </Form.Group>

            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                className="label-margin"
                onChange={onConfirmPasswordChange}
                value={confirmPassword}
                required
                type="text"
                placeholder="Confirm Password"
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Both passwords must match.</Form.Label>
            </Form.Group>

            <Button
              className="btn-login primary-btn"
              onClick={onConfirmPasswordClick}
            >
              Reset Password
            </Button>
          </div>
        </Col>
      </Row>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </Form>
  );
};

export interface ConfirmPasswordFormProps {
  onSubmit: (password: string, confirmPassword: string) => void;
  // data: ConfirmPasswordModel;
}
export default ConfirmPasswordForm;
