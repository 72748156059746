import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
import db from '../../../app/firebaseInit'
import { endpoint } from '../../../constants';
// const userRef = db.collection("USERS");
const messageChat = db.collection("MESSAGE_CHAT");


export const getUserContactAll = createAsyncThunk('get/Partner/GetList', async () => {
    const response = await axios.get(`${endpoint}​/api/Partner/GetListForChat`, {
        // type 0 for Customer,
        // type 1 for Stockist,
        // type 2 for Reseller
        // params: {
        //     type : 2
        // }
    })
    return response.data
})

export const getUserContactReseller = createAsyncThunk('get/Partner/GetListReseller', async () => {
    const response = await axios.get(`${endpoint}​/api/Partner/GetListForChatByType?type=2`, {
    })
    return response.data
})

export const getUserContactStockist = createAsyncThunk('get/Partner/GetListStockist', async () => {
    const response = await axios.get(`${endpoint}​/api/Partner/GetListForChatByType?type=1`, {
    })
    return response.data
})

export const getUserContactCustomer = createAsyncThunk('get/Partner/GetListCustomer', async () => {
    const response = await axios.get(`${endpoint}​/api/Partner/GetListForChatByType?type=0`, {
    })
    return response.data
})

export const postLastMessage = createAsyncThunk('post/postLastMessage', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}​/api/User/UpdateLastMessage/`, null, {
            params: body
        })
        return response.data

    } catch (error: any) {
        console.log(error)
        return error
    }
})

export const sendMessageChatPage = createAsyncThunk('send/message/chatPage', async (data: any) => {
    try {
        const response = await messageChat.doc(uuidv4()).set(data)
        console.log(response, 'success send message')
        return response
    } catch (error) {
        console.log(error)
        return error
    }
})


export const getFirebaseContact = createAsyncThunk('get/firebase/contact', async () => {
    // userRef
    // .orderBy("updated", "desc")
    // .onSnapshot((res)=>{
    //     let data = res.docs.map(doc=>{
    //         return { ...doc.data(), id: doc.id };
    //         // console.log(doc)
    //     })
    //     // setUser(data)
    //     console.table(data)
    // })
})

export const postImageUploadChat = createAsyncThunk('upload/imagesConvert', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/web/api/Upload/UploadImage`, body)
        return response
    }
    catch (error: any) {
        return error.response
    }
})
