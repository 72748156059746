import { ApiErrorResponse } from "../../..";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AddressModel } from "./address.model";
import AddressApi from "./address.api";

export interface AddressSlice {
 singleData : AddressModel;
 isLoading?: boolean;
 error?: ApiErrorResponse<any>;
 status?: string;
}

export const getEmoryAddress = createAsyncThunk(
 'SettingAddressState/getEmoryAddress',
 async (_: string | undefined = undefined, { rejectWithValue }) => {
     try {
         return await AddressApi.getEmoryAddress();
     } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
     }
 }
);

export const updateEmoryAddress = createAsyncThunk(
    'SettingAddressState/updateEmoryAddress',
    async (model : AddressModel, { rejectWithValue }) => {
        try {
            return await AddressApi.updateEmoryAddress(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
   );

const AddressSlice = createSlice({
 name : 'AddressSlice',
 initialState : {} as AddressSlice,
 reducers : {
  resetSingle: (state) => {
   state.singleData = {} as AddressModel
  },
  resetStatus : (state) => {
   state.status = ""
  }
 }, 
 extraReducers : (builder) => {

builder.addCase(getEmoryAddress.pending, (state) => {
   state.isLoading = true;
});
builder.addCase(getEmoryAddress.fulfilled, (state, { payload }) => {
   state.isLoading = false;
   state.singleData = payload.data;
});
builder.addCase(getEmoryAddress.rejected, (state, { payload }) => {
   state.error = payload as ApiErrorResponse<any>;
   state.isLoading = false;
});

builder.addCase(updateEmoryAddress.pending, (state) => {
    state.isLoading = true;
 });
 builder.addCase(updateEmoryAddress.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.status = "success";
 });
 builder.addCase(updateEmoryAddress.rejected, (state, { payload }) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed"
 });

 }
})

export const {resetSingle, resetStatus} = AddressSlice.actions;
export default AddressSlice.reducer;