import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import PesananCashlessApi from "./pesananCashless.api";
import { FilterModel, SalesOrderModel } from "./pesananCashless.model";

export interface PesananCashlessSlice {
  name?: string;
  list?: SalesOrderModel[];
  totalRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const getList = createAsyncThunk(
  "PesananCashlessState/getPesananCashlessList",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await PesananCashlessApi.getList(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

// export const deleteData = createAsyncThunk(
//   "SalesOrderState/deleteSalesOrder",
//   async (id: string, { rejectWithValue }) => {
//     try {
//       return await Api.deleteData(id);
//     } catch (e) {
//       return rejectWithValue(e as ApiErrorResponse<any>);
//     }
//   }
// );

const pesananCashlessSlice = createSlice({
  name: "PesananCashlessState",
  initialState: {} as PesananCashlessSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { resetStatus } = pesananCashlessSlice.actions;
export default pesananCashlessSlice.reducer;
