import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { ProductCategoryModel } from "../productCategory.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { convertBase64 } from "../../../../components/Library/Base64";
import Dropzone from "react-dropzone";
import upload from "./../../../../assets/vector/upload.png";
import { reset, getListParentForDropDown, resetStatus } from "../productCategory.reducer";
import { DragSwitch } from "react-dragswitch";
import "../productCategory.css";
import { TreeSelect } from "antd";
import { endpoint } from "../../../../constants";

const ProductCategoryForm = ({
  data = {} as ProductCategoryModel,
  isEdit = false,
  ...props
}: ProductCategoryFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  let createStatus = useSelector((state: RootState) => state.productCategory.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.productCategory.error ?? "");

  const parentState = useSelector((state: RootState) => state.productCategory.parentList ?? []);

  useEffect(() => {
    dispatch(getListParentForDropDown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [parent, setParent] = useState("");
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [fileFormat, setFileFormat] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(reset());
      dispatch(resetStatus());
      history.replace("/product-category/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onSlugChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSlug(e.target.value);
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onParentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setParent(e.target.value);
  };

  const onImageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setImage(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      // let arr = JSON.stringify(errorMessage);
      // let parse = JSON.parse(arr);
      // let message = parse.data.title.toString()
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "Data Tidak Terisi / Foto Kosong",
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setCode(data.code);
      setName(data.name);
      setSlug(data.slug);
      setDescription(data.description);
      setParent(data.parent);
      setIsActive(data.isActive);
    } else {
      setCode("");
      setName("");
      setSlug("");
      setDescription("");
      setParent("00000000-0000-0000-0000-000000000000");
      setImage("");
      setIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleImage = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImage(result);
      });
    } else {
      console.log("error");
    }
  };

  const onParentChange2 = (e: any) => {
    setParent(e);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">
          {isEdit ? "Edit Data Product Category" : "Add Data End Product Category"}
        </Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div>{isEdit ? "Edit Data End Product Category" : "Add Data End Product Category"}</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  if (code === "") {
                    setCode("00000000-0000-0000-0000-000000000000");
                  }
                  props.onSubmit(
                    code,
                    name,
                    slug,
                    description,
                    parent,
                    image,
                    fileFormat,
                    data.image,
                    isActive
                  );
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Code
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" disabled onChange={onCodeChange} value={code} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onNameChange} value={name} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Slug
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onSlugChange} value={slug} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Parent
                </Form.Label>
                <Col sm={5}>
                  <TreeSelect
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeData={parentState}
                    placeholder="Please select"
                    //treeDefaultExpandAll
                    onChange={onParentChange2}
                  />
                  {/* <Form.Control as="select" onChange={onParentChange} value={parent}>
                    <option value={"00000000-0000-0000-0000-000000000000"}>None</option>
                    {parentState.map((Item) =>{
                      return(
                        <option style={{paddingTop:30}} value={Item.id}>{Item.name}</option>
                      )
                    })}
                  </Form.Control> */}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Description
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    onChange={onDescriptionChange}
                    value={description}
                  />
                </Col>
              </Form.Group>
              {/* <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Image
                </Form.Label>
                <Col sm={2}>
                  <Dropzone onDrop={(acceptedFiles) => handleImage(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone file-upload-frame w-100">
                        <div className="dz-message needsclick" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="text-center file-upload-label">
                            <div>
                              {(image !== "" || Object.keys(data).length !== 0) && (
                                <img
                                  src={
                                    image !== ""
                                      ? image
                                      : endpoint + data.image
                                  }
                                  className="file-upload-image mb3"
                                />
                              )}

                              {image === "" && Object.keys(data).length === 0 && (
                                <>
                                  <div className="file-upload-image mb3" style={{ paddingTop: 50 }}>
                                    <img src={upload} alt="upload" />
                                    <br />
                                    <span style={{ color: "black" }}>Select File</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </Col>
              </Form.Group> */}
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}
              <div className="btn-footer-group">
                <Link to={{ pathname: `/product-category/index` }}>
                  <Button className="btn-cancel" variant="light">
                    Cancel
                  </Button>
                  {"  "}
                </Link>
                <Button variant="info" style={{ width: 130, height: 45 }} type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export interface ProductCategoryFormProps {
  onSubmit: (
    name: string,
    slug: string,
    code: string,
    description: string,
    parent: string,
    image: string,
    fileFormat: string,
    imagePath: string,
    isActive: boolean
  ) => void;
  data: ProductCategoryModel;
  isEdit: boolean;
}

export default ProductCategoryForm;
