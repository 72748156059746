import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ChangeStatusModel, CreateInternalUseModel, CreateInternalUseProductModel, CreateInternalUseWithDetailModel, FilterModel, InternalUseModel, InternalUseProductModel, ProductModel, UpdateInternalUseModel, WarehouseModel } from "./internalUse.model";
import InternalUseApi from "./internalUse.api";

export interface InternalUseSlice {
    name?: string;
    dateModifiedState?: Date,
    list?: InternalUseModel[];
    single?: InternalUseModel;
    generatedInternalUseNumber?: string;
    warehouseList?: WarehouseModel[];
    productByWarehouseList?: ProductModel[];
    internalUseItemList? : InternalUseProductModel[];
    totalRow? : number;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
    internalUseId? : string;
}

export const getInternalUseList = createAsyncThunk(
    'getInternalUseListState/getInternalUseList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await InternalUseApi.getInternalUse();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getInternalUseListWithPaging = createAsyncThunk(
    'getInternalUseListWithPagingState/getInternalUseListWithPaging',
    async (model : FilterModel, { rejectWithValue }) => {
        try {
            return await InternalUseApi.getListWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getInternalUseSingleItem = createAsyncThunk(
    'getInternalUseSingleItemState/getInternalUseSingleItem',
    async (internalUseId: string, { rejectWithValue }) => {
        try {
            return await InternalUseApi.getSingleItem(internalUseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getGeneratedInternalUseNumber = createAsyncThunk(
    'getGeneratedInternalUseNumber/generateInternalUseNumber',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await InternalUseApi.generateInternalUseNumber();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteInternalUse = createAsyncThunk(
    'deleteInternalUseState/deleteInternalUse',
    async (internalUseId: string, { rejectWithValue }) => {
        try {
            return await InternalUseApi.deleteInternalUse(internalUseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getWarehouseList = createAsyncThunk(
    'getWarehouseListState/getWarehouseList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await InternalUseApi.getWarehouseList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const createInternalUse = createAsyncThunk(
    'createInternalUseState/createInternalUse',
    async (model: CreateInternalUseModel, { rejectWithValue }) => {
        try {
            return await InternalUseApi.createInternalUse(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const createInternalUseWithDetail = createAsyncThunk(
    'createInternalUseWithDetailState/createInternalUseWithDetail',
    async (model: CreateInternalUseWithDetailModel, { rejectWithValue }) => {
        try {
            return await InternalUseApi.createInternalUseWithDetail(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updateInternalUse = createAsyncThunk(
    'updateInternalUseState/updateInternalUse',
    async (model: CreateInternalUseModel, { rejectWithValue }) => {
        try {
            return await InternalUseApi.updateInternalUse(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const changeStatusInternalUse = createAsyncThunk(
    'changeStatusInternalUseState/changeStatusInternalUse',
    async (model: ChangeStatusModel, { rejectWithValue }) => {
        try {
            return await InternalUseApi.changeStatusInternalUse(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const createInternalUseProduct = createAsyncThunk(
    'createInternalUseProductState/createInternalUseProduct',
    async (model: CreateInternalUseProductModel, { rejectWithValue }) => {
        try {
            return await InternalUseApi.createInternalUseProduct(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getProductListByWarehouse = createAsyncThunk(
    'getProductListByWarehouseState/getProductListByWarehouse',
    async (warehouseId: string, { rejectWithValue }) => {
        try {
            return await InternalUseApi.getProductListByWarehouse(warehouseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getProductListByInternalUse = createAsyncThunk(
    'getProductListByInternalUseState/getProductListByInternalUse',
    async (internalUseId: string, { rejectWithValue }) => {
        try {
            return await InternalUseApi.getProductListByInternalUse(internalUseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getListInternalUseProductByInternalUse = createAsyncThunk(
    'getListInternalUseProductByInternalUseState/getListInternalUseProductByInternalUse',
    async (internalUseId: string, { rejectWithValue }) => {
        try {
            return await InternalUseApi.getListInternalUseProductByInternalUse(internalUseId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteInternalUseProduct = createAsyncThunk(
    'deleteInternalUseProductState/deleteInternalUseProduct',
    async (internalUseProductId: string, { rejectWithValue }) => {
        try {
            return await InternalUseApi.deleteInternalUseProduct(internalUseProductId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const InternalUseSlice = createSlice({
    name: 'InternalUseState',
    initialState: {} as InternalUseSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        resetSingle: (state) => {
            state.single = {} as InternalUseModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        },
        resetInternalUseProductItem: (state) => {
            state.internalUseItemList = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createInternalUse.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(createInternalUse.fulfilled, (state, { payload }) => {
            state.name = new Date().toISOString();
            state.isLoading = false;
            state.status = "success";
            state.internalUseId = payload.id;
        });
        builder.addCase(createInternalUse.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(createInternalUseWithDetail.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(createInternalUseWithDetail.fulfilled, (state, { payload }) => {
            state.name = new Date().toISOString();
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(createInternalUseWithDetail.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(updateInternalUse.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(updateInternalUse.fulfilled, (state, { payload }) => {
            state.name = new Date().toISOString();
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(updateInternalUse.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(changeStatusInternalUse.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(changeStatusInternalUse.fulfilled, (state, { payload }) => {
            state.name = new Date().toISOString();
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(changeStatusInternalUse.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(getInternalUseList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getInternalUseList.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getInternalUseList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getInternalUseListWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getInternalUseListWithPaging.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getInternalUseListWithPaging.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getInternalUseSingleItem.pending, (state) => {
            state.single = undefined;
            state.isLoading = true;
        });
        builder.addCase(getInternalUseSingleItem.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.isLoading = false;
            state.internalUseId = payload.id;
        });
        builder.addCase(getInternalUseSingleItem.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(deleteInternalUse.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteInternalUse.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.name = "";
            state.status = "success-delete";
        });
        builder.addCase(deleteInternalUse.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = "failed-delete";
        });
        builder.addCase(getWarehouseList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWarehouseList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.warehouseList = payload;
        });
        builder.addCase(getWarehouseList.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getGeneratedInternalUseNumber.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getGeneratedInternalUseNumber.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.generatedInternalUseNumber = payload;
        });
        builder.addCase(getGeneratedInternalUseNumber.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getProductListByInternalUse.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProductListByInternalUse.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.name = "";
        });
        builder.addCase(getProductListByInternalUse.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getProductListByWarehouse.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProductListByWarehouse.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.productByWarehouseList = payload;
        });
        builder.addCase(getProductListByWarehouse.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getListInternalUseProductByInternalUse.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListInternalUseProductByInternalUse.fulfilled, (state, { payload }) => {
            state.internalUseItemList = payload;
            state.isLoading = false;
        });
        builder.addCase(getListInternalUseProductByInternalUse.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(createInternalUseProduct.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(createInternalUseProduct.fulfilled, (state, { payload }) => {
            state.name = new Date().toISOString();
            state.isLoading = false;
            state.status = "success-add-product";
        });
        builder.addCase(createInternalUseProduct.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed-add-product";
        });
        builder.addCase(deleteInternalUseProduct.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(deleteInternalUseProduct.fulfilled, (state, { payload }) => {
            state.name = new Date().toISOString();
            state.isLoading = false;
            state.status = "success-delete-product";
        });
        builder.addCase(deleteInternalUseProduct.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed-delete-product";
        });
        
    }
})

export const { setFilter, resetSingle, resetStatus, resetInternalUseProductItem } = InternalUseSlice.actions;
export default InternalUseSlice.reducer;