import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";

const SettingImageForm = ({ type = 0, ...props }) => {

  const [imageSetting, setImageSetting] = useState(0);

  const ImageSettingListState = useSelector(
    (state: RootState) => state.settingImage.imageSettingList ?? []
  )

  const ImageSettingData = ImageSettingListState.filter(
    (item) => item.type === type
  )[0];

  const onSaveClick = () => {
    props.onUpdate(
      ImageSettingData.id,
      ImageSettingData.type,
      ImageSettingData.titleHowToOrder,
      imageSetting,
      ImageSettingData.titleBroadcastTheme,
      ImageSettingData.toBroadcastTheme,
      ImageSettingData.messageBroadcastTheme,
      ImageSettingData.linkIOSStockist,
      ImageSettingData.linkAndroidStockist,
      ImageSettingData.linkIOSReseller,
      ImageSettingData.linkAndroidReseller,
      ImageSettingData.linkIOSEndCustomer
    )
  }

  useEffect(() => {
    if (
      ImageSettingData &&
      Object.keys(ImageSettingData).length !== 0 &&
      ImageSettingData.constructor == Object
    ) {
      setImageSetting(ImageSettingData.imageSetting);
    }
  }, [ImageSettingListState]);

  return (
    <div>
      <br />

      <hr />

      <Form.Group as={Row} className="input-padding">
        <Form.Label column sm={4}>
          Image Setting
        </Form.Label>
        <Col>
          <Form>
            {/* <Form.Row> */}

            <Row className="fee-input">
              <Form.Check
                label="Sesuai dengan nama waktu Anda upload"
                name="membershipFee"
                type="radio"
                value={0}
                checked={imageSetting === 0}
                onChange={() => setImageSetting(0)}
              />
            </Row>

            {/* <Row className="fee-input">
              <Form.Check
                label="Berdasarkan Nama Toko"
                name="membershipFee"
                type="radio"
                value={0}
                checked={imageSetting === 1}
                onChange={() => setImageSetting(1)}
              />
            </Row>

            <Row className="fee-input">
              <Form.Check
                label="Berdasarkan Nama Produk"
                name="membershipFee"
                type="radio"
                value={0}
                checked={imageSetting === 2}
                onChange={() => setImageSetting(2)}
              />
            </Row> */}

            {/* </Form.Row> */}
          </Form>
        </Col>
      </Form.Group>

      <hr />

      <div className="btn-footer-group">
        <Button
          variant="outline-info"
          style={{ width: 130, height: 45 }}
          // onClick={onAddProductClick}
        >
          Cancel
        </Button>
        {"   "}
        <Button
          variant="info"
          style={{ width: 130, height: 45 }}
          type="submit"
          form="faqForm"
          onClick={onSaveClick}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export interface SettingImageFormProps {
  onUpdate : (
    id: string,
    type: number,
    titleHowToOrder: string,
    imageSetting: number,
    titleBroadcastTheme: string,
    toBroadcastTheme: string,
    messageBroadcastTheme: string,
    linkIOSStockist: string,
    linkAndroidStockist: string,
    linkIOSReseller: string,
    linkAndroidReseller: string,
    linkIOSEndCustomer: string
  ) => void;
  type: number;
}

export default SettingImageForm;
