import React, { Fragment } from "react";
import {Spinner} from "react-bootstrap";

const CircleLoader = ({ children, loading, loadingName }: FormLoaderProps) => {
    return (
        <Fragment>
            {loading ? (
                <div className="loading-circle-container">
                    <div className="loading-screen-blur" style={{ height: "100%" }}>
                        <div className="text-center">
                            <Spinner animation="grow" className="loading-screen-blur-spinner" />
                        </div>
                        <div className="text-center">
                            <small>{loadingName}</small>
                        </div>
                    </div>
                    <div>{children}</div>
                </div>
            ) : (
                <div>{children}</div>
            )}
        </Fragment>
    );
};

export interface FormLoaderProps {
    children: React.ReactElement;
    loading?: boolean;
    loadingName?: string;
}

export default CircleLoader;
