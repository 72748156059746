import axios from "axios";
import { endpoint } from "../../constants";

export default class ComplainApi {

    static getComplainList(data: any) {
        return axios
            .post<any>(endpoint + "/api/Complaint/GetListWithFilter", data)
            .then((res) => res.data.data);
    }

    static getComplainDetail(id: string) {
        return axios
            .get<any>(endpoint + "/api/Complaint/GetSingleItem?id=" + id)
            .then((res) => res.data.data);
    }

    static updateComplain(data: any) {
        return axios
            .post<any>(endpoint + "/api/Complaint/Update", data
            )
            .then((res) => res.data.data);
    }

    static getSalesOrderList() {
        return axios
            // .get<any>(endpoint + "​/api/SalesOrderDetail/GetList")
            .get<any>(endpoint + "/api/SalesOrder/GetListDelivered")
            .then((res) => res.data.data);
    }

    static getItemDetailBySONumber(soNumber: string) {
        return axios
            .get<any>(endpoint + "/api/Complaint/GetItemDetailBySONumber?SONumber=" + soNumber)
            .then((res) => res.data.data);
    }

    static createComplain(data: any) {
        return axios
            .post<any>(endpoint + "/api/Complaint/Create", data
            )
            .then((res) => res.data.data);
    }

    static getWarehouseList() {
        return axios
            .get<any>(endpoint + "/api/Warehouse/GetList")
            .then((res) => res.data.data);
    }

    static getListProduct() {
        return axios
            .get<any>(endpoint + "/api/Product/GetList")
            .then((res) => res.data.data);
    }

    static getListVariationByProduct(id: string) {
        return axios
            .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
            .then((res) => res.data.data);
    }

    static getRequestedSONumber() {
        return axios
            .get<any>(endpoint + "/api/SalesOrder/GetListSOInComplaintRetur")
            .then((res) => res.data.data);
    }

}