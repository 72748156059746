import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel, UpdateExpeditionReportRequestModel } from "./laporanEkspedisi.model";
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';

export default class Api {

 static getListWithPaging(model: FilterModel) {
  return axios
  .post<any>(endpoint + "/api/SalesOrder/GetExpeditionReport", model)
  .then((res) => res.data.data);
}

static updateExpeditionReport(model: UpdateExpeditionReportRequestModel) {
  return axios
  .post<any>(endpoint + "/api/SalesOrder/UpdateReportExpedition?"
  +"salesOrderId="+model.salesOrderId+"&"
  +"paymentStatus="+model.paymentStatus+"&"
  +"expeditionNote="+model.expeditionNote)
  .then((res) => res.data.data);
}

static exportToExcel(model: FilterModel){
  return axios
  .post<any>(endpoint+ "/api/SalesOrder/GetExpeditionReportExportToExcel", model,  { responseType: "blob" })
            .then((res: any) => {
                console.log(res)
                const url = URL.createObjectURL(new Blob([res.data]));
                console.log("url : " + url)
                const link = document.createElement('a');                
                link.href = url;
                link.setAttribute('download', `Laporan Pelunasan - ${model.reportType === 0 ? 'harian' : 'bulanan'} ${moment(new Date(model.filterMonthly)).format('DD-MMM-YYYY')}.xlsx`);
                document.body.appendChild(link);

                console.log("link : " + link)
           
                link.click();
            })
            .catch((err: any) => console.log(err))
}
 
}