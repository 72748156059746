import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import "react-phone-number-input/style.css";
import { DragSwitch } from "react-dragswitch";
import AlertMessage from "../../../components/alertMessage";

import { useDispatch, useSelector } from "react-redux";
import {
  getStockistList
} from "../../registerStockist/registerStockist.reducer";
import { setUsername } from "../../login/user.reducer";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AdminStockistModel } from "../adminStockist.model";
import { resetSingle, resetStatus } from "../adminStockist.reducer";

const AdminStockistCreate = ({
  data = {} as AdminStockistModel,
  isEdit = false,
  ...props
}: AdminStockistFormProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const stockistList = useSelector(
    (state: RootState) => state.adminStockist.stockistList ?? []
  );

  const statusState = useSelector(
    (state: RootState) => state.adminStockist.status ?? ""
  );

  const [stockistId, setStockistId] = useState("");
  const [adminName, setAdminName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [isSerial, setIsSerial] = useState(true);
  const [isUnit, setIsUnit] = useState(true);
  const [status, setStatus] = useState(true);

  const [passwordShown, setPasswordShown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    dispatch(getStockistList());
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setAdminName(data.adminName);
      setEmail(data.email);
      setPhoneNumber(data.phoneNumber);
      setStockistId(data.partnerId);
      setIsSerial(data.isSerial);
      setIsUnit(data.isUnit);
      setStatus(data.isActive);
    } else {
    }
  }, [data]);

  useEffect(() => {
    if (statusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Data Admin Stockist sudah tersimpan.",
        content: " ",
      });
      setShowAlert(true);
    } else if (statusState === "success-update") {
      setAlertProps({
        variant: "success",
        title: "Data Admin Stockist sudah terupdate.",
        content: " ",
      });
      setShowAlert(true);
    }
  }, [statusState]);

  const onStockistChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStockistId(e.target.value);
  };

  const onAdminNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAdminName(e.target.value);
  };

  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setConfirmPassword(e.target.value);
  };

  const onPincodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPinCode(e.target.value);
  };

  const onPhoneNumberChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPhoneNumber(e.target.value);
  };

  const togglePasswordInvisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  const onAlertClose = () => {
    setShowAlert(false);
    resetForm();
    history.replace("/admin-stockist/index");
  };

  const resetForm = () => {
    setAdminName("")
    setPhoneNumber("")
    setStockistId("") 
    setStatus(false);
    setIsSerial(true)
    setIsUnit(true)
    dispatch(resetSingle());
    dispatch(resetStatus());  
  }

  return (
    <div>
      <Button
        className="float-right"
        style={{ width: 160, marginLeft: 10 }}
        variant="info"
        type="submit"
        form="adminStockistForm">
        Save
      </Button>{" "}
      <Button
        className="float-right"
        style={{ width: 130, marginLeft: 10 }}
        variant="outline-info"
        onClick={() => {
          resetForm();
          history.goBack()
        }    }>
        Cancel
      </Button>{" "}
      <Form.Group>
        <Form.Label className="title">Admin Stockist</Form.Label>
      </Form.Group>
      <Grid>
        <Card>
          <Card.Body>
            {isEdit ? (
              <b>Edit Data Stockist Admin</b>
            ) : (
              <b>Add Data Stockist Admin</b>
            )}

            <Form
              noValidate
              id="adminStockistForm"
              onSubmit={(e) => {
                e.preventDefault();
                props.onSubmit(
                  stockistId,
                  adminName,
                  isSerial,
                  isUnit,
                  phoneNumber,
                  data?.userId,
                  email,
                  password,
                  pinCode,
                  status,
                );
              }}>
              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={4}>
                  Nama Stockist
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={onStockistChange}
                    value={stockistId}>
                    <option>Pilih Stockist</option>
                    {stockistList.map((item) => {
                      return <option value={item.id}>{item.fullName}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={4}>
                  Nama Admin
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Nama Admin"
                    type="text"
                    onChange={onAdminNameChange}
                    value={adminName}
                    required
                  />
                </Col>
              </Form.Group>

              <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Email
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        onChange={onEmailChange}
                        value={email}
                        autoComplete="off"
                        readOnly={isEdit}
                        required
                      />
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Username
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Username"
                        autoComplete="off"
                        type="text"
                        onChange={onUserNameChange}
                        value={userName}
                        readOnly={isEdit}
                        required
                      />
                    </Col>
                  </Form.Group>

                  <hr />
           {!isEdit ? (
               <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Password
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <Form.Control
                          type={passwordShown ? "text" : "password"}
                          onChange={onPasswordChange}
                          value={password}
                          required
                          placeholder="Password"
                          autoComplete="off"
                        />
                        <InputGroup.Prepend className="toggle-eye-icon">
                          <InputGroup.Text
                            style={{
                              backgroundColor: "#fff",
                              color: "#F5B95D",
                              border: 0,
                            }}
                            onClick={togglePasswordInvisibility}>
                            {passwordShown ? <FaEye /> : <FaEyeSlash />}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Confirm Password
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <Form.Control
                          type={passwordShown ? "text" : "password"}
                          onChange={onConfirmPasswordChange}
                          value={confirmPassword}
                          required
                          placeholder="Confirm Password"
                        />
                        <InputGroup.Prepend className="toggle-eye-icon">
                          <InputGroup.Text
                            style={{
                              backgroundColor: "#fff",
                              color: "#F5B95D",
                              border: 0,
                            }}
                            onClick={togglePasswordInvisibility}>
                            {passwordShown ? <FaEye /> : <FaEyeSlash />}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      </InputGroup>
                      {password !== confirmPassword ? (
                        <Form.Label className="card-subtitle">
                          Both password must match
                        </Form.Label>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      User PIN code
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        onChange={onPincodeChange}
                        value={pinCode}
                        required
                        placeholder="PIN"
                      />
                    </Col>
                  </Form.Group>

                  <hr />
                </>
              ) : (
                <></>
              )}

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={4}>
                  Jenis Varian
                </Form.Label>
                <Col sm={2}>
                  <Form.Check
                    label="Serian"
                    type="checkbox"
                    checked={isSerial}
                    onChange={(e) => {
                      setIsSerial(e.target.checked);
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    label="Satuan"
                    type="checkbox"
                    checked={isUnit}
                    onChange={(e) => {
                      setIsUnit(e.target.checked);
                    }}
                  />
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={4}>
                  Telephone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Telephone"
                    type="text"
                    onChange={onPhoneNumberChange}
                    value={phoneNumber}
                    required
                  />
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={4}>
                Status
              </Form.Label>
              <Col>
                <DragSwitch
                  checked={status}
                  onChange={(e) => {
                    console.log(e);
                    setStatus(e);
                  }}
                />
                <br />
              </Col>
            </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Grid>
      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    </div>
  );
};

export interface AdminStockistFormProps {
  onSubmit: (
    partnerId: string,
    adminName: string,
    isSerial: boolean,
    isUnit: boolean,
    phoneNumber: string,
    userId: string,
    email: string,
    password: string,
    pin: string,
    isActive: boolean
  ) => void;
  data: AdminStockistModel;
  isEdit: boolean;
}

export default AdminStockistCreate;
