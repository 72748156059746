import { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../app/store";
import PenerimaanInventoryTable from "../components/penerimaanInventoryTable";
import { getList, resetSingle } from "../penerimaanInventory.reducer";

const PenerimaanInventoryIndex = () => {
  const dispatch = useDispatch();

  const modifiedDateState = useSelector(
    (state: RootState) => state.penerimaanInventory.modifiedDate ?? ""
  );

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDateState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Penerimaan Barang Langsung</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/penerimaan-inventory/create",
            }}
          >
            <Button className="float-right" style={{ width: 230 }} variant="info">
              + Add Penerimaan Inventory
            </Button>
          </Link>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={12}>
          <PenerimaanInventoryTable />
        </Col>
      </Row>
    </Container>
  );
};

export default PenerimaanInventoryIndex;
