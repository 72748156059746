import React, { Component, CSSProperties, HTMLAttributes } from 'react';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {}

const defaulBGColor = '#fff';
const defaultFontColor = '#fff';

const defaultHeaderStyle: CSSProperties = {
  backgroundColor: defaulBGColor,
  font: defaultFontColor,
};

export default class Header extends Component<HeaderProps> {
  render() {
    return (
      <div className={`admin-header ${this.props.className || ''}`} style={this.props.style || {}}>
        <div className="admin-header__content" style={{ ...defaultHeaderStyle }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
