import { createSlice } from '@reduxjs/toolkit'
import { getAllCustomerFilterAutoCancel, postExportAutoCancel, postAutoCancelReport } from './AutoCancelReport.api'

export interface AutoCancelReportState {
  loading : boolean,
  listCustomerFilterAutoCancel : any,
}

const initialState: AutoCancelReportState = {
  loading : false,
  listCustomerFilterAutoCancel : []
}

export const AutoCancelReportSlice = createSlice({
    name: 'laporan autocancel',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {

      builder.addCase(getAllCustomerFilterAutoCancel.pending, (state, action) => {
          state.loading = true
        })

        builder.addCase(getAllCustomerFilterAutoCancel.fulfilled, (state, action) => {
            state.loading = false
            state.listCustomerFilterAutoCancel = action.payload.data
        })

        builder.addCase(getAllCustomerFilterAutoCancel.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(postExportAutoCancel.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postExportAutoCancel.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postExportAutoCancel.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(postAutoCancelReport.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postAutoCancelReport.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postAutoCancelReport.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default AutoCancelReportSlice.reducer