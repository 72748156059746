import { Card, Form, Row } from "react-bootstrap";
import { Tree } from "antd";
import "../product.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useEffect, useState } from "react";
import { setProductCategoriesTempForm } from "../product.reducer";
import { ProductModel } from "../product.model";

const ProductionCategoriesForm = ({
  data = {} as ProductModel,
  isEdit = false,
  ...props
}: TabsTypeProps) => {
  const dispatch = useDispatch();

  const productCateoriesState = useSelector(
    (state: RootState) => state.product.listProductCategories ?? []
  );

  const [productCategoriesTempArray, setProductCategoriesTempArray] = useState<
    Array<string>
  >([]);

  const onCheck = (checkedKeys: any, info: any) => {
    console.log("onCheck", checkedKeys.checked);
    let array = checkedKeys.checked;
    setProductCategoriesTempArray(array);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setProductCategoriesTempArray(data.productCategoryList);
    }

    // else {
    //   setProductCategoriesTempArray([]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(setProductCategoriesTempForm(productCategoriesTempArray));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCategoriesTempArray]);

  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Group as={Row}>
            <Form.Label
              style={{ fontWeight: 610, fontSize: 17, paddingTop: 0 }}
              column
              sm={12}
            >
              Product Categories
            </Form.Label>
          </Form.Group>
          <hr />
          <Form>
            <section>
              <Tree
                checkedKeys={productCategoriesTempArray}
                checkable
                checkStrictly={true}
                onCheck={onCheck}
                treeData={productCateoriesState}
              />
            </section>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export interface TabsTypeProps {
  data: ProductModel;
  isEdit: boolean;
}

export default ProductionCategoriesForm;
