import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import SettingExpeditionApi from "./SettingExpedition.api";
import { SettingExpeditionModel, UpdateSettingExpeditionModel } from "./SettingExpedition.model";

export interface SettingExpeditionSlice {
  resellerExpeditionList?: SettingExpeditionModel[];
  stockistExpeditionList?: SettingExpeditionModel[];
  endCustomerExpeditionList?: SettingExpeditionModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getResellerSettingExpedition = createAsyncThunk(
  "getResellerSettingExpeditionState/getResellerSettingExpedition",
  async (_: string | undefined = undefined,{ rejectWithValue }) => {
    try {
      return await SettingExpeditionApi.getResellerExpeditionSettingList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getStockistSettingExpedition = createAsyncThunk(
  "getStockistSettingExpeditionState/getStocksitSettingExpedition",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await SettingExpeditionApi.getStockistExpeditionSettingList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getEndCustomerSettingExpedition = createAsyncThunk(
  "getEndCustomerSettingExpeditionState/getEndCustomerSettingExpedition",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await SettingExpeditionApi.getEndCustomerExpeditionSettingList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateSettingExpedition = createAsyncThunk(
  "updateSettingExpeditionState/updateSettingExpedition",
  async (model : UpdateSettingExpeditionModel, { rejectWithValue }) => {
    try {
      return await SettingExpeditionApi.updateExpeditionSetting(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const SettingExpeditionSlice = createSlice({
  name: "SettingExpeditionState",
  initialState: {} as SettingExpeditionSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResellerSettingExpedition.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(
      getResellerSettingExpedition.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.resellerExpeditionList = payload;
      }
    );
    builder.addCase(
      getResellerSettingExpedition.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
        state.status = "failed";
      }
    );
    builder.addCase(getStockistSettingExpedition.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(
      getStockistSettingExpedition.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.stockistExpeditionList = payload;
      }
    );
    builder.addCase(
      getStockistSettingExpedition.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
        state.status = "failed";
      }
    );
    builder.addCase(getEndCustomerSettingExpedition.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(
      getEndCustomerSettingExpedition.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.endCustomerExpeditionList = payload;
      }
    );
    builder.addCase(
      getEndCustomerSettingExpedition.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
        state.status = "failed";
      }
    );
    builder.addCase(updateSettingExpedition.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(
      updateSettingExpedition.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.status = "success-update";
      }
    );
    builder.addCase(
      updateSettingExpedition.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
        state.status = "failed";
      }
    );
  },
});

export const { resetStatus } = SettingExpeditionSlice.actions;
export default SettingExpeditionSlice.reducer;
