import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getAllUserFilterPerubahanStock = createAsyncThunk('get/user/All', async () => {
    const response = await axios.get(`${endpoint}/api/User/GetAll`)
    console.log(response)
    return response.data
})

export const postExportPerubahanStock = createAsyncThunk('post/postExportPerubahanStock', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Report/ReportStockMovementExportToExcel`,
            data: body,
            method: 'post',
            responseType: 'blob',
        })
        console.log(response)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postLaporanPerubahanStock = createAsyncThunk('post/postLaporanPerubahanStock', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportStockMovement`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

