import React, {useEffect, useState} from 'react';
import {Grid} from 'semantic-ui-react'
import {Card, Form, Row, Col, Button, InputGroup, FormControl} from 'react-bootstrap';
import {FaSearch} from "react-icons/fa";

import editIcon from '../../../assets/vector/edit.png';
import deleteIcon from '../../../assets/vector/delete.png';

import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {TableCustomStyles} from '../../../components/DataTable/TableCustomStyles';
import {RootState} from '../../../app/store';
import {deleteEvent, getList, resetStatus} from '../event.reducer';
import {EventModel} from '../event.model';
import DeleteMessage from "../../../components/DeleteMessage";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../constants";
import moment from "moment/moment";
import AlertMessage from "../../../components/alertMessage";
import {MembershipStatusEnum, MembershipTierTransaction} from "../../membersipTier/membershipTier.types";


const EventTable = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const eventList = useSelector(
        (state: RootState) => state.event.list ?? []
    );

    let deleteStatus = useSelector((state: RootState) => state.event.deleteStatus ?? "");

    // datatable components
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle] = useState(false);
    const [selectedId, setSelectedId] = useState("")

    //alert
    const [showAlert, setShowAlert] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        variant: "",
        title: "",
        content: "",
    });


    const filteredItems = eventList.filter(
        (item) =>
            item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const onAlertClose = () => {
        setShowAlert(false);
    };

    const columns = [
        {
            name: "NAMA EVENT",
            selector: "name",
            sortable: true,
        },
        {
            name: "TANGGAL MULAI",
            selector: (d: EventModel) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d?.startDate === null ? "-" : moment(d?.startDate).format('DD/MM/YYYY HH:mm')}
                    </p>
                </>
            ),
            sortable: true,
        },
        {
            name: "TANGGAL BERAKHIR",
            selector: (d: EventModel) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d?.endDate === null ? "-" : moment(d?.endDate).format('DD/MM/YYYY HH:mm')}
                    </p>
                </>
            ),
            sortable: true,
        },
        {
            name: "STATUS",
            selector: (d: { isActive: boolean }) => (
                <p style={{margin: 10}}>
                    {d.isActive && (
                        <span className={"custom-badge-success"}>Active</span>
                    )}
                    {!d.isActive && (
                        <span className={"custom-badge-secondary"}>Inactive</span>
                    )}
                </p>
            ),
            sortable: true,
        },
        {
            name: "KUPON REDEEM",
            selector: (d: EventModel) => (
                <>
                    <button
                        style={{
                            margin: 3,
                            width: 70,
                            height: 23,
                            background: "#2ECC71",
                            borderRadius: 5,
                            color: "white",
                        }}
                        onClick={() => {
                            history.push(`/event/redeem/${d.id}`)
                        }}
                    >
                        View
                    </button>
                </>
            ),
        },
        {
            name: "ACTION",
            selector: (d: { id: any, name: any }) => (
                <>
                    <Link
                        to={{
                            pathname: `/event/form/${d.id}`,
                        }}
                    >
                        <img alt={'edit'} src={editIcon}></img>
                    </Link>
                    {'    '}
                    <span
                        onClick={() => {
                            // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                            //   onDelete(d.id);

                            setSelectedId(d.id);
                            setAlertProps({
                                variant: "delete",
                                title: "Menghapus Event",
                                content: "Apakah kamu yakin akan menghapus event ini?",
                            });
                            setShowAlert(true);
                        }}
                    >
            <img alt={'delete'} src={deleteIcon}></img>
          </span>
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeleteData = () => {
        if (selectedId !== undefined) {
            dispatch(deleteEvent(selectedId));
            setShowAlert(false);
        }
    };

    useEffect(() => {
        if (deleteStatus === "success") {
            setAlertProps({
                variant: "success",
                title: "Berhasil menghapus event",
                content: "",
            });
            setShowDeleteAlert(true);
            dispatch(resetStatus());
            dispatch(getList());
        } else if (deleteStatus === "failed") {
            setAlertProps({
                variant: "failed",
                title: "Gagal",
                content: "Terjadi kesalahan saat menghapus data",
            });
            setShowDeleteAlert(true);
            dispatch(resetStatus());
            dispatch(getList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus]);


    return (
        <div>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label className="page-title">Event</Form.Label>
                    </Form.Group>
                </Col>
                <Col>
                    <Link
                        to={{
                            pathname: "/event/form",
                        }}
                    >
                        <Button className="float-right" variant="info">+ Add Event</Button>
                    </Link>
                </Col>
            </Row>

            <Grid textAlign='center' style={{height: '100vh'}}>
                <Card>
                    <Row>
                        <Col md={4}>
                            <InputGroup className="mb-3 padding-filter" style={{width: 330}}>
                                <InputGroup.Prepend className="search-input">
                                    <InputGroup.Text style={{
                                        backgroundColor: '#fff',
                                        color: '#F5B95D',
                                        border: 0
                                    }}><FaSearch/></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    style={{borderLeft: 0}}
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setFilterText(e.target.value)
                                    }}
                                    value={filterText}
                                />
                            </InputGroup>
                        </Col>
                        {/* <Col md={5}>
              <Form.Group as={Row} className="padding-filter mb-3 ">
                <Form.Label column sm={3} style={{fontStyle: "normal", fontWeight: "normal", fontSize: 14}}>
                  User level
                </Form.Label>
                <Col sm={6}>
                  <Form.Control as="select" style={{height: 35}}>
                    <option>select</option>
                  </Form.Control>
                </Col>
                <Col sm={2}>
                    <Button style={{width: 69, height: 35, fontSize: 14 }}  variant="outline-info">Reset</Button>
                </Col>
              </Form.Group>
            </Col> */}
                    </Row>

                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        noHeader
                        customStyles={TableCustomStyles}
                        paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                    />
                </Card>
            </Grid>

            <DeleteMessage
                {...alertProps}
                show={showAlert}
                onAlertCloseAction={onAlertClose}
                onDeleteAction={onDeleteData}
            ></DeleteMessage>

            <AlertMessage
                {...alertProps}
                show={showDeleteAlert}
                onAlertCloseAction={() => setShowDeleteAlert(false)}
            ></AlertMessage>
        </div>
    )
};

export default EventTable;
