import { Button, ButtonGroup, Card, Col, Container, Form, Row, ToggleButton } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReturDetail, updateRetur } from "../Retur.api";
import { RootState } from "../../../app/store";
import { unwrapResult } from '@reduxjs/toolkit';

export default function ReturApproval() {

    const history = useHistory()
    const dispatch = useDispatch()

    const { returDetail } = useSelector((state: RootState) => state.retur)

    const [note, setNote] = useState("")
    const [status, setStatus] = useState(0)

    useEffect(() => {
        let path = window.location.pathname
        let id = path.split('/')[3]
        dispatch(getReturDetail(id))
    }, [])

    useEffect(() => {
        setNote(returDetail?.noteRetur)
        setStatus(returDetail?.status)
    }, [returDetail])

    const handleSave = () => {
        let data = {
            ...returDetail,
            status: status,
            noteRetur: note
        }
        console.log(data)
        dispatch<any>(updateRetur(data))
            .then(unwrapResult)
            .then((res: any) => {
                history.goBack()
            })
    }

    return (
        <Container fluid>
            <Form.Group>
                <Form.Label className="title">Retur - Aproval</Form.Label>
            </Form.Group>
            <Card>
                <Card.Body>
                    <b>
                        Data Retur
                    </b>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                            SO Number
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                placeholder="-"
                                type="text"
                                value={returDetail?.soNumber}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                            Retur Number
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                placeholder="-"
                                type="text"
                                value={returDetail?.number}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                            Date
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                placeholder="-"
                                type="text"
                                value={returDetail?.createdDate}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                            Status
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                placeholder="-"
                                type="text"
                                value={returDetail?.status ? "Active" : "Inactive"}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                            Warehouse
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                placeholder="-"
                                type="text"
                                value={returDetail?.warehouseName}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                            Note
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                placeholder="-"
                                type="area"
                                value={returDetail?.note}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <DataTable
                        noHeader
                        customStyles={TableCustomStyles}
                        columns={[
                            {
                                name: "KODE PRODUK",
                                selector: "productCode",
                                sortable: true,
                            },
                            {
                                name: "PRODUCT NAME",
                                selector: "productName",
                                sortable: true,
                            },
                            {
                                name: "COLOR VARIANT",
                                selector: "variantName",
                                sortable: true,
                            },
                            {
                                name: "QTY",
                                selector: "quantity",
                                sortable: true,
                            },
                            {
                                name: "Note",
                                selector: "name",
                                sortable: true,
                            }
                        ]}
                        data={returDetail?.returDetail}
                        pagination
                        paginationServer
                    />
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <ButtonGroup>
                        {[
                            {
                                id: 2,
                                label: 'Reject',
                                color: 'danger'
                            },
                            {
                                id: 1,
                                label: 'Approve',
                                color: 'success'
                            }
                        ].map((radio) => {
                            return (
                                <ToggleButton
                                    className="toggle-button"
                                    key={radio.id}
                                    type="radio"
                                    variant={'outline-' + radio.color}
                                    name="radio"
                                    value={radio.id}
                                    checked={status === radio.id}
                                    onChange={(e) => setStatus(radio.id)}
                                >
                                    {radio.label}
                                </ToggleButton>
                            )
                        })}
                    </ButtonGroup>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={3}>
                            Note
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                placeholder="-"
                                type="area"
                                multiple
                                value={note}
                                onChange={e => setNote(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <hr />
                    <Row>
                        <Form.Group as={Row} className="input-padding m-3">
                            <Button
                                className="mr-2"
                                variant="outline-info"
                                style={{ width: 130, height: 45 }}
                                type="submit"
                                onClick={() => history.goBack()}
                            >
                                Cancel
                            </Button>
                            {"     "}
                            <Button
                                variant="info"
                                style={{ width: 130, height: 45 }}
                                type="submit"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}