import React, { useEffect, useState } from "react";
import { Grid, ItemExtra } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import Select from "react-select";
import {
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { PartnerOptionModel } from "../../paymentIn/paymentIn.model";
import {
  createPaymentAllocation,
  getAllUnallocatedInvoices,
  getAllUnallocatedPayments,
  GetSingleWithDetail,
  resetPaymentsAndInvoicesList,
  resetStatus,
  resetSingleData,
  documentAction,
} from "../paymentAllocation.reducer";
import { getListPartner } from "../../../../global/global.reducer";
import NumberFormat from "react-number-format";
import {
  PaymentAllocationDetailModel,
  PaymentAllocationModel,
  PaymentAllocationWithDetail,
  UnallocatedInvoiceModel,
  UnallocatedPaymentModel,
} from "../paymentAllocation.model";
import edit from "./../../../../assets/vector/edit.png";
import { AnyAction } from "@reduxjs/toolkit";
import { isTaggedTemplateExpression } from "typescript";
import AlertMessage from "../../../../components/alertMessage";
import moment from "moment";
import { DatePicker } from "antd";

const PaymentAllocationDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { id } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);

  const partnerListState = useSelector(
    (state: RootState) => state.global.listPartner ?? []
  );

  const UnallocatedPaymentsState = useSelector(
    (state: RootState) => state.paymentAllocation.unallocatedPayments ?? []
  );

  const UnallocatedInvoicesState = useSelector(
    (state: RootState) => state.paymentAllocation.unallocatedInvoices ?? []
  );

  const PaymentAllocationStatusState = useSelector(
    (state: RootState) => state.paymentAllocation.status ?? ""
  );

  const PaymentAllocationWithDetailState = useSelector(
    (state: RootState) =>
      state.paymentAllocation.singleWithDetail ??
      ({} as PaymentAllocationWithDetail)
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);
  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [totalAllocationAmount, setTotalAllocationAmount] = useState(0);
  // const [totalChargeAmount, setTotalChargeAmount] = useState(0);
  const [date, setDate] = useState(new Date());
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState(1);
  const [allocationNumber, setAllocationNumber] = useState("");

  const [tempAppliedValue, setTempAppliedValue] = useState(0);

  const [paymentList, setPaymentList] = useState<UnallocatedPaymentModel[]>([]);
  const [invoiceList, setInvoiceList] = useState<UnallocatedInvoiceModel[]>([]);

  const [selectedPayments, setSelectedPayments] = useState<
    UnallocatedPaymentModel[]
  >([]);
  const [selectedInvoices, setSelectedInvoices] = useState<
    UnallocatedInvoiceModel[]
  >([]);

  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<UnallocatedInvoiceModel>({} as UnallocatedInvoiceModel);
  const [selectedPayment, setSelectedPayment] =
    useState<UnallocatedPaymentModel>({} as UnallocatedPaymentModel);
  const [selectedApplied, setSelectedApplied] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);

    if (!PaymentAllocationStatusState.includes("failed")) {
      dispatch(resetSingleData());
      dispatch(resetStatus());
      setIsEdit(false);
      history.goBack();
    }
    dispatch(resetStatus());
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handlePaymentClose = () => {
    setShowPaymentModal(false);
  };

  const onSelectedPartnerChange = (data: any) => {
    setSelectedPartnerId(data.value);
    dispatch(
      getAllUnallocatedPayments({
        partnerId: data.value,
        filter: "",
        skip: 0,
        take: 100,
      })
    );
    dispatch(
      getAllUnallocatedInvoices({
        partnerId: data.value,
        filter: "",
        skip: 0,
        take: 100,
      })
    );
  };

  const handleSelectedPaymentRow = (e: any) => {
    var updatedPaymentList = UnallocatedPaymentsState.map((originalData) => {
      if (e.selectedRows.some((item: any) => item.id === originalData.id)) {
        return paymentList.filter((si: any) => si.id === originalData.id)[0];
      } else {
        return originalData;
      }
    });

    setPaymentList(updatedPaymentList);

    var updatedSelectedPaymentList = e.selectedRows.map((item: any) => {
      return {
        ...item,
        ["applied"]:
          updatedPaymentList.filter((upl) => upl.id === item.id)[0].applied ??
          item.applied,
      };
    }) as UnallocatedPaymentModel[];
    setSelectedPayments(updatedSelectedPaymentList);

    countTotalAmount();
  };

  const handleSelectedInvoiceRow = (e: any) => {
    var updatedInvoiceList = UnallocatedInvoicesState.map((originalData) => {
      if (e.selectedRows.some((item: any) => item.id === originalData.id)) {
        return invoiceList.filter((si: any) => si.id === originalData.id)[0];
      } else {
        return originalData;
      }
    });

    setInvoiceList(updatedInvoiceList);

    var updatedSelectedInvoiceList = e.selectedRows.map((item: any) => {
      return {
        ...item,
        ["applied"]:
          updatedInvoiceList.filter((uil) => uil.id === item.id)[0].applied ??
          item.applied,
      };
    }) as UnallocatedInvoiceModel[];
    setSelectedInvoices(updatedSelectedInvoiceList);

    countTotalAmount();
  };

  const countTotalAmount = () => {
    let invoiceTotal = 0;
    let paymentTotal = 0;

    selectedInvoices.map((item) => {
      invoiceTotal += item.applied;
    });

    selectedPayments.map((item) => {
      paymentTotal += item.applied;
    });

    setTotalAllocationAmount(paymentTotal - invoiceTotal);
  };

  const onEditAppliedSaveClick = () => {
    var updatedInvoiceList = invoiceList.map((item) => {
      if (item.id == selectedInvoice.id) {
        return {
          ...item,
          ["applied"]: selectedApplied,
        };
      } else {
        return item;
      }
    });
    setInvoiceList(updatedInvoiceList);

    var updatedSelectedInvoiceList = selectedInvoices.map((item) => {
      if (item.id === selectedInvoice.id) {
        return {
          ...item,
          ["applied"]: selectedApplied,
        };
      } else {
        return item;
      }
    }) as UnallocatedInvoiceModel[];
    setSelectedInvoices(updatedSelectedInvoiceList);

    countTotalAmount();

    handleClose();
    setSelectedApplied(0);
    setSelectedInvoice({} as UnallocatedInvoiceModel);
  };

  const onEditAppliedPaymentSaveClick = () => {
    var updatedPaymentList = paymentList.map((item) => {
      if (item.id == selectedPayment.id) {
        return {
          ...item,
          ["applied"]: selectedApplied,
        };
      } else {
        return item;
      }
    });
    setPaymentList(updatedPaymentList);

    var updatedSelectedPaymentList = selectedPayments.map((item) => {
      if (item.id === selectedPayment.id) {
        return {
          ...item,
          ["applied"]: selectedApplied,
        };
      } else {
        return item;
      }
    }) as UnallocatedPaymentModel[];
    setSelectedPayments(updatedSelectedPaymentList);

    countTotalAmount();

    handlePaymentClose();
    setSelectedApplied(0);
    setSelectedPayment({} as UnallocatedPaymentModel);
  };

  const onSubmitAllocation = () => {

    if(!isEdit){
      var allocationPaymentDetails = selectedPayments.map((payment) => {
        return {
          ["invoiceId"]: null,
          ["paymentId"]: payment.id,
          ["amount"]: payment.applied,
          ["discountAmount"]: 0,
        } as PaymentAllocationDetailModel;
      });
  
      var allocationDetails: PaymentAllocationDetailModel[] = [];
      allocationDetails = allocationPaymentDetails;
      selectedInvoices.map((invoice) => {
        var newData = {
          ["invoiceId"]: invoice.id,
          ["paymentId"]: null,
          ["amount"]: invoice.applied,
          ["discountAmount"]: 0,
        } as PaymentAllocationDetailModel;
  
        allocationDetails = [...allocationDetails, newData];
      });
  
      var PaymentAllocationCreateModel = {
        date: new Date().toISOString(),
        customerId: selectedPartnerId,
        status: selectedDocumentStatus,
        paymentAllocationDetails: allocationDetails,
      } as PaymentAllocationModel;
  
      // console.log(PaymentAllocationCreateModel);
      dispatch(createPaymentAllocation(PaymentAllocationCreateModel));
    } else {
      dispatch(documentAction({
        id : id,
        status : selectedDocumentStatus
      }))
    }
    
  };

  useEffect(() => {
    dispatch(getListPartner());
    dispatch(resetPaymentsAndInvoicesList());
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true);
      dispatch(GetSingleWithDetail(id));
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      PaymentAllocationWithDetailState.id !== "" &&
      PaymentAllocationWithDetailState.id !== undefined &&
      PaymentAllocationWithDetailState.id !== null
    ) {
      console.log(PaymentAllocationWithDetailState);
      setAllocationNumber(PaymentAllocationWithDetailState.allocationNumber);
      setSelectedPartnerId(PaymentAllocationWithDetailState.partnerId);
      setSelectedDocumentStatus(PaymentAllocationWithDetailState.status);
      setDate(new Date(PaymentAllocationWithDetailState.date));
      setPaymentList(PaymentAllocationWithDetailState.paymentDetail);
      setInvoiceList(PaymentAllocationWithDetailState.invoiceDetail);
    }
  }, [PaymentAllocationWithDetailState]);

  useEffect(() => {
    setInvoiceList(UnallocatedInvoicesState);
  }, [UnallocatedInvoicesState]);

  useEffect(() => {
    setPaymentList(UnallocatedPaymentsState);
  }, [UnallocatedPaymentsState]);

  useEffect(() => {
    countTotalAmount();
  }, [invoiceList]);

  useEffect(() => {
    countTotalAmount();
  }, [paymentList]);

  useEffect(() => {
    countTotalAmount();
  }, [selectedInvoices]);

  useEffect(() => {
    countTotalAmount();
  }, [selectedPayments]);

  useEffect(() => {
    if (PaymentAllocationStatusState == "success-create-payment-allocation") {
      setAlertProps({
        variant: "success",
        title: "Berhasil membuat data alokasi baru",
        content: " ",
      });
      setShowAlert(true);
    } else if (
      PaymentAllocationStatusState == "failed-create-payment-allocation"
    ) {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "Gagal membuat data alokasi.",
      });
      setShowAlert(true);
    } else if (PaymentAllocationStatusState == "success-update-payment-allocation") {
      setAlertProps({
        variant: "success",
        title: "Berhasil update data alokasi baru",
        content: " ",
      });
      setShowAlert(true);
    } else if (
      PaymentAllocationStatusState == "failed-update-payment-allocation"
    ) {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "Gagal update data alokasi.",
      });
      setShowAlert(true);
    }
  }, [PaymentAllocationStatusState]);

  useEffect(() => {
    if (partnerListState.length !== 0) {
      setPartnerList([]);

      partnerListState.map((item) => {
        var partnerTypeString =
          item.type == 0
            ? "Customer"
            : item.type == 1
            ? "Stockist"
            : "Reseller";

        setPartnerList((prevState) => [
          ...prevState,
          {
            value: item.id,
            label:
              item.fullName + " - " + item.email + " - " + partnerTypeString,
            type: item.type,
          },
        ]);
      });
    }
  }, [partnerListState]);

  const paymentColumns = [
    {
      name: "PAYMENT NO.",
      selector: "paymentNumber",
      sortable: true,
    },
    {
      name: "TANGGAL",
      selector: "paymentDateString",
      sortable: true,
    },
    {
      name: "AMOUNT",
      selector: (d: { paymentAmount: number }) => (
        <>
          <NumberFormat
            value={d.paymentAmount}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "APPLIED",
      cell: (row: any) =>
        selectedPayments.some((item) => item.id === row.id) ? (
          <Row>
            <Col sm={10}>
              <NumberFormat
                key={row.id}
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={row.applied}
                disabled
              />
            </Col>
            <Col sm={2}>
              <img
                src={edit}
                onClick={() => {
                  setSelectedApplied(row.applied);
                  setSelectedPayment(row);
                  setShowPaymentModal(true);
                }}
              ></img>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={row.applied}
                disabled
              />
            </Col>
            {/* <Col sm={2}></Col> */}
          </Row>
        ),
    },
    // {
    //   name: "STATUS",
    //   selector: "statusName",
    //   sortable: true,
    // },
  ];

  const invoiceColumns = [
    {
      name: "INVOICE NO.",
      selector: "invoiceNumber",
      sortable: true,
    },
    {
      name: "SO NUMBER",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "TANGGAL",
      selector: (d: { invoiceDate: string }) => (
        <p>
          {d.invoiceDate !== undefined && (
            <span>
              {d.invoiceDate.substr(8, 2)}-{d.invoiceDate.substr(5, 2)}-
              {d.invoiceDate.substr(0, 4)}
            </span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "TOTAL",
      selector: (d: { total: number }) => (
        <>
          <NumberFormat
            value={d.total}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "APPLIED",
      cell: (row: any) =>
        selectedInvoices.some((item) => item.id === row.id) ? (
          <Row>
            <Col sm={10}>
              <NumberFormat
                key={row.id}
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={row.applied}
                disabled
              />
            </Col>
            <Col sm={2}>
              <img
                src={edit}
                onClick={() => {
                  setSelectedApplied(row.applied);
                  setSelectedInvoice(row);
                  setShowModal(true);
                }}
              ></img>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={row.applied}
                disabled
              />
            </Col>
            {/* <Col sm={2}></Col> */}
          </Row>
        ),

      sortable: true,
    },
    // {
    //   name: "STATUS",
    //   selector: "name",
    //   sortable: true,
    // },
  ];

  return (
    <div>
      <Grid>
        <Form.Label>
          <h4>Payment Allocation</h4>
        </Form.Label>
        <Card>
          <Card.Body>
            <Row>
              {isEdit ? (
                <Col md={6}>
                  <Form.Group as={Row} className="input-padding m-1">
                    <Form.Label column sm={4}>
                      No. Alokasi
                    </Form.Label>
                    <Col>
                      <Form.Control
                        placeholder="-"
                        type="text"
                        disabled
                        value={
                          PaymentAllocationWithDetailState.allocationNumber ??
                          "-"
                        }
                        // onChange={(e) => {
                        //   setFilterPaymentInNumber(e.target.value);
                        // }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              ) : (
                <Col md={6}>
                  <Form.Group as={Row} className="input-padding m-1">
                    <Form.Label column sm={4}>
                      Tanggal
                    </Form.Label>
                    <Col>
                      <DatePicker
                        defaultValue={moment(date, "DD-MM-YYYY")}
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY"
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              )}

              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Partner
                  </Form.Label>
                  <Col>
                    {!isEdit ? (
                      <Select
                        // defaultValue={selectedPartnerId}
                        onChange={onSelectedPartnerChange}
                        options={partnerList}
                      />
                    ) : (
                      <Form.Control
                        as="select"
                        value={selectedPartnerId}
                        disabled
                      >
                        {partnerListState.map((Item) => {
                          return (
                            <option value={Item.id}>
                              {Item.fullName} - {Item.email}
                            </option>
                          );
                        })}
                      </Form.Control>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {isEdit ? (
                <>
                  <Col md={6}>
                    <Form.Group as={Row} className="input-padding m-1">
                      <Form.Label column sm={4}>
                        Tanggal
                      </Form.Label>
                      <Col>
                        <DatePicker
                          defaultValue={moment(date, "DD-MM-YYYY")}
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row} className="input-padding m-1">
                      <Form.Label column sm={4}>
                        Status
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            setSelectedDocumentStatus(parseInt(e.target.value));
                          }}
                          value={selectedDocumentStatus}
                        >
                          {/* <option value="0">DRAFT</option> */}
                          <option value="1">COMPLETE</option>
                          <option value="2">VOID</option>
                          {/* <option value="3">CLOSE</option>
                      <option value="4">INVALID</option> */}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            {isEdit ? (
              <DataTable
                columns={paymentColumns}
                data={paymentList}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                noHeader
                customStyles={TableCustomStyles}
              />
            ) : (
              <DataTable
                columns={paymentColumns}
                data={paymentList}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                noHeader
                customStyles={TableCustomStyles}
                selectableRows
                onSelectedRowsChange={handleSelectedPaymentRow}

                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
              />
            )}
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Body>
            {isEdit ? (
              <DataTable
                columns={invoiceColumns}
                data={invoiceList}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                noHeader
                customStyles={TableCustomStyles}
              />
            ) : (
              <DataTable
                columns={invoiceColumns}
                data={invoiceList}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                noHeader
                customStyles={TableCustomStyles}
                selectableRows
                onSelectedRowsChange={handleSelectedInvoiceRow}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
              />
            )}
          </Card.Body>
        </Card>
        <hr />
        <Card>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Total
                  </Form.Label>
                  <Col>
                    <NumberFormat
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      className="form-control"
                      inputMode="numeric"
                      prefix="Rp. "
                      value={totalAllocationAmount}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                {/* <Form.Group as={Row} className="input-padding m-1">
                  <Form.Label column sm={4}>
                    Charge
                  </Form.Label>
                  <Col>
                    <NumberFormat
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      className="form-control"
                      inputMode="numeric"
                      prefix="Rp. "
                      value={totalChargeAmount}
                      disabled
                    />
                  </Col>
                </Form.Group> */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />
        <Row>
          <Col>
            <Link to={{ pathname: `/payment-allocation/index` }}>
              <Button variant="outline-info">CANCEL</Button>
              {"  "}
            </Link>

            <Button
              variant="info"
              style={{ width: 130, height: 45, marginLeft: 10 }}
              type="submit"
              onClick={onSubmitAllocation}
            >
              SAVE
            </Button>
          </Col>
        </Row>
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Edit Applied Amount</div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="input-padding">
            <Form.Label>Invoice Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="-"
              value={selectedInvoice.invoiceNumber}
              disabled
            />
          </Form.Group>
          <Form.Group className="input-padding">
            <Form.Label>SO Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="-"
              value={selectedInvoice.soNumber}
              disabled
            />
          </Form.Group>
          <Form.Group className="input-padding">
            <Form.Label>Total</Form.Label>
            <NumberFormat
              thousandSeparator={"."}
              decimalSeparator={","}
              className="form-control"
              inputMode="numeric"
              prefix="Rp. "
              value={selectedInvoice.total}
              disabled
            />
          </Form.Group>
          <Form.Group className="input-padding">
            <Form.Label>Applied</Form.Label>
            <NumberFormat
              thousandSeparator={"."}
              decimalSeparator={","}
              className="form-control"
              inputMode="numeric"
              prefix="Rp. "
              value={selectedApplied}
              onValueChange={({ floatValue }) => {
                var val = floatValue ?? 0;
                setSelectedApplied(val);
              }}
            />
          </Form.Group>
          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onEditAppliedSaveClick}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showPaymentModal}
        onHide={handlePaymentClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <div style={{ fontWeight: "bold" }}>Edit Applied Amount</div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="input-padding">
            <Form.Label>Payment Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="-"
              value={selectedPayment.paymentNumber}
              disabled
            />
          </Form.Group>
          {/* <Form.Group className="input-padding">
            <Form.Label>SO Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="-"
              value={selectedInvoice.soNumber}
              disabled
            />
          </Form.Group> */}
          <Form.Group className="input-padding">
            <Form.Label>Amount</Form.Label>
            <NumberFormat
              thousandSeparator={"."}
              decimalSeparator={","}
              className="form-control"
              inputMode="numeric"
              prefix="Rp. "
              value={selectedPayment.paymentAmount}
              disabled
            />
          </Form.Group>
          <Form.Group className="input-padding">
            <Form.Label>Applied</Form.Label>
            <NumberFormat
              thousandSeparator={"."}
              decimalSeparator={","}
              className="form-control"
              inputMode="numeric"
              prefix="Rp. "
              value={selectedApplied}
              onValueChange={({ floatValue }) => {
                var val = floatValue ?? 0;
                setSelectedApplied(val);
              }}
            />
          </Form.Group>
          <div className="btn-footer-group float-right">
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onEditAppliedPaymentSaveClick}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </div>
  );
};

export default PaymentAllocationDetail;
