import {useEffect, useState} from "react";
import {Container, Table} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import logo from "../../../assets/logo.png";
import {getSalesOrderSingleDetail} from "../Invoice.reducer";
import {SalesOrderSingleDetailModel} from "../Invoice.model";
import moment from "moment";
import "../../../assets/styles/style-print.css";

export default function InvoicePrintCashless() {
    const dispatch = useDispatch();

    const salesOrderSingleDetail = useSelector(
        (state: RootState) =>
            state.invoice.salesOrderSingleDetail ??
            ({} as SalesOrderSingleDetailModel)
    );

    useEffect(() => {
        let path = window.location.pathname;
        console.log(path);
        let id = path.split("/")[3];
        dispatch(getSalesOrderSingleDetail(id));
        setTimeout(() => {
            window.print();
        }, 1000);
    }, []);

    const moneyParser = (value: any) => {
        if (value) {
            let valueString = value.toString();
            let valueList = valueString.split("");
            let resultList: any = [];
            valueList.reverse().map((c: any, i: number) => {
                resultList.push(c);
                if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
                    resultList.push(".");
                }
            });
            let result = resultList.reverse().join("");
            return "Rp" + result + ",00";
        }
        return value;
    };

    const replaceAddressString = (address: string) => {
        const regexp = /\*/gi;
        let _address: any = [];
        if (address !== undefined) {
            _address = address.split(/[\n,]+/);
            let replacer = _address.map((item: string) => {
                if (item !== "") {
                    const sameStringLen = item.match(regexp)?.length ?? 0;
                    if (sameStringLen > 6) {
                        let _str = item.replace(regexp, "");
                        return _str + "******";
                    } else {
                        return item
                    }
                }
            });
            let final = replacer.filter((item: any) => item !== undefined);
            return final.toString().replaceAll(',', ', ');
        }

        return _address.toString();
    }

    return (
        <div className="print-container">
            {/* <img src={logo} />
      <br /> */}
            <br/>
            <Table size="sm">
                <tr>
                    <td width={120}>Printed By</td>
                    <td>:</td>
                    <td>
                        {JSON.parse(localStorage.getItem("__emoryuser") || "{}").username}
                    </td>
                </tr>

                {salesOrderSingleDetail?.shipment?.status !== 6 &&
                salesOrderSingleDetail?.shipment?.status !== 7 ? (
                    <>
                        <tr>
                            <td>Cetakan ke</td>
                            <td>:</td>
                            <td>{salesOrderSingleDetail?.salesOrder?.countCadangan}</td>
                        </tr>
                    </>
                ) : (
                    <></>
                )}

                <tr>
                    <td>
                        <b>Gudang</b>
                    </td>
                    <td>:</td>
                    <td>
                        {salesOrderSingleDetail.salesOrderDetail
                            ? salesOrderSingleDetail.salesOrderDetail[0].warehouseName
                            : "-"}
                    </td>
                </tr>
                <tr>
                    <td>Tanggal</td>
                    <td>:</td>
                    <td>
                        {moment(
                            new Date(salesOrderSingleDetail?.salesOrder?.orderDate)
                        ).format("DD/MM/YYYY HH:mm")}
                    </td>
                </tr>
                <tr>
                    <td>Nama Pelanggan</td>
                    <td>:</td>
                    <td>{salesOrderSingleDetail?.salesOrder?.customerName}</td>
                </tr>
                <tr>
                    <td>No. Nota</td>
                    <td>:</td>
                    <td>{salesOrderSingleDetail?.salesOrder?.soNumber}</td>
                </tr>
                <tr>
                    <td width={120}>Gudang/QC/ Packing</td>
                    <td>:</td>
                    <td>
                        {salesOrderSingleDetail?.salesOrder?.adminGudang === ""
                            ? "-"
                            : salesOrderSingleDetail?.salesOrder?.adminGudang}{" "}
                        /{" "}
                        {salesOrderSingleDetail?.salesOrder?.adminQC === ""
                            ? "-"
                            : salesOrderSingleDetail?.salesOrder?.adminQC}{" "}
                        /{" "}
                        {salesOrderSingleDetail?.salesOrder?.adminPacking === ""
                            ? "-"
                            : salesOrderSingleDetail?.salesOrder?.adminPacking}
                    </td>
                </tr>

                <tr>
                    <td>Ekspedisi</td>
                    <td>:</td>
                    <td>
                        {" "}
                        {salesOrderSingleDetail?.shipment?.expeditionName}
                        {salesOrderSingleDetail?.salesOrder?.paymentType === 0 ? (
                            <> - CASHLESS</>
                        ) : (
                            <></>
                        )}
                    </td>
                </tr>

                {salesOrderSingleDetail?.salesOrder?.note !== "" &&
                salesOrderSingleDetail?.salesOrder?.note !== null ? (
                    <>
                        <tr>
                            <td>No. Pesanan Marketplace</td>
                            <td>:</td>
                            <td>{salesOrderSingleDetail?.salesOrder?.note.replace(/[\/]([^\/]*)$/, '/ $1')}</td>
                        </tr>
                    </>
                ) : (
                    <></>
                )}

                <tr>
                    <td>
                        <b>Nomor Resi</b>
                    </td>
                    <td>:</td>
                    <td>
                        <b>{salesOrderSingleDetail?.shipment?.resiNumber}</b>
                    </td>
                </tr>

                {/* <td>
          {salesOrderSingleDetail?.salesOrder?.paymentType === 0 ? (
            <>CASHLESS</>
          ) : (
            <></>
          )}
        </td> */}
                <tr>{}</tr>
            </Table>

            <br/>


            <h4>
                <b>Data Belanja</b>
            </h4>

            <div>
                <Table size="sm" bordered>
                    <thead>
                    <tr>
                        <th>SKU</th>
                        <th>ITEM NAME</th>
                        <th>QTY</th>
                    </tr>
                    </thead>
                    <tbody>
                    {salesOrderSingleDetail?.salesOrderDetail?.map((item: any) => (
                        <tr>
                            <td>{item?.inventorySKU}</td>
                            <td>
                                {item?.productName} - {item?.variationName}
                            </td>
                            <td>{item?.quantity}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>


            {/* <Table className="m-0" size="sm" bordered>
        <tr>
          <td>
            <b>Diskon</b>
          </td>
          <td align="right">
            <b>
              {moneyParser(
                salesOrderSingleDetail?.salesOrder?.discount
              )}
            </b>
          </td>
        </tr>
      </Table> */}

            <br/>

            {
                salesOrderSingleDetail?.salesOrder?.paymentType !== 0 && (
                    <>
                        <h4>
                            <b>Data Shipping</b>
                        </h4>
                        <Table className="m-0" size="sm">
                            <tr>
                                <td width={120}>
                                    <b>No. SO</b>
                                </td>
                                <td>:</td>
                                <td>
                                    <b>{salesOrderSingleDetail?.salesOrder?.soNumber}</b>
                                </td>
                            </tr>

                            {salesOrderSingleDetail?.salesOrder?.note !== "" &&
                            salesOrderSingleDetail?.salesOrder?.note !== null ? (
                                <>
                                    <tr>
                                        <td>No. Pesanan Marketplace</td>
                                        <td>:</td>
                                        <td>{salesOrderSingleDetail?.salesOrder?.note.replace(/[\/]([^\/]*)$/, '/ $1')}</td>
                                    </tr>
                                </>
                            ) : (
                                <></>
                            )}

                            <tr>
                                <td>
                                    <b>Dari</b>
                                </td>
                                <td>:</td>
                                <td>
                                    <b>
                                        {salesOrderSingleDetail?.shipment?.senderName === "" ||
                                        salesOrderSingleDetail?.shipment?.senderName === undefined ||
                                        salesOrderSingleDetail?.shipment?.senderName === null
                                            ? "-"
                                            : salesOrderSingleDetail?.shipment?.senderName}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>Kepada</td>
                                <td>:</td>
                                <td>{salesOrderSingleDetail?.shipment?.recipientName}</td>
                            </tr>
                            <tr>
                                <td>No. Telp Penerima</td>
                                <td>:</td>
                                <td>{salesOrderSingleDetail?.shipment?.recipientPhone}</td>
                            </tr>
                            <tr>
                                <td>Alamat Penerima</td>
                                <td>:</td>
                                <td>
                                    <tr>{replaceAddressString(salesOrderSingleDetail?.shipment?.recipientAddress)} </tr>
                                    <tr>{salesOrderSingleDetail?.shipment?.recipientDistrictName === "" ? <></> : <>{salesOrderSingleDetail?.shipment?.recipientDistrictName?.replaceAll(',', ', ')}</>}</tr>
                                    <tr>{salesOrderSingleDetail?.shipment?.recipientCityName === "" ? <></> : <>{salesOrderSingleDetail?.shipment?.recipientCityName?.replaceAll(',', ', ')}</>}</tr>
                                    <tr>{salesOrderSingleDetail?.shipment?.recipientProvinceName === "" ? <></> : <>{salesOrderSingleDetail?.shipment?.recipientProvinceName?.replaceAll(',', ', ')}</>}</tr>
                                </td>
                            </tr>
                            <tr>
                                <td>Kode Pos</td>
                                <td>:</td>
                                <td>{salesOrderSingleDetail?.shipment?.recipientPostalCode}</td>
                            </tr>
                            <tr>
                                <td>Third Party</td>
                                <td>:</td>
                                <td>{salesOrderSingleDetail?.salesOrder?.thirdPartyName}</td>
                            </tr>
                            {
                                salesOrderSingleDetail?.salesOrder?.paymentType !== 0 && (
                                    <tr>
                                        <td>Ekspedisi</td>
                                        <td>:</td>
                                        <td>
                                            {" "}
                                            {salesOrderSingleDetail?.shipment?.expeditionName}
                                            {salesOrderSingleDetail?.salesOrder?.paymentType === 0 ? (
                                                <> - CASHLESS</>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td>
                                    <b>Nomor Resi</b>
                                </td>
                                <td>:</td>
                                <td>
                                    <b>{salesOrderSingleDetail?.shipment?.resiNumber}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Ongkos Kirim</b>
                                </td>
                                <td>:</td>
                                <td>
                                    <b>
                                        {salesOrderSingleDetail?.shipment?.totalShippingCost === 0 ? (
                                            <>CASHLESS</>
                                        ) : (
                                            moneyParser(salesOrderSingleDetail?.shipment?.totalShippingCost)
                                        )}
                                    </b>
                                </td>
                            </tr>
                        </Table>
                    </>
                )
            }
        </div>
    );
}
