import "./styles.css";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {
  checkPin,
  getUserList,
  getUserListWithPaging,
  getUserRole,
  resetCheckPinStatus,
} from "../user.reducers";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import edit from "./../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import { RootState } from "../../../app/store";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { checkPinModel, RoleModel } from "../models";
import { Grid } from "semantic-ui-react";
import { Select } from "antd";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const UserTable = () => {
  let history = useHistory();

  const dispatch = useDispatch();

  const userId = useSelector((state: RootState) => state.user.id ?? "");

  const userState = useSelector(
    (state: RootState) => state.account.userlist ?? []
  );

  const checkPinStatusState = useSelector(
    (state: RootState) => state.account.checkPinStatus ?? ""
  );

  const userRoleState = useSelector(
    (state: RootState) => state.account.roleList ?? ([] as RoleModel[])
  );

  const totalRowState = useSelector(
    (state: RootState) => state.account.totalRow ?? 0
  );

  const [filterUsername, setFilterUsername] = useState("");
  const [filterFullName, setFilterFullName] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterRoleId, setFilterRoleId] = useState<string | null>(null);
  const [filterTempRoleId, setFilterTempRoleId] = useState("");

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const [filterText, setFilterText] = useState("");
  const [show, setShow] = useState(false);
  const [pin, setPin] = useState("");
  const [note, setNote] = useState("");
  const [selectedRowId, setselectedRowId] = useState("");
  const [checkPinStatus, setCheckPinStatus] = useState(true);
  const [validated, setValidated] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const onPinChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value);
  };

  // datatable components
  const filteredItems = userState.filter(
    (item) =>
      (item.code &&
        item.code.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.username &&
        item.username.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
  );

  const onFilterRoleIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterRoleId(e.target.value);
    setFilterTempRoleId(e.target.value);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterCode("");
    setFilterUsername("");
    setFilterEmail("");
    setFilterFullName("");
    setFilterRoleId(null);
    setFilterTempRoleId("");

    dispatch(
      getUserListWithPaging({
        userName: "",
        fullName: "",
        email: "",
        code: "",
        roleId: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);
    dispatch(getUserRole());
    dispatch(
      getUserListWithPaging({
        userName: filterUsername,
        fullName: filterFullName,
        email: filterEmail,
        code: filterCode,
        roleId: filterRoleId,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [userState]);

  useEffect(() => {
    fetchData(0, perPage);
    // dispatch(getListCity());
  }, []);

  const columns = [
    {
      name: "ID",
      selector: "code",
      sortable: true,
    },
    {
      name: "NAME",
      selector: "username",
      sortable: true,
    },
    {
      name: "USER FULL NAME",
      selector: "name",
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: "email",
      sortable: true,
    },
    {
      name: "ROLE",
      selector: "roleName",
      sortable: true,
    },
    // {
    //   name: "PINCODE",
    //   selector: "pinCode",
    //   sortable: true,
    // },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && (
            <span className={"custom-badge-success"}>Active</span>
          )}
          {d.isActive === false && (
            <span className={"custom-badge-secondary"}>Inactive</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <span
            onClick={() => {
              onClickEditbutton(d.id);
            }}
          >
            <img src={edit}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components

  useEffect(() => {
    dispatch(getUserList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkPinStatusState === "success") {
      history.push(`/edit-user/${selectedRowId}`);
      //history.replace(`/edit-user/${selectedRowId}`);
    } else if (checkPinStatusState === "failed") {
      setCheckPinStatus(false);
    }
    return () => {
      dispatch(resetCheckPinStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPinStatusState]);

  const handleClose = () => {
    setShow(false);
    setPin("");
    setNote("");
  };

  const onClickEditbutton = (id: string) => {
    setShow(true);
    setselectedRowId(id);
  };

  const onCheckPin = () => {
    dispatch(
      checkPin({
        userId: userId,
        pin,
        note,
      } as checkPinModel)
    );
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  User Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterUsername}
                    onChange={(e) => {
                      setFilterUsername(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Full Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterFullName}
                    onChange={(e) => {
                      setFilterFullName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Email
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterEmail}
                    onChange={(e) => {
                      setFilterEmail(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Code
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterCode}
                    onChange={(e) => {
                      setFilterCode(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Role
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onFilterRoleIdChange}
                    value={filterTempRoleId}
                  >
                    <option value="">Select</option>
                    {userRoleState.map((Item) => {
                      if (
                        Item.roleName !== "Reseller" &&
                        Item.roleName !== "Stockist" &&
                        Item.roleName !== "End Customer"
                      ) {
                        return (
                          <option value={Item.roleId}>{Item.roleName}</option>
                        );
                      }
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          {/* <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row> */}

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          <hr />
          <DataTable
            columns={columns}
            data={userState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="checkPinForm"
            noValidate
            validated={validated}
            onSubmit={(e) => {
              const form = e.currentTarget;
              if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
              } else {
                e.preventDefault();
                onCheckPin();
              }
              setValidated(true);
            }}
          >
            <Form.Group className="input-padding">
              <Form.Label>Pin</Form.Label>
              <Form.Control
                type="text"
                onChange={onPinChange}
                value={pin}
                required
              />
            </Form.Group>
          </Form>
          {checkPinStatus ? (
            <></>
          ) : (
            <Form.Label
              style={{ paddingTop: 10, color: "red" }}
              className="card-subtitle check-pin-status-text"
            >
              * Pin tidak cocok, silahkan coba lagi.
            </Form.Label>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            form="checkPinForm"
            type="submit"
            style={{ width: 100 }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserTable;
