import axios from "axios";
import { endpoint } from "../../../constants";
import { StockSettingModel } from "./SettingStock.model";

export default class SettingStockApi {

    static getStockSettingList() {
        return axios
            .get<any>(endpoint + "​/api/StockSetting/GetList")
            .then((res) => res.data);
    }

    static updateStockSetting(model: StockSettingModel) {
        return axios
            .post<any>(
                endpoint + "/api/StockSetting/Update",
                model
            )
            .then((res) => res.data);
    }

}