import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { ApplicationLinkModel } from "../SettingApplicationLink.model";
import {
  getApplicationLinkSetting,
  updateApplicationLinkSetting,
  resetSingle,
  resetStatus
} from "../SettingApplicationLink.reducer";
import AlertMessage from '../../../../components/alertMessage';


const SettingLinkAplication = () => {
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [linkIOSStockist, setLinkIOSStockist] = useState("");
  const [linkIOSReseller, setLinkIOSReseller] = useState("");
  const [linkIOSEndCustomer, setLinkIOSEndCustomer] = useState("");
  const [linkAndroidStockist, setLinkAndroidStockist] = useState("");
  const [linkAndroidReseller, setLinkAndroidReseller] = useState("");
  const [linkAndroidEndCustomer, setLinkAndroidEndCustomer] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const ApplicationLinkSettingState = useSelector(
    (state: RootState) =>
      state.settingApplicationLink.singleApplicationLinkSetting ?? ""
  );

  const StatusState = useSelector(
    (state: RootState) => state.settingApplicationLink.status ?? ""
  );

  const loadingState = useSelector(
    (state: RootState) => state.settingApplicationLink.isLoading ?? false
  );

  useEffect(() => {
    dispatch(getApplicationLinkSetting());
  }, []);

  useEffect(() => {
    if (
      ApplicationLinkSettingState &&
      ApplicationLinkSettingState.constructor == Object
    ) {
      setId(ApplicationLinkSettingState.id);
      setLinkAndroidEndCustomer(
        ApplicationLinkSettingState.linkAndroidEndCustomer
      );
      setLinkIOSEndCustomer(ApplicationLinkSettingState.linkIOSEndCustomer);
      setLinkAndroidReseller(ApplicationLinkSettingState.linkAndroidReseller);
      setLinkIOSReseller(ApplicationLinkSettingState.linkIOSReseller);
      setLinkAndroidStockist(ApplicationLinkSettingState.linkAndroidStockist);
      setLinkIOSStockist(ApplicationLinkSettingState.linkIOSStockist);
    }
  }, [ApplicationLinkSettingState]);

  useEffect(() => {
    if(StatusState === "success"){
      dispatch(getApplicationLinkSetting())
      setAlertProps({
        variant: "success",
        title: "Berhasil Meng-update Setting Link Aplikasi",
        content: "",
      });
      setShowAlert(true);
    }
  }, [StatusState])

  const onAlertClose = () =>{
    setShowAlert(false)
    if(StatusState === "success"){
      // dispatch(resetSingle())
    }
  }

  const onLinkIOSStockistChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkIOSStockist(e.target.value);
  };

  const onLinkAndroidStockistChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkAndroidStockist(e.target.value);
  };

  const onLinkIOSResellerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkIOSReseller(e.target.value);
  };

  const onLinkAndroidResellerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkAndroidReseller(e.target.value);
  };

  const onLinkIOSEndCustomerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkIOSEndCustomer(e.target.value);
  };

  const onLinkAndroidEndCustomerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkAndroidEndCustomer(e.target.value);
  };

  const onSaveClick = () => {

    if(!loadingState){
      dispatch(
        updateApplicationLinkSetting({
          id,
          linkIOSStockist,
          linkAndroidStockist,
          linkIOSReseller,
          linkAndroidReseller,
          linkIOSEndCustomer,
          linkAndroidEndCustomer,
        } as ApplicationLinkModel)
      );
    } else {
      setAlertProps({
        variant: "failed",
        title: "Sedang mengumpulkan data aplikasi, harap tunggu.",
        content: "",
      });
      setShowAlert(true);
    }
    
  };

  const onCancelClick = () => {};

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label className="title">Link Application</Form.Label>
              </Form.Group>
              <Card>
                <Card.Body>
                  <b>Link Application</b>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Stockist Aplication Link
                    </Form.Label>
                    <Col>
                      <Form>
                        {/* <Form.Row> */}

                        <Row className="fee-input">
                          <Form.Control
                            placeholder="Link IOS"
                            type="text"
                            value={linkIOSStockist}
                            onChange={onLinkIOSStockistChange}
                          />
                        </Row>

                        <Row className="fee-input">
                          <Form.Control
                            placeholder="Link Android"
                            type="text"
                            value={linkAndroidStockist}
                            onChange={onLinkAndroidStockistChange}
                          />
                        </Row>

                        {/* </Form.Row> */}
                      </Form>
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Reseller Aplication Link
                    </Form.Label>
                    <Col>
                      <Form>
                        {/* <Form.Row> */}

                        <Row className="fee-input">
                          <Form.Control
                            placeholder="Link IOS"
                            type="text"
                            value={linkIOSReseller}
                            onChange={onLinkIOSResellerChange}
                          />
                        </Row>

                        <Row className="fee-input">
                          <Form.Control
                            placeholder="Link Android"
                            type="text"
                            value={linkAndroidReseller}
                            onChange={onLinkAndroidResellerChange}
                          />
                        </Row>

                        {/* </Form.Row> */}
                      </Form>
                    </Col>
                  </Form.Group>

                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      End Customer Aplication Link
                    </Form.Label>
                    <Col>
                      <Form>
                        {/* <Form.Row> */}

                        <Row className="fee-input">
                          <Form.Control
                            placeholder="Link IOS"
                            type="text"
                            value={linkIOSEndCustomer}
                            onChange={onLinkIOSEndCustomerChange}
                          />
                        </Row>

                        <Row className="fee-input">
                          <Form.Control
                            placeholder="Link Android"
                            type="text"
                            value={linkAndroidEndCustomer}
                            onChange={onLinkAndroidEndCustomerChange}
                          />
                        </Row>

                        {/* </Form.Row> */}
                      </Form>
                    </Col>
                  </Form.Group>

                  <hr />

                  <div className="btn-footer-group">
                    <Button
                      variant="outline-info"
                      style={{ width: 130, height: 45 }}
                      onClick={onCancelClick}
                    >
                      Cancel
                    </Button>
                    {"   "}
                    <Button
                      variant="info"
                      style={{ width: 130, height: 45 }}
                      type="submit"
                      form="faqForm"
                      onClick={onSaveClick}
                    >
                      Save
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>

    </Container>
  );
};

export default SettingLinkAplication;
