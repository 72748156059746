import axios from "axios";
import { endpoint } from "../../constants";
import { CheckTokenModel, LoginCredential, LoginSuccessResponse } from "./model";

export default class UserApi {
  static login(userLogin: LoginCredential) {
    return axios.post<any>(endpoint+"/api/user/login", userLogin).then((res) => res.data.data);
  }

  static checkUserToken(model : CheckTokenModel) {
    return axios
        .post<any>(endpoint + "​/api/User/CheckTokenValidation?token="+model.token+"&userId="+model.userId)
        .then((res) => res.data.data);
}

}
