import ConfigureTermsIndex from "../containers/ConfigureTermsIndex";
import { Link, useHistory, useParams } from "react-router-dom";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";
import { ConfigureTermsModel } from "../attribute.model";
import { useDispatch, useSelector } from "react-redux";
import { getParentList, resetStatus } from "../attribute.reducer";
import { RootState } from "../../../../app/store";
import { Grid } from "semantic-ui-react";
import { TreeSelect } from "antd";
import { HexColorPicker } from "react-colorful";


const ConfigureTermsEditForm = ({
  data = {} as ConfigureTermsModel,
  ...props
}: ConfiguretermsEditFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const parentState = useSelector((state: RootState) => state.attribute.parentList ?? []);

  const statusState = useSelector((state: RootState) => state.attribute.status ?? "")


  let history = useHistory();
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [parentId, setParent] = useState("");
  const [configurationAttributeId, setConfigurationAttributeId] = useState("");
  const [description, setDescription] = useState("");
  const [validated, setValidated] = useState(false);
  const [color, setColor] = useState("#ebf2ff")
  const [isActive, setIsactive] = useState(true);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onSlugChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSlug(e.target.value);
  };

  const onParentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setParent(e.target.value);
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onColorChange = (value: string) => {
    setColor(value);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setName(data.name);
      setSlug(data.slug);

      if(data.parentId === "00000000-0000-0000-0000-000000000000"){
        setParent("");
      } else {
        setParent(data.parentId);
      }
      
      setDescription(data.description);
      setConfigurationAttributeId(data.productAttributeId);

      if(data.color != null && data.color !== ""){
        setColor(data.color)
      }
     

      console.log(data)
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if(statusState === "success-update-configure-term"){
      dispatch(resetStatus())
      history.goBack()
    }
  }, [statusState])

  const onParentChange2 = (e: any) => {
    setParent(e);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">Edit Configure Terms (Attribute Name)</Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div className="">Edit Configure Terms (Attribute Name)</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    name,
                    slug,
                    parentId,
                    configurationAttributeId,
                    description,
                    isActive,
                    color
                  );
                  setName("");
                  setSlug("");
                  setParent("");
                  setDescription("");
                  setColor("#ebf2ff")
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" onChange={onNameChange} value={name} required />
                </Col>
              </Form.Group>

              {/* <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Slug
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" required onChange={onSlugChange} value={slug} />
                  <Form.Label className="card-subtitle">
                    * lowercase and only contains letters, number and hyphens
                  </Form.Label>
                </Col>
              </Form.Group> */}

              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Parent
                </Form.Label>
                <Col sm={4}>
                  <TreeSelect
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeData={parentState}
                    value={parentId}
                    placeholder="Please select"
                    //treeDefaultExpandAll
                    onChange={onParentChange2}
                  />
                </Col>
              </Form.Group>
              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Description
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    onChange={onDescriptionChange}
                    value={description}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Variant Color
                </Form.Label>
                <Col sm={4}>
                  <Row>
                    <HexColorPicker
                      color={color}
                      onChange={(e) => {
                        onColorChange(e);
                      }}
                    />
                  </Row>

                  <Row>
                    {" "}
                    <div className="value" style={{ borderLeftColor: color }}>
                      Current color is {color}
                    </div>
                  </Row>

                  <Row>
                    <Form.Control
                      type="text"
                      onChange={(e) => onColorChange(e.target.value)}
                      value={color}
                    />
                  </Row>
                </Col>
              </Form.Group>

              <hr />

              <Row>
                <Col sm={6}>
                  <Link to={{ pathname: `/attribute/index` }}>
                    <Button className="btn-cancel" variant="light">
                      Cancel
                    </Button>
                    {"  "}
                  </Link>
                  <Button variant="info" style={{ width: 130, height: 46 }} type="submit">
                    Save
                  </Button>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>

        <br />

        {/* <ConfigureTermsIndex /> */}
      </Grid>
    </div>
  );
};

export interface ConfiguretermsEditFormProps {
  onSubmit: (
    name: string,
    slug: string,
    parentId: string,
    configurationAttributeId: string,
    description: string,
    isActive: boolean,
    color: string
  ) => void;
  data?: ConfigureTermsModel;
}

export default ConfigureTermsEditForm;
