import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import noImage from "../../../../assets/no-image.png";

import { Link } from "react-router-dom";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../../app/store";
import { deleteExpedition, getExpeditionList, resetStatus } from "../Expedition.reducer";
import DeleteMessage from "../../../../components/DeleteMessage";
import { endpoint } from "../../../../constants";


const ExpeditionTable = () => {
  const dispatch = useDispatch();

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("")

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleterAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [selectedId, setSelectedId] = useState("")

  const ExpeditionListState = useSelector(
    (state: RootState) => state.expedition.list ?? []
  )

  const ExpeditionStatusState = useSelector(
    (state: RootState) => state.expedition.status ?? ""
  )

  const filteredITems = ExpeditionListState.filter(
    (item) => 
      item.name.toLowerCase().includes(filterText.toLowerCase())
  )

  const onDeleteData = () => {
      dispatch(deleteExpedition(selectedId));
  };

  const onDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    dispatch(resetStatus());
  };

  useEffect(() => {
    dispatch(getExpeditionList())
  }, [])

  useEffect(() => {
    if(ExpeditionStatusState === "success-delete"){
      setShowDeleteAlert(false)
      dispatch(resetStatus())
      dispatch(getExpeditionList())
    } 
  }, [ExpeditionStatusState])

  const expeditionList = [
    {
      id: "001",
      name: "JNE",
      status: "aktif",
    },
    {
      id: "002",
      name: "J&T",
      status: "aktif",
    },
    {
      id: "003",
      name: "Tiki",
      status: "aktif",
    },
    {
      id: "004",
      name: "POS",
      status: "aktif",
    },
  ];

  const columns = [
    {
      name: "EKSPEDISI",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "ICON",
    //   selector: (d: { icon: string }) => (
    //     <p style={{ margin: 5 }}>
    //       {d.icon !== "" ? (
    //         <img
    //           src={d.icon !== "" ? endpoint + d.icon : "none"}
    //           style={{ width: 50 }}
    //         />
    //       ) : (
    //         <img src={noImage} style={{ width: 50 }} />
    //       )}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-expedition/${d.id}`,
            }}>
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              setSelectedId(d.id);

              setDeleterAlertProps({
                variant: "info",
                title: "Delete Data Expedisi",
                content: "Yakin akan menghapus data ekspedisi ini ?",
              });

              setShowDeleteAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Ekspedisi</Form.Label>
          </Form.Group>
        </Col>
        <Link
          to={{
            pathname: "/expedition/create",
          }}>
          {"  "}
          <Button
            className="button-create-customer float-right"
            style={{ width: 160, marginLeft: 10 }}>
            + Add Expedition
          </Button>
          {"  "}
        </Link>
        {/* <Button
          className="float-right"
          style={{ width: 130, marginLeft: 10 }}
          variant="outline-info"
        >
          Print
        </Button>{" "}
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="outline-info"
        >
          Export to excel
        </Button>{" "} */}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup
                className="mb-3 padding-filter"
                style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}>
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredITems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            customStyles={TableCustomStyles}
          />
        </Card>
      </Grid>
      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDeleteData}></DeleteMessage>
    </div>
  );
};

export default ExpeditionTable;
