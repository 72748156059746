import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { UOMModel, ProductModel } from "../UOM.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { resetSingle, resetStatus } from "../UOM.reducer";
import { DragSwitch } from "react-dragswitch";
import { Typeahead } from "react-bootstrap-typeahead";
import { Select } from "antd";

const UOMForm = ({ data = {} as UOMModel, isEdit = false, ...props }: UOMFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const variantState = useSelector((state: RootState) => state.UOM.listProduct ?? []);

  const createStatus = useSelector((state: RootState) => state.UOM.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.UOM.error ?? "");

  let history = useHistory();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [variant, setVariant] = useState<Array<string>>([]);
  const [unit, setUnit] = useState("");
  const [symbol, setSymbol] = useState("");
  const [note, setNote] = useState("");
  const [validated, setValidated] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/UOM/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const onProductCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductCode(e.target.value);
  };

  // const onVariantChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setVariant(e.target.value);
  // };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onUnitChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUnit(e.target.value);
  };

  const onSymbolChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSymbol(e.target.value);
  };

  const onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setCode(data.code);
      setName(data.name);
      setUnit(data.unit);
      setSymbol(data.symbol);
      setNote(data.note);
      setProductCode(data.productCode);
      setVariant(data.variant.split(","));
      setIsActive(data.isActive);
    } else {
      setCode("");
      setName("");
      setUnit("");
      setSymbol("");
      setNote("");
      setProductCode("");
      setVariant([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (e: any) => {
    setVariant(e);
    console.log(e);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">{isEdit ? "Edit Data UOM" : "Add Data UOM"}</Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div>{isEdit ? "Edit Data UOM" : "Add Data UOM"}</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const status = false;
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    name,
                    productCode,
                    variant.toString(),
                    code,
                    unit,
                    symbol,
                    note,
                    isActive
                  );
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  UOM Code
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onCodeChange} value={code} disabled />
                </Col>
              </Form.Group>
              <hr />
              {/* <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Product Code
                </Form.Label>
                <Col sm={5}>
                  <Select
                    mode="multiple"
                    placeholder="Collection Tag"
                    value={productCode}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  >
                    {productState.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Form.Group>
              <hr /> */}
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Product
                </Form.Label>
                <Col sm={5}>
                  <Select
                    mode="multiple"
                    value={variant}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  >
                    {variantState.map((item) => (
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                  {/* <Form.Control
                    type="text"
                    required
                    placeholder="Variant"
                    onChange={onVariantChange}
                    value={variant}
                  /> */}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Unit
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onUnitChange} value={unit} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Symbol
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onSymbolChange} value={symbol} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    onChange={onNoteChange}
                    value={note}
                  />
                </Col>
              </Form.Group>
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}

              <div className="btn-footer-group">
                <Link to={{ pathname: `/UOM/index` }}>
                  <Button className="btn-cancel" variant="light">
                    Cancel
                  </Button>
                  {"  "}
                </Link>
                <Button variant="info" style={{ width: 130, height: 45 }} type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export interface UOMFormProps {
  onSubmit: (
    name: string,
    productCode: string,
    variant: string,
    code: string,
    unit: string,
    symbol: string,
    note: string,
    isActive: boolean
  ) => void;
  data: UOMModel;
  isEdit: boolean;
}

export default UOMForm;
