import "firebase/messaging";
// import firebase from "firebase/app";
import localforage from "localforage";
import firebase from "firebase/compat/app";

const firebaseCloudMessaging = {
    //checking whether token is available in indexed DB
    tokenInlocalforage: async () => {
        return localforage.getItem("fcm_token");
    },

    //initializing firebase app
    init: async function () {
        if (!firebase.apps.length) {
            // change to firebase API key
            firebase.initializeApp({
             apiKey: "AIzaSyAjWIJe9AaL6fDZVn9tRajF-BUexEPFZyA",
             authDomain: "react-notif-40228.firebaseapp.com",
             projectId: "react-notif-40228",
             storageBucket: "react-notif-40228.appspot.com",
             messagingSenderId: "790644971731",
             appId: "1:790644971731:web:dc0c5d007d2b961af3dc26"
            });
            try {
                const messaging = firebase.messaging();
                const tokenInLocalForage = await this.tokenInlocalforage();
                //if FCM token is already there just return the token
                if (tokenInLocalForage !== null) {
                    return tokenInLocalForage;
                }
                //requesting notification permission from browser
                const status = await Notification.requestPermission();
                if (status && status === "granted") {
                    //getting token from FCM
                    const fcm_token = await messaging.getToken();
                    if (fcm_token) {
                        //setting FCM token in indexed db using localforage

                        localforage.setItem("fcm_token", fcm_token);

                        //return the FCM token after saving it
                        return fcm_token;
                    }
                }
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    },
};

export { firebaseCloudMessaging };