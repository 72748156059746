import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { ApiErrorResponse } from '../../..';
import Api from './paymentConfirmation.api';
import { FilterModel, PaymentConfirmationModel } from './paymentConfirmation.model';

export interface PaymentConfirmationSlice {
    name? :string;
    list?: PaymentConfirmationModel[];
    totalRow? : number;
    singleCustomer?: PaymentConfirmationModel;
    singleStockist?: PaymentConfirmationModel;
    singleReseller?: PaymentConfirmationModel;
    isLoading?: boolean;    
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const createData = createAsyncThunk(
    'PaymentConfirmationCreateState/PaymentConfirmationCreate',
    async (Model: PaymentConfirmationModel, {rejectWithValue}) => {
        try {
            return await Api.createData(Model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getList = createAsyncThunk(
     'PaymentConfirmationGetListState/PaymentConfirmationGetList',
     async ( _:string | undefined = undefined, {rejectWithValue}) => {
         try {
             return await Api.getList();
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 );

 export const getListWithPaging = createAsyncThunk(
    'PaymentConfirmationGetListWithPagingState/PaymentConfirmationGetListWithPaging',
    async ( model : FilterModel, {rejectWithValue}) => {
        try {
            return await Api.getListWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);


export const getCustomerSingleItem = createAsyncThunk(
     'PaymentConfirmationCustomerSingleItemState/PaymentConfirmationSingleItem',
    async (_:string | undefined = undefined, {rejectWithValue}) => {
         try {
             return await Api.getCustomerSingleItem();
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 )

 export const getStockistSingleItem = createAsyncThunk(
    'PaymentConfirmationStockistSingleItemState/PaymentConfirmationSingleItem',
   async (_:string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await Api.getStockistSingleItem();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getResellerSingleItem = createAsyncThunk(
    'PaymentConfirmationResellerSingleItemState/PaymentConfirmationSingleItem',
   async (_:string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await Api.getResellerSingleItem();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

 export const updateData = createAsyncThunk(
     'PaymentConfirmationState/PaymentConfirmationUpdate',
     async (model: PaymentConfirmationModel, {rejectWithValue}) => {
         try {
             return await Api.updateData(model);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
)

export const deleteData = createAsyncThunk(
    'PaymentConfirmationDeleteState/PaymentConfirmationDelete',
    async (id: string, {rejectWithValue}) =>{
        try {
            return await Api.deleteData(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const PaymentConfirmationSlice = createSlice({
    name: 'PaymentConfirmationState',
    initialState: {} as PaymentConfirmationSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        resetSingle: (state) => {
            state.singleCustomer = {} as PaymentConfirmationModel;
            state.singleStockist = {} as PaymentConfirmationModel;
            state.singleReseller = {} as PaymentConfirmationModel;
        },
        resetStatus: (state) => {
            state.status = "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createData.pending, (state) => {
          state.isLoading = true;
          state.status = "";
        });
        builder.addCase(createData.fulfilled, (state, { payload }) => {
          state.name = new Date().toISOString();
          state.isLoading = false;
          state.status = "success";
        });
        builder.addCase(createData.rejected, (state, { payload }) => {
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
          state.status = "failed";
        });

        builder.addCase(getCustomerSingleItem.pending, (state) =>{
            state.singleCustomer = undefined;
            state.isLoading = true;   
        });
        builder.addCase(getCustomerSingleItem.fulfilled, (state, {payload})=>{
            state.singleCustomer = payload;
            state.isLoading = false;
        })
        builder.addCase(getCustomerSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })

        builder.addCase(getStockistSingleItem.pending, (state) =>{
            state.singleStockist = undefined;
            state.isLoading = true;   
        });
        builder.addCase(getStockistSingleItem.fulfilled, (state, {payload})=>{
            state.singleStockist = payload;
            state.isLoading = false;
        })
        builder.addCase(getStockistSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })

        builder.addCase(getResellerSingleItem.pending, (state) =>{
            state.singleReseller = undefined;
            state.isLoading = true;   
        });
        builder.addCase(getResellerSingleItem.fulfilled, (state, {payload})=>{
            state.singleReseller = payload;
            state.isLoading = false;
        })
        builder.addCase(getResellerSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })

        builder.addCase(getList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload})=> {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getListWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListWithPaging.fulfilled, (state, {payload})=> {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getListWithPaging.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateData.fulfilled, (state, {payload})=> {
            state.isLoading = false;
            state.name = new Date().toISOString();
            state.status = "success";
        })
        builder.addCase(updateData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.status = "failed";
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteData.fulfilled, (state, {payload}) => {
            state.isLoading = false;
        })
        builder.addCase(deleteData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

      },
})

export const { setFilter, resetSingle, resetStatus } = PaymentConfirmationSlice.actions;
export default PaymentConfirmationSlice.reducer;