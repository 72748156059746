import moment from 'moment';
import React from 'react'
import { Bar } from 'react-chartjs-2';

export default function StatistikLaporan({statisticList, label}:any ) {
    console.log(statisticList);
    
    const dataLine= {
        // labels: ['22 Maret 2021', '23 Maret 2021', '24 Maret 2021', '25 Maret 2021', '26 Maret 2021', '27 Maret 2021'],
        labels: statisticList.map((res:any)=> moment(res.createdDate).format('DD MMM YYYY')),
        datasets: [
            {
                id: 1,
                label: 'Statistik Laporan',
                // data: [22222, 22422, 33232, 43434,39394, 12123],
                data:  statisticList.map((res:any)=> res.totalPrice),
                backgroundColor: ["#12A1FD"],
            },
        ],
    }
    return (
        <div className='statistikLaporan p-4'>
            <Bar
                type='bar'
                data={dataLine}
            />
            <h3 className='my-3 text-center'>
                Statistik Laporan <br /> 
                {label()}
            </h3>
        </div>
    )
}
