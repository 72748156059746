import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import {
  Card,
  InputGroup,
  FormControl,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { KeepBelumLunasModel } from "../keepBelumLunas.model";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../../../constants";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DeleteMessage from "../../../../../components/DeleteMessage";
import hapus from "./../../../../../assets/vector/delete.png";
import edit from "./../../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { getListKeepUnpaid } from "../../pesananDalamProses.reducer";
import { getPendingNotification } from "../../../../../global/global.reducer";

/*
 * created by : dikembe mutombo
 * created date : 11/oct/2021
 */

const KeepBelumLunasTable = () => {
  const dispatch = useDispatch();

  const totalRowsState = useSelector(
    (state: RootState) => state.pesananDalamProses.totalRow ?? 0
  );

  const KeepBelumLunasListState = useSelector(
    (state: RootState) => state.pesananDalamProses.list ?? []
  );

  const [filterText, setFilterText] = useState("");
  const [list, setList] = useState<KeepBelumLunasModel[]>([]);
  const [selectedId, setSelectedId] = useState("");

  const [filterName, setFilterName] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [filterSoNumber, setfilterSoNumber] = useState("");
  const [filterNote, setFilterNote] = useState("");
  const [filterQty, setFilterQty] = useState<number | null>(null);
  const [filterQtyTemp, setFilterQtyTemp] = useState(0);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  // useEffect(() => {
  //   axios
  //     .get<any>(endpoint + "​/api/SalesOrder/GetListWithDetail")
  //     .then((res) => setList(res.data.data));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onDelete = () => setShowAlert(false);

  const onAlertClose = () => setShowAlert(false);

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListKeepUnpaid({
        customerName: filterName,
        productName: filterProductName,
        startOrderDate: filterStartDate,
        endOrderDate: filterEndDate,
        soNumber: filterSoNumber,
        note: filterNote,
        qty: null,
        skip: page,
        take: perPage,
      })
    );
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterName("");
    setFilterProductName("");
    setFilterQty(null);
    setFilterStartDate("");
    setFilterEndDate("");
    setfilterSoNumber("");

    dispatch(
      getListKeepUnpaid({
        customerName: "",
        productName: "",
        startOrderDate: "",
        endOrderDate: "",
        soNumber: "",
        note: "",
        qty: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  useEffect(() => {
    setLoading(false);
    if (KeepBelumLunasListState.length !== 0) {
        dispatch(getPendingNotification())
    }
  }, [KeepBelumLunasListState]);

  const columns = [
    {
      name: "Nama Pelanggan",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "Jenis Pelanggan",
      selector: (d: { partnerType: number }) => (
        <p style={{ margin: 10 }}>
          {d.partnerType === 0 && <span>End Customer</span>}
          {d.partnerType === 1 && <span>Stockist</span>}
          {d.partnerType === 2 && <span>Reseller</span>}
          {d.partnerType === 4 && <span>Admin Stockist</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Nomor SO",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "SKU",
      selector: "inventorySKU",
      sortable: true,
    },
    {
      name: "Product Name",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (d: { orderDate: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.orderDate.slice(0, 10)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Varian",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "Qty",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "Harga",
      selector: (d: { grandTotal: any; price: any }) => (
        <>
          <NumberFormat
            // value={d.grandTotal}
            value={d.price}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "Subtotal",
      selector: (d: { grandTotal: any }) => (
        <>
          <NumberFormat
            // value={d.grandTotal}
            value={d.grandTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "Tipe Produk",
      selector: (d: { productType: number }) => (
        <p style={{ margin: 10 }}>
          {d.productType === 0 && <span>Ready</span>}
          {d.productType === 1 && <span>Pre Order</span>}
          {d.productType >= 2 && <span>-</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Shipping Status",
      selector: (d: { shippingStatus: number }) => (
        <p style={{ margin: 10 }}>
          {d.shippingStatus === 0 && <span>Pending</span>}
          {d.shippingStatus === 1 && <span>Print Request</span>}
          {d.shippingStatus === 2 && <span>On Progress</span>}
          {d.shippingStatus === 3 && <span>On Delivery</span>}
          {d.shippingStatus === 4 && <span>Delivered</span>}
          {d.shippingStatus === 5 && <span>Completed</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (d: { paymentStatus: number }) => (
        <>
          <p style={{ paddingTop: 10 }}>
            {d.paymentStatus === 0 ? "UNPAID" : "PAID"}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Note",
      selector: "note",
      sortable: true,
    },
    // {
    //   name: "APPROVAL STATUS",
    //   selector: (d: { limitApprovalStatus: number }) => (
    //     <p style={{ marginTop: 15 }}>
    //       {d.limitApprovalStatus === 0 && (
    //         <span className={"custom-badge-secondary"}>Pending</span>
    //       )}
    //       {d.limitApprovalStatus === 1 && (
    //         <span className={"custom-badge-success"}>Approved</span>
    //       )}
    //       {d.limitApprovalStatus === 2 && (
    //         <span className={"custom-badge-danger"}>Rejected</span>
    //       )}
    //       {/* {d.contractStockistApproval === 3 && (
    //         <span className={"custom-badge-danger"}>-</span>
    //       )} */}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "ACTION",
      selector: (d: { partnerId: any; customerName: any }) => (
        <>
          <Link
            to={{
              pathname: `/keep-belum-lunas-detail/${d.partnerId}/${d.customerName}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setSelectedId(d.partnerId);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          {/* <InputGroup className="mb-3 padding-filter " style={{ width: 330 }}>
            <InputGroup.Prepend className="search-input">
              <InputGroup.Text
                style={{
                  backgroundColor: "#fff",
                  color: "#F5B95D",
                  border: 0,
                }}
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              style={{ borderLeft: 0 }}
              placeholder="Search"
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              value={filterText}
            />
          </InputGroup> */}

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Customer Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal Order
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Product
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterProductName}
                    onChange={(e) => {
                      setFilterProductName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  SO Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder=""
                    type="text"
                    value={filterSoNumber}
                    onChange={(e) => {
                      setfilterSoNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Note
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder=""
                    type="text"
                    value={filterNote}
                    onChange={(e) => {
                      setFilterNote(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          <hr />

          {/* <DataTable
            columns={columns}
            data={list}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}

          <DataTable
            columns={columns}
            data={KeepBelumLunasListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowsState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default KeepBelumLunasTable;
