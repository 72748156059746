import { Container, Form } from "react-bootstrap";
import PesananCashlessTable from "../components/pesananCashlessTable";

const PesananCashlessIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan Cashless</Form.Label>
      </Form.Group>
      <PesananCashlessTable />
    </Container>
  );
};

export default PesananCashlessIndex;
