import React from 'react'
import { Card, Col, Container, Form, Row, Tab, TabContent, Tabs } from 'react-bootstrap'
import ChatProductCustomer from '../components/ChatProductCustomer'
import ChatProductReseller from '../components/ChatProductReseller'
import ChatProductStockist from '../components/ChatProductStockist'
import ChatProductPage from './ChatProductPage'

const ChatProductContainerPage = () => {
 return (
  <Container className="content-body" fluid>
   <Row>
    <Col md={12}>
     <Form.Group>
      <Form.Label className="title">Chat Product</Form.Label>
     </Form.Group>

      <Tabs>
              <Tab eventKey="reseller" title="Reseller">
                  <TabContent>
                   <ChatProductReseller/>
                  </TabContent>
              </Tab>
              <Tab eventKey="stockist" title="Stockist">
                  <TabContent>
                   <ChatProductStockist/>
                   {/* <ChatPage/> */}
                  </TabContent>
              </Tab>
              <Tab eventKey="customer" title="Customer">
                  <TabContent>
                   <ChatProductCustomer/>
                  </TabContent>
              </Tab>
          </Tabs>

    
    </Col>
   </Row>

  </Container>
)
}

export default ChatProductContainerPage