import React, { useEffect } from "react";
import CustomerTable from "../components/CustomerTable";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getCustomerList } from "../customer.reducer";
import { Link } from "react-router-dom";

const CustomerTablePage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector((state: RootState) => state.customer.name);

  useEffect(() => {
    // dispatch(getCustomerList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);
  return (
    <div style={{ padding: 10 }}>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Customer</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/create-customer",
            }}
          >
            <Button className="button-create-customer float-right">
              + Add Data Customer
            </Button>
          </Link>
        </Col>
      </Row>
      <section>
        <CustomerTable />
      </section>
    </div>
  );
};

export default CustomerTablePage;
