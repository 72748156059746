import axios from "axios";
import { endpoint } from "../../../../constants";
import { SalesOrderModel } from "./keepBelumLunas.model";

export default class Api {
  static updateOrderDetail(model: SalesOrderModel) {
    return axios
      .post<any>(endpoint + "​/api/SalesOrder/Update", model)
      .then((res) => res.data.data);
  }

  static getKeepBelumLunasList() {
    return axios
      .get<any>(endpoint + "​/api/SalesOrder/GetListWithDetail")
      .then((res) => res.data.data);
  }

  static getKeepBelumLunasDetailList() {
    return axios.get<any>(endpoint + "/api/SalesOrderDetail/GetList").then((res) => res.data.data);
  }

  static getOrderDetailSingle(id: string) {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static getListByPaymentStatusUnpaid(status: number) {
    return axios
      .get<any>(endpoint + "/api/SalesOrder/GetListByPaymentStatus?status=" + status)
      .then((res) => res.data.data);
  }
}
