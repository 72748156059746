import { Container, Form, Tab, Tabs } from "react-bootstrap";
import DropshipIndex from "../droshipBelumLunas/containers/dropshipIndex";
import JatuhTempoDropshipIndex from "../jatuhTempoDropship/containers/JatuhTempoDropshipIndex";
import JatuhTempoRekapIndex from "../jatuhTempoDropship/containers/JatuhTempoRekapIndex";
import KeepBelumLunasIndex from "../keepBelumLunas/containers/KeepBelumLunasIndex";
import KeepByProductIndex from "../keepPerProduct/containers/KeepByProductIndex";

const PesananDalamProsesIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan Dalam Proses</Form.Label>
      </Form.Group>

      <Tabs
        style={{ marginLeft: 16, marginRight: 16 }}
        variant="pills"
        defaultActiveKey="1"
        className="tabs-style mb-3"
      >
        <Tab eventKey="1" title="Pesanan Dalam Proses & Walk In (Belum Lunas)">
          <KeepBelumLunasIndex />
        </Tab>
        <Tab eventKey="2" title="Pesanan Dalam Proses Per Produk">
          <KeepByProductIndex />
        </Tab>
        <Tab eventKey="3" title="Dropship Belum Lunas">
          <DropshipIndex />
        </Tab>
        {/* <Tab eventKey="4" title="Jatuh Tempo (Rekap/COD)">
          <JatuhTempoRekapIndex />
        </Tab> */}
        <Tab eventKey="4" title="Jatuh Tempo (Dropship)">
          <JatuhTempoDropshipIndex />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default PesananDalamProsesIndex;
