import {
  getCityByProvince,
  getDistrictByCity,
  getListExpedition,
  getProvince,
} from "../../../../global/global.reducer";
import { Card, Form, Row, Col, Button, InputGroup, Container } from "react-bootstrap";
import AlertMessage from "../../../../components/alertMessage";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { RootState } from "../../../../app/store";
import NumberFormat from "react-number-format";
import { getSingleShipment, resetStatus, updateShipmentData } from "../pesananDalamProses.reducer";
import { ShipmentModel } from "../jatuhTempoDropship/jatuhTempoDropship.model";
import { useHistory, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

const EditShipment = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  let { id } = useParams<{ id: string }>();

  const provinceState = useSelector((state: RootState) => state.global.listProvince ?? []);

  const cityState = useSelector((state: RootState) => state.global.listCityByProvince ?? []);

  const districtState = useSelector((state: RootState) => state.global.listDistrictByCity ?? []);

  const expeditionState = useSelector((state: RootState) => state.global.listExpedition ?? []);

  const singleDataState = useSelector(
    (state: RootState) => state.pesananDalamProses.single ?? ({} as ShipmentModel)
  );

  const status = useSelector((state: RootState) => state.pesananDalamProses.status ?? "");

  const error = useSelector((state: RootState) => state.pesananDalamProses.error ?? "");

  //shipment state
  const [deliveryName, setDeliveryName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientPostalCode, setRecipientPostalCode] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [recipientProvince, setRecipientProvince] = useState("");
  const [recipientCity, setRecipientCity] = useState("");
  const [recipientSubDistrict, setRecipientSubDistrict] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [resiNumber, setResiNumber] = useState("");
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const [expeditionId, setExpeditionId] = useState("");
  const [tarifType, setTarifType] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [salesOrderId, setSalesOrderId] = useState("");
  const [administrationPrice, setAdministrationPrice] = useState(0);
  const [totalOngkosKirimEnum, setTotalOngkosKirimEnum] = useState(0);

  const [note, setNote] = useState("");

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    if (status === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "",
      });
      setShowAlert(true);
    } else if (status === "failed") {
      let arr = JSON.stringify(error);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = message.title;

      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: xhr,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    dispatch(getSingleShipment(id));
    dispatch(getListExpedition());
    dispatch(getProvince());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      dispatch(getCityByProvince(singleDataState.recipientCity));
      dispatch(getDistrictByCity(singleDataState.recipientSubDistrict));

      setDeliveryName(singleDataState.deliveryName);
      setShippingAddress(singleDataState.shippingAddress);
      setRecipientName(singleDataState.recipientName);
      setRecipientAddress(singleDataState.recipientAddress);
      setRecipientPostalCode(singleDataState.recipientPostalCode);
      setRecipientPhone(singleDataState.recipientPhone);
      setRecipientProvince(singleDataState.recipientProvince);
      setRecipientEmail(singleDataState.recipientEmail);
      setResiNumber(singleDataState.resiNumber);
      setTotalWeight(singleDataState.totalWeight);
      setTotalShippingCost(singleDataState.totalShippingCost);
      setExpeditionId(singleDataState.expeditionId);
      setTarifType(singleDataState.tarifType);
      setDeliveryDate(singleDataState.deliveryDate);
      setSalesOrderId(singleDataState.salesOrderId);
      setAdministrationPrice(singleDataState.administrationPrice);
      setNote(singleDataState.note);

      dispatch(getCityByProvince(singleDataState.recipientProvince));
      dispatch(getDistrictByCity(singleDataState.recipientCity));

      setRecipientCity(singleDataState.recipientCity);
      setRecipientSubDistrict(singleDataState.recipientSubDistrict);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  const onProvinceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientProvince(e.target.value);
    dispatch(getCityByProvince(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientCity(e.target.value);
    dispatch(getDistrictByCity(e.target.value));
  };

  const onDistrictChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientSubDistrict(e.target.value);
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (status === "success") {
      dispatch(resetStatus());
    } else {
      dispatch(resetStatus());
    }
  };

  const onSubmit = () => {
    dispatch(
      updateShipmentData({
        id,
        deliveryName,
        shippingAddress,
        recipientName,
        recipientAddress,
        recipientPostalCode,
        recipientPhone,
        recipientProvince,
        recipientCity,
        recipientSubDistrict,
        recipientEmail,
        resiNumber,
        totalWeight,
        totalShippingCost,
        expeditionId,
        tarifType,
        deliveryDate,
        salesOrderId,
        administrationPrice,
        note,
      } as ShipmentModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <div onClick={() => history.goBack()} className="form-title">
          <FaChevronLeft />
          {"            "}
          Edit Data Shipment
        </div>
      </Form.Group>
      <Card>
        <Card.Body>
          <Form>
            <Form.Label column sm={3}>
              <h5>Data Pengiriman</h5>
            </Form.Label>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Dikirim Dari
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  value={deliveryName}
                  onChange={(e) => setDeliveryName(e.target.value)}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}></Form.Label>
              <Col sm={4}>
                <Form.Control
                  value={shippingAddress}
                  onChange={(e) => setShippingAddress(e.target.value)}
                  as="textarea"
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Kepada
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  value={recipientName}
                  onChange={(e) => setRecipientName(e.target.value)}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Telpon Penerima
              </Form.Label>
              <Col sm={4}>
                <PhoneInput
                  international
                  defaultCountry="ID"
                  onChange={setRecipientPhone}
                  value={recipientPhone}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Alamat Penerima
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  type="text"
                  value={recipientAddress}
                  onChange={(e) => setRecipientAddress(e.target.value)}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Provinsi
              </Form.Label>
              <Col sm={4}>
                <Form.Control as="select" onChange={onProvinceChange} value={recipientProvince}>
                  <option>select</option>
                  {provinceState.map((Item) => {
                    return <option value={Item.id}>{Item.name}</option>;
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Kota
              </Form.Label>
              <Col sm={4}>
                <Form.Control as="select" onChange={onCityChange} value={recipientCity}>
                  <option>select</option>
                  {cityState.map((Item) => {
                    return <option value={Item.id}>{Item.name}</option>;
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Kecamatan
              </Form.Label>
              <Col sm={4}>
                <Form.Control as="select" onChange={onDistrictChange} value={recipientSubDistrict}>
                  <option>select</option>
                  {districtState.map((Item) => {
                    return <option value={Item.id}>{Item.name}</option>;
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Kode pos
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  value={recipientPostalCode}
                  onChange={(e) => setRecipientPostalCode(e.target.value)}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Expedisi
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setExpeditionId(e.target.value);
                  }}
                  value={expeditionId}
                >
                  <option>select</option>
                  {expeditionState.map((Item) => {
                    return <option value={Item.id}>{Item.name}</option>;
                  })}
                </Form.Control>{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Nomor Resi
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  value={resiNumber}
                  onChange={(e) => setResiNumber(e.target.value)}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Tarif Tipe
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  // value={tarifType}
                  // onChange={(e) => tarifType(parseInt(e.target.value))}
                >
                  <option value={0}>OK</option>
                  <option value={1}>Reg</option>
                  <option value={2}>Yes</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Total Berat
              </Form.Label>
              <Col sm={4}>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="basic-addon1"
                    type="number"
                    value={totalWeight}
                    onChange={(e) => setTotalWeight(parseInt(e.target.value))}
                  ></Form.Control>
                  <InputGroup.Text id="basic-addon1">Kg</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Total Ongkos Kirim
              </Form.Label>
              <Col sm={4}>
                <Row>
                  <Col>
                    <Form.Check
                      label="Ongkir Manual"
                      type="radio"
                      checked={totalOngkosKirimEnum === 0}
                      value={0}
                      onChange={() => setTotalOngkosKirimEnum(0)}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      checked={totalOngkosKirimEnum === 1}
                      value={1}
                      onChange={() => {
                        setTotalOngkosKirimEnum(1);
                        setTotalShippingCost(0);
                      }}
                      label="Gratis Ongkir"
                    />
                  </Col>
                </Row>
                <br />
                {totalOngkosKirimEnum === 0 ? (
                  <>
                    <NumberFormat
                      placeholder="Rp."
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      className="form-control"
                      inputMode="numeric"
                      prefix="Rp. "
                      value={totalShippingCost}
                      onValueChange={({ floatValue }) => {
                        let val = floatValue ?? 0;
                        setTotalShippingCost(val);
                      }}
                    />
                  </>
                ) : (
                  <NumberFormat
                    placeholder="Rp."
                    thousandSeparator={true}
                    className="bootstrap-input"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={totalShippingCost}
                    disabled
                  />
                )}
              </Col>
            </Form.Group>
            <div className="btn-footer-group">
              <Button
                className="btn"
                style={{ width: 135, height: 46 }}
                variant="info"
                onClick={onSubmit}
              >
                Save
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
    </Container>
  );
};

export default EditShipment;
