import axios from "axios";
import { endpoint } from "../../constants";

export default class InvoiceApi {

    static getInvoice() {
        return axios
            .get<any>(endpoint + "​/api/Invoice/GetList")
            .then((res) => res.data.data);
    }

    static getSalesOrderList() {
        return axios
            .get<any>(endpoint + "​​/api/SalesOrderDetail/GetList")
            .then((res) => res.data.data);
    }

    static getInvoiceBySONumber(SONumber : string) {
        return axios
            .get<any>(endpoint + "​/api/Invoice/GetSingleBySONumber?SONumber=" + SONumber)
            .then((res) => res.data.data);
    }

    static getSalesOrderDetail(salesOrderId : string) {
        return axios
            .get<any>(endpoint + "​/api/SalesOrder/GetMyOrderDetail?salesOrderId=" + salesOrderId)
            .then((res) => res.data.data.salesOrderDetail);
    }

    static getSalesOrderSingleDetail(id : string) {
        return axios
            .get<any>(endpoint + "​/api/SalesOrder/GetSingleWithDetail?id=" + id)
            .then((res) => res.data.data);
    }

}