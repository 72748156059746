export interface FilterModel {
  soNumber: string;
  customerName: string;
  orderDate: string;
  salesOrderStatus: string;
  paymentStatus: string;
  paymentType?: number | null;
  skip: number;
  take: number;
}

export interface SalesOrderModel {
  id: string;
  salesOrderId: string;
  orderDate: Date;
  dueDate: Date;
  paymentMethodId: string;
  statusOrder: number;
  shipmentCost: number;
  discount: number;
  discountPercentage: number;
  discountType: number;
  note: string;
  soNumber: string;
  transactionStatus: number;
  subTotal: number;
  grandTotal: number;
  qtyTotal: number;
  totalTax: number;
  taxPercentage: number;
  taxStatus: number;
  partnerId: string;
  orderType: number;
  adminFee: number;
  discountId: string;
  countNota: number;
  countExpedition: number;
  programDiscount: number;
  point: number;
  nominalPoint: number;
  kursPoint: number;
  ref: string;
  customerType: number;
  isPrintNote: boolean;
  isPrintExpedition: boolean;
  userId: string;
  isAddedPoint: boolean;
  isGetPoint: boolean;
  shippingStatus: number;
  paymentStatus: number;
}

export enum SalesOrderPaymentTypeEnum
{
  Cashless, NonCashless, Piutang, Cash,Non
}