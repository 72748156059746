import React, { useEffect, useState, useRef } from 'react'
import '../reportTotalPerformance.scss'
import { Container, Button } from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import { getLaporanTotalPerfomaStaff } from '../reportTotalPerformance.api';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { DatePicker, Space } from 'antd';
import {RootState} from "../../../../app/store";
import {Link} from "react-router-dom";
import {BsEyeFill} from "react-icons/bs";
import {moneyParser} from "../../../../helper/moneyParser";
import {totalStaffPerformance} from "../reportTotalPerformance.reducer";
const { RangePicker } = DatePicker;

export default function ReportTotalPerformancePage() {
  const dispatch = useDispatch()
  const { list, total } = useSelector((state: RootState) => state.totalStaffPerformance)
  const tableRef = useRef(null);

  const initialValues = {
    "adminName": "",
    "startDate": "",
    "endDate": "",
    "skip": 0,
    "take": '10',
    page: 1
  }

  const [values, setValues] = useState({
    "adminName": "",
    "startDate": "",
    "endDate": "",
    "skip": 0,
    "take": '10',
    page: 1
  })

  const getListPesanan = () => {
    const data = {
      "adminName": values.adminName,
      "startDate":  values.startDate === "" ? "" : new Date(values.startDate).toISOString(),
      "endDate": values.endDate === "" ? "" : new Date(values.endDate).toISOString(),
      "skip": values.skip,
      "take": parseInt(values.take)
    }
    dispatch<any>(getLaporanTotalPerfomaStaff(data))
  }

  useEffect(() => {
    getListPesanan()
  }, [values.take, values.skip, values.page])

  const handlePagination = (page: any) => {
    setValues({
      ...values,
      skip: parseInt(values.take) * (page - 1),
      page: page
    })
  }

  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const handleRadioChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: parseInt(e.target.value),
    })
  }
  const handlePerPage = (e: any) => {
    setValues({
      ...values,
      take: e.target.value,
      skip: 0,
      page: 1
    })
  }

  const handleRangePickupPicker = (event: any, picker: any) => {
    setValues({
      ...values,
      startDate: picker[0],
      endDate: picker[1],
    })
  }

  const handleSubmit = (e: any) => {
    const data = {
      "adminName": values.adminName,
      "startDate":  values.startDate === "" ? "" : new Date(values.startDate).toISOString(),
      "endDate": values.endDate === "" ? "" : new Date(values.endDate).toISOString(),
      "skip": 0,
      "take": parseInt(values.take)
    }
    setValues({
      ...values,
      page: 1,
    })

    dispatch<any>(getLaporanTotalPerfomaStaff(data))
    e.preventDefault()
  }

  const [isReset, setisReset] = useState(false)
  useEffect(() => {
    getListPesanan()
  }, [isReset])

  const handleReset = () => {
    setValues(initialValues)
    setisReset(!isReset)
  }

  return (
      <Container className="content-body orderDataReport" fluid>
        <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                <span style={{fontSize: '24px'}}>
                  Laporan Performa Staff
                </span>
        </div>
        <div className="box">
          <div className="head w-100">
            <form onSubmit={handleSubmit}>
              <div className="row w-100">
                <div className="col-md-5">
                  <div className="wrap-inp">
                    <label htmlFor="title">Tanggal</label>
                    <Space direction="vertical" size={12}>
                      <RangePicker
                          className='custext'
                          onChange={handleRangePickupPicker}
                          value={values.startDate !== "" && values.endDate !== "" ? [moment(values.startDate, 'YYYY-MM-DD'), moment(values.endDate, 'YYYY-MM-DD')] : undefined}
                          format="YYYY-MM-DD"
                      />
                    </Space>
                  </div>
                  <div className="wrap-inp">
                    <label htmlFor="title">Nama Admin</label>
                    <input className='custext' type="text" placeholder='Nama Admin' value={values.adminName} name="adminName" onChange={handleChange} id="" />
                  </div>
                </div>

                <div className="col-md-5 mr-auto">

                </div>
                <div className="wrapbutfil col-md-7">
                  <Button className='addor' onClick={handleReset}>Reset</Button>
                  <Button className='addorrev' type='submit'>Filter</Button>
                </div>
              </div>
            </form>
          </div>
          <div className="con" >
            <div className="filter d-flex justify-content-between align-items-center">
              Showing {list.length} of {total} results
              <div className="result d-flex justify-content-between align-items-center">
                <span>Results per page:</span>
                <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage} >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>

            <div style={{ overflowX: 'scroll' }} >
              <table id='table' className='table'>
                <thead className="">
                <tr>
                  {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                  <th scope="col">No</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Admin Ekspedisi</th>
                  <th scope="col">Admin Gudang</th>
                  <th scope="col">Admin Package</th>
                  <th scope="col">Admin QC</th>
                </tr>
                </thead>
                <tbody>
                {
                  list.map((res: any, i: number) => (
                      <tr key={i}>
                        {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                        <td>
                          {
                            values.skip === 0 ?
                                i + 1
                                :
                                i + 1 + (values.page - 1) * parseInt(values.take)
                          }
                        </td>
                        <td>{res?.name}</td>
                        <td>{res?.adminEkspedisi}</td>
                        <td>{res?.adminGudang}</td>
                        <td>{res?.adminPackage}</td>
                        <td>{res?.adminQC}</td>
                      </tr>
                  ))
                }

                </tbody>
              </table>

              <div style={{ display: "none" }}>
                <table id='table' className='table' ref={tableRef}>
                  <thead>
                  <tr className='mb-3' >
                    <th colSpan={100} >
                      <h4 >Laporan Performa Staff</h4>
                    </th>
                  </tr>
                  </thead>

                  <thead className="">
                  <tr>
                    {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                    <th scope="col">No</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Admin Ekspedisi</th>
                    <th scope="col">Admin Gudang</th>
                    <th scope="col">Admin Package</th>
                    <th scope="col">Admin QC</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    list.map((res: any, i: number) => (
                        <tr key={i}>
                          {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                          <td>
                            {
                              values.skip === 0 ?
                                  i + 1
                                  :
                                  i + 1 + (values.page - 1) * parseInt(values.take)
                            }
                          </td>
                          <td>{res?.name}</td>
                          <td>{res?.adminEkspedisi}</td>
                          <td>{res?.adminGudang}</td>
                          <td>{res?.adminPackage}</td>
                          <td>{res?.adminQC}</td>
                        </tr>
                    ))
                  }

                  </tbody>
                </table>
              </div>
            </div>


            <div className="d-flex justify-content-end">
              {/* <Pagination current={values.page} onChange={handlePagination} total={total} /> */}
              <Pagination
                  activePage={values.page}
                  itemClass='item'
                  itemsCountPerPage={parseInt(values.take)}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePagination}
                  prevPageText='⟨ PREVIOUS'
                  itemClassPrev='prev'
                  itemClassNext='next'
                  nextPageText='NEXT ⟩'
                  hideFirstLastPages
              />
            </div>
          </div>
        </div>
      </Container>
  )
}
