import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import logo from "../../../assets/logo.png";
import moment from "moment";
import { StockModel } from "../stockOpname.model";
import { getWarehouseStockByWarehouseWithPaging } from "../stockOpname.reducer";
import { useParams } from "react-router-dom";

const StockOpnamePrint = () => {
  const dispatch = useDispatch();

  const warehouseStockState = useSelector(
    (state: RootState) => state.stockOpname.warehouseStockList ?? [] as StockModel[] 
  );

  let { id } = useParams<{ id: string }>();

  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp " + result + ",-";
    }
    return value;
  };

  useEffect(() => {
    dispatch(
        getWarehouseStockByWarehouseWithPaging({
          warehouseId: id,
          variantName: "",
          inventorySKU: "",
          skip: 0,
          take: 1000,
        })
      );
  }, []);

  useEffect(() => {
    if(warehouseStockState.length !== 0){
        window.print();
    }
  }, [warehouseStockState])
  

  return (
    <div>
      <br />
      <h3 className="m-3">Stock Opname</h3>
      <h5 className="m-3">{warehouseStockState[0]?.warehouseName} </h5>
      <Table className="m-3" size="sm" bordered>
        <thead>
          <tr>
            <th>
              <b>SKU</b>
            </th>
            <th>
              <b>VARIAN</b>
            </th>
            <th>
              <b>STOCK SYSTEM</b>
            </th>
            <th>
              <b>STOCK GUDANG</b>
            </th>
            <th>
              <b>UOM</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {warehouseStockState.map((item) => (
            <tr>
            
              <td>{item.code}</td>
              <td>{item.variationName}</td>
              <td>{item.quantitySystem}</td>
              <td>{item.quantity}</td>
              <td>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StockOpnamePrint;
