import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../../assets/vector/edit.png";
import approve from "./../../../../assets/vector/approve.png";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { deleteData, getListWithPaging } from "../inventoryReceive.reducer";
import { getListWarehouse } from "../../../../global/global.reducer";
import { StringDecoder } from "string_decoder";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const InventoryReceiveTable = () => {
  const dispatch = useDispatch();

  const inventoryReceiveListState = useSelector(
    (state: RootState) => state.inventoryReceive.list ?? []
  );

  const warehouseListState = useSelector(
    (state: RootState) => state.global.listWarehouse ?? []
  );

  const totalRowState = useSelector(
    (state: RootState) => state.inventoryReceive.totalRow ?? 0
  );

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filterReceiveName, setFilterReceiveName] = useState("");
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const [filterTempStatus, setFilterTempStatus] = useState(3);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterFromWarehouse, setFilterFromWarehouse] = useState("");
  const [filterToWarehouse, setFilterToWarehouse] = useState("");
  const [filterInventoryMoveNumber, setFilterInventoryMoveNumber] = useState("");

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  // const filteredItems = inventoryReceiveListState.filter(
  //   (item) => item.receiveName && item.receiveName
  // );

  const onFromWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterFromWarehouse(e.target.value);
  };

  const onToWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterToWarehouse(e.target.value);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterReceiveName("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterFromWarehouse("");
    setFilterToWarehouse("");
    setFilterStatus(null);
    setFilterTempStatus(3);
    setFilterInventoryMoveNumber("");

    dispatch(
      getListWithPaging({
        receiveName: "",
        startDate: "",
        endDate: "",
        fromWarehouse: null,
        toWarehouse: null,
        status: null,
        inventoryMoveNumber : "",
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        receiveName: filterReceiveName,
        startDate: filterStartDate,
        endDate: filterEndDate,
        fromWarehouse: filterFromWarehouse === "" ? null : filterFromWarehouse,
        toWarehouse: filterToWarehouse === "" ? null : filterToWarehouse,
        status: filterStatus,
        inventoryMoveNumber : filterInventoryMoveNumber,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [inventoryReceiveListState]);

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getListWarehouse())
  }, []);


  const columns = [
    {
      name: "INVENTORY RECEIVE",
      selector: (d: { code: string }) => (
        <p style={{ margin: 10 }}>
          {d.code}
        </p>
      ),
      sortable: true,
    },

    {
      name: "INVENTORY MOVE",
      selector: (d: { inventoryNumber: string }) => (
        <p style={{ margin: 10 }}>
          {d.inventoryNumber}
        </p>
      ),
      sortable: true,
    },
    {
      name: "TANGGAL INVENTORY MOVE",
      selector: (d: { inventoryMoveDate: string }) => (
        <p style={{ margin: 10 }}>
          {d.inventoryMoveDate.substring(0, 10)}
        </p>
      ),
      sortable: true,
    },
    {
      name: "FROM WAREHOUSE",
      selector: "fromWarehouseName",
      sortable: true,
    },
    {
      name: "TO WAREHOUSE",
      selector: "toWarehouseName",
      sortable: true,
    },
    {
      name: "STATUS APPROVAL",
      selector: (d: { status: number }) => (
        <p style={{ margin: 10 }}>
          {d.status === 0 && <span className={"custom-badge-secondary"}>waiting for approval</span>}
          {d.status === 1 && <span className={"custom-badge-primary"}>Approved</span>}
          {d.status === 2 && <span className={"custom-badge-danger"}>Rejected</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "USER CREATE",
      selector: (d: { approvedBy : string, rejectedBy: string }) => (    
        <>
         {d.approvedBy === "" && <span>{d.rejectedBy}</span>}
         {d.rejectedBy === "" && <span>{d.approvedBy}</span>}

        </>
      ),
      sortable: true,
    },
    {
      name: "ROLE",
      selector: "roleName",
      sortable: true,
    },
    {
      name: "NOTE PENERIMAAN",
      selector: "approvalInventoryReceiveNote",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/approval-inventory-receive/${d.id}`,
            }}
          >
            <img src={approve}></img>
          </Link>
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>

        <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Receive Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterReceiveName}
                    onChange={(e) => {
                      setFilterReceiveName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  From
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onFromWarehouseChange}
                    value={filterFromWarehouse}
                  >
                    <option value="">Select</option>
                    {warehouseListState.map((Item) => {
                      return (
                        <option value={Item.id}>
                          {Item.code} - {Item.name} - {Item.city}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  To
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onToWarehouseChange}
                    value={filterToWarehouse}
                  >
                    <option value="">Select</option>
                    {warehouseListState.map((Item) => {
                      return (
                        <option value={Item.id}>
                          {Item.code} - {Item.name} - {Item.city}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterTempStatus}
                    onChange={(e) => {
                      if (e.target.value.toString() === "3") {
                        setFilterStatus(null);
                        setFilterTempStatus(parseInt(e.target.value));
                      } else {
                        setFilterTempStatus(parseInt(e.target.value));
                        setFilterStatus(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={3}>All</option>
                    <option value={0}>waiting for approval</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  IM Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterInventoryMoveNumber}
                    onChange={(e) => {
                      setFilterInventoryMoveNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row> */}

          <hr/>

          <DataTable
            columns={columns}
            data={inventoryReceiveListState}
            pagination
            paginationServer
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            progressPending={loading}
            customStyles={TableCustomStyles}
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default InventoryReceiveTable;
