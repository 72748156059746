import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import logo from "../../../assets/logo.png";
import moment from "moment";
import "../../../assets/styles/style-print.css";
import { SalesOrderSingleDetailModel } from "../../invoice/Invoice.model";
import { getSalesOrderSingleDetail } from "../../invoice/Invoice.reducer";
import { endpoint } from "../../../constants";
const BarcodeImagePrint = () => {


    const dispatch = useDispatch();

    useEffect(() => {
        let path = window.location.pathname;
        let id = path.split("/")[3];
        dispatch(getSalesOrderSingleDetail(id));
        setTimeout(() => {
          window.print();
        }, 1000);
      }, []);

  const salesOrderSingleDetail = useSelector(
    (state: RootState) =>
      state.invoice.salesOrderSingleDetail ??
      ({} as SalesOrderSingleDetailModel)
  );

  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp" + result + ",00";
    }
    return value;
  };


  return (
    <div><br />
    <img src={ endpoint + salesOrderSingleDetail?.salesOrder?.barcodeImage} />
    <br />
    <br /></div>
  )
}

export default BarcodeImagePrint