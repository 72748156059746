import { Form, Row, Col } from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { getListGrouping, getProductGroupingPerProduct, setLinkProductTempForm, setProductGroupingTempForm } from "../../product.reducer";
import { Select } from "antd";

const LinkProduct = ({ isEdit = false, productId = "", ...props }: LinkProductProps) => {
  const dispatch = useDispatch();

  const GroupingListPerProductState = useSelector(
    (state:RootState) => state.product.listGroupingPerProduct ?? []
  )

  const GroupingListState = useSelector(
    (state:RootState) => state.product.listGrouping ?? []
  )

  const LinkedProductsListState = useSelector(
    (state:RootState) => state.product.linkedProductsList ?? []
  )

  const [productCrossSell, setProductCrossSell] = useState("");
  const [productUpSell, setProductUpSell] = useState("");
  const [group, setGroup] = useState("");
  const [productGroup, setProductGroup] = useState<Array<string>>([]);

  const onProductCrossSellChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductCrossSell(e.target.value);
  };

  const onProductUpSellChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductUpSell(e.target.value);
  };

  const onGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGroup(e.target.value);
  };

  const handleGroupingChange = (e: any) => {
    
    // let diff_arr = e.filter((d: string) => !tag.includes(d));
    // axios
    //   .get<any>(
    //     endpoint +
    //       "/api/ConfigurationAttribute/GetListByProductAttribute?productAttributeId=" +
    //       diff_arr.toString()
    //   )
    //   .then((res) =>
    //     setListOfConfigurationTerms({
    //       ...listOfConfigurationTerms,
    //       ["property" + (e.length - 1)]: res.data.data,
    //     })
    //   );

    // setAttributePropList([
    //   ...attributePropList,
    //   { isVisible: false, isForVariation: false, arrayOfConfigureTermsId: [] },
    // ]);

   
    setProductGroup(e);
  };

  useEffect(() => {
    dispatch(
      setLinkProductTempForm({
        productCrossSell,
        productUpSell,
        group,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCrossSell]);

  useEffect(() => {
    dispatch(
      setLinkProductTempForm({
        productCrossSell,
        productUpSell,
        group,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productUpSell]);

  useEffect(() => {
    dispatch(
      setLinkProductTempForm({
        productCrossSell,
        productUpSell,
        group,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  // useEffect(() => {
  //   if(LinkedProductsListState.length > 0 ){
  //     setProductGroup(LinkedProductsListState)
  //   } else {
  //     setProductGroup([])
  //   }
  // }, [LinkedProductsListState])
  

  useEffect(() => {
    dispatch(getListGrouping())
    dispatch(getProductGroupingPerProduct(productId))
   
    if (isEdit) {

    } else {

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(GroupingListPerProductState)
    if(GroupingListPerProductState.length > 0){

      setProductGroup([])
      
      GroupingListPerProductState?.map((item) => {
        setProductGroup((prevState) => [
          ...prevState,
          item.id
        ])
      })
    }
  }, [GroupingListPerProductState])

  useEffect(() => {
    dispatch(setProductGroupingTempForm(productGroup))
  }, [productGroup])
  
  

  return (
    <div>
      <hr />
      <Form>
        {/* <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            Up - Sells
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              required
              onChange={onProductCrossSellChange}
              value={productCrossSell}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            Cross - Sells
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              required
              onChange={onProductUpSellChange}
              value={productUpSell}
            />
          </Col>
        </Form.Group> */}
        <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            Grouping
          </Form.Label>
          <Col sm={8}>

          <Select
              mode="multiple"
              placeholder="Please select"
              value={productGroup}
              onChange={handleGroupingChange}
              style={{ width: "100%" }}
              filterOption={(input : any, option : any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {GroupingListState.map((item) => (
                <Select.Option key={item.name} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            {/* <Form.Control
              type="text"
              placeholder=""
              required
              onChange={onGroupChange}
              value={group}
            /> */}
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};

export interface LinkProductProps {
  isEdit: boolean;
  productId: string;
}

export default LinkProduct;
