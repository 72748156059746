import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getApplicationSettingByType, postApplicationSetting, updateApplicationSetting } from './aplication.api'


export interface AplicationState {
  activeTab : string,
  loading : boolean
}
const initialState: AplicationState = {
  activeTab : 'Stockist',
  loading : false,
}

export const aplicationSlice = createSlice({
    name: 'aplication settings',
    initialState,
    reducers: {
        handleTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload
        },
        // decrement: (state) => {
        // state.value -= 1
        // },
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        // state.value += action.payload
        // },
    },
    extraReducers : (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        // builder.addCase(getCompany.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(getCompany.fulfilled, (state, action) => {
        //     state.loading = false
        //     state.listCompany = action.payload.data
        // })
        // builder.addCase(getCompany.rejected, (state, action) => {
        //     state.loading = false
        // })
        //post
        builder.addCase(postApplicationSetting.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postApplicationSetting.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postApplicationSetting.rejected, (state, action) => {
            state.loading = false
        })
        //get by type
        builder.addCase(getApplicationSettingByType.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getApplicationSettingByType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getApplicationSettingByType.rejected, (state, action) => {
            state.loading = false
        })
        //update
        builder.addCase(updateApplicationSetting.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(updateApplicationSetting.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateApplicationSetting.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
export const { handleTab} = aplicationSlice.actions

export default aplicationSlice.reducer