import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel, ProductCategoryModel, UploadImageModel } from "./productCategory.model";

export default class ProductCategoryApi {
    static createData(model: ProductCategoryModel) {
        return axios
        .post<any>(endpoint + "/api/ProductCategory/Create", model)
        .then((res) => res.data.data);
    }

    static getList(isActive: boolean) {
        return axios
        .get<any>(endpoint + "/api/ProductCategory/GetList?isActive=" + isActive)
        .then((res) => res.data.data);
    }

    static getListWithPaging(model: FilterModel) {
        return axios
        .post<any>(endpoint + "/api/ProductCategory/GetListWithPaging", model)
        .then((res) => res.data.data);
    }

    static getSingleItem(id: string) {
        return axios
        .get<any>(endpoint +"/api/ProductCategory/GetSingleItem?id=" + id)
        .then((res) => res.data.data);
    }

    static updateData(model: ProductCategoryModel) {
        return axios
        .post<any>(endpoint + "/api/ProductCategory/update", model)
        .then((res) => res.data.data);
    }

    static deleteData(id: string) {
        return axios
        .post<any>(endpoint + "/api/ProductCategory/Delete?Id=" + id)
        .then((res) => [
            { response: res.data.data },
        ]);
    }

    static uploadImage(model: UploadImageModel) {
        return axios
        .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
        .then((res) => res);
    }

    static getParentList() {
        return axios
        .get<any>(endpoint + "/api/ProductCategory/GetListForDropDown")
        .then((res) => res.data.data);
    }

}
