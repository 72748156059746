import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {getPartnerTypeEnum, setSelectedMembershipTierList, setSelectedRoleList} from "../voucher.reducer";
import { VoucherModel } from "../voucher.model";
import {getMembershipTier} from "../../../membersipTier/membershipTier.api";

const MembershipForm = (  ) => {
  const dispatch = useDispatch();

  const roleListState = useSelector(
    (state: RootState) => state.global.listRole ?? []
  );

  const membershipTiers = useSelector(
    (state: RootState) => state.membershipTier.membershipTiers ?? []
  );

  const singleDataState = useSelector(
    (state: RootState) => state.voucher.single ?? ({} as VoucherModel)
  );

  const [membership, setMembership] = useState<Array<string>>([]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      var array = singleDataState.membershipTierList?.split(",");
      setMembership(array ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getMembershipTier());
  }, []);


  const onCheck = (item: string) => {
    let newArr = [];
    if (!membership.includes(item)) {
      newArr = [...membership, item];
    } else {
      newArr = membership.filter((a) => a !== item);
    }
    setMembership(newArr ?? []);
    // dispatch(setSelectedRoleList(newArr.toString()));
  };

  useEffect(() => {
    if(membership && membership.length !== 0){
      dispatch(setSelectedMembershipTierList(membership.toString()));
    }
  }, [membership])

  return (
    <div>
      <Grid textAlign="center">
        <Card>
          <Card.Body>
            <Form>
              <section>
                <h6 className="input-padding">Membership</h6>
                <hr />
                <Form.Group as={Row} className="input-padding">
                  <Col sm={8}>
                    {membershipTiers.map((item) => {
                      return (
                        <Form.Check
                          label={item.name}
                          name="group1"
                          type="checkbox"
                          value={item.id.toString()}
                          onClick={() => onCheck(item.id.toString())}
                          checked={
                            membership?.some((val) => val === item.id.toString())
                          }
                          
                          // defaultChecked
                        />
                      );

                    })}
                  </Col>
                </Form.Group>
              </section>
            </Form>
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export default MembershipForm;
