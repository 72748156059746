import React, { useEffect, useState } from "react";
import { Container, Table, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import logo from "../../../assets/logo.png";
import moment from "moment";
import { AllStockReportModel, FilterModel, KartuStockModel, SummaryDataModel, VariantListModel } from "../laporanKartuStock.model";
import { getLaporanKartuStock } from "../laporanKartuStock.reducer";
import { UserModel } from "../../../../global/global.model";
import "../KartuStockPrint.css";

const KartuStockPrint = () => {
  const dispatch = useDispatch();

  const LaporanKartuStockState = useSelector(
   (state: RootState) =>
     state.laporanKartuStock.list ?? ([] as KartuStockModel[])
 );

 const SummaryDataState = useSelector(
   (state: RootState) =>
     state.laporanKartuStock.summaryData ?? ({} as SummaryDataModel)
 );

 const AllStockReportState = useSelector(
  (state: RootState) =>
    state.laporanKartuStock.allStockReport ?? ([] as AllStockReportModel[])
);

const filterDataState = useSelector(
  (state:RootState) => state.global.filterStockReport ?? {} as FilterModel
)

const userDataState = useSelector(
  (state:RootState) => state.user ?? {} as UserModel
)

useEffect(() => {

  setTimeout(() => {
    window.print();
  }, 500);


  // dispatch(
  //   getLaporanKartuStock({
  //     inventorySKU: filterDataState.inventorySKU,
  //     warehouseId: filterDataState.warehouseId,
  //     productId: filterDataState.productId,
  //     customerId: filterDataState.customerId,
  //     startDate: filterDataState.startDate,
  //     endDate: filterDataState.endDate,
  //     soNumber: filterDataState.soNumber,
  //     skip: filterDataState.skip,
  //     take: filterDataState.take,
  //   })
  // );
}, [])


  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp " + result + ",-";
    }
    return value;
  };

  return (
    <div className="m-3">
      <h3>Laporan Kartu Stock</h3>
      <h6>Printed by : {userDataState.username}</h6>
      <br />

      <div style={{ overflowX: "scroll", scrollbarWidth: "none" }}>
            {/* <h5 className="m-3">
              {" "}
              Produk :  {SummaryDataState?.stockStart[0].inventorySKU} - {SummaryDataState?.stockStart[0].productName}
            </h5> */}

            <h5 className="m-3 p-1">
              {" "}
              Produk :{" "}
              {
                AllStockReportState[0]?.list[0]?.variantList[0].inventorySKU
              } - {AllStockReportState[0]?.list[0]?.variantList[0].productName}
            </h5>

            {AllStockReportState.map((reportStockItem) => (
              <Card
                className="m-3"
                style={{ overflowX: "scroll", scrollbarWidth: "none" }}
              >
                <h6 className="m-2">
                  Warehouse : {reportStockItem.list[0]?.warehouseName}
                </h6>
                <Table className="m-2 table" size="sm" bordered>
                  <thead>
                    <tr>
                      <th rowSpan={2}>
                        <h6>NO</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>TANGGAL</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>SO NUMBER</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>NAMA CUSTOMER</h6>
                      </th>
                      <th
                        colSpan={
                          reportStockItem.list[0]?.variantList.length !==
                          undefined
                            ? reportStockItem.list[0]?.variantList.length
                            : reportStockItem.summary?.stockStart?.length
                        }
                      >
                        <h6>Varian</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>SO</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>PRINT</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>GD</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>QC</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>PK</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>EXP</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>STATUS</h6>
                      </th>
                      <th rowSpan={2}>
                        <h6>KETERANGAN</h6>
                      </th>
                    </tr>
                    <tr>
                      {reportStockItem.list[0]?.variantList.length ===
                        undefined &&
                        reportStockItem.summary?.stockStart?.map(
                          (variantItem: VariantListModel) => (
                            <td>{variantItem.variantName}</td>
                          )
                        )}

                      {reportStockItem.list[0]?.variantList.length !== 0 &&
                        reportStockItem.list[0]?.variantList.map(
                          (variantItem: VariantListModel) => (
                            <td>{variantItem.variantName}</td>
                          )
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {reportStockItem.list.map(
                      (item: KartuStockModel, index: number) => (
                        <tr>
                          <td>
                            {!item.isRetur && <p> {index + 1}</p>}
                            {item.isRetur && (
                              <p style={{ color: "red" }}> {index + 1}</p>
                            )}
                          </td>
                          <td>
                            {!item.isRetur && (
                              <p>
                                {" "}
                                {moment(new Date(item.orderDate)).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            )}
                            {item.isRetur && (
                              <p style={{ color: "red" }}>
                                {" "}
                                {moment(new Date(item.orderDate)).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            )}
                          </td>

                          <td>
                            {!item.isRetur && <p> {item.soNumber}</p>}
                            {item.isRetur && (
                              <p style={{ color: "red" }}> {item.soNumber}</p>
                            )}
                          </td>

                          <td>
                            {!item.isRetur && <p> {item.customerName}</p>}
                            {item.isRetur && (
                              <p style={{ color: "red" }}>
                                {" "}
                                {item.customerName}
                              </p>
                            )}
                          </td>

                          {item.variantList.length !== 0 &&
                            item.variantList.map(
                              (variantItem: VariantListModel) => (
                                <td>
                                  {!item.isRetur && <p> {variantItem.qty}</p>}
                                  {item.isRetur && (
                                    <p style={{ color: "red" }}>
                                      {" "}
                                      {variantItem.qty}
                                    </p>
                                  )}
                                </td>
                              )
                            )}

                          {item.variantList.length === 0 &&
                            reportStockItem.summary.stockStart.map(
                              (variantItem: VariantListModel) => (
                                <td>
                                  {!item.isRetur && <p> </p>}
                                  {item.isRetur && (
                                    <p style={{ color: "red" }}> </p>
                                  )}
                                </td>
                              )
                            )}

                          <td>-</td>
                          <td>-</td>
                          <td>{item.adminGudang}</td>
                          <td>{item.adminQC}</td>
                          <td>{item.adminPacking}</td>
                          <td>{item.adminEkspedisi}</td>
                          <td>{item.transactionStatus}</td>
                          <td>-</td>
                        </tr>
                      )
                    )}

                    <tr>
                      <td
                        colSpan={
                          reportStockItem.list[0]?.variantList.length + 12
                        }
                        height={30}
                      ></td>
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>STOCK AWAL</h6>
                        </td>

                        {reportStockItem.summary?.stockStart?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>RETUR</h6>
                        </td>

                        {reportStockItem.summary?.retur?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>COMPLAINT</h6>
                        </td>

                        {reportStockItem.summary?.complaint?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>KEEP</h6>
                        </td>

                        {reportStockItem.summary?.keep?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>STOCK OPNAME</h6>
                        </td>

                        {reportStockItem.summary?.stockOpname?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>INTERNAL USE</h6>
                        </td>

                        {reportStockItem.summary?.internalUse?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>INVENTORY MOVE</h6>
                        </td>

                        {reportStockItem.summary?.inventoryMove?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>ITEM RECEIVE</h6>
                        </td>

                        {reportStockItem.summary?.itemReceive?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>SO</h6>
                        </td>

                        {reportStockItem.summary?.salesOrder?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>STOCK AKHIR</h6>
                        </td>

                        {reportStockItem.summary?.stockEnd?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    <tr>
                        <td colSpan={4}>
                            <h6>CUSTOMER KEEP</h6>
                        </td>

                        {reportStockItem.summary?.keepLive?.map(
                            (variantItem: VariantListModel) => (
                                <td>{variantItem.qty}</td>
                            )
                        )}
                    </tr>

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>RETUR</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.returStock?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>STOCK IN</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.updateStockIn?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>STOCK OUT</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.updateStockOut?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td colSpan={4}>*/}
                    {/*        <h6>KEEP IN</h6>*/}
                    {/*    </td>*/}

                    {/*    {reportStockItem.summary?.keepIn?.map(*/}
                    {/*        (variantItem: VariantListModel) => (*/}
                    {/*            <td>{variantItem.qty}</td>*/}
                    {/*        )*/}
                    {/*    )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td colSpan={4}>*/}
                    {/*        <h6>KEEP OUT</h6>*/}
                    {/*    </td>*/}

                    {/*    {reportStockItem.summary?.keepOut?.map(*/}
                    {/*        (variantItem: VariantListModel) => (*/}
                    {/*            <td>{variantItem.qty}</td>*/}
                    {/*        )*/}
                    {/*    )}*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*  <td colSpan={4}>*/}
                    {/*    <h6>SISA STOCK</h6>*/}
                    {/*  </td>*/}

                    {/*  {reportStockItem.summary?.stockEnd?.map(*/}
                    {/*    (variantItem: VariantListModel) => (*/}
                    {/*      <td>{variantItem.qty}</td>*/}
                    {/*    )*/}
                    {/*  )}*/}
                    {/*</tr>*/}
                  </tbody>
                </Table>
              </Card>
            ))}
          </div>
      
    </div>
  );
};

export default KartuStockPrint;
