import React, {useState} from 'react';
import {AppDispatch} from "../../app/store";
import {useDispatch} from "react-redux";
import {getListDeliveredWithFilter, getProductDropdown} from "../../global/global.reducer";
import {unwrapResult} from "@reduxjs/toolkit";
import {ProductOptionModel} from "../../features/report/laporanKartuStock/laporanKartuStock.model";
import {SOListDeliveredOptionModel} from "../../global/global.model";

const useAsyncSalesOrderOption = () => {
    const dispatch : AppDispatch = useDispatch();

    const getListDeliveredOptions = async (inputValue: string) => {
        const resultAction = await dispatch(getListDeliveredWithFilter({
            soNumber: inputValue.toLowerCase() === "" ? "so" : inputValue.toLowerCase(),
        }));

        const originalPromiseResult = unwrapResult(resultAction);
        let _option = originalPromiseResult?.map((item: any) => {
            return {
                ...item,
                value: item.id,
                label: item.soNumber,
            }
        });
        return _option as SOListDeliveredOptionModel[];
    };

    return {getListDeliveredOptions} as const;
};

export default useAsyncSalesOrderOption;
