import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../constants'
import {
    ConfirmMembershipTierPaymentRequest,
    GetMembershipTierTransactionRequest,
    RejectMembershipTierPaymentRequest
} from "./membershipTier.types";

export const getMembershipTier = createAsyncThunk('membershipTier/getMembershipTier', async () => {
    try {
        const response = await axios.get(`${endpoint}/api/MembershipTier/GetList`)
        return response.data

    }
    catch (error: any) {
        return error.response
    }
});

export const getMembershipTierTransaction = createAsyncThunk('membershipTier/getMembershipTierTransaction', async (args: GetMembershipTierTransactionRequest) => {
    try {
        const response = await axios.get(`${endpoint}/api/MembershipTier/GetListTransaction`, {
            params: args
        })
        return response.data

    }
    catch (error: any) {
        return error.response
    }
});

export const confirmPayment = createAsyncThunk('membershipTier/confirmPayment', async (args: ConfirmMembershipTierPaymentRequest) => {
    try {
        const response = await axios.post(`${endpoint}/api/MembershipTier/ConfirmPayment`, args)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
});

export const rejectPayment = createAsyncThunk('membershipTier/rejectPayment', async (args: RejectMembershipTierPaymentRequest) => {
    try {
        const response = await axios.post(`${endpoint}/api/MembershipTier/RejectPayment`, args)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
});
