import React, { useEffect, useState, useRef } from 'react'
import '../orderDataReport.scss'
import { Container, Form, Button } from "react-bootstrap";
import { FiEdit, FiPlus, FiPrinter, FiTrash } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { deleteSlideShow, getSlideShow } from '../slideShow.api';
// import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaFileExport } from 'react-icons/fa';
// import { postStockistDataPesanan } from '../stockistOrderDataReport.api';
import moment from 'moment';
import Pagination from "react-js-pagination";
import ReactToPrint from 'react-to-print';

import { RootState } from '../../../../app/store';
import { DatePicker, Space } from 'antd';
import {transactionStatus} from "../constants";
import Select from "react-select";
import {
    getPartnerByAdminStockistType,
    getPartnerByStockistType,
    getStockistDataPesanan
} from "../stockistOrderDateReport.reducer";
import {Option} from "../stockistOrderDataReport.models";
const { RangePicker } = DatePicker;

export default function StockistOrderDataReport() {
    // const history = useHistory()
    const dispatch = useDispatch()
    // const { list, total } = useSelector((state: RootState) => state.orderDataReport)
    // const [list, setListData] = useState([]);
    // const [total, setTotal] = useState(0);
    const tableRef = useRef(null);

    const [partnerOptions, setPartnerOptions] = useState<Option[]>([]);

    const list = useSelector(
        (state: RootState) => state.stockistOrderDataReport.list ?? []
    );
    const total = useSelector(
        (state: RootState) => state.stockistOrderDataReport.totalRow ?? 0
    );
    const adminStockistOptions = useSelector(
        (state: RootState) => state.stockistOrderDataReport.adminStockistOptions ?? []
    );
    const stockistOptions = useSelector(
        (state: RootState) => state.stockistOrderDataReport.stockistOptions ?? []
    );
    const isLoading = useSelector(
        (state: RootState) => state.stockistOrderDataReport.isLoading
    );
    const isAdminStockistOptionsLoading = useSelector(
        (state: RootState) => state.stockistOrderDataReport.isAdminStockistOptionsLoading
    );
    const isStockistOptionsLoading = useSelector(
        (state: RootState) => state.stockistOrderDataReport.isStockistOptionsLoading
    );

    useEffect(() => {
        dispatch(getPartnerByAdminStockistType());
        dispatch(getPartnerByStockistType());
    }, []);

    useEffect(() => {
        if ((!isAdminStockistOptionsLoading && !isStockistOptionsLoading) && (adminStockistOptions.length > 0 || stockistOptions.length > 0)) {
            const options = adminStockistOptions.concat(stockistOptions);
            setPartnerOptions(options);
        }

    }, [isAdminStockistOptionsLoading, isStockistOptionsLoading,  adminStockistOptions, stockistOptions]);



    const initialValues = {
        "partnerId": "null",
        "groupId": "null",
        "skip": 0,
        "take": '10',
        page: 1
    }

    const [values, setValues] = useState({
        "partnerId": "null",
        "groupId": "null",
        "skip": 0,
        "take": '10',
        page: 1
    })

    const getListPesanan = () => {
        const data = {
            "partnerId": values.partnerId === "null" ? null : values.partnerId,
            "groupId": values.groupId === "null" ? null : values.groupId,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(getStockistDataPesanan(data));
        //     .then(unwrapResult)
        //     .then((res: any) => {
        //         console.log(res.list);
        //         setListData(res.list);
        //         setTotal(res.total);
        //         setIsLoading(false);
        //     })
        //     .catch((err: any) => {
        //         setIsLoading(false);
        //     })
    }

    useEffect(() => {
        getListPesanan()
    }, [values.take, values.skip, values.page])

    const handlePagination = (page: any) => {
        setValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        })
    }


    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
        console.log(e.target.name)
        console.log(e.target.value)
    }

    const handleChangeOption = (data: any, name: string) => {
        setValues({
            ...values,
            [name]: data?.value ?? "null",
        })
    }

    const handlePerPage = (e: any) => {
        setValues({
            ...values,
            take: e.target.value,
            skip: 0,
            page: 1
        })
    }

    const handleSubmit = (e: any) => {
        const data = {
            "partnerId": values.partnerId === "null" ? null : values.partnerId,
            "groupId": values.groupId === "null" ? null : values.groupId,
            "skip": 0,
            "take": parseInt(values.take)
        }
        setValues({
            ...values,
            page: 1,
        })
        dispatch<any>(getStockistDataPesanan(data));

        // dispatch<any>(postDataPesanan(data))

        //Revision By: MulyoDev
        // dispatch<any>(postStockistDataPesanan(data))
        //     .then(unwrapResult)
        //     .then((res: any) => {
        //         setIsLoading(false);
        //         // console.log(res.list)
        //         setListData(res.list)
        //         setTotal(res.total)
        //     })
        //     .catch((err: any) => {
        //         setIsLoading(false);
        //     })
        e.preventDefault()
    }

    const [isReset, setisReset] = useState(false)
    useEffect(() => {
        getListPesanan()
    }, [isReset])

    const handleReset = () => {
        setValues(initialValues)
        setisReset(!isReset)
    }

    return (
        <Container className="content-body orderDataReport" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                <span style={{fontSize: '24px'}}>
                    Laporan Order & Keep
                </span>
            </div>
            <div className="box">
                <div className="head w-100">
                    <form onSubmit={handleSubmit}>
                        <div className="row w-100">
                            <div className="col-md-5">
                                <div className="wrap-inp">
                                    <label htmlFor="title">Filter Partner</label>
                                    <Select
                                        className="basic-single w-75"
                                        classNamePrefix="select"
                                        isLoading={isStockistOptionsLoading || isAdminStockistOptionsLoading}
                                        isClearable
                                        isSearchable
                                        name="partnerId"
                                        options={partnerOptions}
                                        onChange={(data: any) => handleChangeOption(data, 'partnerId')}
                                    />
                                </div>
                                <div className="wrap-inp">
                                    <label htmlFor="title">Filter Group</label>
                                    <Select
                                        className="basic-single w-75"
                                        classNamePrefix="select"
                                        isLoading={isStockistOptionsLoading}
                                        isClearable
                                        isSearchable
                                        name="groupId"
                                        options={stockistOptions}
                                        onChange={(data: any) => handleChangeOption(data, 'groupId')}
                                    />
                                </div>
                                <div className="wrapbutfil">
                                    <Button className='addor' onClick={handleReset}>Reset</Button>
                                    <Button className='addorrev' type='submit'>Filter</Button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div className="con" >
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing {list.length} of {total} results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span>
                            <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage} >
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>

                    <div style={{ overflowX: 'scroll' }} >
                        <table id='table' className='table'>

                            {/* <thead style={{ display: "none" }}>
                                    <tr className='mb-3' >
                                        <th colSpan={100} >
                                            <h4 >Laporan Data Pesanan</h4>
                                        </th>
                                    </tr>
                                </thead> */}

                            <thead className="">
                                <tr>
                                    {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                    <th scope="col">No</th>
                                    <th scope="col">Tanggal pesanan</th>
                                    <th scope="col">Pembeli</th>
                                    <th scope="col">Admin Group</th>
                                    <th scope="col">Type Customer</th>
                                    <th scope="col">SO Number</th>
                                    <th scope="col">Kode produk</th>
                                    <th scope="col">Produk</th>
                                    <th scope="col">Varian</th>
                                    <th scope="col">QTY</th>
                                    <th scope="col">Subtotal</th>
                                    <th scope="col">Status Pesanan</th>
                                    <th scope="col">Status Bayar</th>
                                    <th scope="col">Status Transaksi</th>
                                    <th scope="col">Catatan SO</th>
                                    <th scope="col">nama pengirim</th>
                                </tr>
                            </thead>
                            {
                                isLoading ?
                                    <tbody>
                                    <tr>
                                        <td colSpan={14}>
                                            <h4 className="text-center">Loading...</h4>
                                        </td>
                                    </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                    {
                                        list.map((res: any, i: number) => (
                                            <tr key={i}>
                                                {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                                <td>
                                                    {
                                                        values.skip === 0 ?
                                                            i + 1
                                                            :
                                                            i + 1 + (values.page - 1) * parseInt(values.take)
                                                    }
                                                </td>
                                                <td>{moment(res?.orderDate).format('DD/MM/YYYY')}</td>
                                                <td>{res?.customerName}</td>
                                                <td>{res?.groupName}</td>
                                                <td>
                                                    {
                                                        res?.type === 1 ? 'Stockist' :
                                                            res?.type === 2 ? 'Reseller' :
                                                                res?.type === 3 ? 'Admin' :
                                                                    res?.type === 4 ? 'Admin Stokist' :
                                                                        res?.type === 0 ? 'End Customer' : null
                                                    }
                                                </td>
                                                <td>{res?.soNumber}</td>
                                                <td>{res?.productCode}</td>
                                                <td>{res?.productName}</td>
                                                <td>{res?.variationName}</td>
                                                <td>{res?.quantity}</td>
                                                <td>Rp{res?.subTotal}</td>
                                                <td>{res?.statusOrder === 0 ? 'Keep' : 'Dropship'}</td>
                                                <td>{res?.paymentStatus === 0 ? 'Belum Lunas' : 'Lunas'}</td>
                                                <td>{transactionStatus.find(item => item.value === res?.transactionStatus.toString())?.label}</td>
                                                <td>{res?.note}</td>
                                                <td>{res?.senderName}</td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>
                            }
                        </table>

                        <div style={{ display: "none" }}>
                            <table id='table' className='table' ref={tableRef}>
                                <thead>
                                    <tr className='mb-3' >
                                        <th colSpan={100} >
                                            <h4 >Laporan Data Pesanan</h4>
                                        </th>
                                    </tr>
                                </thead>

                                <thead className="">
                                    <tr>
                                        {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                        <th scope="col">No</th>
                                        <th scope="col">Tanggal pesanan</th>
                                        <th scope="col">Pembeli</th>
                                        <th scope="col">Admin Group</th>
                                        <th scope="col">Type Customer</th>
                                        <th scope="col">SO Number</th>
                                        <th scope="col">Kode produk</th>
                                        <th scope="col">Produk</th>
                                        <th scope="col">Varian</th>
                                        <th scope="col">QTY</th>
                                        <th scope="col">Subtotal</th>
                                        <th scope="col">Status Pesanan</th>
                                        <th scope="col">Status Bayar</th>
                                        <th scope="col">Status Transaksi</th>
                                        <th scope="col">Catatan SO</th>
                                        <th scope="col">nama pengirim</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((res: any, i: number) => (
                                            <tr key={i}>
                                                {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                                <td>
                                                    {
                                                        values.skip === 0 ?
                                                            i + 1
                                                            :
                                                            i + 1 + (values.page - 1) * parseInt(values.take)
                                                    }
                                                </td>
                                                <td>{moment(res.orderDate).format('DD/MM/YYYY')}</td>
                                                <td>{res?.customerName}</td>
                                                <td>{res?.groupName}</td>
                                                <td>
                                                    {
                                                        res?.type === 1 ? 'Stockist' :
                                                            res?.type === 2 ? 'Reseller' :
                                                                res?.type === 3 ? 'Admin' :
                                                                    res?.type === 4 ? 'Admin Stockist' :
                                                                        res?.type === 0 ? 'End Customer' : null
                                                    }
                                                </td>
                                                <td>{res?.soNumber}</td>
                                                <td>{res?.productCode}</td>
                                                <td>{res?.productName}</td>
                                                <td>{res?.variationName}</td>
                                                <td>{res?.quantity}</td>
                                                <td>Rp{res?.subTotal}</td>
                                                <td>{res?.statusOrder === 0 ? 'Keep' : 'Dropship'}</td>
                                                <td>{res?.paymentStatus === 0 ? 'Belum Lunas' : 'Lunas'}</td>
                                                <td>{transactionStatus.find(item => item.value === res?.transactionStatus.toString())?.label}</td>
                                                <td>{res?.note}</td>
                                                <td>{res?.senderName}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="d-flex justify-content-end">
                        {/* <Pagination current={values.page} onChange={handlePagination} total={total} /> */}
                        <Pagination
                            activePage={values.page}
                            itemClass='item'
                            itemsCountPerPage={parseInt(values.take)}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePagination}
                            prevPageText='⟨ PREVIOUS'
                            itemClassPrev='prev'
                            itemClassNext='next'
                            nextPageText='NEXT ⟩'
                            hideFirstLastPages
                        />
                    </div>

                </div>
            </div>
        </Container>
    )
}
