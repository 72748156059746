import { Card, Form, Row } from "react-bootstrap";
import "../product.css";
import { useEffect, useState } from "react";
import { getListCollectionTag, setProductTagsTempForm } from "../product.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { Select } from "antd";
import { ProductModel } from "../product.model";

const ProductTagsForm = ({
  data = {} as ProductModel,
  isEdit = false,
  ...props
}: TabsTypeProps) => {
  const dispatch = useDispatch();

  const collectionTagState = useSelector(
    (state: RootState) => state.product.listCollectionTag ?? []
  );

  useEffect(() => {
    dispatch(getListCollectionTag());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tag, setTag] = useState<Array<string>>([]);

  useEffect(() => {
    dispatch(setProductTagsTempForm(tag));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const handleChange = (e: any) => {
    setTag(e);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setTag(data.productCollectionTagList);
    }
    // else {
    //   setTag([]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Group as={Row}>
            <Form.Label
              style={{
                fontWeight: 610,
                fontSize: 17,
                paddingTop: 0,
                paddingBottom: 0,
              }}
              column
              sm={12}
            >
              Product Tags
            </Form.Label>
          </Form.Group>
          <hr />
          <Form>
            <section>
              <Select
                mode="multiple"
                placeholder="Collection Tag"
                value={tag}
                onChange={handleChange}
                style={{ width: "100%" }}
              >
                {collectionTagState.map((item) => (
                  <Select.Option key={item.name} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>

              <Form.Label
                className="card-subtitle"
                style={{
                  cursor: "pointer",
                  color: "#00A8CC",
                  fontSize: 14,
                  paddingTop: 15,
                }}
              >
                Choose from the most used Product Tags
              </Form.Label>
            </section>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export interface TabsTypeProps {
  data: ProductModel;
  isEdit: boolean;
}

export default ProductTagsForm;
