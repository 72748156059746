import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import ExpeditionApi from "./Expedition.api";
import { ExpeditionModel, UploadImageModel } from "./Expedition.model";

export interface ExpeditionSlice {
    single? : ExpeditionModel;
    list? : ExpeditionModel[];
    iconPath? : string;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getExpeditionList = createAsyncThunk(
    'getExpeditionListState/getExpeditionList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await ExpeditionApi.getExpeditionList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleExpedition = createAsyncThunk(
    'getSingleExpeditionState/getSingleExpedition',
    async (expeditionId : string, { rejectWithValue }) => {
        try {
            return await ExpeditionApi.getSingleExpedition(expeditionId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const uploadPhoto = createAsyncThunk(
    'UploadIconState/UploadIcon',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await ExpeditionApi.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const createExpedition = createAsyncThunk(
    'CreateExpeditionState/CreateExpedition',
    async (model: ExpeditionModel, { rejectWithValue }) => {
        try {
            return await ExpeditionApi.createExpedition(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updateExpedition = createAsyncThunk(
    'UpdateExpeditionState/UpdateExpedition',
    async (model: ExpeditionModel, { rejectWithValue }) => {
        try {
            return await ExpeditionApi.updateExpedition(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteExpedition = createAsyncThunk(
    'DeleteExpeditionState/DeleteExpedition',
    async (expeditionId:string, { rejectWithValue }) => {
        try {
            return await ExpeditionApi.deleteExpedition(expeditionId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const ExpeditionSlice = createSlice({
    name : "ExpeditionState",
    initialState: {} as ExpeditionSlice,
    reducers: {
        resetSingle: (state) => {
            state.single = {} as ExpeditionModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getExpeditionList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getExpeditionList.fulfilled, (state, { payload }) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getExpeditionList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSingleExpedition.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSingleExpedition.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.isLoading = false;
        });
        builder.addCase(getSingleExpedition.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(uploadPhoto.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhoto.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.iconPath = payload.data.imagepath;
        })
        builder.addCase(uploadPhoto.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(createExpedition.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(createExpedition.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success";
        })
        builder.addCase(createExpedition.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(updateExpedition.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateExpedition.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-update";
        })
        builder.addCase(updateExpedition.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteExpedition.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteExpedition.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-delete";
        })
        builder.addCase(deleteExpedition.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
    }
})

export const {resetSingle,resetStatus} = ExpeditionSlice.actions;
export default ExpeditionSlice.reducer;