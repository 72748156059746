import { Form, Row, Col } from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSimpleProductInventoryTempForm } from "../../product.reducer";
import { ProductModel } from "../../product.model";

const InventoryProduct = ({
  data = {} as ProductModel,
  productType = 0,
  ...props
}: TabsTypeProps) => {
  const dispatch = useDispatch();

  // const [inventorySKU, setInventorySKU] = useState("");
  const [isManageStockInventory, setIsManageStockInventory] = useState(true);
  const [isSoldindivitualyInventory, setIsSoldindivitualyInventory] =
    useState(true);
  const [stockStatusInventory, setStockStatusInventory] = useState(0);
  const [uomType, setUomType] = useState(0);

  // const onInventorySKUChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setInventorySKU(e.target.value);
  // };

  const onStockStatusInventoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStockStatusInventory(parseInt(e.target.value));
  };

  const onUomType = (e: ChangeEvent<HTMLInputElement>) => {
    setUomType(parseInt(e.target.value));
  };

  // useEffect(() => {
  //   dispatch(
  //     setSimpleProductInventoryTempForm({
  //       inventorySKU,
  //       isManageStockInventory,
  //       stockStatusInventory,
  //       isSoldindivitualyInventory,
  //     })
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inventorySKU]);

  useEffect(() => {
    dispatch(
      setSimpleProductInventoryTempForm({
        // inventorySKU,
        uom: uomType,
        isManageStockInventory,
        stockStatusInventory,
        isSoldindivitualyInventory,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManageStockInventory]);

  useEffect(() => {
    dispatch(
      setSimpleProductInventoryTempForm({
        // inventorySKU,
        uom: uomType,
        isManageStockInventory,
        stockStatusInventory,
        isSoldindivitualyInventory,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uomType]);

  useEffect(() => {
    dispatch(
      setSimpleProductInventoryTempForm({
        // inventorySKU,
        uom: uomType,
        isManageStockInventory,
        stockStatusInventory,
        isSoldindivitualyInventory,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockStatusInventory]);

  useEffect(() => {
    dispatch(
      setSimpleProductInventoryTempForm({
        // inventorySKU,
        uom: uomType,
        isManageStockInventory,
        stockStatusInventory,
        isSoldindivitualyInventory,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoldindivitualyInventory]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      // setInventorySKU(data.inventorySKU);
      setUomType(data.uom);
      setIsManageStockInventory(data.isManageStockInventory);
      setIsSoldindivitualyInventory(data.isSoldindivitualyInventory);
      setStockStatusInventory(data.stockStatusInventory);
    } else {
      // setInventorySKU("");
      setUomType(0);
      setIsManageStockInventory(true);
      setIsSoldindivitualyInventory(true);
      setStockStatusInventory(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <hr />
      <Form>
        {/* <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            SKU
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              required
              onChange={onInventorySKUChange}
              value={inventorySKU}
            />
          </Col>
        </Form.Group> */}

        <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            UOM
          </Form.Label>
          <Col sm={4}>
            <Form.Control as="select" onChange={onUomType} value={uomType}>
              <option value={0}>PCS</option>
              <option value={1}>SERIAN</option>
            </Form.Control>
          </Col>
        </Form.Group>

        {/* {productType === 0 && (
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Manage Stock?
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                label="Enable Stock Management at Product Level"
                name="group1"
                type="checkbox"
                defaultChecked
                onChange={(e) => {
                  setIsManageStockInventory(e.target.checked);
                }}
              />
            </Col>
          </Form.Group> */}
        {/* )} */}

        {/* {productType === 3 && (
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Manage Stock?
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                label="Enable Stock Management at Product Level"
                name="group1"
                type="checkbox"
                defaultChecked
                onChange={(e) => {
                  setIsManageStockInventory(e.target.checked);
                }}
              />
            </Col>
          </Form.Group>
        )} */}

        {productType === 0 && (
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Stock Status
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={onStockStatusInventoryChange}
                value={stockStatusInventory}>
                <option value={0}>In Stock</option>
                <option value={1}>Out of Stock</option>
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {productType === 1 && (
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Stock Status
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={onStockStatusInventoryChange}
                value={stockStatusInventory}>
                <option value={0}>In Stock</option>
                <option value={1}>Out of Stock</option>
              </Form.Control>
            </Col>
          </Form.Group>
        )}

        {productType === 3 && (
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Enable Review
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                label="Enable this only one item of this item brought in single order"
                name="group1"
                type="checkbox"
                defaultChecked
                onChange={(e) => {
                  setIsSoldindivitualyInventory(e.target.checked);
                }}
              />
            </Col>
          </Form.Group>
        )}
      </Form>
    </div>
  );
};

export interface TabsTypeProps {
  data: ProductModel;
  productType: number;
}

export default InventoryProduct;
