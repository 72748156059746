import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import edit from "./../../../assets/vector/edit.png";
import hapus from "./../../../assets/vector/delete.png";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import AlertMessage from "../../../components/alertMessage";
import DeleteMessage from "../../../components/DeleteMessage";

import { RootState } from "../../../app/store";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {
  deleteAdminStockist,
  getAdminStockistList,
  resetStatus,
} from "../adminStockist.reducer";
import { AdminStockistModel } from "../adminStockist.model";
import { BsEyeFill } from "react-icons/bs";
import moment from "moment";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const AdminStockistTable = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleterAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });
  const [selectedId, setSelectedId] = useState("");
  const adminStockistListState = useSelector(
    (state: RootState) => state.adminStockist.list ?? []
  );

  const statusState = useSelector(
    (state: RootState) => state.adminStockist.status ?? ""
  );

  const [filterText, setFilterText] = useState("");
  const [filterStatus, setFilterStatus] = useState("0");

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filteredAdminStockistList, setFilteredAdminStockistList] = useState<
    AdminStockistModel[]
  >([]);

  useEffect(() => {
    setFilteredAdminStockistList(
      adminStockistListState.filter(
        (item) =>
          (item.adminName.toLowerCase().includes(filterText.toLowerCase()) ||
            item.stockistName
              .toLowerCase()
              .includes(filterText.toLowerCase())) &&
          ((filterStatus === "0" && item.isActive === true) ||
            (filterStatus === "1" && item.isActive === false) ||
            (filterStatus === "2" &&
              (item.isActive === true || item.isActive === false)))
      )
    );
  }, [filterText, filterStatus]);

  useEffect(() => {
    dispatch(getAdminStockistList());
  }, []);

  useEffect(() => {
    if (statusState === "success-delete") {
      dispatch(getAdminStockistList());
      setAlertProps({
        variant: "success",
        title: "Data Admin Stockist Menjadi Inactive",
        content: " ",
      });
      setShowAlert(true);
    } else if (statusState === "success-get-list") {
      // setFilteredAdminStockistList(
      //   adminStockistListState.filter(
      //     (item) =>
      //       (item.adminName.toLowerCase().includes(filterText.toLowerCase()) ||
      //         item.stockistName
      //           .toLowerCase()
      //           .includes(filterText.toLowerCase())) &&
      //       ((filterStatus === "0" && item.isActive === true) ||
      //         (filterStatus === "1" && item.isActive === false) ||
      //         (filterStatus === "2" &&
      //           (item.isActive === true || item.isActive === false)))
      //   )
      // );
    }
  }, [statusState]);

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteAdminStockist(id));
    }
  };

  const onDeleteData = () => {
    setShowDeleteAlert(false);
    dispatch(deleteAdminStockist(selectedId));
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  const onAlertClose = () => {
    setShowAlert(false);
    dispatch(resetStatus());
  };

  const onDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    dispatch(resetStatus());
  };

  const columns = [
    {
      name: "ID STOCKIST",
      selector: "stockistId",
      sortable: true,
    },
    {
      name: "NAMA STOCKIST",
      selector: "stockistName",
      sortable: true,
    },
    {
      name: "ID ADMIN",
      selector: "adminId",
      sortable: true,
    },
    {
      name: "NAMA ADMIN",
      selector: "adminName",
      sortable: true,
    },
    {
      name: "NO. TELP",
      selector: "phoneNumber",
      sortable: true,
    },
    {
      name: "JENIS VARIAN",
      selector: "variantType",
      sortable: true,
    },
    {
      name: "TRANSAKSI TERAKHIR",
      selector: (d: { lastTransaction: string | null}) => (
        <>
          {d.lastTransaction !== null ? (
            <> {d.lastTransaction.substr(8, 2)}/{d.lastTransaction.substr(5, 2)}/
            {d.lastTransaction.substr(0, 4)}</>
          ) : (
            <>-</>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && <span>Active</span>}
          {d.isActive === false && <span>Inactive</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any; adminPartnerId: any; partnerId: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-admin-stockist/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onDelete(d.id);
              setDeleterAlertProps({
                variant: "info",
                title: "Delete Data",
                content: "Yakin akan menghapus data ini ?",
              });
              setSelectedId(d.id);
              setShowDeleteAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
          {"    "}
          <Link
            to={{
              pathname: `/detail-admin-stockist/${d.adminPartnerId}`,
            }}
          >
            <Button className="detail-button" size="sm">
              Detail
            </Button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* Page Title */}

      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Data Admin Stockist</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link to={{ pathname: "/create-admin-stockist" }}>
            <Button
              className="float-right ml-2"
              variant="outline-info"
              style={{ width: 200 }}
            >
              Add Admin Stockist
            </Button>
          </Link>
        </Col>
      </Row>

      <Grid>
        <Card>
          <Row>
            <Col sm={4}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Search Data
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search Stockist or Admin Name"
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  >
                    <option value={"0"}>Active</option>
                    <option value={"1"}>Inactive</option>
                    <option value={"2"}>All</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            <Col className="btn-footer-group m-4">
              <Button
                className="mr-2"
                variant="outline-info"
                style={{ width: 130, height: 45 }}
                type="submit"
                onClick={() => {
                  setFilterText("");
                  setFilterStatus("0");
                  dispatch(getAdminStockistList());
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredAdminStockistList}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>

      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default AdminStockistTable;
