import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import {
  getCity,
  getDistrict,
  getProvince,
  resetSingle,
  resetStatus,
} from "../customer.reducer";
import { CustomerModel } from "../customer.model";
import { RootState } from "../../../app/store";
import AlertMessage from "../../../components/alertMessage";
import "./styles.css";
import "react-phone-number-input/style.css";
import { DragSwitch } from "react-dragswitch";
import NumberFormat from "react-number-format";


const CustomerCreate = ({
  data = {} as CustomerModel,
  isEdit = false,
  ...props
}: CustomerFormProps) => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const UserDataState = useSelector(
    (state: RootState) => state.customer.singleUserData ?? []
  );

  const provinceState = useSelector(
    (state: RootState) => state.customer.listProvince ?? []
  );

  const cityState = useSelector(
    (state: RootState) => state.customer.listCity ?? []
  );

  const districtState = useSelector(
    (state: RootState) => state.customer.listDistrict ?? []
  );

  const createStatus = useSelector(
    (state: RootState) => state.customer.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.customer.error ?? ""
  );

  useEffect(() => {
    dispatch(getProvince());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();
  const [fullName, setName] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState(0);
  const [address, setAddress] = useState("");
  const [countryId, setCountry] = useState(
    "76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7"
  );
  const [provinceId, setProvince] = useState("");
  const [cityId, setCity] = useState("");
  const [districtId, setDistrict] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);
  const [totalWallet, setTotalWallet] = useState(0);
  const [passwordShown, setPasswordShown] = useState(false);

  const [generatedId, setGeneratedId] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/customer/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const onEmailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const onAddressChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAddress(e.target.value);
  };

  const onProvinceChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setProvince(e.target.value);

    dispatch(getCity(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCity(e.target.value);
    dispatch(getDistrict(e.target.value));
  };

  const onDistrictChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDistrict(e.target.value);
  };

  const onPostalcodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPostalcode(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    console.log(UserDataState);

    if (
      UserDataState.passwordLog !== "" &&
      UserDataState.passwordLog !== undefined
    ) {
      setPassword(UserDataState.passwordLog);
      setConfirmPassword(UserDataState.passwordLog);
    }
  }, [UserDataState]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      // let arr = JSON.stringify(errorMessage);
      // let parse = JSON.parse(arr);
      // let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "password salah / data belum terisi",
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      console.log(data);
      dispatch(getCity(data.provinceId));
      dispatch(getDistrict(data.cityId));
      setName(data.fullName);
      setPhone(data.phoneNumber);
      setEmail(data.email);
      setGender(data.gender);
      setAddress(data.address);
      // setPassword(data.passwordLog);
      // setConfirmPassword(data.passwordLog);
      setProvince(data.provinceId);
      setCity(data.cityId);
      setDistrict(data.districtId);
      setPostalcode(data.postalCode);
      setIsActive(data.isActive);
      setTotalWallet(data.wallet);
      if (data.code !== null) {
        setGeneratedId(data.code);
      }
    } else {
      setName("");
      setPhone("");
      setEmail("");
      setGender(0);
      setAddress("");
      setPassword("");
      setConfirmPassword("");
      setProvince("");
      setCity("");
      setDistrict("");
      setPostalcode("");
      setPasswordShown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const togglePasswordInvisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div>
              {isEdit ? "Edit Data End Customer" : "Add Data End Customer"}
            </div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  if (password !== confirmPassword) {
                    setAlertProps({
                      variant: "failed",
                      title: "Gagal",
                      content: "password tidak sama",
                    });
                    setShowAlert(true);
                  } else {
                    props.onSubmit(
                      fullName,
                      phoneNumber,
                      email,
                      gender,
                      password,
                      confirmPassword,
                      address,
                      countryId,
                      provinceId,
                      cityId,
                      districtId,
                      postalcode,
                      isActive
                    );
                  }
                }
                setValidated(true);
              }}
            >
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Generated ID
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="-"
                    type="text"
                    value={generatedId}
                    disabled
                    required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  End Customer Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Customer Name"
                    type="text"
                    onChange={onNameChange}
                    value={fullName}
                    required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Email
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={onEmailChange}
                    value={email}
                    required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Phone Number
                </Form.Label>
                <Col sm={4}>
                  <PhoneInput
                    international
                    defaultCountry="ID"
                    onChange={setPhone}
                    value={phoneNumber}
                    required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Gender
                </Form.Label>
                <Col sm={4}>
                  <Form>
                    <Form.Row>
                      <Col>
                        <Form.Check
                          label="Male"
                          type="radio"
                          checked={gender === 0}
                          value={0}
                          onClick={() => setGender(0)}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          checked={gender === 1}
                          value={1}
                          onClick={() => setGender(1)}
                          label="Female"
                        />
                      </Col>
                    </Form.Row>
                  </Form>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Password
                </Form.Label>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Control
                      type={passwordShown ? "text" : "password"}
                      onChange={onPasswordChange}
                      value={password}
                      required
                      placeholder="Password"
                    />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text
                        style={{
                          backgroundColor: "#fff",
                          color: "#F5B95D",
                          border: 0,
                        }}
                        onClick={togglePasswordInvisibility}
                      >
                        {passwordShown ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                  {/* {
                    password.length < 8 ? 
                    <Form.Label className="card-subtitle"> Must be at least 8 Character</Form.Label> : <></>
                  }
                  {
                    password.toLowerCase() ? <></> :
                    <Form.Label className="card-subtitle"> Must obtain 1 uppercase letter and numeric</Form.Label> 
                  } */}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Confirm Password
                </Form.Label>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Control
                      type={passwordShown ? "text" : "password"}
                      onChange={onConfirmPasswordChange}
                      value={confirmPassword}
                      required
                      placeholder="Confirm Password"
                    />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text
                        style={{
                          backgroundColor: "#fff",
                          color: "#F5B95D",
                          border: 0,
                        }}
                        onClick={togglePasswordInvisibility}
                      >
                        {passwordShown ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                  {password !== confirmPassword ? (
                    <Form.Label className="card-subtitle">
                      Both password must match
                    </Form.Label>
                  ) : (
                    <></>
                  )}
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Address
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={onAddressChange}
                    value={address}
                    // required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Province
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={onProvinceChange}
                    value={provinceId}
                  >
                    <option>select</option>
                    {provinceState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  City
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={onCityChange}
                    value={cityId}
                  >
                    <option>select</option>
                    {cityState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  District
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={onDistrictChange}
                    value={districtId}
                  >
                    <option>select</option>
                    {districtState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Postal Code
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    placeholder="Postal Code"
                    onChange={onPostalcodeChange}
                    value={postalcode}
                    required
                  />
                </Col>
              </Form.Group>
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Wallet
                    </Form.Label>
                    <Col sm={4}>
                     
                      <NumberFormat
                       className="form-control"
                        value={totalWallet}
                        displayType="text"
                        thousandSeparator={true}
                        prefix=""
                        disabled
                      />
                    </Col>
                  </Form.Group>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Point
                    </Form.Label>
                    <Col sm={4}>
                      <NumberFormat
                          className="form-control"
                          value={data?.point ?? 0}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          disabled
                      />
                    </Col>
                  </Form.Group>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}
              <div className="btn-footer-group">
                <Link to={{ pathname: `/customer` }}>
                  <Button className="btn-cancel" variant="light">
                    Cancel
                  </Button>
                  {"  "}
                </Link>
                <Button
                  className="btn"
                  style={{ width: 135, height: 46 }}
                  variant="info"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>

        <AlertMessage
          {...alertProps}
          show={showAlert}
          onAlertCloseAction={onAlertClose}
        ></AlertMessage>
      </Grid>
    </div>
  );
};

export interface CustomerFormProps {
  onSubmit: (
    fullName: string,
    phoneNumber: string,
    email: string,
    gender: number,
    password: string,
    confirmPassword: string,
    address: string,
    countryId: string,
    provinceId: string,
    cityId: string,
    districtId: string,
    postalcode: string,
    isActive: boolean
  ) => void;
  data: CustomerModel;
  isEdit: boolean;
}

export default CustomerCreate;
