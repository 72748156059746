import axios from "axios";
import {
  ConfirmPasswordModel,
  ConfirmPasswordResponse,
} from "./ConfirmPasswordModel";
import { endpoint } from "../../../src/constants";

export default class ConfirmPasswordApi {
  static changePassword(changePassword: ConfirmPasswordModel) {
    return axios
      .post<any>(
        endpoint + "/api/User/ChangePasswordAsync",
        changePassword
      )
      .then((res) => res.data.data);
  }
}
