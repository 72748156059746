import React, { useState } from "react";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import ReturForm from "../components/ReturForm";

const ReturPage = () => {

   
    return (
        <div>
            <ReturForm/>
        </div>
    )
}

export default ReturPage
