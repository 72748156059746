import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import ProductTable from "../components/productTable";
import { getList, resetSingleProduct } from "../product.reducer";

const ProductTablePage = () => {
  const dispatch = useDispatch();

  const modifiedDate = useSelector(
    (state: RootState) => state.product.modifiedDate
  );

  useEffect(() => {
    // dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDate]);

  useEffect(() => {
    dispatch(resetSingleProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDate]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <ProductTable />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductTablePage;
