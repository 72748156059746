import React from 'react';
import LoginForm from '../components/LoginForm';
import {  } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { Redirect } from 'react-router-dom';


const UserLoginPage = () => {
  const userState = useSelector((state: RootState) => state.user);
  if (userState.token) {
    return <Redirect to="/" />;
  }
  return <LoginForm />;
};

export default UserLoginPage;
