import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { MenuModel, RoleModel } from "../role.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import AlertMessage from "../../../components/alertMessage";
import { resetSingle, resetStatus } from "../role.reducer";
import { DragSwitch } from "react-dragswitch";
import { register } from "../../../serviceWorker";

const RoleForm = ({
  data = {} as RoleModel,
  isEdit = false,
  ...props
}: RoleFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const createStatus = useSelector(
    (state: RootState) => state.role.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.role.error ?? ""
  );

  const MenuState = useSelector(
    (state: RootState) => state.role.menuList ?? []
  );

  const MenuAccessState = useSelector(
    (state: RootState) => state.role.menuAccessList ?? []
  );

  let history = useHistory();
  const [roleName, setRoleName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [isAll, setIsAll] = useState(false);
  const [menuId, setMenuId] = useState<Array<string>>([]);
  const [validated, setValidated] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [noHeaderMenuList, setNoHeaderMenuList] = useState<Array<MenuModel>>(
    []
  );
  const [masterMenuList, setMasterMenulist] = useState<Array<MenuModel>>([]);
  const [inventoryMenuList, setInventoryMenuList] = useState<Array<MenuModel>>(
    []
  );
  const [settingMenuList, setSettingMenuList] = useState<Array<MenuModel>>([]);
  const [salesOrderMenuList, setSalesOrderMenuList] = useState<
    Array<MenuModel>
  >([]);
  const [registerMenuList, setRegisterMenuList] = useState<Array<MenuModel>>(
    []
  );
  const [confirmationReceiptMenuList, setConfirmationReceiptMenuList] =
    useState<Array<MenuModel>>([]);
  const [chatMenuList, setChatMenuList] = useState<Array<MenuModel>>([]);
  const [reportMenuList, setReportMenuList] = useState<Array<MenuModel>>([]);
  const [paymentMenuList, setPaymentMenuList] = useState<Array<MenuModel>>([]);
  const [webSettingMenuList, setWebSettingMenuList] = useState<Array<MenuModel>>([]);

  // const [stockList, setStocklist] = useState<Array<MenuModel>>([]);
  // const [penjualanList, setPenjualanlist] = useState<Array<MenuModel>>([]);
  // const [laporanList, setLaporanlist] = useState<Array<MenuModel>>([]);
  // const [pengaturanList, setPengaturanlist] = useState<Array<MenuModel>>([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/role/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setRoleName(data.name);
      if (MenuState.length !== 0) {
        const array = MenuAccessState.map((item) => {
          if (MenuState.some((i) => i.id === item.menuId)) {
            return item.menuId;
          }
        }) as string[];
        setMenuId(array);
      }
    } else {
      setRoleName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let arrNoHeader: MenuModel[] = [];
    let arrMaster: MenuModel[] = [];
    let arrInventory: MenuModel[] = [];
    let arrSetting: MenuModel[] = [];
    let arrSalesOrder: MenuModel[] = [];
    let arrRegister: MenuModel[] = [];
    let arrConfirmationReceipt: MenuModel[] = [];
    let arrChat: MenuModel[] = [];
    let arrReport: MenuModel[] = [];
    let arrPayment: MenuModel[] =[];
    let arrWebSetting: MenuModel[] = [];

    MenuState.forEach((value, index) => {
      switch (value.category) {
        case 0:
          return arrNoHeader.push(value);
        case 1:
          return arrMaster.push(value);
        case 2:
          return arrInventory.push(value);
        case 3:
          return arrSetting.push(value);
        case 4:
          return arrSalesOrder.push(value);
        case 5:
          return arrRegister.push(value);
        case 6:
          return arrConfirmationReceipt.push(value);
        case 7:
          return arrChat.push(value);
        case 8:
          return arrReport.push(value);
        case 9:
          return arrPayment.push(value);
        case 10:
          return arrWebSetting.push(value);
      }
    });

    setNoHeaderMenuList(arrNoHeader);
    setMasterMenulist(arrMaster);
    setInventoryMenuList(arrInventory);
    setSettingMenuList(arrSetting);
    setSalesOrderMenuList(arrSalesOrder);
    setRegisterMenuList(arrRegister);
    setConfirmationReceiptMenuList(arrConfirmationReceipt);
    setChatMenuList(arrChat);
    setReportMenuList(arrReport);
    setPaymentMenuList(arrPayment);
    setWebSettingMenuList(arrWebSetting);
  }, [MenuState]);

  useEffect(() => {
    if (MenuAccessState.length > 0) {
      let idList = MenuAccessState.map((item: any) => {
        return item.menuId
      })
      setMenuId(idList)
    }
  }, [MenuAccessState])

  const onCheckMenu = (item: string) => {
    let newArr = [];
    if (!menuId.includes(item)) {
      newArr = [...menuId, item];
    } else {
      newArr = menuId.filter((a) => a !== item);
    }
    setMenuId(newArr);
  };

  const onCheckedAll = (e: any) => {
    let newArr: any = [];
    if (e.target.checked) {
      noHeaderMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      masterMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      inventoryMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      settingMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      salesOrderMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      registerMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      confirmationReceiptMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      chatMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      reportMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      paymentMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
      webSettingMenuList.map((item) => {
        newArr = [...newArr, item.id]
      })
    }
    setMenuId(newArr);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">
          {isEdit ? "Edit Data Role" : "Add Data Role"}
        </Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div>{isEdit ? "Edit Data Role" : "Add Data Role"}</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const status = false;
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(roleName, roleId, isAll, menuId);
                }
                setValidated(true);
              }}>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={2}>
                  Nama User Level
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    type="text"
                    placeholder="Nama User Level"
                    required
                    onChange={onRoleNameChange}
                    value={roleName}
                  />
                </Col>
              </Form.Group>
              <hr />
              {/* <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={2}>
                    Active Status
                  </Form.Label>
                  <Col sm={4}>
                    <DragSwitch 
                      checked={isActive} 
                      onChange={(e) => {
                        setIsActive(e)
                      }}/>
                    <br/>
                  </Col>
                </Form.Group> 
              <hr/>  */}

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={2}>
                  Menu
                </Form.Label>
                <Col sm={4} style={{ paddingTop: 8 }}>
                  <Form.Check
                    label="Choose All"
                    name="group1"
                    type="checkbox"
                    onChange={(e) => {
                      setIsAll(e.target.checked);
                      onCheckedAll(e);
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={2} />
                <Col sm={2}>
                  <span>No Header</span>
                  <br />
                  {noHeaderMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Master</span>
                  <br />
                  {masterMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Inventory</span>
                  <br />
                  {inventoryMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Setting</span>
                  <br />
                  {settingMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Sales Order</span>
                  <br />
                  {salesOrderMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={2}></Form.Label>

                <Col sm={2}>
                  <span>Register</span>
                  <br />
                  {registerMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Konfirmasi dan Resi</span>
                  <br />
                  {confirmationReceiptMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Chat</span>
                  <br />
                  {chatMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Report</span>
                  <br />
                  {reportMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>

                <Col sm={2}>
                  <span>Payment</span>
                  <br />
                  {paymentMenuList.map((item, index) => {
                    return (
                      <Form.Check
                        label={item.name}
                        name="group1"
                        type="checkbox"
                        checked={menuId.includes(item.id)}
                        onClick={() => onCheckMenu(item.id)}
                      />
                    )
                  })}
                </Col>


              </Form.Group>

<hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={2}></Form.Label>
                <Col sm={2}>
                  <span>Web Setting</span>
                  <br />
                  {webSettingMenuList.map((item, index) => {
                    return (
                        <Form.Check
                            label={item.name}
                            name="group1"
                            type="checkbox"
                            checked={menuId.includes(item.id)}
                            onClick={() => onCheckMenu(item.id)}
                        />
                    )
                  })}
                </Col>
              </Form.Group>

              <hr />

              <div className="btn-footer-group">
                <Link to={{ pathname: `/role/index` }}>
                  <Button className="btn-cancel" variant="light">
                    Cancel
                  </Button>
                  {"  "}
                </Link>
                <Button
                  variant="info"
                  style={{ width: 130, height: 45 }}
                  type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    </div>
  );
};

export interface RoleFormProps {
  onSubmit: (
    roleName: string,
    roleId: string,
    isAll: boolean,
    menuId: string[]
  ) => void;
  data: RoleModel;
  isEdit: boolean;
}

export default RoleForm;
