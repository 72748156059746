import { createSlice } from '@reduxjs/toolkit'
import {
    getRecommendedList,
    createRecommended,
    uploadImage,
    updateRecommended,
    deleteRecommended,
    getRecommendedDetail
} from './recommendedSlide.api'
import {RecommendedModel} from "./model";
import {getSlideShowDetail} from "../slideShow/slideShow.api";

export interface RecommendedSlideSlice {
  loading : boolean,
  recommendedList : RecommendedModel[],
}
const initialState: RecommendedSlideSlice = {
  loading : false,
  recommendedList : []
}

export const recommendedSlideSlice = createSlice({
    name: 'recommendedSlideState',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(getRecommendedList.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getRecommendedList.fulfilled, (state, action) => {
            state.loading = false
            state.recommendedList = action.payload.data
        })
        builder.addCase(getRecommendedList.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(createRecommended.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(createRecommended.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRecommended.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(uploadImage.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(uploadImage.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(uploadImage.rejected, (state, action) => {
            state.loading = false
        })
        //delete
        builder.addCase(deleteRecommended.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(deleteRecommended.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRecommended.rejected, (state, action) => {
            state.loading = false
        })
        //detail
        builder.addCase(getRecommendedDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRecommendedDetail.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getRecommendedDetail.rejected, (state, action) => {
            state.loading = false
        })
        //update
        builder.addCase(updateRecommended.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateRecommended.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateRecommended.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default recommendedSlideSlice.reducer