import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { RootState } from "../../../../../app/store";
import {
  generateForVariation,
  getListAttribute,
  setListOfConfigureTermsListData,
  setVariableProductAttributeTempForm,
  setVariationData,
} from "../../product.reducer";
import axios from "axios";
import { endpoint } from "../../../../../constants";
import {
  ListOfCustomProductAttribute,
  ProductModel,
} from "../../product.model";

export interface ConfigureTermsModel {
  id: string;
  name: string;
  slug: string;
  parent: string;
  description: string;
  productAttributeId: string;
}

export interface inputConfigureTermsModel {
  [key: string]: any;
  // attributeId: string;
  isVisible: boolean;
  isForVariation: boolean;
  arrayOfConfigureTermsId: string[];
}

interface ListConfigurationTermModel {
  [key: string]: ConfigureTermsModel[];
}

const AttributeVariableProductForm = ({
  data = {} as ProductModel,
  isEdit = false,
  ...props
}) => {
  const dispatch = useDispatch();

  const attributeState = useSelector(
    (state: RootState) => state.product.listAttribute ?? []
  );

  const isAttributeGenerated = useSelector(
    (state: RootState) => state.product.isAttributeGenerated ?? false
  );

  const [tag, setTag] = useState<Array<string>>([]);
  const [productAttributeName, setAttributeName] = useState<Array<string>>([]);
  const [listOfConfigurationTerms, setListOfConfigurationTerms] =
    useState<ListConfigurationTermModel>({} as ListConfigurationTermModel);
  const [attributePropList, setAttributePropList] = useState<
    inputConfigureTermsModel[]
  >([]);
  const [tempValue, setTempValue] = useState(0);
  const [isLoadingAttributeData, setisLoadingAttributeData] = useState(false);

  useEffect(() => {
    dispatch(getListAttribute());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tag.length !== 0) {
      const array: string[] = [];
      tag.map((item) => {
        const found = attributeState.find((element) => element.id === item);
        if (found !== undefined) {
          array.push(found.name);
        }
      });
      setAttributeName(array);
    } else {
      setAttributeName([]);
    }

    console.log(tag);
    console.log(attributeState);
    dispatch(setVariableProductAttributeTempForm(tag));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const getListByProductAttributeApi = async (productAttributeId: string) => {
    return axios.get<any>(
      endpoint +
        "/api/ConfigurationAttribute/GetListByProductAttribute?productAttributeId=" +
        productAttributeId
    );
  };

  let _listOfConfigurationTerms: ListConfigurationTermModel =
    {} as ListConfigurationTermModel;

  let _attributePropList: inputConfigureTermsModel[] = [];

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor === Object) {
      setisLoadingAttributeData(true);
      data.productAttributeWithDetail.map(
        async (attributeItem, attributeIndex) => {
          let configureTermUpdate = attributeItem.productConfiguration.map(
            (termItem) => {
              return termItem.id;
            }
          );

          _attributePropList.push({
            isVisible: true,
            isForVariation: true,
            arrayOfConfigureTermsId: configureTermUpdate,
          });

          const attributeResponse = await getListByProductAttributeApi(
            attributeItem.productAttributeId
          );

          if (attributeResponse.data.status) {
            _listOfConfigurationTerms["property" + attributeIndex] =
              attributeResponse.data.data;
          }

          if (attributeIndex === data.productAttributeWithDetail.length - 1) {
            setisLoadingAttributeData(false);
          }
        }
      );

      setListOfConfigurationTerms(_listOfConfigurationTerms);

      setAttributePropList(_attributePropList);

      setTag(data.productAttributeList);

      // const updatedAttributeTerm = attributePropList.map(
      //     (item, itemIndex) => {
      //       // if (itemIndex === index) {
      //         return {
      //           ...item,
      //           ["arrayOfConfigureTermsId"]: item.productConfiguration.map((termItem:any)=>{
      //             return termItem.id
      //           })
      //         };
      //       // }
      //       return item;
      //     }
      //   );

      //   setAttributePropList(updatedAttributeTerm);

      // data.productAttributeWithDetail.map((item, index) => {

      //   axios
      //     .get<any>(
      //       endpoint +
      //         "/api/ConfigurationAttribute/GetListByProductAttribute?productAttributeId=" +
      //         item.productAttributeId
      //     )
      //     .then((res) => {
      //       console.log("SET TERM " + index);

      //       let productConfig = item.productConfiguration.map((termItem:any )=>{
      //         return termItem.id
      //       })

      //       setAttributePropList([
      //         ...attributePropList,
      //         {
      //           isVisible: true,
      //           isForVariation: true,
      //           arrayOfConfigureTermsId:productConfig,
      //         },
      //       ]);

      //       setListOfConfigurationTerms({
      //         ...listOfConfigurationTerms,
      //         ["property" + index]: res.data.data,
      //       });

      //     });
      // });
    }
    // else {
    //    setTag([]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onListOfConfigurationChange = (param: any, index: number) => {
    let updatedListOfConfigurationTerms = {
      ...listOfConfigurationTerms,
      ["property" + index]: param,
    };

    setListOfConfigurationTerms(updatedListOfConfigurationTerms);
  };

  //handle on Change data
  const handleChange = (e: any) => {
    let diff_arr = e.filter((d: string) => !tag.includes(d));

    let deletedAttributeId = "";

    if (e !== 0) {
      axios
        .get<any>(
          endpoint +
            "/api/ConfigurationAttribute/GetListByProductAttribute?productAttributeId=" +
            diff_arr.toString()
        )
        .then((res) =>
          setListOfConfigurationTerms({
            ...listOfConfigurationTerms,
            ["property" + (e.length - 1)]: res.data.data,
          })
        );

      setAttributePropList([
        ...attributePropList,
        { isVisible: true, isForVariation: true, arrayOfConfigureTermsId: [] },
      ]);

      setTag(e);
    } else {
      setAttributePropList([]);
      setTag([]);
      setListOfConfigurationTerms({});
    }

    console.log(attributePropList);
  };

  // handle input change
  const handleInputConfigureTermsChange = (
    eventName: any,
    eventVal: any,
    index: number
  ) => {
    // let list = [...attributePropList];
    // list[index][eventName] = eventVal;
    // setAttributePropList(list);

    const updatedAttributeTerm = attributePropList.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          [eventName]: eventVal,
        };
      }
      return item;
    });

    setAttributePropList(updatedAttributeTerm);
    console.log(attributePropList);
  };

  //funct
  const handleClickBtn = () => {
    let array: ListOfCustomProductAttribute[] = [];

    if (attributePropList.length !== 0) {
      attributePropList.map((item) => {
        if (
          item.isForVariation === true &&
          item.arrayOfConfigureTermsId?.length !== 0
        ) {
          let temp = {
            configurationAttributeId: item.arrayOfConfigureTermsId,
          };
          array.push(temp);
        }
      });

      dispatch(generateForVariation(array));

      dispatch(setVariationData(attributePropList));
    }
  };

  const generateVariation = () => {
    let array: ListOfCustomProductAttribute[] = [];
    attributePropList.map((item) => {
      let temp = {
        configurationAttributeId: item.arrayOfConfigureTermsId,
      };
      array.push(temp);
    });

    dispatch(generateForVariation(array));

    dispatch(setVariationData(attributePropList));
  };

  useEffect(() => {
    if (attributePropList.length !== 0 && !isLoadingAttributeData) {
      generateVariation();
    }
  }, [attributePropList]);

  // console.log(attributePropList);
  // console.log(listOfConfigurationTerms);

  return (
    <div
    // style={{ paddingBottom: 100 }}
    >
      <hr />

      <Form>
        <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            Product Attribute
          </Form.Label>
          <Col sm={8}>
            <Select
              mode="multiple"
              placeholder="Attribute"
              value={tag}
              onChange={handleChange}
              style={{ width: "100%" }}
              optionFilterProp="children"
              disabled={isEdit}
            >
              {attributeState.map((item) => (
                <Select.Option key={item.name} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Form.Group>

        {productAttributeName.map((item, index) => {
          return (
            <div>
              <hr />
              {/* <h6>{item}</h6> */}

              <Form.Group as={Row} className="input-padding">
                <Col sm={4}>
                  <Form.Label>
                    <h6> {item}</h6>
                  </Form.Label>
                  {/* <Form.Check
                    label="Visible on Product Page"
                    name="isVisible"
                    type="checkbox"
                    onChange={(e) =>
                      handleInputConfigureTermsChange(e.target.name, e.target.checked, index)
                    }
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  />
                  <Form.Check
                    label="Used for variations"
                    name="isForVariation"
                    type="checkbox"
                    onChange={(e) =>
                      handleInputConfigureTermsChange(e.target.name, e.target.checked, index)
                    }
                  /> */}
                </Col>
                <Col sm={8}>
                  <Select
                    disabled={isEdit}
                    mode="multiple"
                    placeholder="Configure Terms"
                    optionLabelProp="label"
                    optionFilterProp="children"
                    value={
                      attributePropList[index] !== undefined
                        ? attributePropList[index].arrayOfConfigureTermsId
                        : []
                    }
                    onChange={(e: any) =>
                      handleInputConfigureTermsChange(
                        "arrayOfConfigureTermsId",
                        e,
                        index
                      )
                    }
                    style={{ width: "100%" }}
                  >
                    {listOfConfigurationTerms["property" + index] !== undefined
                      ? listOfConfigurationTerms["property" + index].map(
                          (item) => (
                            <Select.Option
                              value={item.id}
                              label={item.name}
                              key={item.name}
                              disabled={isEdit}
                            >
                              {item.name}
                            </Select.Option>
                          )
                        )
                      : null}
                  </Select>
                </Col>
              </Form.Group>
            </div>
          );
        })}
        {/* <Button
          onClick={handleClickBtn}
          variant="info"
          style={{ width: 50, height: 27, textAlign: "center", padding: 0 }}
        >
          Save Attribute
        </Button> */}
      </Form>
    </div>
  );
};

export default AttributeVariableProductForm;
