import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

// export const getCompany = createAsyncThunk('account/getCompany', async () => {
//     const response = await axios.get(`https://api.utilityfielding.com/api/Company/GetList`)
//     console.log(response)
//     return response.data
// })

export const postInformationStore = createAsyncThunk('api/information/postInformation', async (body:any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Information/Create`, body)
        // console.log(response.data)
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const getInformationStoreByType = createAsyncThunk('api/GetListByPartnerType', async (body:any) => {
    try {
        const response = await axios.get(`${endpoint}/api/Information/GetListByPartnerType`, {
            params : {
                type : body
            }
        })
        // console.log(response.data, 'infotypoe')
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const updateInformationStore = createAsyncThunk('api/information/updateInformation', async (body:any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Information/Update`, body)
        // console.log(response.data)
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})
// export const deleteCompany = createAsyncThunk('account/deleteCompany', async (id:string) => {
//     try {
//         const resCompany = await axios.delete(`https://api.utilityfielding.com/api/Company/Delete`, {
//             params : {
//                 targetCompanyId : id
//             }
//         })
//         return resCompany
//     } 
//     catch (error:any) {
//         return error.response
//     }
// })