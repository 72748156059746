import React, { Component, HTMLAttributes, ReactNode } from 'react';

export interface SidebarMenuItemProps extends HTMLAttributes<HTMLLIElement> {
  children?: (iconClassName: string, labelClassName: string) => ReactNode;
}

export default class SidebarMenuItem extends Component<SidebarMenuItemProps> {
  render() {
    return (
      <li
        className={`sidebar-menu-item ${this.props.className || ''}`}
        style={this.props.style || {}}
      >
        {this.props.children &&
          this.props.children('sidebar-menu-item__icon', 'sidebar-menu-item__label')}
      </li>
    );
  }
}
