import axios from "axios";
import { endpoint } from "../../../../constants";
import { KeepProductFilterModel } from "./keepPerProduct.model";

export default class Api {
  static getListKeepByProduct() {
    return axios
      .get<any>(endpoint + "​/api/SalesOrder/GetListKeepByProduct")
      .then((res) => res.data.data);
  }

  static getListKeepPerProductWithPaging(model: KeepProductFilterModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/GetListKeepPerProductWithPaging", model)
      .then((res) => res.data);
  }

}
