import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import Switch from "react-switch";
import {
  getApplicationSettingByType,
  postApplicationSetting,
  updateApplicationSetting,
} from "../aplication.api";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

enum TimeType {Day, Hour}

export default function AplicationReseller() {
  const dispatch = useDispatch();

  const [values, setValues] = useState<any>({
    type: 2,
    isKeepLimitSetting: false,
    keepLimitSetting: 0,
    timeKeepLimitSetting: TimeType.Day,
    isAutoCancelLimitSetting: false,
    autoCancelLimitSetting: 0,
    timeAutoCancelLimitSetting: TimeType.Day,
    isConfirmProductReceiveSetting: false,
    confirmProductReceiveSetting: "",
    timeTypeConfirmProduct: 0,
    isDueDateSetting: false,
    dueDateSetting: "",
    timeDueDateSetting: 0,
    isDueDateDropshipSetting: false,
    dueDateDropshipSetting: "",
    timeDueDateDropshipSetting: 0,
    isShowGuide: false,
    isSettingYourAppEntryDisplayStatus: false,
    isCODFeatureSetting: false,
    isYourApplicationStatusSetting: false,
    isKeepSetting: false,
    applicationOffMessage: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getDetailData();
  }, []);

  const getDetailData = () => {
    dispatch<any>(getApplicationSettingByType(values.type))
      .then(unwrapResult)
      .then((res: any) => {
        console.log(res.data);
        if (res.data.length !== 0) {
          setIsEdit(true);
          setValues(res.data[0]);
        } else {
          setIsEdit(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          text: "Setting application cannot loaded!",
        });
      });
  };

  const handleRadio = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: parseInt(e.target.value),
    });
    console.log(parseInt(e.target.value));
  };

  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (value: boolean, e: any, id: any) => {
    setValues({
      ...values,
      [id]: value,
    });
  };
  // NOTE: unusable part
  // const onStartTimeKeepChange = (time: any, timeString: string) => {
  //   console.log(timeString);
  //   setValues({
  //     ...values,
  //     ["resellerKeepLimitStartTime"]: timeString,
  //   });
  // };
  //
  // const onEndTimeKeepChange = (time: any, timeString: string) => {
  //   console.log(timeString);
  //
  //   setValues({
  //     ...values,
  //     ["resellerKeepLimitEndTime"]: timeString,
  //   });
  // };

  const handleCancel = () => getDetailData();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (isEdit) {
      let data = values;
      data.timeTypeConfirmProduct = parseInt(values.timeTypeConfirmProduct);
      data.timeDueDateSetting = parseInt(values.timeDueDateSetting);
      data.timeDueDateDropshipSetting = parseInt(
        values.timeDueDateDropshipSetting
      );
      console.log(data);
      dispatch<any>(updateApplicationSetting(data))
        .then(unwrapResult)
        .then((res: any) => {
          console.log(res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              text: "Application setting updated",
              showConfirmButton: false,
              timer: 1500,
            });
            getDetailData();
          } else {
            Swal.fire({
              icon: "error",
              text: "Application setting cannot updated!",
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            text: "Store information cannot updated!",
          });
        });
    } else {
      let data = values;
      data.timeTypeConfirmProduct = parseInt(values.timeTypeConfirmProduct);
      data.timeDueDateSetting = parseInt(values.timeDueDateSetting);
      data.timeDueDateDropshipSetting = parseInt(
        values.timeDueDateDropshipSetting
      );
      console.log(data);
      dispatch<any>(postApplicationSetting(data))
        .then(unwrapResult)
        .then((res: any) => {
          console.log(res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              text: "Application setting saved",
              showConfirmButton: false,
              timer: 1500,
            });
            getDetailData();
          } else {
            Swal.fire({
              icon: "error",
              text: "Application setting cannot saved!",
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            text: "Store information cannot saved!",
          });
        });
    }
  };
  return (
    <div className="tabAplicationStockist">
      <form onSubmit={handleSubmit}>
        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Pengaturan Limit Keep</p>{" "}
          </Col>
          <Col md={8}>
            <div className="w-100 mb-2">
              <Switch
                  onChange={handleSwitch}
                  id="isKeepLimitSetting"
                  checked={values.isKeepLimitSetting}
              />
            </div>
            <div className="w-100 d-flex align-items-center">
              <input
                  type="number"
                  required
                  name="keepLimitSetting"
                  placeholder=""
                  onChange={handleChange}
                  value={values.keepLimitSetting}
              />
              <Form.Check
                  checked={values.timeKeepLimitSetting === TimeType.Day}
                  className="radiocustom ml-3"
                  inline
                  type="radio"
                  id="time-keep-limit-setting-day"
                  name="timeKeepLimitSetting"
                  required
                  onChange={handleRadio}
                  value={TimeType.Day}
                  label="Day"
              />
              <Form.Check
                  checked={values.timeKeepLimitSetting === TimeType.Hour}
                  className="radiocustom ml-3"
                  inline
                  type="radio"
                  id="time-keep-limit-setting-hour"
                  required
                  name="timeKeepLimitSetting"
                  onChange={handleRadio}
                  value={TimeType.Hour}
                  label="Hour"
              />
            </div>
          </Col>
        </Row>
        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Pengaturan Limit Temporary Shipment</p>{" "}
          </Col>
          <Col md={8}>
            <div className="w-100 mb-2">
              <Switch
                  onChange={handleSwitch}
                  id="isAutoCancelLimitSetting"
                  checked={values.isAutoCancelLimitSetting}
              />
            </div>
            <div className="w-100 d-flex align-items-center">
              <input
                  type="number"
                  required
                  name="autoCancelLimitSetting"
                  placeholder=""
                  onChange={handleChange}
                  value={values.autoCancelLimitSetting}
              />
              <Form.Check
                  checked={values.timeAutoCancelLimitSetting === TimeType.Day}
                  className="radiocustom ml-3"
                  inline
                  type="radio"
                  id="time-auto-cancel-limit-setting-day"
                  name="timeAutoCancelLimitSetting"
                  required
                  onChange={handleRadio}
                  value={TimeType.Day}
                  label="Day"
              />
              <Form.Check
                  checked={values.timeAutoCancelLimitSetting === TimeType.Hour}
                  className="radiocustom ml-3"
                  inline
                  type="radio"
                  id="time-auto-cancel-limit-setting-hour"
                  required
                  name="timeAutoCancelLimitSetting"
                  onChange={handleRadio}
                  value={TimeType.Hour}
                  label="Hour"
              />
            </div>
          </Col>
        </Row>
        {/*<Row className="forms">*/}
        {/*  <Col md={4}>*/}
        {/*    <p className="mb-3">Pengaturan Limit Keep</p>{" "}*/}
        {/*  </Col>*/}
        {/*  <Col md={8}>*/}
        {/*    <div className="w-100 mb-2">*/}
        {/*      <Switch*/}
        {/*        onChange={handleSwitch}*/}
        {/*        id="isResellerKeepLimitSetting"*/}
        {/*        checked={values.isResellerKeepLimitSetting}*/}
        {/*      />*/}
        {/*    </div>*/}

        {/*    {values.isKeepLimitSetting ? (*/}
        {/*      <>*/}
        {/*        <div className="w-100 d-flex">*/}
        {/*          <Col sm={2}>*/}
        {/*            <TimePicker*/}
        {/*              value={moment(values.resellerKeepLimitStartTime, "H:mm")}*/}
        {/*              onChange={onStartTimeKeepChange}*/}
        {/*              defaultValue={moment("00:00", "H:mm")}*/}
        {/*              format={"H:mm"}*/}
        {/*            />*/}
        {/*          </Col>*/}
        {/*          <Col sm={1}>-</Col>*/}
        {/*          <Col sm={2}>*/}
        {/*            <TimePicker*/}
        {/*              value={moment(values.resellerKeepLimitEndTime, "H:mm")}*/}
        {/*              onChange={onEndTimeKeepChange}*/}
        {/*              defaultValue={moment("00:00", "H:mm")}*/}
        {/*              format={"H:mm"}*/}
        {/*            />*/}
        {/*          </Col>*/}
        {/*        </div>*/}
        {/*      </>*/}
        {/*    ) : (*/}
        {/*      <>*/}
        {/*        <div className="w-100 d-flex">*/}
        {/*          <Col sm={2}>*/}
        {/*            <TimePicker*/}
        {/*              value={moment(values.resellerKeepLimitStartTime, "H:mm")}*/}
        {/*              defaultValue={moment("00:00", "H:mm")}*/}
        {/*              format={"H:mm"}*/}
        {/*              disabled*/}
        {/*            />*/}
        {/*          </Col>*/}
        {/*          <Col sm={1}>-</Col>*/}
        {/*          <Col sm={2}>*/}
        {/*            <TimePicker*/}
        {/*              value={moment(values.resellerKeepLimitEndTime, "H:mm")}*/}
        {/*              defaultValue={moment("00:00", "H:mm")}*/}
        {/*              format={"H:mm"}*/}
        {/*              disabled*/}
        {/*            />*/}
        {/*          </Col>*/}
        {/*        </div>*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Pengaturan Konfirmasi Terima Produk</p>{" "}
          </Col>
          <Col md={8}>
            <div className="w-100 mb-2">
              <Switch
                onChange={handleSwitch}
                id="isConfirmProductReceiveSetting"
                checked={values.isConfirmProductReceiveSetting}
              />
            </div>
            <div className="w-100 d-flex align-items-center">
              <input
                type="number"
                required
                name="confirmProductReceiveSetting"
                placeholder=""
                onChange={handleChange}
                value={values.confirmProductReceiveSetting}
              />
              <Form.Check
                checked={values.timeTypeConfirmProduct === 0}
                className="radiocustom ml-3"
                inline
                type="radio"
                id="user4"
                name="timeTypeConfirmProduct"
                required
                onChange={handleRadio}
                value={0}
                label="Day"
              />
              <Form.Check
                checked={values.timeTypeConfirmProduct === 1}
                className="radiocustom ml-3"
                inline
                type="radio"
                id="user4"
                required
                name="timeTypeConfirmProduct"
                onChange={handleRadio}
                value={1}
                label="Hour"
              />
            </div>
          </Col>
        </Row>

        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Pengaturan Masa Jatuh Tempo (Default)</p>{" "}
          </Col>
          <Col md={8}>
            <div className="w-100 mb-2">
              <Switch
                onChange={handleSwitch}
                id="isDueDateSetting"
                checked={values.isDueDateSetting}
              />
            </div>
            <div className="w-100 d-flex align-items-center">
              <input
                required
                type="number"
                name="dueDateSetting"
                placeholder=""
                onChange={handleChange}
                value={values.dueDateSetting}
              />
              <Form.Check
                checked={values.timeDueDateSetting === 0}
                className="radiocustom ml-3"
                inline
                type="radio"
                id="user4"
                name="timeDueDateSetting"
                required
                onChange={handleRadio}
                value="0"
                label="Day"
              />
              <Form.Check
                checked={values.timeDueDateSetting === 1}
                className="radiocustom ml-3"
                inline
                type="radio"
                id="user4"
                name="timeDueDateSetting"
                required
                onChange={handleRadio}
                value="1"
                label="Hour"
              />
            </div>
          </Col>
        </Row>
        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Pengaturan Masa Jatuh Tempo Rekap/Dropship</p>{" "}
          </Col>
          <Col md={8}>
            <div className="w-100 mb-2">
              <Switch
                onChange={handleSwitch}
                id="isDueDateDropshipSetting"
                checked={values.isDueDateDropshipSetting}
              />
            </div>
            <div className="w-100 d-flex align-items-center">
              <input
                type="number"
                required
                name="dueDateDropshipSetting"
                placeholder=""
                onChange={handleChange}
                value={values.dueDateDropshipSetting}
              />
              <Form.Check
                checked={values.timeDueDateDropshipSetting === 0}
                className="radiocustom ml-3"
                inline
                type="radio"
                id="user4"
                name="timeDueDateDropshipSetting"
                required
                onChange={handleRadio}
                value="0"
                label="Day"
              />
              <Form.Check
                checked={values.timeDueDateDropshipSetting === 1}
                className="radiocustom ml-3"
                inline
                type="radio"
                id="user4"
                name="timeDueDateDropshipSetting"
                required
                onChange={handleRadio}
                value="1"
                label="Hour"
              />
            </div>
          </Col>
        </Row>
        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Tampilkan Panduan</p>{" "}
          </Col>
          <Col md={8}>
            <Switch
              onChange={handleSwitch}
              id="isShowGuide"
              checked={values.isShowGuide}
            />
          </Col>
        </Row>
        <Row className='forms'>
          <Col md={4}><p className='mb-3'>Pengaturan Setting Keep</p> </Col>
          <Col md={8}>
            <Switch onChange={handleSwitch} id='isKeepSetting' checked={values.isKeepSetting} />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">
              Pengaturan Status Tampilan Masuk Aplikasi Anda
            </p>{" "}
          </Col>
          <Col md={8}>
            <Switch
              onChange={handleSwitch}
              id="isSettingYourAppEntryDisplayStatus"
              checked={values.isSettingYourAppEntryDisplayStatus}
            />
          </Col>
        </Row>
        {/* <Row className='forms'>
                    <Col md={4}><p className='mb-3'>Pengaturan Fitur Rekap/COD</p> </Col>
                    <Col md={8}>
                        <Switch onChange={handleSwitch} id='isCODFeatureSetting' checked={values.isCODFeatureSetting} />
                    </Col>
                </Row> */}
        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Pengaturan Status Aplikasi Anda</p>{" "}
          </Col>
          <Col md={8}>
            <Switch
              onChange={handleSwitch}
              id="isYourApplicationStatusSetting"
              checked={values.isYourApplicationStatusSetting}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={4}>
            <p className="mb-3">Pesan Saat Aplikasi OFF</p>{" "}
          </Col>
          <Col md={8}>
            <input
              type="text"
              required
              name="applicationOffMessage"
              placeholder=""
              onChange={handleChange}
              value={values.applicationOffMessage}
            />
          </Col>
        </Row>
        <div className="wrap-buttons">
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit">{isEdit ? "Update" : "Save"}</Button>
        </div>
      </form>
    </div>
  );
}
