import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { InventoryMoveDetailModel } from "../inventoryMove.model";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const ProductTableApprovalComponent = () => {
  const listInventoryMoveDetailState = useSelector(
    (state: RootState) => state.inventoryMove.listInventoryMoveDetail ?? []
  );

 

  // datatable components
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [inventoryMoveDetail, setInventoryMove] = useState<
    InventoryMoveDetailModel[]
  >([]);

  const columns = [
    {
      name: "SKU",
      selector: "productSku",
      sortable: true,
    },
    {
      name: "PRODUCT NAME",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIANT",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "Quantity",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "UOM",
      selector: () => (
        <p style={{ margin: 10 }}>
          PCS
        </p>
      ),
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
  ];

  useEffect(() => {
    setInventoryMove(listInventoryMoveDetailState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInventoryMoveDetailState]);

  return (
    <div>
      <Grid textAlign="center">
        <Card>
          <DataTable
            columns={columns}
            data={inventoryMoveDetail}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default ProductTableApprovalComponent;
