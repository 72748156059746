import { getCity, getDistrict, getProvince, resetSingle, resetStatus } from "../warehouse.reducer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import AlertMessage from "../../../../components/alertMessage";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WarehouseModel } from "../warehouse.model";
import { RootState } from "../../../../app/store";
import { DragSwitch } from "react-dragswitch";
import { Grid } from "semantic-ui-react";

const WarehouseForm = ({
  data = {} as WarehouseModel,
  isEdit = false,
  ...props
}: WarehouseFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const provinceState = useSelector((state: RootState) => state.warehouse.listProvince ?? []);

  const cityState = useSelector((state: RootState) => state.warehouse.listCity ?? []);

  const districtState = useSelector((state: RootState) => state.warehouse.listDistrict ?? []);

  const createStatus = useSelector((state: RootState) => state.warehouse.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.warehouse.error ?? "");

  useEffect(() => {
    dispatch(getProvince());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [provinceId, setProvince] = useState("");
  const [cityId, setCity] = useState("");
  const [districtId, setDistrict] = useState("");
  const [validated, setValidated] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/warehouse/index");
    } else {
      dispatch(resetStatus());
    }
  };
  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onPhoneNumberChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPhoneNumber(e.target.value);
  };

  const onAddressChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAddress(e.target.value);
  };

  const onProvinceChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setProvince(e.target.value);

    dispatch(getCity(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCity(e.target.value);
    dispatch(getDistrict(e.target.value));
  };

  const onDistrictChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDistrict(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      dispatch(getCity(data.provinceId));
      dispatch(getDistrict(data.cityId));
      setCode(data.code);
      setName(data.name);
      setPhoneNumber(data.phoneNumber);
      setAddress(data.address);
      setProvince(data.provinceId);
      setCity(data.cityId);
      setDistrict(data.districtId);
      setIsActive(data.isActive);
    } else {
      setName("");
      setCode("");
      setPhoneNumber("");
      setAddress("");
      setProvince("");
      setCity("");
      setDistrict("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      <Form.Group>
        <Form.Label className="title">Add Data Warehouse</Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div className="profile-setting-subtitle">Add data Warehouse</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    code,
                    name,
                    phoneNumber,
                    address,
                    provinceId,
                    cityId,
                    districtId,
                    isActive
                  );
                }
                setValidated(true);
              }}
            >
              <hr />
              {isEdit === false ? (
                <></>
              ) : (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Code
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        onChange={onCodeChange}
                        value={code}
                        required
                        disabled
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              )}
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" onChange={onNameChange} value={name} required />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Telephone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    onChange={onPhoneNumberChange}
                    value={phoneNumber}
                    required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Address
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={onAddressChange}
                    value={address}
                    required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Province
                </Form.Label>
                <Col sm={4}>
                  <Form.Control as="select" onChange={onProvinceChange} value={provinceId}>
                    <option>select</option>
                    {provinceState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  City
                </Form.Label>
                <Col sm={4}>
                  <Form.Control as="select" onChange={onCityChange} value={cityId}>
                    <option>select</option>
                    {cityState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  District
                </Form.Label>
                <Col sm={4}>
                  <Form.Control as="select" onChange={onDistrictChange} value={districtId}>
                    <option>select</option>
                    {districtState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}

              <div className="btn-footer-group">
                <Link to={{ pathname: `/warehouse/index` }}>
                  <Button className="btn-cancel" variant="light">
                    Cancel
                  </Button>
                  {"  "}
                </Link>
                <Button
                  style={{ width: 135, height: 46 }}
                  className="btn"
                  variant="info"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>

        <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
      </Grid>
    </div>
  );
};

export interface WarehouseFormProps {
  onSubmit: (
    code: string,
    name: string,
    phoneNumber: string,
    address: string,
    provinceId: string,
    cityId: string,
    districtId: string,
    isActive: boolean
  ) => void;
  data: WarehouseModel;
  isEdit: boolean;
}

export default WarehouseForm;
