import {ApiErrorResponse} from "../../../index";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Api from "./stockistOrderDataReport.api";
import {
  Option,
  PartnerModel,
  PartnerTypeEnum,
  StockistDataPesananRequest,
  StockistOrderDataReportModel
} from "./stockistOrderDataReport.models";

export interface StockistOrderDataReportSlice{
  list: StockistOrderDataReportModel[];
  stockistOptions?: Option[];
  adminStockistOptions?: Option[];
  totalRow? : number;
  isLoading?: boolean;
  isStockistOptionsLoading?: boolean;
  isAdminStockistOptionsLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getStockistDataPesanan = createAsyncThunk(
    'stockistDataPesanan/getStockistDataPesanan',
    async ( args : StockistDataPesananRequest, {rejectWithValue}) => {
      try {
        return await Api.getStockistDataPesanan(args);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getPartnerByAdminStockistType = createAsyncThunk(
    'stockistDataPesanan/getPartnerByAdminStockistType',
    async ( _: string | undefined = undefined, {rejectWithValue}) => {
      try {
        return await Api.getPartnerByType({type: PartnerTypeEnum.AdminStockist});
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getPartnerByStockistType = createAsyncThunk(
    'stockistDataPesanan/getPartnerByStockistType',
    async ( _: string | undefined = undefined, {rejectWithValue}) => {
      try {
        return await Api.getPartnerByType({type: PartnerTypeEnum.Stockist});
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

const stockistOrderDataReportSlice = createSlice({
  name: "StockistOrderDataReportState",
  initialState: {} as StockistOrderDataReportSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
    resetList: (state) => {
      state.list = [];
      state.stockistOptions = [];
      state.adminStockistOptions = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStockistDataPesanan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStockistDataPesanan.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getStockistDataPesanan.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getPartnerByAdminStockistType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPartnerByAdminStockistType.fulfilled, (state, { payload }) => {
      state.adminStockistOptions = payload?.map((item: PartnerModel) => ({ value: item.id, label: item.fullName }));
      state.isLoading = false;
    });
    builder.addCase(getPartnerByAdminStockistType.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getPartnerByStockistType.pending, (state) => {
      state.isStockistOptionsLoading = true;
    });
    builder.addCase(getPartnerByStockistType.fulfilled, (state, { payload }) => {
      state.stockistOptions = payload?.map((item: PartnerModel) => ({value: item.id, label: item.fullName}));
      state.isStockistOptionsLoading = false;
    });
    builder.addCase(getPartnerByStockistType.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isStockistOptionsLoading = false;
    });
  }
});

export const { resetStatus,resetList } = stockistOrderDataReportSlice.actions;
export default stockistOrderDataReportSlice.reducer;