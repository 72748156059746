import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { ApiErrorResponse } from '../../';
import { checkPinModel, CreateRoleModel, createUserModel, CreateUserSuccessResponse, FilterModel, RoleModel, UserModel } from './models';
import UserApi from './user.api';

export interface CreateUserSlice {
    name? :string;
    list?: UserModel[];
    userlist?: UserModel[];
    single?: UserModel;
    roleList?: RoleModel[];
    successResponses?: CreateUserSuccessResponse;
    totalRow? : number;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
    checkPinStatus?: string;
}

export const createUser = createAsyncThunk(
    'createuserState/createUser',
    async (model: createUserModel, {rejectWithValue}) => {
        try {
            //return await UserApi.createUser(model.userData);
            const response = await UserApi.createUser(model.userData);
            if (response.status === true){
                let customerModelData = model.assignRoleData;
                customerModelData.userId = response.data.id;
                return await UserApi.assignMultiRole(customerModelData);
            }  
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getUserList = createAsyncThunk(
     'getUserListState/getuserList',
     async ( _:string | undefined = undefined, {rejectWithValue}) => {
         try {
             return await UserApi.getUserList();
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 );

 export const getUserListWithPaging = createAsyncThunk(
    'getUserListWithPagingState/getuserListWithPaging',
    async ( model: FilterModel, {rejectWithValue}) => {
        try {
            return await UserApi.getUserListWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getUserSingleItem = createAsyncThunk(
     'userState/userSingleItem',
    async (userId: string, {rejectWithValue}) => {
         try {
             return await UserApi.getSingleItem(userId);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 )

 export const updateUser = createAsyncThunk(
     'userState/updateUser',
     async (model: createUserModel, {rejectWithValue}) => {
         try {
             //return await UserApi.updateUser(UserModel);
            const response = await UserApi.updateUser(model.userData);
            if (response.status === true){
                let customerModelData = model.assignRoleData;
                customerModelData.userId = response.data.id;
                return await UserApi.assignMultiRole(customerModelData);
            }  
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
)


export const checkPin = createAsyncThunk(
    'checkPinState/checkPin',
    async (model: checkPinModel, {rejectWithValue}) => {
        try {
            return await UserApi.checkPin(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getUserRole = createAsyncThunk(
    'geRoleState/getRoleList',
    async ( _:string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await UserApi.getRole();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const assignUserRole = createAsyncThunk(
    'createuserState/createUser',
    async (Model: CreateRoleModel, {rejectWithValue}) => {
        try {
            return await UserApi.assignRole(Model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const assignMultiRole = createAsyncThunk(
    'createuserState/assignMultiRole',
    async (Model: CreateRoleModel, {rejectWithValue}) => {
        try {
            return await UserApi.assignMultiRole(Model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

// export const deleteProfessionalType = createAsyncThunk(
//     'professionalTypeState/deleteProfessionalType',
//     async (professionalTypeModel: ProfessionalTypeModel, {rejectWithValue}) =>{
//         try {
//             return await ProfessionalTypeApi.deleteProfessionalType( professionalTypeModel);
//         } catch (e) {
//             return rejectWithValue(e as ApiErrorResponse<any>);
//         }
//     }
// )

const CreateUserSlice = createSlice({
    name: 'createUserState',
    initialState: {} as CreateUserSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        resetSingle: (state) => {
            state.single = {} as UserModel
        },
        resetStatus: (state) => {
            state.status = ""
        },
        resetCheckPinStatus: (state) => {
            state.checkPinStatus = ""
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createUser.pending, (state) => {
          state.isLoading = true;
          state.status = "";
        });
        builder.addCase(createUser.fulfilled, (state, { payload }) => {
          state.name = new Date().toISOString();
          state.isLoading = false;
          state.successResponses = payload;
          state.status = "success";
        });
        builder.addCase(createUser.rejected, (state, { payload }) => {
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
          state.status = "failed";
        });
        builder.addCase(getUserSingleItem.pending, (state) =>{
            state.single = undefined;
            state.isLoading = true;
        });
        builder.addCase(getUserSingleItem.fulfilled, (state, {payload})=>{
            state.single = payload;
            state.isLoading = false;
        })
        builder.addCase(getUserSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })
        builder.addCase(getUserList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserList.fulfilled, (state, {payload})=> {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getUserList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "";
        });
        builder.addCase(getUserListWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserListWithPaging.fulfilled, (state, {payload})=> {
            state.userlist = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getUserListWithPaging.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "";
        });
        builder.addCase(updateUser.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        })
        builder.addCase(updateUser.fulfilled, (state, {payload})=> {
            state.isLoading = false;
            state.status = "success";
            state.successResponses = payload;
            state.name = new Date().toISOString();
        })
        builder.addCase(updateUser.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.status = "failed";
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(checkPin.pending, (state) => {
        state.isLoading = true;
        state.checkPinStatus = "";
        })
        builder.addCase(checkPin.fulfilled, (state, {payload})=> {
            state.isLoading = false;
            state.checkPinStatus = "success";
        })
        builder.addCase(checkPin.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.checkPinStatus = "failed";
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(getUserRole.pending, (state) => {
            state.isLoading = true;
         });
        builder.addCase(getUserRole.fulfilled, (state, {payload})=> {
            state.roleList = payload;
            state.isLoading = false;
        });
        builder.addCase(getUserRole.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        // builder.addCase(assignUserRole.pending, (state) => {
        //     state.isLoading = true;
        // })
        // builder.addCase(assignUserRole.fulfilled, (state, {payload})=> {
        //     state.isLoading = false;
        // })
        // builder.addCase(assignUserRole.rejected, (state, {payload}) => {
        //     state.isLoading = false;
        //     state.error = payload as ApiErrorResponse<any>;
        // })
      },
})

export const { setFilter, resetSingle, resetStatus, resetCheckPinStatus } = CreateUserSlice.actions;
export default CreateUserSlice.reducer;