import { Col, Form, Row, Card, Button } from "react-bootstrap";
import { Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { PaymentConfirmationModel, ShipmentModel } from "../../model";
import {
  SalesOrderModel,
  UpdateStatusModel,
} from "../jatuhTempoDropship.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  getCityByProvince,
  getDistrictByCity,
} from "../../../../../global/global.reducer";
import NumberFormat from "react-number-format";
import {
  updateSalesOrder,
  updateShipmentData,
  resetStatus
} from "../../pesananDalamProses.reducer";
import AlertMessage from "../../../../../components/alertMessage";
import {
  getPartnerTypeEnum,
  resetStatusJatuhTempoDropship,
  updateStatusPayment,
} from "../jatuhTempoDropship.reducer";

const JatuhTempoDropshipForm = ({
  data = {} as ShipmentModel,
  dataSo = {} as SalesOrderModel,
  dataPaymentConfirm = {} as PaymentConfirmationModel,
  isEdit = false,
  ...props
}: JatuhTempoDropshipFormProps) => {
  const dispatch = useDispatch();

  const userState = useSelector(
    (state: RootState) => state.user.username ?? ""
  );

  const provinceState = useSelector(
    (state: RootState) => state.global.listProvince ?? []
  );

  const cityState = useSelector(
    (state: RootState) => state.global.listCityByProvince ?? []
  );

  const districtState = useSelector(
    (state: RootState) => state.global.listDistrictByCity ?? []
  );

  const expeditionState = useSelector(
    (state: RootState) => state.global.listExpedition ?? []
  );

  const partnerState = useSelector(
    (state: RootState) => state.global.listPartner ?? []
  );

  const statusState = useSelector(
    (state: RootState) => state.pesananDalamProses.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.pesananDalamProses.error ?? ""
  );

  const paymentMethodState = useSelector(
    (state: RootState) => state.global.listPaymentMethod ?? []
  );

  const PartnerTypeState = useSelector(
    (state:RootState) => state.jatuhTempoDropship.partnerTypeEnum ?? []
  )

  //shipment state
  const [deliveryName, setDeliveryName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientPostalCode, setRecipientPostalCode] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [recipientProvince, setRecipientProvince] = useState("");
  const [recipientCity, setRecipientCity] = useState("");
  const [recipientSubDistrict, setRecipientSubDistrict] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [resiNumber, setResiNumber] = useState("");
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const [expeditionId, setExpeditionId] = useState("");
  const [tarifType, setTarifType] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [salesOrderId, setSalesOrderId] = useState("");
  const [administrationPrice, setAdministrationPrice] = useState(0);
  const [status, setStatus] = useState(0);

  //sales order state
  const [orderDate, setOrderDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [statusOrder, setStatusOrder] = useState(0);
  const [shipmentCost, setShipmentCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountType, setDiscountType] = useState(0);
  const [note, setNote] = useState("");
  const [soNumber, setSoNumber] = useState("");
  const [transactionStatus, setTransactionStatus] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxStatus, setTaxStatus] = useState(0);
  const [partnerId, setPartnerId] = useState("");
  const [orderType, setOrderType] = useState(1);
  const [adminFee, setAdminFee] = useState(0);
  const [discountId, setDiscountId] = useState("");
  const [countNota, setCountNota] = useState(0);
  const [countExpedition, setCountExpedition] = useState(0);
  const [programDiscount, setprogramDiscount] = useState(0);
  const [point, setpoint] = useState(0);
  const [nominalPoint, setnominalPoint] = useState(0);
  const [kursPoint, setkursPoint] = useState(0);
  const [ref, setref] = useState("");
  const [isPrintExpedition, setisPrintExpedition] = useState(false);
  const [userId, setuserId] = useState("");
  const [isAddedPoint, setisAddedPoint] = useState(false);
  const [isPrintNote, setIsPrintNote] = useState(false);
  const [customerType, setCustomerType] = useState(0);
  const [isGetPoint, setisGetPoint] = useState(false);

  //payment Confirmation
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [approveDate, setApproveDate] = useState(new Date());

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setDeliveryName(data.deliveryName);
      setShippingAddress(data.shippingAddress);
      setRecipientName(data.recipientName);
      setRecipientAddress(data.recipientAddress);
      setRecipientPostalCode(data.recipientPostalCode);
      setRecipientPhone(data.recipientPhone);
      setRecipientProvince(data.recipientProvince);
      setRecipientEmail(data.recipientEmail);
      setResiNumber(data.resiNumber);
      setTotalWeight(data.totalWeight);
      setTotalShippingCost(data.totalShippingCost);
      setExpeditionId(data.expeditionId);
      setTarifType(data.tarifType);
      setDeliveryDate(data.deliveryDate);
      setSalesOrderId(data.salesOrderId);
      setAdministrationPrice(data.administrationPrice);

      setRecipientCity(data.recipientCity);
      setRecipientSubDistrict(data.recipientSubDistrict);

      dispatch(getCityByProvince(data.recipientProvince));
      dispatch(getDistrictByCity(data.recipientCity));
      // deliveryDateRef.current.setDate(moment(data.deliveryDate).format("MM/DD/YYYY"));
    }

    if (
      dataSo &&
      Object.keys(dataSo).length !== 0 &&
      dataSo.constructor == Object
    ) {
      setOrderDate(new Date(dataSo.orderDate));
      setDueDate(new Date(dataSo.dueDate));
      setPaymentMethodId(dataSo.paymentMethodId);
      setStatusOrder(dataSo.statusOrder);
      setShipmentCost(dataSo.shipmentCost);
      setDiscount(dataSo.discount);
      setDiscountPercentage(dataSo.discountPercentage);
      setDiscountType(dataSo.discountType);
      setNote(dataSo.note);
      setSoNumber(dataSo.soNumber);
      setTransactionStatus(dataSo.transactionStatus);
      setSubTotal(dataSo.subTotal);
      setGrandTotal(dataSo.grandTotal);
      setQtyTotal(dataSo.qtyTotal);
      setTotalTax(dataSo.totalTax);
      setTaxPercentage(dataSo.taxPercentage);
      setTaxStatus(dataSo.taxStatus);
      setPartnerId(dataSo.partnerId);
      setOrderType(dataSo.orderType);
      setAdminFee(dataSo.adminFee);
      setDiscountId(dataSo.discountId);
      setCountNota(dataSo.countNota);
      setCountExpedition(dataSo.countExpedition);
      setprogramDiscount(dataSo.programDiscount);
      setpoint(dataSo.point);
      setnominalPoint(dataSo.nominalPoint);
      setkursPoint(dataSo.kursPoint);
      setref(dataSo.ref);
      setisPrintExpedition(dataSo.isPrintExpedition);
      setuserId(dataSo.userId);
      setisAddedPoint(dataSo.isAddedPoint);
      setisGetPoint(dataSo.isGetPoint);
      setIsPrintNote(dataSo.isPrintNote);
      setCustomerType(dataSo.customerType);
    }

    if (
      dataPaymentConfirm &&
      Object.keys(dataPaymentConfirm).length !== 0 &&
      dataPaymentConfirm.constructor == Object
    ) {
      setPaymentStatus(dataPaymentConfirm.status);
      setApproveDate(new Date(dataPaymentConfirm.approveDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (statusState === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (statusState === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = "";

      if (message.message === "error") {
        xhr = message.data[0].description;
      } else {
        xhr = parse.data.title;
      }
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: xhr,
      });
      setShowAlert(true);
    }
    dispatch(resetStatusJatuhTempoDropship())
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  useEffect(() => {
    dispatch(resetStatus())
    dispatch(getPartnerTypeEnum())
  }, [])
  

  const onSubmitForm = () => {
    dispatch(
      updateShipmentData({
        id: data.id,
        deliveryName,
        shippingAddress,
        recipientName,
        recipientAddress,
        recipientPostalCode,
        recipientPhone,
        recipientProvince,
        recipientCity,
        recipientSubDistrict,
        recipientEmail,
        resiNumber,
        totalWeight,
        totalShippingCost,
        expeditionId,
        tarifType,
        deliveryDate,
        salesOrderId,
        administrationPrice,
        note,
      } as ShipmentModel)
    );
    dispatch(
      updateSalesOrder({
        id: dataSo.id,
        orderDate,
        dueDate,
        paymentMethodId,
        statusOrder,
        shipmentCost,
        discount,
        discountPercentage,
        discountType,
        note,
        soNumber,
        transactionStatus,
        subTotal,
        grandTotal,
        qtyTotal,
        totalTax,
        taxPercentage,
        taxStatus,
        partnerId,
        orderType,
        adminFee,
        discountId,
        countNota,
        countExpedition,
        programDiscount,
        point,
        nominalPoint,
        kursPoint,
        ref,
        customerType,
        isPrintNote,
        isPrintExpedition,
        userId,
        isAddedPoint,
        isGetPoint,
      } as SalesOrderModel)
    );
    dispatch(
      updateStatusPayment({
        salesOrderId: dataSo.id,
        status: paymentStatus,
        approveBy: userState,
        approveDate: approveDate,
      } as UpdateStatusModel)
    );
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (statusState === "success") {
      dispatch(resetStatusJatuhTempoDropship());
    } else {
      dispatch(resetStatusJatuhTempoDropship());
    }
  };

  return (
    <Card>
      <Card.Body>
        <div>Edit Jatuh Tempo</div>
        <hr />
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Pembeli
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Nama Customer
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={deliveryName}
                onChange={(e) =>
                  setDeliveryName(e.target.value)
                }></Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Address Customer
            </Form.Label>
            <Col sm="5">
              <Form.Control as="textarea" rows={3} />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Phone Customer
            </Form.Label>
            <Col sm="5">
              <Form.Control />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Tanggal Pesan
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={new Date(orderDate).toISOString().substr(0, 10)}
                type="date"
                onChange={(e) => setOrderDate(new Date(e.target.value))}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Date Payment
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={new Date(approveDate).toISOString().substr(0, 10)}
                type="date"
                onChange={(e) => setApproveDate(new Date(e.target.value))}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Due Datetime
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={new Date(dueDate).toISOString().substr(0, 10)}
                type="date"
                onChange={(e) => setDueDate(new Date(e.target.value))}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Shipping Fee
            </Form.Label>
            <Col sm="5">
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={totalShippingCost}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setTotalShippingCost(val);
                }}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Shipping Weight
            </Form.Label>
            <Col sm="5">
              <NumberFormat
                placeholder=" .kg"
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                suffix=" kg"
                value={totalWeight}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setTotalWeight(val);
                }}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Shipping From
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={deliveryName}
                onChange={(e) => setDeliveryName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Shipping To
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Shipping Status
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(parseInt(e.target.value))}>
                <option value={0}>Pending</option>
                <option value={1}>Print Request</option>
                <option value={2}>On Progress</option>
                <option value={3}>On Delivery</option>
                <option value={4}>Delivered</option>
                <option value={5}>Completed</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Resi
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={resiNumber}
                onChange={(e) => setResiNumber(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Provinsi
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={recipientProvince}
                onChange={(e) => setRecipientProvince(e.target.value)}>
                <option>select</option>
                {provinceState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Kota
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={recipientCity}
                onChange={(e) => console.log(e)}>
                <option>select</option>
                {cityState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Kecamatan
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={recipientSubDistrict}
                onChange={(e) => console.log(e)}>
                <option>select</option>
                {districtState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Tarif
            </Form.Label>
            <Col sm="5">
              <Form.Control />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Ekspedisi
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={expeditionId}
                onChange={(e) => console.log(e)}>
                <option>select</option>
                {expeditionState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Tarif Tipe
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={tarifType}
                onChange={(e) => {
                  setTarifType(parseInt(e.target.value));
                }}>
                <option value={0}> Regular</option>
                <option value={1}> Pack</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Address Recipient
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="textarea"
                rows={3}
                value={recipientAddress}
                onChange={(e) => setRecipientAddress(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Phone Recipient
            </Form.Label>
            <Col sm="5">
              <PhoneInput
                international
                defaultCountry="ID"
                value={recipientPhone}
                onChange={(e) => setRecipientPhone}
                required
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Postal Code
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={recipientPostalCode}
                onChange={(e) => setRecipientPostalCode(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Subtotal
            </Form.Label>
            <Col sm="5">
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={subTotal}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setSubTotal(val);
                }}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Total
            </Form.Label>
            <Col sm="5">
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={grandTotal}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setGrandTotal(val);
                }}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Diskon
            </Form.Label>
            <Col sm="5">
              <NumberFormat
                placeholder="Rp."
                thousandSeparator={"."}
                decimalSeparator={","}
                className="form-control"
                inputMode="numeric"
                prefix="Rp. "
                value={discount}
                onValueChange={({ floatValue }) => {
                  let val = floatValue ?? 0;
                  setDiscount(val);
                }}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Program Diskon
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                onChange={(e) => setprogramDiscount(parseInt(e.target.value))}
                value={programDiscount}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Point
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                onChange={(e) => setpoint(parseInt(e.target.value))}
                value={point}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Nominal Point
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                onChange={(e) => setnominalPoint(parseInt(e.target.value))}
                value={nominalPoint}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Kurs Point
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="number"
                onChange={(e) => setkursPoint(parseInt(e.target.value))}
                value={kursPoint}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Order Status
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={statusOrder}
                onChange={(e) => {
                  setStatusOrder(parseInt(e.target.value));
                }}>
                <option value={0}> PesananDalamProses</option>
                <option value={1}> PesananBayarDitempat</option>{" "}
                <option value={2}> PesananBelumLunas</option>{" "}
                <option value={3}> PesananBayarCash</option>{" "}
                <option value={4}> PesananDropshipBelumLunas</option>{" "}
                <option value={5}> PesananDropshipLunas</option>{" "}
                <option value={6}> PesananPiutang</option>{" "}
                <option value={7}> PesananTanpaKonfirmasiPembayaran</option>{" "}
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Status Pembayaran
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={paymentStatus}
                onChange={(e) => {
                  setPaymentStatus(parseInt(e.target.value));
                }}>
                <option value={0}> Pending</option>
                <option value={1}> Approve</option>
                <option value={2}> Reject</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Payment Method
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                value={paymentMethodId}
                onChange={(e) => {
                  setPaymentMethodId(e.target.value);
                }}>
                <option>select</option>
                {paymentMethodState.map((Item) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Notes
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="textarea"
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr />
          {/* <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Ref
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={ref}
                onChange={(e) => setref(e.target.value)}
              />
            </Col>
          </Form.Group>
          <hr /> */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Jenis Customer
            </Form.Label>
            <Col sm="5">
              <Form.Control
                as="select"
                onChange={(e) => setCustomerType(parseInt(e.target.value))}
                value={customerType}>
                <option value={-1}>Select</option>

                {
                  PartnerTypeState.map((item)=>{
                    return <option value={item.id}>{item.name}</option>
                  })
                }
              
              </Form.Control>{" "}
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Status Order
            </Form.Label>
            <Col sm="5">
              {/* <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                // onChange={(e) => setStatusOrder(e)}
                // checked={statusOrder}
              /> */}
              <Form.Control
                as="select"
                onChange={(e) => setStatusOrder(parseInt(e.target.value))}
                value={statusOrder}>
                <option value={0}>Inactive</option>
                <option value={1}>Active</option>
              </Form.Control>{" "}
            </Col>
          </Form.Group>
          {/* <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Print Nota
            </Form.Label>
            <Col sm="5">
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={(e) => setIsPrintNote(e)}
                checked={isPrintNote}
              />
            </Col>
          </Form.Group> */}
          {/* <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Print Ekspedisi
            </Form.Label>
            <Col sm="5">
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={(e) => setisPrintExpedition(e)}
                checked={isPrintExpedition}
              />
            </Col>
          </Form.Group>
          <hr /> */}
          {/* <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              User
            </Form.Label>
            <Col sm="5">
              <Form.Control />
            </Col>
          </Form.Group>
          <hr /> */}
          {/* <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Added Point
            </Form.Label>
            <Col sm="5">
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={(e) => setisAddedPoint(e)}
                checked={isAddedPoint}
              />
            </Col>
          </Form.Group> */}
          {/* <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3">
              Get Point
            </Form.Label>
            <Col sm="5">
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={(e) => setisGetPoint(e)}
                checked={isGetPoint}
              />
            </Col>
          </Form.Group> */}
          <hr />
          <Button
            style={{ width: 115, height: 46 }}
            className="btn"
            variant="info"
            type="button"
            onClick={onSubmitForm}>
            Save
          </Button>
        </Form>
      </Card.Body>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </Card>
  );
};

export interface JatuhTempoDropshipFormProps {
  data: ShipmentModel;
  dataSo: SalesOrderModel;
  dataPaymentConfirm: PaymentConfirmationModel;
  isEdit: boolean;
}

export default JatuhTempoDropshipForm;
