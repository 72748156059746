import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import Api from "./UOM.api";
import { VariatioModel, UOMModel } from "./UOM.model";

export interface UOMSlice {
  name?: string;
  list?: UOMModel[];
  listProduct?: VariatioModel[];
  single?: UOMModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const createData = createAsyncThunk(
  "createState/create",
  async (Model: UOMModel, { rejectWithValue }) => {
    try {
      return await Api.createData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "getListState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "SingleItemState/SingleItem",
  async (customerId: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(customerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "State/update",
  async (model: UOMModel, { rejectWithValue }) => {
    try {
      return await Api.updateData(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "deleteUOMState/deleteUOM",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListProduct = createAsyncThunk(
  "getListProductUOMState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getListProduct();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const UOMSlice = createSlice({
  name: "SupplierState",
  initialState: {} as UOMSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as UOMModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListProduct.fulfilled, (state, { payload }) => {
      state.listProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getListProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status = "success";
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = "";
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = UOMSlice.actions;
export default UOMSlice.reducer;
