import { createSlice } from '@reduxjs/toolkit'
import { deleteSlideShow, getSlideShow, getSlideShowDetail, postImageUpload, postSlideShow, updateSlideShow } from './slideShow.api'


export interface SlideShowState {
  loading : boolean,
  listSlideShow : any,
}
const initialState: SlideShowState = {
  loading : false,
  listSlideShow : []
}

export const slideShowSlice = createSlice({
    name: 'slide Show',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(getSlideShow.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getSlideShow.fulfilled, (state, action) => {
            state.loading = false
            console.log(action.payload.data)
            state.listSlideShow = action.payload.data
        })
        builder.addCase(getSlideShow.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(postSlideShow.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postSlideShow.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postSlideShow.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(postImageUpload.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postImageUpload.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postImageUpload.rejected, (state, action) => {
            state.loading = false
        })
        //delete
        builder.addCase(deleteSlideShow.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(deleteSlideShow.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSlideShow.rejected, (state, action) => {
            state.loading = false
        })
        //detail
        builder.addCase(getSlideShowDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSlideShowDetail.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getSlideShowDetail.rejected, (state, action) => {
            state.loading = false
        })
        //update
        builder.addCase(updateSlideShow.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateSlideShow.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateSlideShow.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default slideShowSlice.reducer