import React, { useEffect, useState } from "react";
import { Grid, GridColumn, ItemExtra } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import Select from "react-select";
import {
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import NumberFormat from "react-number-format";
import { UnallocatedInvoiceModel } from "../../paymentAllocation/paymentAllocation.model";
import { PaymentInModel } from "../paymentIn.model";
import { getAllocationHistory, getSingleItem } from "../paymentIn.reducer";

const PaymentInAllocationHistory = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();
  const [invoiceList, setInvoiceList] = useState<UnallocatedInvoiceModel[]>([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const paymentInDataState = useSelector(
    (state: RootState) => state.paymentIn.single ?? ({} as PaymentInModel)
  );

  const allocationHistoryState = useSelector(
    (state : RootState) => state.paymentIn.allocationHistoryList ?? []
  )

  useEffect(() => {
    dispatch(getSingleItem(id));
    dispatch(getAllocationHistory(id));
  }, []);

  const invoiceColumns = [
    {
      name: "INVOICE NO.",
      selector: "invoiceNumber",
      sortable: true,
    },
    {
      name: "SO NUMBER",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "TANGGAL",
      selector: (d: { invoiceDate: string }) => (
        <p>
          {d.invoiceDate !== undefined && (
            <span>
              {d.invoiceDate.substr(8, 2)}-{d.invoiceDate.substr(5, 2)}-
              {d.invoiceDate.substr(0, 4)}
            </span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "TOTAL",
      selector: (d: { total: number }) => (
        <>
          <NumberFormat
            value={d.total}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "APPLIED",
      selector: (d: { applied: number }) => (
        <>
          <NumberFormat
            value={d.applied}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),

      sortable: true,
    },
    // {
    //   name: "STATUS",
    //   selector: "name",
    //   sortable: true,
    // },
  ];

  return (
    <div className="p-2">
      <Grid>
        <Row>
          <Col>
            <Row style={{ marginLeft: 8 }}>
              <Form.Label>
                <h4>Deposit - Allocation History</h4>
              </Form.Label>
            </Row>
            <Row style={{ marginLeft: 8 }}>
              <Form.Label>
                <h5>
                  {paymentInDataState?.paymentNumber} - {" "}
                  <NumberFormat
                    value={paymentInDataState?.paymentAmount}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp. "
                  />
                </h5>
              </Form.Label>
            </Row>
          </Col>
          <Col>
            <Link to={{ pathname: `/payment-in/index` }}>
              <Button variant="outline-info" className="float-right">
                BACK
              </Button>
              {"  "}
            </Link>
          </Col>
        </Row>
        <br />
        <Card>
          <Card.Body>
            <DataTable
              columns={invoiceColumns}
              data={allocationHistoryState}
              paginationResetDefaultPage={resetPaginationToggle}
              noHeader
              customStyles={TableCustomStyles}
            />
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export default PaymentInAllocationHistory;
