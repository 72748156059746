import { useEffect } from 'react';
import { } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import RegisterStockistTable from '../components/RegisterStockistTable';
import { getStockistList } from '../registerStockist.reducer';

const RegisterStockistTablePage = () => {

    const dispatch = useDispatch();

    const statusState = useSelector(
        (state: RootState) => state.registerStockist.name
    )

    useEffect(() => {
        // dispatch(getStockistList());
    }, [statusState]);

    return (
        <div>
            <RegisterStockistTable />
        </div>
    )
}

export default RegisterStockistTablePage
