import { Col } from 'react-bootstrap';
import AdminStockistDetail from '../components/AdminStockistDetail';
import '../AdminStockist.scss'

export default function AdminStockistTablePage() {
    return (
        <div>
            <Col md={12}>
                <AdminStockistDetail />
            </Col>
        </div>
    )
}