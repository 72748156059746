import { Container, Form } from "react-bootstrap";
import PesananEndCustomerTable from "../components/pesananEndCustomerTable";

const PesananEndCustomerIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan End Customer</Form.Label>
      </Form.Group>
      <PesananEndCustomerTable />
    </Container>
  );
};

export default PesananEndCustomerIndex;
