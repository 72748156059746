import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel, PaymentConfirmationModel } from "./paymentConfirmation.model";

export default class PaymentConfirmationApi {
    static createData(model: PaymentConfirmationModel) {
        return axios
        .post<any>(endpoint + "/api/PaymentConfirmSetting/Create", model)
        .then((res) => res.data.data);
    }

    static getList() {
        return axios
        .get<any>(endpoint + "/api/PaymentConfirmSetting/GetList")
        .then((res) => res.data.data);
    }

    static getListWithPaging(model: FilterModel) {
        return axios
        .post<any>(endpoint + "/api/PaymentConfirmSetting/GetListWithPaging", model)
        .then((res) => res.data.data);
    }

    static getCustomerSingleItem() {
        return axios
        .get<any>(endpoint +"/api/PaymentConfirmSetting/GetSingleItem?type=0")
        .then((res) => res.data.data);
    }

    static getResellerSingleItem() {
        return axios
        .get<any>(endpoint +"/api/PaymentConfirmSetting/GetSingleItem?type=1")
        .then((res) => res.data.data);
    }

    static getStockistSingleItem() {
        return axios
        .get<any>(endpoint +"/api/PaymentConfirmSetting/GetSingleItem?type=2")
        .then((res) => res.data.data);
    }

    static updateData(model: PaymentConfirmationModel) {
        return axios
        .post<any>(endpoint + "/api/PaymentConfirmSetting/update", model)
        .then((res) => res.data.data);
    }

    static deleteData(id: string) {
        return axios
        .post<any>(endpoint + "/api/PaymentConfirmSetting/Delete?Id=" + id)
        .then((res) => [
            { response: res.data.data },
        ]);
    }
}
