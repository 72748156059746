import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {
  deleteData,
  getListContract,
  getListContractWithPaging,
  resetStatus,
} from "../contract.reducer";
import {
  Card,
  InputGroup,
  FormControl,
  Form,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteMessage from "../../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

const ContractTable = () => {
  const dispatch = useDispatch();

  const contractListState = useSelector(
    (state: RootState) => state.contract.list ?? []
  );

  const statusState = useSelector(
    (state: RootState) => state.contract.status ?? ""
  );

  const totalRowState = useSelector(
    (state: RootState) => state.contract.totalRow ?? 0
  );

  //filter
  const [filterText, setFilterText] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [tempFilterStatus, setTempFilterStatus] = useState(3);
  const [tempFilterIsActive, setTempFilterIsActive] = useState(2);
  const [tempFilterIsUnlimited, setTempFilterIsUnlimited] = useState(false);

  const [filterAdminStokistName, setFilterAdminStokistName] = useState("");
  const [filterPartnerName, setFilterPartnerName] = useState("");
  const [filterIsUnlimited, setFilterIsUnlimited] = useState<boolean | null>(
    null
  );
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const [filterIsActive, setFilterIsActive] = useState<boolean | null>(null);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterApprovalStatus, setFilterApprovalStatus] = useState("0");

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const [totalRows, setTotalRows] = useState(0);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onDelete = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterPartnerName("");
    setFilterAdminStokistName("");
    setFilterStatus(null);
    setFilterIsActive(null);
    setFilterIsUnlimited(null);
    setFilterStartDate("");
    setFilterEndDate("");

    setTempFilterIsActive(2);
    setTempFilterStatus(3);
    setTempFilterIsUnlimited(false);

    dispatch(
      getListContractWithPaging({
        adminStockistName: "",
        partnerName: "",
        status: null,
        isActive: null,
        isUnlimitedHutang: null,
        startDateMaximumHutang: "",
        endDateMaximumHutang: "",
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListContractWithPaging({
        adminStockistName: filterAdminStokistName,
        partnerName: filterPartnerName,
        status: filterStatus,
        isActive: filterIsActive,
        isUnlimitedHutang: filterIsUnlimited,
        startDateMaximumHutang: filterStartDate,
        endDateMaximumHutang: filterEndDate,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [contractListState]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  useEffect(() => {
    if(statusState === "success-delete"){
      fetchData(0, perPage);
      dispatch(resetStatus());
      onAlertClose();
    }
  }, [statusState])
  

  const columns = [
    {
      name: "STOCKIST NAME",
      selector: "partnerName",
      sortable: true,
    },

    {
      name: "ADMIN STOCKIST NAME",
      selector: "adminStockistName",
      sortable: true,
    },
    // {
    //   name: "VARIANT TYPE",
    //   selector: "amountAdminStockist",
    //   sortable: true,
    // },
    {
      name: "TANGGAL BERAKHIR",
      selector: (d: { endDateContractType: any; endDateContract: any }) => (
        <p style={{ marginTop: 15 }}>
          {d.endDateContractType === 0
            ? d.endDateContract.slice(0, 10)
            : "unlimited"}
        </p>
      ),
      sortable: true,
    },
    {
      name: "APPROVAL STATUS",
      selector: (d: { status: number }) => (
        <p style={{ marginTop: 15 }}>
          {d.status === 0 && (
            <span className={"custom-badge-secondary"}>Pending</span>
          )}
          {d.status === 1 && (
            <span className={"custom-badge-success"}>Approved</span>
          )}
          {d.status === 2 && (
            <span className={"custom-badge-danger"}>Rejected</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ marginTop: 15 }}>
          {d.isActive === true && (
            <span className={"custom-badge-success"}>active</span>
          )}
          {d.isActive === false && (
            <span className={"custom-badge-secondary"}>Inactive</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/contract-setting-edit/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Stockist Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterPartnerName}
                    onChange={(e) => {
                      setFilterPartnerName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Active
                </Form.Label>
                <Col className="m-1">
                  <Form.Control
                    as="select"
                    value={tempFilterIsActive}
                    onChange={(e) => {
                      if (e.target.value.toString() === "2") {
                        setFilterIsActive(null);
                        setTempFilterIsActive(parseInt(e.target.value));
                      } else if (e.target.value.toString() === "0") {
                        setTempFilterIsActive(parseInt(e.target.value));
                        setFilterIsActive(true);
                      } else if (e.target.value.toString() === "1") {
                        setTempFilterIsActive(parseInt(e.target.value));
                        setFilterIsActive(false);
                      }
                    }}
                  >
                    <option value={2}>All</option>
                    <option value={0}>Active</option>
                    <option value={1}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Admin Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterAdminStokistName}
                    onChange={(e) => {
                      setFilterAdminStokistName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal Berakhir
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    value={tempFilterStatus}
                    onChange={(e) => {
                      if (e.target.value.toString() === "3") {
                        setFilterStatus(null);
                        setTempFilterStatus(parseInt(e.target.value));
                      } else {
                        setTempFilterStatus(parseInt(e.target.value));
                        setFilterStatus(parseInt(e.target.value));
                      }
                    }}
                  >
                    <option value={3}>All</option>
                    <option value={0}>Pending</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            <Col md={4} className="input-padding m-1">
              <Form.Check
                label="Unlimited"
                name="filterIsUnlimited"
                checked={tempFilterIsUnlimited}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterIsUnlimited(e.target.checked);
                    setTempFilterIsUnlimited(true);
                  } else {
                    setTempFilterIsUnlimited(false);
                    setFilterIsUnlimited(null);
                  }
                }}
                type="checkbox"
              />
            </Col>
          </Row>

          <Row>
            <Col>

            <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>

             
            </Col>
          </Row>

          <hr />

          <DataTable
            columns={columns}
            data={contractListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            style={{ overflowX: "scroll" }}
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}

          <br />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default ContractTable;
