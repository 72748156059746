import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {Card, Form, Row, Col, Button, Spinner} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { DatePicker } from "antd";
import { InventoryMoveModel, InventoryReceiveHistoryModel } from "../inventoryMove.model";
import {
  approvalInventoryMove,
  createInventoryHistoryReceive,
  resetSingle,
  resetStatus,
} from "../inventoryMove.reducer";
import moment from "moment";
import ProductTableApprovalComponent from "./productTableApprovalComponent";

const InventoryMoveApprovalPage = ({
  data = {} as InventoryMoveModel,
  isEdit = false,
  ...props
}: InventoryMoveProps) => {
  let history = useHistory();

  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const WarehouseListState = useSelector((state: RootState) => state.global.listWarehouse ?? []);

  const createStatus = useSelector((state: RootState) => state.inventoryMove.status ?? "");

  const userState = useSelector((state: RootState) => state.user ?? {});

  const userIdState = useSelector((state: RootState) => state.user.id ?? "");

  const errorMessage = useSelector((state: RootState) => state.inventoryMove.error ?? {} as any);

  const isLoading = useSelector(
      (state: RootState) => state.inventoryMove.isLoading
  );

  const [inventoryNumber, setInventoryNumber] = useState("");
  const [status, setStatus] = useState(0);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");
  const [userId, setUserId] = useState(userIdState);
  const [inventoryMoveDate, setInventoryMoveDate] = useState(new Date());
  const [note, setNote] = useState("");
  const [reason, setReason] = useState("");
  const [inventoryReceiveId, setInventoryReceiveId] = useState("");
  const [approveBy, setApproveBy] = useState("");
  const [rejectedBy, setRejectedBy] = useState("");
  const [approvedDate, setApprovedDate] = useState(new Date());
  const [rejectedDate, setRejectedDate] = useState(new Date());

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.message;
      let xhr = "";

      if (message.message === "error") {
        xhr = message.data[0].description;
      } else {
        xhr = parse.data.title;
      }
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: errorMessage?.message?.toString() === "" ? "Terjadi kesalahan pada sistem." : errorMessage?.message?.toString(),
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setInventoryNumber(data.inventoryNumber);
      setStatus(data.status);
      setFromWarehouse(data.fromWarehouse);
      setToWarehouse(data.toWarehouse);
      setNote(data.note);
      setReason(data.reason);
    } else {
      setInventoryNumber("");
      setStatus(0);
      setFromWarehouse("");
      setToWarehouse("");
      setNote("");
      setInventoryMoveDate(new Date());
      setInventoryReceiveId("");
      setApproveBy("");
      setRejectedBy("");
      setApprovedDate(new Date());
      setRejectedDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onInventoryMoveChange = (value: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setInventoryMoveDate(date);
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/inventory-move/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onClickApproveBtn = () => {
    dispatch(
      approvalInventoryMove({
        id: id,
        inventoryNumber,
        inventoryMoveDate,
        status: 1,
        userId: userState.id,
        fromWarehouse,
        toWarehouse,
        note,
        reason,
        inventoryReceiveId,
        approveBy: userState.username,
        rejectedBy: "",
        approvedDate: approvedDate,
        rejectedDate,
      } as InventoryMoveModel)
    );

    dispatch(
      createInventoryHistoryReceive({
        receiveName: userState.username,
        userId: userState.id,
        inventoryMoveId: id,
        approvedBy: userState.username,
        rejectedBy,
        note: "",
      } as InventoryReceiveHistoryModel)
    );
  };

  const onClickRejectBtn = () => {
    dispatch(
      approvalInventoryMove({
        id: id,
        inventoryNumber,
        inventoryMoveDate,
        status: 2,
        userId,
        fromWarehouse,
        toWarehouse,
        note,
        reason,
        inventoryReceiveId,
        approveBy: "",
        rejectedBy: userState.username,
        approvedDate,
        rejectedDate: approvedDate,
      } as InventoryMoveModel)
    );
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Form.Group>
          <Form.Label>
            <h4>Approve Inventory Move</h4>
          </Form.Label>
        </Form.Group>

        <Card>
          <Card.Body>
            <div className="profile-setting-subtitle">Inventory Move</div>
            <Form>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Inventory Number
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    onChange={(e) => setInventoryNumber(e.target.value)}
                    value={inventoryNumber}
                    required
                    disabled
                  ></Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Inventory Move Date
                </Form.Label>
                <Col sm={4}>
                  <DatePicker
                    defaultValue={moment(inventoryMoveDate, "YYYY-MM-DD HH:mm")}
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onInventoryMoveChange}
                    disabled
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={(e) => setFromWarehouse(e.target.value)}
                    value={fromWarehouse}
                    disabled
                  >
                    <option>select</option>
                    {WarehouseListState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse Tujuan
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={(e) => setToWarehouse(e.target.value)}
                    value={toWarehouse}
                    disabled
                  >
                    <option>select</option>
                    {WarehouseListState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <br />
        <section>
          <ProductTableApprovalComponent />
        </section>
        <br />
        <section>
          <Card>
            <Card.Body>
              <Form>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Note
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="textarea"
                      onChange={(e) => setReason(e.target.value)}
                      value={reason}
                      rows={3}
                      placeholder="Note"
                    />
                  </Col>
                </Form.Group>
                {
                    data.status === 0 && (
                        <div className="btn-footer-group">
                          <Button
                              style={{ width: 115, height: 46 }}
                              className="btn"
                              variant="success"
                              onClick={onClickApproveBtn}
                              disabled={isLoading}
                          >
                            {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Approve"}
                          </Button>
                          {"  "}
                          <Button
                              style={{ width: 115, height: 46 }}
                              form="inventoryMove"
                              className="btn"
                              variant="danger"
                              type="submit"
                              onClick={onClickRejectBtn}
                              disabled={isLoading}
                          >
                            {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Reject"}
                          </Button>
                        </div>
                    )
                }
              </Form>
            </Card.Body>
          </Card>
        </section>

        <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
      </Grid>
    </div>
  );
};

export interface InventoryMoveProps {
  data: InventoryMoveModel;
  isEdit: boolean;
}

export default InventoryMoveApprovalPage;
