import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllCategoryFilterAsset, getAllProductFilterAsset, postAssetCategoryReport, postAssetProductReport, postExportAssetCategory, postExportAssetProduct } from './AssetReport.api'

export interface AssetReportState {
  loading: boolean,
  listProductFilterAsset: any,
  listCategoryFilterAsset: any,
  byProductValues: any,
  byCategoryValues: any
}

const initialState: AssetReportState = {
  loading: false,
  listProductFilterAsset: [],
  listCategoryFilterAsset: [],
  byProductValues: {
    filterProduct: "",
    skip: 0,
    take: "10",
    page: 1,
  },
  byCategoryValues: {
    categoryName: "",
    skip: 0,
    take: "10",
    page: 1,
  }
}

export const AssetReportSlice = createSlice({
  name: 'laporan aset',
  initialState,
  reducers: {
    setByProductValues: (state, action: PayloadAction<any>) => {
      state.byProductValues = action.payload;
    },
    setByCategoryValues: (state, action: PayloadAction<any>) => {
      state.byCategoryValues = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getAllProductFilterAsset.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAllProductFilterAsset.fulfilled, (state, action) => {
      state.loading = false
      state.listProductFilterAsset = action.payload.data
    })
    builder.addCase(getAllProductFilterAsset.rejected, (state, action) => {
      state.loading = false
    })

    builder.addCase(getAllCategoryFilterAsset.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAllCategoryFilterAsset.fulfilled, (state, action) => {
      state.loading = false
      state.listCategoryFilterAsset = action.payload.data
    })
    builder.addCase(getAllCategoryFilterAsset.rejected, (state, action) => {
      state.loading = false
    })

    builder.addCase(postExportAssetProduct.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(postExportAssetProduct.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(postExportAssetProduct.rejected, (state, action) => {
      state.loading = false
    })

    builder.addCase(postAssetProductReport.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(postAssetProductReport.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(postAssetProductReport.rejected, (state, action) => {
      state.loading = false
    })

    builder.addCase(postExportAssetCategory.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(postExportAssetCategory.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(postExportAssetCategory.rejected, (state, action) => {
      state.loading = false
    })

    builder.addCase(postAssetCategoryReport.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(postAssetCategoryReport.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(postAssetCategoryReport.rejected, (state, action) => {
      state.loading = false
    })

  },
})

export const {
  setByProductValues,
  setByCategoryValues
} = AssetReportSlice.actions;

export default AssetReportSlice.reducer