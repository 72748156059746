import axios from "axios";
import { NonNullChain } from "typescript";
import { endpoint } from "../../../constants";
import { AddToCartRequestModel, GetVariationByProductAndUserRequest } from "./KeepBarangForm.model";

export default class KeepBarangFormAPI {
  static getListPartner() {
    return axios
      .get<any>(endpoint + "/api/Partner/GetList")
      .then((res) => res.data.data);
  }

  static getListVariationByProductAndUser(model : GetVariationByProductAndUserRequest) {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListByProductAndUser?productId=" + model.productId +"&partnerType="+model.partnerType+"&userId="+model.userId)
      .then((res) => res.data.data);
  }

  static createKeepFromAdmin(model: AddToCartRequestModel) {
    return axios
      .post<any>(endpoint + "/api/Cart/AdminAddItem", model)
      .then((res) => res.data.data);
  }
}
