import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { postAutoCancelMasterReport } from "../AutoCancelMasterReport.api"
import { unwrapResult } from '@reduxjs/toolkit';
import moment from "moment";

export default function PrintAutoCancelMasterReport() {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        customerName: new URLSearchParams(window.location.search).get('customerName'),
        startFilterDate: new URLSearchParams(window.location.search).get('startFilterDate'),
        endFilterDate: new URLSearchParams(window.location.search).get('endFilterDate'),
        productName: new URLSearchParams(window.location.search).get('productName'),
        skip: new URLSearchParams(window.location.search).get('skip'),
        take: new URLSearchParams(window.location.search).get('take')
    })

    const [list, setList] = useState<any>([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        const data = {
            "productName": values.productName,
            "startFilterDate": values.startFilterDate === "" ? new Date().toISOString() : new Date(values.startFilterDate).toISOString(),
            "endFilterDate": values.endFilterDate === "" ? new Date().toISOString() : new Date(values.endFilterDate).toISOString(),
            "customerName": values.customerName,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(postAutoCancelMasterReport(data))
            .then(unwrapResult)
            .then((res: any) => {
                setList(res.data.list)
                setTimeout(() => {
                    window.print()
                }, 1000)
            })
            .catch((err: any) => console.log(err))
    }

    const moneyParser = (value: any) => {
        let valueString = value.toString()
        let valueList = valueString.split('')
        let resultList: any = []
        valueList.reverse().map((c: any, i: number) => {
            resultList.push(c)
            if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
                resultList.push('.')
            }
        })
        let result = resultList.reverse().join('')
        return "Rp" + result + ",00"
    }

    return (
        <table className="table">
            <thead className="">
                <tr>
                    <th scope="col">No</th>
                    <th scope="col">Tanggal Pesanan</th>
                    <th scope="col">Pembeli</th>
                    <th scope="col">Produk</th>
                    <th scope="col">Varian</th>
                    <th scope="col">QTY</th>
                    <th scope="col">Harga</th>
                    <th scope="col">Subtotal</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map((res: any, i: number) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{moment(new Date(res.orderDate)).format('DD/MM/YYYY HH:mm')}</td>
                            <td>{res.customerName}</td>
                            <td>{res.product}</td>
                            <td>{res.variant}</td>
                            <td>{res.qty}</td>
                            <td>{moneyParser(res.price)}</td>
                            <td>{moneyParser(res.totalPrice)}</td>
                        </tr>
                    ))
                }

            </tbody>
        </table>
    )
}