import axios from "axios";
import { endpoint } from "../constants";
import {
  GetPartnerWithTypeDropdownRequest,
  ListDeliveredWithFilterRequest,
  ProductDropdownRequest,
  RegisterFirebaseTokenModel
} from "./global.model";

export default class globalApi {
  static getListRole() {
    return axios.get<any>(endpoint + "/api/User/GetAllRoleList").then((res) => res.data);
  }

  static getListUser() {
    return axios.get<any>(endpoint + "/api/AdminStockist/GetList").then((res) => res.data.data);
  }

  static getListProduct() {
    return axios.get<any>(endpoint + "/api/Product/GetList").then((res) => res.data.data);
  }

  static getProductDropdown(args: ProductDropdownRequest) {
    return axios.get<any>(endpoint + "/api/Product/GetProductDropdown", {
        params: args
    }).then((res) => res.data.data);
  }

  static getPartnerDropdown(partnerName: string) {
    return axios.get<any>(endpoint + "/api/Partner/GetListWithNameFilter", {
      params: {
        partnerName: partnerName
      }
    }).then((res) => res.data.data);
  }

  static getPartnerWithTypeDropdown(args: GetPartnerWithTypeDropdownRequest) {
    return axios.get<any>(endpoint + "/api/Partner/GetListWithNameAndTypeFilter", {
      params: {
        partnerName: args.partnerName,
        type: args.type
      }
    }).then((res) => res.data.data);
  }

  static getListDeliveredWithFilter(args: ListDeliveredWithFilterRequest) {
    return axios.get<any>(endpoint + "/api/SalesOrder/getListDeliveredWithFilter", {
      params: args
    }).then((res) => res.data.data);
  }

  static checkDestination(search: string) {
    return axios.get<any>(endpoint + "/api/Address/GetRajaOngkirDestination", {
      params: {
        name: search
      }
    }).then((res) => res.data.data);
  }

  static getListActiveProduct() {
    return axios.get<any>(endpoint + "/api/Product/GetActiveList").then((res) => res.data.data);
  }

  static getListCollectionTag() {
    return axios
      .get<any>(endpoint + "/api/ProductCollectionTag/GetList")
      .then((res) => res.data.data);
  }

  static getListCity() {
    return axios.get<any>(endpoint + "/api/City/GetListCity").then((res) => res.data.data);
  }

  static getListWarehouse() {
    return axios.get<any>(endpoint + "/api/Warehouse/GetList").then((res) => res.data.data);
  }

  static getListUOM() {
    return axios.get<any>(endpoint + "/api/UOM/GetList").then((res) => res.data.data);
  }

  static getListProductCategory() {
    return axios
      .get<any>(endpoint + "/api/productCategory/GetList?isActive=true")
      .then((res) => res.data.data);
  }

  static getListVariationForUOM() {
    return axios.get<any>(endpoint + "/api/Variation/GetList").then((res) => res.data.data);
  }

  static getListPartner() {
    return axios.get<any>(endpoint + "/api/Partner/GetList").then((res) => res.data.data);
  }

  static getProvince() {
    return axios
      .get<any>(
        endpoint + "/api/Address/GetProvinceByCountry?Id=76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7"
      )
      .then((res) => res.data.data);
  }

  static getCityByProvince(provinceId: string) {
    return axios
      .get<any>(endpoint + "/api/Address/GetCityByProvince?Id=" + provinceId)
      .then((res) => res.data.data);
  }

  static getDistrictByCity(cityId: string) {
    return axios
      .get<any>(endpoint + "/api/Address/GetDistrictByCity?Id=" + cityId)
      .then((res) => res.data.data);
  }

  static getListExpedition() {
    return axios.get<any>(endpoint + "/api/Expedition/GetList").then((res) => res.data.data);
  }

  static getListPaymentMethod() {
    return axios.get<any>(endpoint + "/api/PaymentMethod/GetList").then((res) => res.data.data);
  }

  static getListAdminStockist() {
    return axios.get<any>(endpoint + "/api/AdminStockist/GetList").then((res) => res.data.data);
  }

  static async getListPartnerByType(type: number) {
    const res = await axios.get<any>(endpoint + "/api/Partner/GetListByType?type=" + type);
    return res.data.data;
  }

  static getPendingNotification() {
    return axios
      .get<any>(
        endpoint + "/api/Notification/GetListPendingNotif"
      )
      .then((res) => res.data.data);
  }

  static registerFirebaseToken(model: RegisterFirebaseTokenModel) {
    return axios
    .post<any>(endpoint + "/api/Notification/RegisterFirebaseToken", model)
    .then((res) => res.data.data);
}
}
