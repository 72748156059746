import { useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import moment from "moment";
import logo from "../../../assets/logo.png";
import { SalesOrderSingleDetailModel } from "../../invoice/Invoice.model";
import { getSalesOrderSingleDetail } from "../../invoice/Invoice.reducer";
import { Delivery } from "../../confirmationReceipt/receiptDelivery/receiptDelivery.model";
import { getDeliveryReceiptById } from "../customer.reducer";
import "../../../assets/styles/style-print.css";

const WarehouseFormatPrint = () => {
  const dispatch = useDispatch();

  const salesOrderSingleDetail = useSelector(
    (state: RootState) =>
      state.invoice.salesOrderSingleDetail ??
      ({} as SalesOrderSingleDetailModel)
  );

  const deliveryReceiptDataState = useSelector(
    (state: RootState) => state.customer.deliveryReceiptData ?? ({} as Delivery)
  );

  useEffect(() => {
    let path = window.location.pathname;
    let id = path.split("/")[3];
    dispatch(getSalesOrderSingleDetail(id));

    setTimeout(() => {
      window.print();
    }, 1500);
  }, []);

  useEffect(() => {
    if (salesOrderSingleDetail?.salesOrder?.soNumber !== undefined) {
      dispatch(
        getDeliveryReceiptById(salesOrderSingleDetail?.salesOrder?.soNumber)
      );
    }
  }, [salesOrderSingleDetail]);

  const moneyParser = (value: any) => {
    if (value) {
      let valueString = value.toString();
      let valueList = valueString.split("");
      let resultList: any = [];
      valueList.reverse().map((c: any, i: number) => {
        resultList.push(c);
        if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
          resultList.push(".");
        }
      });
      let result = resultList.reverse().join("");
      return "Rp" + result + ",00";
    }
    return value;
  };

  return (
    <div className="print-container-warehouse">
      <Table className="m-3" borderless>
        <tr>
          <td colSpan={3}>
            <h6>KODE JOB : </h6>
          </td>
        </tr>
        <tr>
          <td colSpan={3} align="center">
            {" "}
            <h4>
              <b>EMORY STYLE</b>
            </h4>{" "}
          </td>
        </tr>
      </Table>

      <Table className="m-3" borderless>
        <tr>
          Kepada yang Tersayang :{" "}
          <b>{salesOrderSingleDetail?.salesOrder?.customerName}</b>
        </tr>
        <tr>
          Alamat :{" "}
          <b>
            {salesOrderSingleDetail?.shipment?.recipientAddress},
            {salesOrderSingleDetail?.shipment?.recipientAddress},
            {salesOrderSingleDetail?.shipment?.recipientDistrictName},
            {salesOrderSingleDetail?.shipment?.recipientCityName},
            {salesOrderSingleDetail?.shipment?.recipientProvinceName}
          </b>
        </tr>
        <tr>
          Order No. :<b>{salesOrderSingleDetail?.salesOrder?.soNumber}</b>
        </tr>
        <tr>
          Produk :{" "}
          {salesOrderSingleDetail?.salesOrderDetail?.map((item: any, index) =>
            index === salesOrderSingleDetail?.salesOrderDetail?.length - 1 ? (
              <b>{item?.productName + " - " + item?.variationName}</b>
            ) : (
              <b> {item?.productName + " - " + item?.variationName + ","}</b>
            )
          )}
        </tr>
        <br />
        Semoga EMORY ini dapat mencerahkan hari dan hati Kesayangan yaaa. Pas
        sempat mohon testi atau tag foto kesayangan di Instagram kami
        @emorystyle
        <br />
        <br />
        Note :
        <br />
        Langsung periksa isi paket, jika ada masalah mohon video buka paket dan
        foto permasalahan dan langsung chat ke Whatsapp +628117783800 hanya
        dalam 1x24 jam setelah penereimaan ya.
        <br />
        Gomawoyoo..
      </Table>

      <Table className="m-3" borderless>
        <br />
        <tr>
          Tgl/Jam :{" "}
          <b>
            {moment(
              new Date(salesOrderSingleDetail?.salesOrder?.orderDate)
            ).format("DD/MM/YYYY HH:mm")}
          </b>
        </tr>
        <tr>
          Order No. : <b>{salesOrderSingleDetail?.salesOrder?.soNumber}</b>
        </tr>
        <tr>
          Kurir/Ongkir/Kilo :{" "}
          <b>{salesOrderSingleDetail?.shipment?.expeditionName}</b>/
          <b>{salesOrderSingleDetail?.shipment?.totalShippingCost}</b>/
          <b>{salesOrderSingleDetail?.shipment?.totalWeight}</b>
        </tr>
      </Table>

      <Table className="m-3" size="sm" bordered>
        <thead>
          <th style={{ textAlign: "center" }}>
            <b>GD</b>
          </th>
          <th style={{ textAlign: "center" }}>
            <b>QC</b>
          </th>
          <th style={{ textAlign: "center" }}>
            <b>PC</b>
          </th>
        </thead>
        <tbody>
          <tr>
            <td align="center">
              {deliveryReceiptDataState?.adminGudang === undefined
                ? "-"
                : deliveryReceiptDataState?.adminGudang}
            </td>
            <td align="center">
              {deliveryReceiptDataState?.adminQC === undefined
                ? "-"
                : deliveryReceiptDataState?.adminQC}
            </td>
            <td align="center">
              {deliveryReceiptDataState?.adminPacking === undefined
                ? "-"
                : deliveryReceiptDataState?.adminPacking}
            </td>
          </tr>
        </tbody>
      </Table>

      <Table className="m-3" borderless>
        <br />
        Print By :{" "}
        {JSON.parse(localStorage.getItem("__emoryuser") || "{}").username}
      </Table>
    </div>
  );
};

export default WarehouseFormatPrint;
