import React, { useEffect } from 'react'
import '../slide.scss'
import { Container, Button } from "react-bootstrap";
import {FiEdit, FiPlus, FiTrash} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRecommended, getRecommendedList } from '../recommendedSlide.api';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { unwrapResult } from '@reduxjs/toolkit';

export default function RecommendedSlide() {
    const dispatch = useDispatch()
    const {recommendedList} = useSelector((state:RootState) => state.recommendedSlide);

    useEffect(() => {
        dispatch(getRecommendedList())
    }, [])

    const handleDelete=(id:any)=>{
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data yang sudah di hapus tidak akan bisa di kembalikan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus!',
            cancelButtonText: 'Batalkan'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch<any>(deleteRecommended(id))
                .then(unwrapResult)
                .then((res:any)=>{
                    if (res.status) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Slide show deleted',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        .then(()=>{
                            dispatch(getRecommendedList())
                        })
                    }else{
                        Swal.fire(
                            'Delete slide show failed!',
                            ``,
                            'error'
                        ) 
                    }
                })

            }
        })
    }

    return (
        <Container className="content-body slide" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                Recommended Slide
                <Link to='/recommended-slide/form' >
                    <Button className='addor bg-info'><FiPlus/> Add Recommended Slide</Button>
                </Link>
            </div>
            <div className="box">
                <div className="con">
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing {recommendedList?.length ?? "0"} of {recommendedList?.length ?? "0"} results
                        {/*<div className="result d-flex justify-content-between align-items-center">*/}
                        {/*    <span>Results per page:</span> */}
                        {/*    <select className="custom-select sel">*/}
                        {/*        <option value="1">10</option>*/}
                        {/*        <option value="2">20</option>*/}
                        {/*        <option value="3">30</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                    </div>

                    <table className="table">
                        <thead className="">
                            <tr>
                                {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                <th scope="col">image</th>
                                <th scope="col">link</th>
                                <th scope="col">
                                    <div className="text-center">
                                    action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                recommendedList.map((res:any, i:number)=>(
                                    <tr key={i}>
                                        {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                        {/*<td>*/}
                                        {/*    <Link to={`/setting-slide/view/${res.id}`} className='text-dark'>*/}
                                        {/*    {res.title}*/}
                                        {/*    </Link>*/}
                                        {/*</td>*/}
                                        <td>
                                            <img src={res.image} alt="slide" />
                                        </td>
                                        <td>{res.link}</td>
                                        <td className='text-center'>
                                            <div className="action">
                                                <Link to={`/recommended-slide/form/${res.id}`}>
                                                <FiEdit className='text-info' style={{zIndex : 2}} />
                                                </Link>
                                                <FiTrash style={{zIndex : 2}} onClick={()=> handleDelete(res.id)} className='text-danger'/>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                               
                        </tbody>
                    </table>

                </div>
            </div>
        </Container>
    )
}
