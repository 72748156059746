import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
  Button,
} from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import { DragSwitch } from "react-dragswitch";
import { RootState } from "../../../../app/store";
import {PartnerTypeEnum} from "../SettingPointReward.model";

const SettingPointRewardForm = ({ type= 0 , ...props}) => {

  const [id, setId] = useState("")
  const [isPointReward, setIsPointReward] = useState(false);
  const [pointType, setPointType] = useState(0)
  const [shoppingAmountToPoint, setshoppingAmountToPoint] = useState(0)
  const [qty, setQty] = useState(0)
  const [point, setPoint] = useState(0)
  const [pointToShoppingAmount, setPointToShoppingAmount] = useState(0)

  const PointRewardListState = useSelector(
    (state: RootState) => state.settingPointReward.pointRewardSettingList ?? []
  )

    const PointRewardSettingData = PointRewardListState.filter(
      (item) => item.partnerType === type
    )[0];

  const onShoppingAmountToPointChange = (e: ChangeEvent<HTMLInputElement>) => {
      setshoppingAmountToPoint(parseInt(e.target.value))
  }

  const onQtyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQty(parseInt(e.target.value))
}

const onPointChange = (e: ChangeEvent<HTMLInputElement>) => {
  setPoint(parseInt(e.target.value))
}

  const onPointToShoppingAmountChange = (e : ChangeEvent<HTMLInputElement>) => {
    setPointToShoppingAmount(parseInt(e.target.value))
  }
  
  const onSaveClick = () => {
    console.log("Klik save")
    props.onUpdate(
      id,
      type,
      isPointReward,
      pointType,
      shoppingAmountToPoint,
      qty,
      point,
      pointToShoppingAmount
    )
  }

  useEffect(() => {

    if (
      PointRewardSettingData &&
      PointRewardSettingData.constructor == Object
    ) {

      setId(PointRewardSettingData.id)
      setIsPointReward(PointRewardSettingData.isPointReward)
      setPointType(PointRewardSettingData.pointType)
      setshoppingAmountToPoint(PointRewardSettingData.shoppingAmountToPoint)
      setQty(PointRewardSettingData.qty)
      setPoint(PointRewardSettingData.point)
      setPointToShoppingAmount(PointRewardSettingData.pointToShoppingAmount)
    }
    
  }, [PointRewardSettingData])



  return (
    <div>
      <Form>
        <Grid>
          <hr />
          <br />
          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={3}>
              Status Point dan Reward
            </Form.Label>
            <Col sm={9}>
              <DragSwitch
                checked={isPointReward}
                onChange={(e) => {
                  setIsPointReward(e);
                }}
              />
            </Col>
          </Form.Group>
          <hr />

          <b>Mendapatkan Poin</b>
          <br />
          <br />
          <Form.Group as={Row} className="input-padding ml-1">
            <Col sm={4}>
              <Form.Check
                label="Nilai Nominal Belanja ke Point"
                type="radio"
                checked={pointType === 0}
                value={0}
                onClick={() => setPointType(0)}
              />
            </Col>

            <Col sm={6}>
              <Form.Control
                placeholder="Rp."
                type="number"
                onChange={onShoppingAmountToPointChange}
                value={shoppingAmountToPoint}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="input-padding pt-0">
            <Col sm={4}></Col>
            <Col sm={6}>
              <span>
                * Nilai belanja yang diperlukan pelanggan untuk mendapatkan 1
                point. Jika diisi dengan 1000, artinya jika pelanggan belanja
                senilai Rp1.000.000 maka akan mendapatkan 1.000 point.
              </span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="input-padding ml-1">
            <Col sm={4}>
              <Form.Check
                label="Nilai QTY Belanja ke Point"
                type="radio"
                checked={pointType === 1}
                value={1}
                onClick={() => setPointType(1)}
              />
            </Col>

            <Col sm={3}>
              <Form.Control
                placeholder="QTY"
                type="number"
                onChange={onQtyChange}
                value={qty}
                
              />
            </Col>
            <Col sm={3}>
              <Form.Control
                placeholder="Point"
                type="number"
                onChange={onPointChange}
                value={point}
                
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="input-padding pt-0">
            <Col sm={4}></Col>
            <Col sm={6}>
              <span>
                * Nilai QTY belanja yang diinput sama dengan berapa point yang didapat customer. Jika diisi dengan 10 qty dan 1 point, artinya jika pelanggan belanja senilai belanja sebanyak 10 qty maka akan mendapatkan 1 point.
              </span>
            </Col>
          </Form.Group>
          <hr />

          <b>Tukar Poin</b>
          <br />
          <br />

          <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Nilai Point ke Nominal Belanja
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                placeholder="Rp."
                type="number"
                  onChange={onPointToShoppingAmountChange}
                  value={pointToShoppingAmount}
                  // required
              />
            </Col>
          </Form.Group>

          <hr />

          <div className="btn-footer-group float-left">
            <Button
              variant="outline-info"
              style={{ width: 130, height: 45 }}
            
            >
              Cancel
            </Button>
            {"     "}
            <Button
              variant="info"
              style={{ width: 130, height: 45 }}
              onClick={onSaveClick}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Form>
    </div>
  );
};

export interface SettingPointRewardProps {
  onUpdate: (
    id: string,
    partnerType: number,
    isPointReward: boolean,
    pointType : number,
    shoppingAmountToPoint: number,
    qty: number,
    point: number,
    pointToShoppingAmount: number
  ) => void;
  type: PartnerTypeEnum
}

export default SettingPointRewardForm;
