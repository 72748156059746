import axios from "axios";
import { endpoint } from "../../../constants";
import {
  CreateInventoryMoveDetailModel,
  FilterModel,
  InventoryMoveModel,
  InventoryReceiveHistoryModel,
} from "./inventoryMove.model";

export default class Api {
  static createData(model: InventoryMoveModel) {
    return axios
      .post<any>(endpoint + "/api/InventoryMove/Create", model)
      .then((res) => res.data);
  }

  static createDetailData(model: CreateInventoryMoveDetailModel) {
    return axios
      .post<any>(endpoint + "/api/InventoryMoveDetail/Create", model)
      .then((res) => res.data.data);
  }

  static getList() {
    return axios
      .get<any>(endpoint + "/api/InventoryMove/GetList")
      .then((res) => res.data.data);
  }

  static getListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/InventoryMove/GetListWithPaging", model)
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/InventoryMove/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: InventoryMoveModel) {
    return axios
      .post<any>(endpoint + "/api/InventoryMove/update", model)
      .then((res) => res.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/InventoryMove/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static getInventoryMoveDetailList(id: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/InventoryMoveDetail/GetListByMaster?inventoryMoveId=" +
          id
      )
      .then((res) => res.data.data);
  }

  static deleteInventoryMoveDetail(id: string) {
    return axios
      .post<any>(endpoint + "/api/InventoryMoveDetail/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  //inventoryReceive
  static createInventoryHistoryReceive(model: InventoryReceiveHistoryModel) {
    return axios
      .post<any>(endpoint + "/api/HistoryInventoryReceive/Create", model)
      .then((res) => res.data);
  }

  static getProductListByWarehouse(id: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/product/GetListByWarehouse?warehouseId=" +
          id
      )
      .then((res) => res.data.data);
  }

  static getListVariationByProduct(id: string) {
    return axios
      .get<any>(endpoint + "/api/Variation/GetListByProduct?productId=" + id)
      .then((res) => res.data.data);
  }
}
