import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { ApiErrorResponse } from '../../..';
import { UploadImageModel } from '../../registerReseller/registerReseller.model';
import { BankModel } from '../bank/bank.model';
import Api from './paymentMethod.api';
import { FilterModel, PaymentMethodModel, RoleModel } from './paymentMethod.model';

export interface PaymentMethodSlice {
    name? :string;
    list?: PaymentMethodModel[];
    roleList?: RoleModel[];
    single?: PaymentMethodModel;
    bankList? : BankModel[];
    imagePath? : string;
    isLoading?: boolean;   
    totalRow? : number; 
    error?: ApiErrorResponse<any>;
    status?: string;
    modifiedDate?: string;
}

export const createData = createAsyncThunk(
    'PaymentMethodCreateState/PaymentMethodCreate',
    async (Model: PaymentMethodModel, {rejectWithValue}) => {
        try {
            return await Api.createData(Model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getList = createAsyncThunk(
     'PaymentMehtodGetListState/PaymentMethodGetList',
     async ( _:string | undefined = undefined, {rejectWithValue}) => {
         try {
             return await Api.getList();
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 );

 export const getListWithPaging = createAsyncThunk(
    'PaymentMehtodGetListWithPagingState/PaymentMethodGetListWithPaging',
    async ( model: FilterModel, {rejectWithValue}) => {
        try {
            return await Api.getListWithPaging(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

 export const getBankList = createAsyncThunk(
    'getPaymentMethodBankListState/getBankList',
    async ( _:string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await Api.getBankList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const uploadPhoto = createAsyncThunk(
    'UploadPhotoPaymentMethodState/UploadPhotoPaymentMethod',
    async (model: UploadImageModel, { rejectWithValue }) => {
        try {
            return await Api.uploadImage(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getSingleItem = createAsyncThunk(
     'PaymentMethodSingleItemState/PaymentMethodSingleItem',
    async (customerId: string, {rejectWithValue}) => {
         try {
             return await Api.getSingleItem(customerId);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
 )

 export const updateData = createAsyncThunk(
     'PaymentMehthodStatePaymentMehthodUpdate',
     async (model: PaymentMethodModel, {rejectWithValue}) => {
         try {
             return await Api.updateData(model);
         } catch (e) {
             return rejectWithValue(e as ApiErrorResponse<any>);
         }
     }
)

export const deleteData = createAsyncThunk(
    'PaymentMethodDeleteState/PaymentMethodDelete',
    async (id: string, {rejectWithValue}) =>{
        try {
            return await Api.deleteData(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getRoleList = createAsyncThunk(
    'GetRoleListState/GetRoleList',
    async ( _:string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await Api.getRoleList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const PaymentMethodSlice = createSlice({
    name: 'PaymentMethodState',
    initialState: {} as PaymentMethodSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        reset: (state) => {
            state.single = {} as PaymentMethodModel;
            state.status = "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createData.pending, (state) => {
          state.isLoading = true;
          state.status = "";
        });
        builder.addCase(createData.fulfilled, (state, { payload }) => {
          state.name = new Date().toISOString();
          state.isLoading = false;
          state.status = "success";
        });
        builder.addCase(createData.rejected, (state, { payload }) => {
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
          state.status = "failed";
        });
        builder.addCase(getSingleItem.pending, (state) =>{
            state.single = undefined;
            state.isLoading = true;
            
        });
        builder.addCase(getSingleItem.fulfilled, (state, {payload})=>{
            state.single = payload;
            state.isLoading = false;
        })
        builder.addCase(getSingleItem.rejected, (state, {payload}) =>{
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })
        builder.addCase(getList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload})=> {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getListWithPaging.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListWithPaging.fulfilled, (state, {payload})=> {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getListWithPaging.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getBankList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getBankList.fulfilled, (state, {payload})=> {
            state.bankList = payload;
            state.isLoading = false;
        });
        builder.addCase(getBankList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(updateData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateData.fulfilled, (state, {payload})=> {
            state.isLoading = false;
            state.name = new Date().toISOString();
            state.status = "success";
        })
        builder.addCase(updateData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.status = "failed";
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteData.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.modifiedDate = new Date().toISOString();
        })
        builder.addCase(deleteData.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })

        builder.addCase(getRoleList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRoleList.fulfilled, (state, {payload})=> {
            state.roleList = payload;
            state.isLoading = false;
        });
        builder.addCase(getRoleList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(uploadPhoto.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(uploadPhoto.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imagePath = payload.data.imagepath;
        })
        builder.addCase(uploadPhoto.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        })
      },
})

export const { setFilter, reset } = PaymentMethodSlice.actions;
export default PaymentMethodSlice.reducer;