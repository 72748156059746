import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Spinner,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import "../chatPage.scss";
import {
  FaSearch,
  FaRegEdit,
  FaCircle,
  FaSmile,
  FaFileExport,
} from "react-icons/fa";
import { FiMoreVertical, FiPrinter, FiTrash, FiX } from "react-icons/fi";
import { MdAttachFile, MdSend } from "react-icons/md";
import { Checkbox, Dropdown, Radio } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { endpoint } from "../../../../constants";
import Swal from "sweetalert2";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  getUserContactAll,
  getUserContactCustomer,
  getUserContactReseller,
  postImageUploadChat,
  postLastMessage,
  sendMessageChatPage,
} from "../chatPage.api";
import { RootState } from "../../../../app/store";

import db from "../../../../app/firebaseInit";
import moment from "moment";
import { convertBase64 } from "../../../../components/Library/Base64";

import noImageUser from "../../../../assets/noImageUser.jpeg";
import { getFirestore, doc, setDoc } from "firebase/firestore";

type UnreadMessageCount = {
  id: string;
  unreadCount: number;
};

const ChatCustomer = () => {
  // console.log(isProd)
const messageChat = db.collection("MESSAGE_CHAT");
const chatProduct = getFirestore(db.app);

// const history = useHistory()
const dispatch = useDispatch();

const { listContactCustomer, loading, chatLoading, imageLoading } = useSelector(
  (state: RootState) => state.chatPage
);
const [activePerson, setActivePerson] = useState<any>(null);
const [dialog, setDialog] = useState<any>([]);
const [userEmory] = useState<any>(localStorage.getItem("__emoryuser") || "");
const userState = useSelector((state: RootState) => state.user ?? "");
const [adminId, setAdminId] = useState<any>("");

const [filteredContact, setFilteredContact] = useState<any>([]);
const [unreadCount, setUnreadCount] = useState(0);

  const [unreadMessageCounts, setUnreadMessageCounts] = useState<
    UnreadMessageCount[]
  >([]);

  const [tempUnreadMessageCount, setTempUnreadMessageCount] = useState<
    UnreadMessageCount[]
  >([]);

useEffect(() => {
  dispatch(getUserContactCustomer());
  setAdminId(JSON.parse(userEmory).id);
  getUnreadCount();
}, []);

useEffect(() => {
  setFilteredContact(listContactCustomer);
}, [listContactCustomer]);

useEffect(() => {
  if (activePerson !== null) {
    getLiveMessage();
  }
}, [activePerson]);

useEffect(() => {
  if (tempUnreadMessageCount.length !== 0) {
    tempUnreadMessageCount.forEach((item) => {
      if (unreadMessageCounts.find((uumc) => uumc.id === item.id)) {
        // console.log("FOUND !!");

        const updatedItemData = unreadMessageCounts.map((uid) => {
          if (uid.id === item.id) {
            return {
              ...uid,
              ["unreadCount"]: uid.unreadCount + item.unreadCount,
            };
          }
          return uid;
        });

        setUnreadMessageCounts(updatedItemData as UnreadMessageCount[]);
      } else {
        // console.log("NEW DATA");
        setUnreadMessageCounts([...unreadMessageCounts, item]);
      }
    });
  }
}, [tempUnreadMessageCount]);


// useEffect(() => {

// }, [dialog]);
const messageRef = useRef<any>(null);
const scrollToBottom = () => {
  messageRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
};

const getUnreadCount = () => {
  messageChat.onSnapshot({
    next: (querySnapshot: any) => {
      setUnreadCount(0);
      querySnapshot.docs.forEach((docSnapshot: any) => {
        if (
          docSnapshot.data().unread !== undefined &&
          docSnapshot.data().unread !== 0
        ) {
          console.log("New partnerId !!");

          setTempUnreadMessageCount((prevState) => [
            ...prevState,
            {
              id: docSnapshot.data().partnerId[0],
              unreadCount: unreadCount + docSnapshot.data().unread,
            },
          ]);
        }
      });
    },

    complete: () => {},
  });
};

const readMessage = (messageId: string) => {
  const docRef = doc(chatProduct, "MESSAGE_CHAT", messageId);

  const data = {
    unread: 0,
  };

  setDoc(docRef, data, { merge: true })
    .then((docRef) => console.log("READ MESSAGE"))
    .catch((error) => console.log("error"));

  const temp = unreadMessageCounts.filter(
    (item) => item.id !== activePerson.id
  );
  setUnreadMessageCounts(temp);
};

const getLiveMessage = () => {
  messageChat
    .where("partnerId", "array-contains-any", [activePerson.id])
    .orderBy("createdAt", "asc")
    // .limit(20)
    .onSnapshot({
      next: (querySnapshot: any) => {
        const updatedMessages = querySnapshot.docs.map((docSnapshot: any) => {
          return { ...docSnapshot.data(), id: docSnapshot.id };
        });
        querySnapshot.docs.map((message: any) => {
          if (message.data().userId !== "admin") {
            console.log(message.data())
            readMessage(message.id);
          }
        });
        Promise.all([setDialog(updatedMessages)]).then(() => {
          scrollToBottom();
        });
      },
      error: (err: any) => {
        return [];
      },
    });
};

const [loadingImage, setloadingImage] = useState<any>(false);

const handleImage = async (e: any) => {
  const dataFile: any = await Array.from(e.target.files);

  let finalData: any = [];
  for (const res of dataFile) {
    setloadingImage(true);

    convertBase64(res, async (result: any) => {
      const imageData = await result.split(",");
      const base64Final = await imageData[1];
      const objBase = {
        file_name: res.name,
        base64url: base64Final,
        imagepath: "",
        location: "string",
      };
      // return finalData.push(objBase)
      dispatch<any>(postImageUploadChat(objBase))
        .then(unwrapResult)
        .then((res: any) => {
          setloadingImage(true);
          if (res.status === 200) {
            setloadingImage(true);
            finalData.push(res.data.imagepath);
          } else {
            Swal.fire({
              icon: "error",
              text: "Error uploading image!",
            });
          }
        })
        .then(async () => {
          setloadingImage(true);
          setImage(finalData);
        })
        .then(async () => {
          setloadingImage(false);
        });
    });
  }
};

const handleChangeChatPerson = (person: any) => {
  setActivePerson(person);
};

//   const [recipients, setRecipients] = useState<any>([])
//   const [sendToAll, setSendToAll] = useState<any>(false)
//   const [subject, setSubject] = useState<string>('')
const [description, setDescription] = useState<string>("");
const [image, setImage] = useState<any>([]);

const handleSendMessage = (e: any) => {
  e.preventDefault();
  const data = {
    partnerId: [activePerson.id],
    userId: "admin",
    adminUserName: userState.username,
    content: description,
    sendToAll: false,
    // subject : '',
    image: image,
    unread: 1,
    createdAt: new Date(),
    product: null,
    
    // product : {
    //     // name : '',
    //     // price : '',
    //     // id : '',
    //     // productImage :''
    // }
  };
  dispatch<any>(sendMessageChatPage(data))
    .then(unwrapResult)
    .then((res: any) => {
      console.log(res);
      setDescription("");
      setImage([]);
      scrollToBottom();
      handleLastMessage();
    })
    .catch((err: any) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        text: "Error send a message!",
      });
    });
};

const handleLastMessage = () => {
  const data = {
    userId: activePerson.userId,
  };
  console.log(data);

  dispatch<any>(postLastMessage(data))
    .then(unwrapResult)
    .then((res: any) => {
      console.log(res, "last message");
      dispatch(getUserContactCustomer());
    });
};

const [rerender, setRerender] = useState<any>(new Date());
useEffect(() => {
  setInterval(() => setRerender(new Date()), 60000);
}, []);

const handleChangeFilter = (e: any) => {
  const filtered = listContactCustomer.filter((res: any) =>
    res.fullName.toLowerCase().includes(e.target.value.toLowerCase())
  );
  setFilteredContact(filtered);
};

console.log(unreadMessageCounts)

return (
  <Container className="content-body template_chatpage" fluid>
    {/* <div className="page-title mb-4 d-flex align-items-center justify-content-between">
              Chat
              <div>
                  <Button className='addor'><FaFileExport /> Export to Excel</Button>
                  <Button className='addor'><FiPrinter /> Print</Button>
              </div>
          </div> */}

    <br />
    <div className="boxchat">
      <div className="list">
        <div className="head">Contact</div>
        <div className="search">
          <FaSearch />
          <input
            type="text"
            placeholder="Search or start new chat"
            onChange={handleChangeFilter}
          />
        </div>
        <div className="box">
          <ul>
            {filteredContact.map((res: any, i: number) => (
              <li
                key={i}
                className={activePerson?.id === res.id ? "active-chat" : ""}
                onClick={() => handleChangeChatPerson(res)}
              >
                <img
                  src={endpoint + res.selfPhoto}
                  onError={({ currentTarget }: any) => {
                    currentTarget.src = noImageUser;
                  }}
                  style={{ objectFit: "cover" }}
                  alt="profile"
                />
                <div className="bio">
                  <p className="name">
                    {res.fullName} 
                    <span>
                    {unreadMessageCounts.find(
                          (item) => item.id === res.id
                        ) ? (
                          <>
                            <svg
                              className="sidebar-menu-tree__icon"
                              width="6"
                              height="6"
                            >
                              <circle cx="3" cy="3" r="3" fill="red" />
                            </svg>
                          </>
                        ) : (
                          <>
                            {res.lastChatTime &&
                              moment(
                                new Date(res.lastChatTime),
                                "YYYYMMDD"
                              ).fromNow()}
                          </>
                        )}
                    </span>
                  </p>
                  {/* <p>{res.id}</p> */}
                  <p className="sub">
                    subject message
                    <span>
                      {res.type === 0
                        ? "Customer"
                        : res.type === 2
                        ? "Seller"
                        : "Stokist"}{" "}
                      <FaCircle />
                    </span>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="chat">
        <div className="head">
          {activePerson === null ? (
            "Click Contact to start chat!"
          ) : (
            <div className="prof">
              <img
                src={endpoint + activePerson?.selfPhoto}
                onError={({ currentTarget }: any) => {
                  // currentTarget.onerror = null; // prevents looping
                  currentTarget.src = noImageUser;
                }}
                alt="profile"
                style={{ objectFit: "cover" }}
              />
              <div>
                <p className="name">
                  {activePerson?.fullName}
                  <span>
                    {activePerson?.type === 0
                      ? "Customer"
                      : activePerson?.type === 2
                      ? "Seller"
                      : "Stokist"}{" "}
                    <FaCircle />
                  </span>
                </p>
                <p className="sub">subject message </p>
              </div>
            </div>
          )}
          {/* <button onClick={()=>scrollToBottom()}>scroll</button> */}
        </div>

        <div className="boxtype">
          <div
            className="chatcol"
            // ref={messageRef}
          >
            <div
              className="w-100"
              style={{ paddingBottom: 90 }}
              ref={messageRef}
            >
              {dialog.map((res: any, i: number) => (
                <div
                  className={`col-md-12 d-flex  ${
                    res.userId === "admin"
                      ? "justify-content-end text-right"
                      : "justify-content-start"
                  }`}
                  key={i}
                >
                  <div className="wraptext mb-3">
                    <div className="buble ">
                      {res?.image?.length !== 0
                        ? res.image.map((item: any, i: number) => (
                            <>
                              <img
                                key={i}
                                src={endpoint + item}
                                alt="message-image"
                                width="250"
                                className="imf-fluid mb-2"
                              />
                              <br />
                            </>
                          ))
                        : null}
                      <p
                        className={res.userId === "admin" ? "con" : "concus"}
                      >
                        {res.content}
                      </p>
                      <p className="time">
                        {/* {time} */}
                        {moment(res.createdAt.toDate(), "YYYYMMDD").fromNow()} {res.userId === 'admin' ? <>- By : {res.adminUserName}</> : <></>} 
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              {chatLoading ? (
                <div className="col-md-12 d-flex justify-content-end">
                  <Spinner
                    size="sm"
                    animation="border"
                    style={{ opacity: ".2" }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {activePerson !== null ? (
            <div className="chatinput pt-2 px-3 pb-3">
              {image.length !== 0 ? (
                <div className="wrapimage p-2 w-100 d-flex justify-content-between border bg-light align-items-center radius-2">
                  <div
                    className=" d-flex pb-0 align-items-center"
                    style={{ overflowX: "auto" }}
                  >
                    {image.map((res: any, i: number) => (
                      <img
                        key={i}
                        src={endpoint + res}
                        className="img-fluid mr-2"
                        alt="uploadedimage"
                        style={{ width: 40, height: 40 }}
                      />
                    ))}
                  </div>
                  <FiTrash
                    className="text-danger"
                    size={20}
                    onClick={() => setImage([])}
                  />
                </div>
              ) : null}
              <div className="wrapinput">
                <input
                  type="file"
                  name=""
                  style={{ display: "none" }}
                  onChange={handleImage}
                  id="attach"
                  multiple={true}
                />
                <FaSmile className="emoji" />
                <form onSubmit={handleSendMessage}>
                  <input
                    type="text"
                    placeholder="Type something here..."
                    required
                    onChange={(e: any) => setDescription(e.target.value)}
                    value={description}
                  />
                  {imageLoading || loadingImage ? (
                    <span className="mr-2">
                      <Spinner
                        animation="border"
                        variant="warning"
                        size="sm"
                      />
                    </span>
                  ) : (
                    <label htmlFor="attach">
                      <MdAttachFile className="attachfile" />
                    </label>
                  )}
                  <button
                    className="sendmessage"
                    type="submit"
                    disabled={imageLoading || chatLoading || loadingImage}
                  >
                    <MdSend />
                  </button>
                </form>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  </Container>
);
}
export default ChatCustomer