import axios from "axios";
import { endpoint } from "../../../constants";
import { UpdateSettingExpeditionModel } from "./SettingExpedition.model";

export default class SettingExpeditionApi {

    static getResellerExpeditionSettingList() {
        return axios
            .get<any>(endpoint + "/api/ExpeditionSetting/GetListByPartner?partnerType="+2)
            .then((res) => res.data.data);
    }

    static getStockistExpeditionSettingList() {
        return axios
            .get<any>(endpoint + "/api/ExpeditionSetting/GetListByPartner?partnerType="+1)
            .then((res) => res.data.data);
    }

    static getEndCustomerExpeditionSettingList() {
        return axios
            .get<any>(endpoint + "/api/ExpeditionSetting/GetListByPartner?partnerType="+0)
            .then((res) => res.data.data);
    }

    static getExpeditionSettingList(partnerType: Number) {
        return axios
            .get<any>(endpoint + "/api/ExpeditionSetting/GetListByPartner?partnerType="+partnerType)
            .then((res) => res.data.data);
    }

    static updateExpeditionSetting(model: UpdateSettingExpeditionModel) {
        return axios
            .post<any>(
                endpoint + "/api/ExpeditionSetting/Update",
                model
            )
            .then((res) => res.data.data);
    }
}