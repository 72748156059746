import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import AlertMessage from "../../../components/alertMessage";
import DeleteMessage from "../../../components/DeleteMessage";
import DataTable from "react-data-table-component";
import edit from "./../../../assets/vector/edit.png";
import hapus from "./../../../assets/vector/delete.png";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../app/store";
import {
  deleteMembership,
  getMembershipList,
  resetStatus,
} from "../settingMembership.reducer";
import { MembershipModel } from "../settingMembership.model";

const SettingMembershipTable = () => {
  const dispatch = useDispatch();

  const membershipListState = useSelector(
    (state: RootState) => state.settingMembership.list ?? []
  );

  const settingMembershipStatus = useSelector(
    (state: RootState) => state.settingMembership.status ?? ""
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterStatus, setFilterStatus] = useState("0");
  const [isActiveStatus, setIsActiveStatus] = useState(true);
  const [selectedId, setSelectedId] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleterAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });


  
  const [filteredItems, setFilteredItems] = useState<MembershipModel[]>([]);
  
  // membershipListState.filter(
  //   (item) =>
  //     item.isActive === isActiveStatus &&
  //     item.title.toLowerCase().includes(filterText.toString())
  // );

  const onDelete = (id: string) => {
    dispatch(deleteMembership(id));
  };

  const onDeleteData = () => {
    setShowDeleteAlert(false);
    dispatch(deleteMembership(selectedId));
  };

  const onDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    dispatch(resetStatus());
    dispatch(getMembershipList());
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (settingMembershipStatus === "success-delete") {
      dispatch(resetStatus());
    }
  };

  useEffect(() => {
    dispatch(getMembershipList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(membershipListState.length > 0){
      setFilteredItems(
        membershipListState
      )
    }
  }, [membershipListState]);
  

  useEffect(() => {
    if (settingMembershipStatus !== "") {
      if (settingMembershipStatus === "success-delete") {
        dispatch(getMembershipList());
        setAlertProps({
          variant: "success",
          title: "Data Setting Membership sudah terhapus",
          content: " ",
        });
        setShowAlert(true);
      }
    }
  }, [settingMembershipStatus]);

  const columns = [
    {
      name: "MEMBERSHIP TITLE",
      selector: "title",
      sortable: true,
    },
    // {
    //   name: "DATE",
    //   selector: (d: { periodStart: string }) => (
    //     <span>{d.periodStart.substr(0, 10)}</span>
    //   ),
    //   sortable: true,
    // },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && <span>Active</span>}
          {d.isActive === false && <span>Inactive</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-setting-membership/${d.id}`,
            }}>
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setDeleterAlertProps({
                variant: "info",
                title: "Delete Data",
                content: "Yakin akan menghapus data ini ?",
              });
              setSelectedId(d.id);
              setShowDeleteAlert(true);

              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //     onDelete(d.id);
            }}>
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Membership Setting</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="float-right"
            style={{ width: 130, marginLeft: 10 }}
            variant="outline-info">
            Print
          </Button>{" "}
          <Button
            className="float-right"
            style={{ width: 160, marginLeft: 10 }}
            variant="outline-info">
            Export to excel
          </Button>{" "}
          <Link to={{ pathname: "/create-setting-membership" }}>
            <Button
              className="float-right ml-2"
              variant="info"
              style={{ width: 180 }}>
              + Add Membership
            </Button>
          </Link>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Membership Title
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search Membership Title"
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Status</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="select"
                    value={filterStatus}
                    onChange={(e) => {
                      if (e.target.value === "0") {
                        setIsActiveStatus(true);
                      } else {
                        setIsActiveStatus(false);
                      }
                      setFilterStatus(e.target.value);
                    }}>
                    <option value={"0"}>Active</option>
                    <option value={"1"}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>

            {/* <Col md={5}>
                            <Form.Group as={Row} className="padding-filter mb-3 ">
                                <Form.Label column sm={3} style={{ fontStyle: "normal", fontWeight: "normal", fontSize: 14 }}>
                                    Status
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control as="select" style={{ height: 35 }}>
                                        <option>select</option>
                                    </Form.Control>
                                </Col>
                                <Col sm={2}>
                                    <Button style={{ width: 69, height: 35, fontSize: 14 }} variant="outline-info">Reset</Button>
                                </Col>
                            </Form.Group>
                        </Col> */}
          </Row>

          <Row>
            <Col md={6}>
              {/* <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Date
                </Form.Label>

                <Col sm={4}>
                  <Form.Control type="Date"></Form.Control>
                </Col>

                <Col sm={4}>
                  <Form.Control type="Date"></Form.Control>
                </Col>
              </Form.Group> */}
            </Col>

            <Col>
              <Button
                className="float-right inpur-padding mr-3"
                variant="outline-info"
                style={{ width: 130, height: 45 }}
                type="submit"
                onClick={() => {
                  setFilterText("");
                  setFilterStatus("0");
                  setIsActiveStatus(true);
                }}>
                Reset
              </Button>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            // subHeaderComponent={DefaultSubHeader(
            //   filterText,
            //   resetPaginationToggle,
            //   onSetterFilterText,
            //   elementProps
            // )}
            customStyles={TableCustomStyles}
          />
        </Card>
      </Grid>
      {/* <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage> */}

      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDeleteData}></DeleteMessage>
    </div>
  );
};

export default SettingMembershipTable;
