import axios from "axios";
import { ProfileModel } from "./profile.model";
import { endpoint } from "../../constants";

export default class ProfileApi {
  static getSingleItem(userId: string) {
    return axios
      .get<any>(endpoint + "/api/user/GetSingleItem?id=" + userId)
      .then((res) => res.data.data);
  }

  static updateProfile(user: ProfileModel) {
    return axios.post<any>(endpoint + "/api/User/UpdateUser", user).then((res) => res.data.data);
  }
}
