import axios from "axios";
import { endpoint } from "../../../constants";
import { ImageSettingModel } from "./SettingImage.model";

export default class SettingImageApi {

    static getImageSettingList() {
        return axios
            .get<any>(endpoint + "​/api/WebsitePageSetting/GetList")
            .then((res) => res.data);
    }

    static updateImageSetting(model: ImageSettingModel) {
        return axios
            .post<any>(
                endpoint + "/api/WebsitePageSetting/Update",
                model
            )
            .then((res) => res.data);
    }

}