import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'
import { AddressModel } from './address.model';

export default class AddressApi {

 static getEmoryAddress() {
  return axios
      .get<any>(endpoint + "​/api/address/GetEmoryAddress")
      .then((res) => res.data);
}

static updateEmoryAddress(model: AddressModel) {
    return axios
        .post<any>(
            endpoint + "/api/address/Update",
            model
        )
        .then((res) => res.data);
}

}