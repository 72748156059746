import { ProvinceModel } from "./province.model";
import { endpoint } from "../../../constants";
import axios from "axios";

export default class ProvinceApi {
  static createData(model: ProvinceModel) {
    return axios.post<any>(endpoint + "/api/province/Create", model).then((res) => res.data.data);
  }

  static getList() {
    return axios
      .get<any>(
        endpoint + "/api/Address/GetProvinceByCountry?Id=76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7"
      )
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/City/GetSingleProvince?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: ProvinceModel) {
    return axios.post<any>(endpoint + "/api/province/update", model).then((res) => res.data.data);
  }

  static deleteData(provinceId: string) {
    return axios
      .post<any>(endpoint + "/api/province/Delete?id=" + provinceId)
      .then((res) => [{ response: res.data.data }]);
  }
}
