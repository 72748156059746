import { Container, Form } from "react-bootstrap";
import PesananLunasTable from "../components/pesananLunasTable";

/*
 * created by : dikembe mutombo
 * created date : 28/oct/2021
 */

const PesananLunasIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan Lunas</Form.Label>
      </Form.Group>
      <PesananLunasTable />
    </Container>
  );
};

export default PesananLunasIndex;
