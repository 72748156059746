import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {Card, Form, Row, Col, Button, Spinner} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { DatePicker } from "antd";
// import { PenerimaanInventoryModel } from "../penerimaanInventory.model";
import { resetSingle, resetStatus } from "../penerimaanInventory.reducer";
import ProductTableComponent from "./productTableComponent";
import moment from "moment";
import { ItemReceiveModel } from "../penerimaanInventory.model";
import { getListActiveProduct } from "../../../../global/global.reducer";

const PenerimaanInventoryForm = ({
  data = {} as ItemReceiveModel,
  isEdit = false,
  ...props
}: PenerimaanInventoryProps) => {
  let history = useHistory();

  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const WarehouseListState = useSelector(
    (state: RootState) => state.global.listWarehouse ?? []
  );

  const createStatus = useSelector(
    (state: RootState) => state.penerimaanInventory.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.penerimaanInventory.error ?? ""
  );

  const isLoading = useSelector(
      (state: RootState) => state.penerimaanInventory.isLoading
  );

  const userIdState = useSelector((state: RootState) => state.user.id ?? "");

  const usernameState = useSelector(
    (state: RootState) => state.user.username ?? ""
  );

  const [receiveNumber, setReceiveNumber] = useState("");
  const [status, setStatus] = useState(0);
  const [warehouseId, setWarehouseId] = useState("");
  const [receiveDate, setReceiveDate] = useState(new Date());
  const [userId, setUserId] = useState(userIdState);
  const [note, setNote] = useState("");
  const [from, setFrom] = useState(usernameState);
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
      dispatch(getListActiveProduct());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      console.log("alert");
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = "";

      console.log(parse);

      if (message.message === "error") {
        xhr = message.data[0].description;
      } else {
        xhr = parse.data.title;
      }
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: xhr,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setReceiveNumber(data.inventoryNumber);
      //  setStatus(data.sta);
      setWarehouseId(data.warehouseId);
      setReceiveDate(data.receiveDate);
      setUserId(data.userId);
      setNote(data.note);
      setFrom(data.from);
    } else {
      setReceiveNumber("");
      setStatus(0);
      setWarehouseId("");
      setReceiveDate(new Date());
      setUserId(userIdState);
      setNote("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onReceiveDateChange = (value: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setReceiveDate(date);
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/penerimaan-inventory/index");
    } else {
      dispatch(resetStatus());
    }
  };

  return (
    <div>
      <Grid textAlign="center">
        <Form.Group>
          <Form.Label>
            <h4>
              {isEdit
                ? "Edit Penerimaan Barang Langsung"
                : "Add Penerimaan Barang Langsung"}
            </h4>
          </Form.Label>

          {!isEdit? 
          <>
          <div className="btn-footer-group float-right">
            <Link to={{ pathname: `/penerimaan-inventory/index` }}>
              <Button className="btn-cancel" variant="light">
                Cancel
              </Button>
              {"  "}
            </Link>
            <Button
              style={{ width: 115, height: 46 }}
              form="inventoryMove"
              className="btn"
              variant="info"
              disabled={isLoading}
              type="submit">
              {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Save"}
            </Button>
          </div>
          </> 
          :
          <>
          <div className="btn-footer-group float-right">
          <Link to={{ pathname: `/penerimaan-inventory/index` }}>
              <Button className="btn-cancel" variant="light">
                Cancel
              </Button>
              {"  "}
            </Link>
          </div>
          
          </>}
          
        </Form.Group>

        <Card>
          <Card.Body>
            <div className="profile-setting-subtitle">Penerimaan Inventory</div>
            <Form
              id="inventoryMove"
              noValidate
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    receiveDate,
                    userId,
                    warehouseId,
                    receiveNumber,
                    note,
                    from
                  );
                }
                setValidated(true);
              }}>
              <hr />

              {isEdit ? (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Nomor Penerimaan Barang
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setReceiveNumber(e.target.value)}
                        value={receiveNumber}
                        required
                        disabled></Form.Control>
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Date
                </Form.Label>
                <Col sm={4}>
                  <DatePicker
                    defaultValue={moment(receiveDate, "YYYY-MM-DD HH:mm")}
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onReceiveDateChange}
                    disabled
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Receive By
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" disabled value={from} />
                </Col>
              </Form.Group>
              <hr />

              {
                isEdit ? 
                <>
                 <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={(e) => setWarehouseId(e.target.value)}
                    value={warehouseId}
                    disabled>
                    <option>select</option>
                    {WarehouseListState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    disabled
                  />
                </Col>
              </Form.Group>
                
                </> :
                <>
                 <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Warehouse
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={(e) => setWarehouseId(e.target.value)}
                    value={warehouseId}>
                    <option>select</option>
                    {WarehouseListState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Note
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Note"
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                  />
                </Col>
              </Form.Group>
                
                </>
              }

             
            </Form>
          </Card.Body>
        </Card>
        <br />

        <AlertMessage
          {...alertProps}
          show={showAlert}
          onAlertCloseAction={onAlertClose}
        />
      </Grid>
    </div>
  );
};

export interface PenerimaanInventoryProps {
  onSubmit: (
    receiveDate: Date,
    userId: string,
    warehouseId: string,
    receiveNumber: string,
    note: string,
    from: string
  ) => void;
  data: ItemReceiveModel;
  isEdit: boolean;
}

export default PenerimaanInventoryForm;
