import axios from "axios";
import { endpoint } from "../../constants";
import { FilterModel } from "./printList.model";

export default class PrintListAPI {
  static getListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/PaymentConfirmation/GetListPrintListWithPaging", model)
      .then((res) => res.data.data);
  }
}
