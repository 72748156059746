import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import { endpoint } from "../../../constants";
import Dropzone from "react-dropzone";
import { Link, useHistory, useParams } from "react-router-dom";
import upload from "./../../../assets/vector/upload.png";
import { DragSwitch } from "react-dragswitch";
import "../SettingMembership.css";
import {
  resetSingle,
  resetStatus,
  uploadPhoto,
} from "../settingMembership.reducer";
import { useDispatch, useSelector } from "react-redux";
import { convertBase64 } from "../../../components/Library/Base64";
import { MembershipModel } from "../settingMembership.model";
import { RootState } from "../../../app/store";
import AlertMessage from "../../../components/alertMessage";

const SettingMembershipCreate = ({
  data = {} as MembershipModel,
  isEdit = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [status, setStatus] = useState(false);

  const [membershipId, setMembershipId] = useState("");
  const [title, setTitle] = useState("");
  const [feeIDR, setFeeIDR] = useState(0);
  const [feePercentage, setFeePercentage] = useState(0);
  const [feeUpTo, setFeeUpTo] = useState(0);
  const [minimumPurchase, setMinimumPurchase] = useState(0);
  const [periodType, setPeriodType] = useState(0);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [isStatus, setIsStatus] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [code, setCode] = useState("");
  const [price, setPrice] = useState(0);

  const [fileFormat, setFileFormat] = useState("");
  const [isFeeIDR, setIsFeeIDR] = useState(true);
  const [isFeePercentage, setIsFeePercentage] = useState(false);
  const [isUpTo, setIsUpTo] = useState(false);
  const [membershipFeeType, setMembershipFeeType] = useState(0);
  const [validated, setValidated] = useState(false);

  const [isImageFromAPI, setIsImageFromAPI] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const imagePathState = useSelector(
    (state: RootState) => state.settingMembership.imagePath ?? ""
  );

  const settingMembershipStatus = useSelector(
    (state: RootState) => state.settingMembership.status ?? ""
  );

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const onPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    // if (e.target.value === "" || e.target.value === undefined) {
    //   setPrice(0);
    // } else {
    //   setPrice(parseInt(e.target.value));
    // }
    setPrice(parseInt(e.target.value))
  };

  const onMinimumPurchaseChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.value === undefined) {
      setMinimumPurchase(0);
    } else {
      setMinimumPurchase(parseInt(e.target.value));
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (
      settingMembershipStatus === "success" ||
      settingMembershipStatus === "success-update"
    ) {
      dispatch(resetStatus());
      
      resetForm();
      history.goBack();
    } else {
      dispatch(resetStatus());
      // dispatch(resetSingle());
    }
  };

  const onCancelClick = () => {
    dispatch(resetStatus());
    dispatch(resetSingle());
    resetForm();
    history.replace("/setting-membership/index");
    // history.goBack();
  };

  const resetForm = () => {
    setTitle("");
    setMembershipFeeType(0);
    setFeeIDR(0);
    setFeePercentage(0);
    setFeeUpTo(0);
    setMinimumPurchase(0);
    setPeriodType(0);
    setDescription("");
    setImage("");
    setFileFormat("");
    setCode("");
    setPrice(0);
    setIsImageFromAPI(false);
    dispatch(resetSingle());
  };

  const handleImage = (files: any) => {
    setFileFormat("");
    if (files[0] !== undefined) {
      setIsImageFromAPI(false)
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImage(result);
      });
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    if (image != null && image != "" && fileFormat!= "" && fileFormat != null) {
      dispatch(
        uploadPhoto({
          file_name: title + "." + fileFormat,
          base64url: image.split(",")[1],
          imagepath: "membership",
          location: "membership",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, fileFormat]);

  useEffect(() => {
    if (settingMembershipStatus !== "") {
      if (settingMembershipStatus === "success") {
        setAlertProps({
          variant: "success",
          title: "Data Setting Membership sudah tersimpan.",
          content: " ",
        });
        setShowAlert(true);
      } else if (settingMembershipStatus === "failed") {
        setAlertProps({
          variant: "failed",
          title: "Data Setting Membership gagal tersimpan.",
          content: " ",
        });
        setShowAlert(true);
      } else if (settingMembershipStatus === "success-update") {
        setAlertProps({
          variant: "success",
          title: "Data Setting Membership sudah terupdate.",
          content: " ",
        });
        setShowAlert(true);
      } else if (settingMembershipStatus === "failed-update") {
        setAlertProps({
          variant: "failed",
          title: "Data Setting Membership gagal diupdate.",
          content: " ",
        });
        setShowAlert(true);
      }
    }
  }, [settingMembershipStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setMembershipId(data.id);
      setTitle(data.title);
      setMembershipFeeType(data.fee);
      setPeriodType(data.periodType);
      setFeeIDR(data.feeIDR);
      setMinimumPurchase(data.minimumPurchase);
      setFeePercentage(data.feePercentage);
      setFeeUpTo(data.feeUpTo);
      setDescription(data.description);
      setIsStatus(data.isStatus);
      setIsActive(data.isActive);
      setCode(data.code);
      setPrice(data.price);
      setImage(data.image);
      setIsImageFromAPI(true);
    } else {
    }
  }, [data]);

  useEffect(() => {
    dispatch(resetStatus());
    dispatch(resetSingle());
    resetForm();
  }, []);

  

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">Membership</Form.Label>
      </Form.Group>

      <Grid textAlign="center">
        <Card>
          <Card.Body>
            {isEdit ? <b>Edit Membership Data</b> : <b>Add New Membership</b>}

            <hr />

            <Form
              id="settingMembershipForm"
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    title,
                    membershipFeeType,
                    periodType,
                    feeIDR,
                    minimumPurchase,
                    feePercentage,
                    feeUpTo,
                    description,
                    imagePathState,
                    isStatus,
                    isActive,
                    code,
                    price
                  );
                }
                setValidated(true);
              }}>
              <Row>
                <Col sm={7}>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Membership Title
                    </Form.Label>
                    <Col>
                      <Form.Control
                        placeholder="title"
                        type="text"
                        value={title}
                        onChange={onTitleChange}
                      />
                    </Col>
                  </Form.Group>

                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Code
                    </Form.Label>
                    <Col>
                      <Form.Control
                        placeholder="Code"
                        type="text"
                        value={code}
                        // onChange={onCodeChange}
                        disabled
                      />
                    </Col>
                  </Form.Group>

                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Membership Fee
                    </Form.Label>
                    <Col>
                      <Form.Control
                        
                        type="number"
                        value={price}
                        onChange={onPriceChange}
                      />
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Benefit
                    </Form.Label>
                    <Col>
                      <Form>
                        {/* <Form.Row> */}

                        <Row className="fee-input">
                          <Col sm={5}>
                            <Form.Check
                              label="IDR"
                              name="membershipFee"
                              type="radio"
                              value={0}
                              checked={membershipFeeType === 0}
                              onChange={() => setMembershipFeeType(0)}
                            />
                          </Col>
                          <Col sm={7}>
                            <Form.Control
                              style={{ marginTop: 5 }}
                              disabled={membershipFeeType === 0 ? false : true}
                              type="number"
                              onChange={(e) =>
                                setFeeIDR(parseInt(e.target.value))
                              }
                              value={feeIDR}
                            />{" "}
                          </Col>
                        </Row>

                        <Row className="fee-input">
                          <Col sm={5}>
                            <Form.Check
                              label="Percentage"
                              name="membershipFee"
                              type="radio"
                              value={1}
                              checked={membershipFeeType === 1}
                              onChange={() => setMembershipFeeType(1)}
                            />
                          </Col>
                          <Col sm={7}>
                            <Form.Control
                              style={{ marginTop: 5 }}
                              disabled={membershipFeeType === 1 ? false : true}
                              type="number"
                              onChange={(e) =>
                                setFeePercentage(parseInt(e.target.value))
                              }
                              value={feePercentage}
                            />
                          </Col>
                        </Row>

                        <Row className="fee-input">
                          <Col sm={5}>
                            <Form.Label>Up To (Rp)</Form.Label>
                          </Col>
                          <Col sm={7}>
                            <Form.Control
                              style={{ marginTop: 5 }}
                              disabled={membershipFeeType === 1 ? false : true}
                              type="number"
                              onChange={(e) =>
                                setFeeUpTo(parseInt(e.target.value))
                              }
                              value={feeUpTo}
                            />{" "}
                          </Col>
                        </Row>

                        {/* </Form.Row> */}
                      </Form>
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Minimum Purchase
                    </Form.Label>
                    <Col>
                      <Form.Control
                        placeholder="Rp"
                        type="text"
                        value={minimumPurchase}
                        onChange={onMinimumPurchaseChange}
                      />
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Period Type
                    </Form.Label>
                    <Col>
                      <Form>
                        <Form.Row>
                          <Col>
                          
                              <Form.Check
                                label="Forever"
                                name="periodType"
                                type="radio"
                                value={0}
                                checked={periodType === 0}
                                onChange={() => setPeriodType(0)}
                              />
                          

                            <Form.Check
                              label="3 Month"
                              name="periodType"
                              type="radio"
                              value={1}
                              checked={periodType === 1}
                              onChange={() => setPeriodType(1)}
                            />

                            <Form.Check
                              label="6 Month"
                              name="periodType"
                              type="radio"
                              value={2}
                              checked={periodType === 2}
                              onChange={() => setPeriodType(2)}
                            />

                            <Form.Check
                              label="10 Month"
                              name="periodType"
                              type="radio"
                              value={3}
                              checked={periodType === 3}
                              onChange={() => setPeriodType(3)}
                            />

                            <Form.Check
                              label="12 Month"
                              name="periodType"
                              type="radio"
                              value={4}
                              checked={periodType === 4}
                              onChange={() => setPeriodType(4)}
                            />
                          </Col>
                        </Form.Row>
                      </Form>
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Description
                    </Form.Label>
                    <Col>
                      <Form.Control
                        placeholder="Description"
                        as="textarea"
                        value={description}
                        onChange={onDescriptionChange}
                        rows={3}
                      />
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Image
                    </Form.Label>
                    <Col>
                      <Dropzone
                        onDrop={(acceptedFiles) => handleImage(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone file-upload-frame w-100">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="text-center file-upload-label">
                                <div>
                                  {(image !== "" ||
                                    Object.keys(data).length !== 0) && (

                                    isImageFromAPI ? 
                                      <img
                                        src={ image !== "" ? 
                                          endpoint + image : endpoint + image }
                                          className="file-upload-image mb3"
                                      /> :

                                    <img
                                      src={
                                        image !== "" ?
                                         image
                                          : (endpoint + image)
                                      }
                                      className="file-upload-image mb3"
                                    />

                                  )}

                                  {image === "" &&
                                    Object.keys(data).length === 0 && (
                                      <>
                                        <div
                                          className="file-upload-image mb3"
                                          style={{ paddingTop: 50 }}>
                                          <img src={upload} alt="upload" />
                                          <br />
                                          <span style={{ color: "black" }}>
                                            Select File
                                          </span>
                                        </div>
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                  </Form.Group>

                  <hr />

                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={4}>
                      Status
                    </Form.Label>
                    <Col>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>

                  <Row>
                    <Col sm={6}>
                      <Button
                        className="btn-cancel"
                        variant="light"
                        onClick={onCancelClick}>
                        Cancel
                      </Button>
                      {"  "}
                      <Button
                        variant="info"
                        style={{ width: 130, height: 45 }}
                        type="submit">
                        Save
                      </Button>
                    </Col>
                    <Col sm={2}></Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Grid>
      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    </div>
  );
};

export interface SettingMembershipProps {
  onSubmit: (
    title: string,
    fee: number,
    periodType: number,
    periodStart: string,
    periodEnd: string,
    feeIDR: number,
    minimumPurchase: number,
    feePercentage: number,
    feeUpTo: number,
    description: string,
    image: string,
    isStatus: boolean,
    isActive: boolean,
    code: string,
    price: number
  ) => void;
  data: MembershipModel;
  isEdit: boolean;
}

export default SettingMembershipCreate;
