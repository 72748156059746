import { createSlice } from '@reduxjs/toolkit'
import { getAllCustomerFilterAutoCancelMaster, getAllProductFilterAutoCancelMaster, postAutoCancelMasterReport, postExportAutoCancelMaster } from './AutoCancelMasterReport.api'

export interface AutoCancelMasterReportState {
  loading : boolean,
  listCustomerFilterAutoCancelMaster : any,
  listProductFilterAutoCancelMaster : any
}

const initialState: AutoCancelMasterReportState = {
  loading : false,
  listCustomerFilterAutoCancelMaster : [],
  listProductFilterAutoCancelMaster : []
}

export const AutoCancelMasterReportSlice = createSlice({
    name: 'laporan autocancel master',
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {

        builder.addCase(getAllCustomerFilterAutoCancelMaster.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getAllCustomerFilterAutoCancelMaster.fulfilled, (state, action) => {
            state.loading = false
            state.listCustomerFilterAutoCancelMaster = action.payload.data
        })
        builder.addCase(getAllCustomerFilterAutoCancelMaster.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getAllProductFilterAutoCancelMaster.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getAllProductFilterAutoCancelMaster.fulfilled, (state, action) => {
            state.loading = false
            state.listProductFilterAutoCancelMaster = action.payload.data
        })
        builder.addCase(getAllProductFilterAutoCancelMaster.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(postExportAutoCancelMaster.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postExportAutoCancelMaster.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postExportAutoCancelMaster.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(postAutoCancelMasterReport.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postAutoCancelMasterReport.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postAutoCancelMasterReport.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default AutoCancelMasterReportSlice.reducer