import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import AdminStockistCreate from "../components/AdminStockistCreate";
import { createAdminStockist, getAdminStockistSingleItem, updateAdminStockist } from "../adminStockist.reducer";
import { AdminStockistModel, CreateAdminStockistModel } from "../adminStockist.model";

const AdminStockistPage = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.adminStockist.single ?? ({} as AdminStockistModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getAdminStockistSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    partnerId: string,
    adminName: string,
    isSerial: boolean,
    isUnit: boolean,
    phoneNumber: string,
    userId: string,
    email: string,
    password: string,
    pin: string,
    isActive : boolean
  ) => {
    if(!isEdit){
      dispatch(
        createAdminStockist({
          id : "",
          partnerId,
          adminName,
          isSerial,
          isUnit,
          phoneNumber,
          userId,
          email,
          password,
          pin,
          isActive 
        } as CreateAdminStockistModel)
      );
    } else {
      dispatch(
        updateAdminStockist({
          id,
          partnerId,
          adminName,
          isSerial,
          isUnit,
          phoneNumber,
          userId,
          isActive
        } as CreateAdminStockistModel)
      )
    }
    
  };

  return (
    <div>
      <AdminStockistCreate
        onSubmit={onSubmit}
        data={singleDataState}
        isEdit={isEdit}
      />
    </div>
  );
};

export default AdminStockistPage;
