import React from 'react'
import StockOpnameTable from '../components/StockOpnameTable'

const StockOpnameTablePage = () => {
    return (
        <div>
            <StockOpnameTable/>
        </div>
    )
}

export default StockOpnameTablePage
