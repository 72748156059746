import axios from "axios";
import {endpoint} from "../../../constants";
import {KeepBarangRequest} from "./keepBarang.model";

export default class KeepBarangApi {
    static getKeepBarangList(args: KeepBarangRequest) {
        return axios
            .post<any>(endpoint + "/api/Cart/GetListWithFilter", args)
            .then((res) => res.data.data);
    }

    static removeKeepBarang(cartDetailId: string) {
        return axios
            .post<any>(endpoint + `/api/Cart/RemoveItemById?cartDetailId=${cartDetailId}`)
            .then((res) => res.data.data);
    }
}