import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel } from "./pesananPiutang.model";

export default class Api {
  static getList(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/GetListSalesOrderStockistWithPaging", model)
      .then((res) => res.data.data);
  }
}
