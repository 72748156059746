import React, { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { DragSwitch } from "react-dragswitch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { WarehouseSettingModel } from "../settingWarehouse.model";
import { getEndCustomerWarehouseSetting, updatePartnerWarehouseSetting } from "../settingWarehouse.reducer";

const SettingWarehouseEndCustomer = () => {
 const dispatch = useDispatch();

  const EndCustomerWarehouseSettingState = useSelector(
    (state: RootState) =>
      state.settingWarehouse.customerSettingList ??
      ([] as WarehouseSettingModel[])
  );

  const WarehouseSettingStatusState = useSelector(
    (state: RootState) =>
      state.settingWarehouse.status ?? ""
  );

  useEffect(() => {
   dispatch(getEndCustomerWarehouseSetting())
  }, [])

  useEffect(() => {
    if(WarehouseSettingStatusState==="success-update"){
      dispatch(getEndCustomerWarehouseSetting())
      console.log("success")
    } else if (WarehouseSettingStatusState==="failed-update"){
      dispatch(getEndCustomerWarehouseSetting())
      console.log("success")
    }
  }, [WarehouseSettingStatusState])
  

  const handleToggleChange = (id:string , isActive : boolean) => {
    dispatch(
      updatePartnerWarehouseSetting({
        warehouseSettingId: id,
        isActive: isActive
      })
    )
  }
  

  return (
    <div>
      <Grid>
        <hr />
        <br />

        {EndCustomerWarehouseSettingState.map((item) => (
          <>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={2}>
                {item.warehouseName}
              </Form.Label>
              <Col sm={10}> 
                <DragSwitch
                  checked={item.isActive}
                  onChange={(e) => {
                    handleToggleChange(item.id, !item.isActive);
                  }}
                />
              </Col>
            </Form.Group>
            <hr />
          </>
        ))}

       
      </Grid>
    </div>
  );
};

export default SettingWarehouseEndCustomer