import { AttributeModel, ConfigureTermsModel } from "./attribute.model";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../../";
import AttributeApi from "./attribute.api";

export interface AttributeSlice {
  name?: string;
  list?: AttributeModel[];
  single?: AttributeModel;
  configureTermsList?: ConfigureTermsModel[];
  configureTermSingle?: ConfigureTermsModel;
  parentList?: ConfigureTermsModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const CreateAttribute = createAsyncThunk(
  "createAttributeState/createAttribute",
  async (AttributeModel: AttributeModel, { rejectWithValue }) => {
    try {
      return await AttributeApi.createAttribute(AttributeModel);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAttributeList = createAsyncThunk(
  "getAttributeListState/getAttributeList",
  async (isActive: boolean, { rejectWithValue }) => {
    try {
      return await AttributeApi.getAttributeList(isActive);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAttributeSingleItem = createAsyncThunk(
  "AttributeSingleItemState/AttributeSingleItem",
  async (attributeId: string, { rejectWithValue }) => {
    try {
      return await AttributeApi.getSingleItem(attributeId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateAttribute = createAsyncThunk(
  "AttributeState/updateAttribute",
  async (AttributeModel: AttributeModel, { rejectWithValue }) => {
    try {
      return await AttributeApi.updateAttribute(AttributeModel);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteAttribute = createAsyncThunk(
  "AttributeState/deleteAttribute",
  async (AttributeId: string, { rejectWithValue }) => {
    try {
      return await AttributeApi.deleteAttribute(AttributeId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getConfiguretermsList = createAsyncThunk(
  "getConfiguretermsListState/getConfiguretermsList",
  async (ParentId: string, { rejectWithValue }) => {
    try {
      return await AttributeApi.getConfiguretermList(ParentId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getConfigureTermSingleItem = createAsyncThunk(
  "ConfigureTermSingleItemState/ConfigureTermSingleItem",
  async (ConfigureTermId: string, { rejectWithValue }) => {
    try {
      return await AttributeApi.getConfiguretermSingleItem(ConfigureTermId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createConfigureTerm = createAsyncThunk(
  "createConfigureTermState/createConfigureTerm",
  async (ConfigureTermsModel: ConfigureTermsModel, { rejectWithValue }) => {
    try {
      return await AttributeApi.createConfigureTerm(ConfigureTermsModel);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateConfigureTerms = createAsyncThunk(
  "ConfigureTermsState/updateConfigureTerms",
  async (ConfigureTerms: ConfigureTermsModel, { rejectWithValue }) => {
    try {
      return await AttributeApi.updateConfigureTerms(ConfigureTerms);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteConfigureTerms = createAsyncThunk(
  "ConfigureTermsState/deleteConfigureTerms",
  async (attributeId: string, { rejectWithValue }) => {
    try {
      return await AttributeApi.deleteConfigureTerms(attributeId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getParentList = createAsyncThunk(
  "getParentListState/getParentList",
  async (id: string, { rejectWithValue }) => {
    try {
      return await AttributeApi.getConfigureTermsParent(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const AttributeSlice = createSlice({
  name: "AttributeState",
  initialState: {} as AttributeSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as AttributeModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CreateAttribute.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(CreateAttribute.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(CreateAttribute.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getAttributeSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getAttributeSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getAttributeSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getAttributeList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAttributeList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getAttributeList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateAttribute.pending, (state) => {
      state.isLoading = true;
      state.status = "success";
    });
    builder.addCase(updateAttribute.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.name = new Date().toISOString();
    });
    builder.addCase(updateAttribute.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteAttribute.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAttribute.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = "";
    });
    builder.addCase(deleteAttribute.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getConfiguretermsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConfiguretermsList.fulfilled, (state, { payload }) => {
      state.configureTermsList = payload;
      state.isLoading = false;
    });
    builder.addCase(getConfiguretermsList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(createConfigureTerm.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createConfigureTerm.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success-create-configure-term";
    });
    builder.addCase(createConfigureTerm.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "";
    });

    builder.addCase(updateConfigureTerms.pending, (state) => {
      state.isLoading = true;
      state.status = "update";
    });
    builder.addCase(updateConfigureTerms.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success-update-configure-term";
    });
    builder.addCase(updateConfigureTerms.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "";
    });

    builder.addCase(getConfigureTermSingleItem.pending, (state) => {
      state.configureTermSingle = undefined;
      state.isLoading = true;
    });
    builder.addCase(getConfigureTermSingleItem.fulfilled, (state, { payload }) => {
      state.configureTermSingle = payload;
      state.isLoading = false;
    });
    builder.addCase(getConfigureTermSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(deleteConfigureTerms.pending, (state) => {
      state.isLoading = true;
      state.name = "";
    });
    builder.addCase(deleteConfigureTerms.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status= "success-delete-configure-term"
    });
    builder.addCase(deleteConfigureTerms.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.name = new Date().toISOString();
    });

    builder.addCase(getParentList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getParentList.fulfilled, (state, { payload }) => {
      state.parentList = payload;
      state.isLoading = false;
    });
    builder.addCase(getParentList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = AttributeSlice.actions;
export default AttributeSlice.reducer;
