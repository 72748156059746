import { ApiErrorResponse } from "../../..";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { StockSettingModel } from "./SettingStock.model";
import SettingStockApi from "./SettingStock.api";

export interface SettingStockSlice{
    singleStockSetting : StockSettingModel;
    StockSettingList : StockSettingModel[];
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getStockSettingList = createAsyncThunk(
    'StockSettingListState/StockSetting',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await SettingStockApi.getStockSettingList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateStockSetting = createAsyncThunk(
    'UpdateStockSettingState/UpdateStockSetting',
    async (model: StockSettingModel, { rejectWithValue }) => {
        try {
            return await SettingStockApi.updateStockSetting(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const StockSettingSlice = createSlice({
    name: 'StockSetting',
    initialState: {} as SettingStockSlice,
    reducers : {
        resetSingle: (state) => {
            state.singleStockSetting = {} as StockSettingModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getStockSettingList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStockSettingList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.StockSettingList = payload.data;
        });
        builder.addCase(getStockSettingList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateStockSetting.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(updateStockSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(updateStockSetting.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
    }
})

export const { resetSingle, resetStatus } = StockSettingSlice.actions;
export default StockSettingSlice.reducer;