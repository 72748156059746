import {ApiErrorResponse} from "../../../index";
import {KeepBarangModel, KeepBarangRequest} from "./keepBarang.model";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import KeepBarangApi from "./keepBarang.api";

export interface KeepBarangSlice {
    list?: KeepBarangModel[];
    totalRow?: number;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getKeepBarangList = createAsyncThunk(
    "KeepBarangState/getList",
    async (args: KeepBarangRequest, { rejectWithValue }) => {
        try {
            return await KeepBarangApi.getKeepBarangList(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const removeKeepBarang = createAsyncThunk(
    "KeepBarangState/removeKeepBarang",
    async (cartDetailId: string, { rejectWithValue }) => {
        try {
            return await KeepBarangApi.removeKeepBarang(cartDetailId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const keepBarangSlice = createSlice({
    name: "KeepBarangState",
    initialState: {} as KeepBarangSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(removeKeepBarang.pending, (state) => {
            state.status = "";
            state.isLoading = true;
        });
        builder.addCase(removeKeepBarang.fulfilled, (state) => {
            state.status = "success";
            state.isLoading = false;
        });
        builder.addCase(removeKeepBarang.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });

        builder.addCase(getKeepBarangList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getKeepBarangList.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.totalRow = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getKeepBarangList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    }
});

export const { resetStatus} = keepBarangSlice.actions;
export default keepBarangSlice.reducer;
