import { deleteData, getList, getSingleItem, resetStatus, updateData } from "../province.reducer";
import { Card, Form, Row, Col, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import AlertMessage from "../../../../components/alertMessage";
import hapus from "./../../../../assets/vector/delete.png";
import { ChangeEvent, useEffect, useState } from "react";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { ProvinceModel } from "../province.model";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const ProvinceTable = ({
  data = {} as ProvinceModel,
  isEdit = false,
  ...props
}: ProvinceFormProps) => {
  const dispatch = useDispatch();

  const ProvinceState = useSelector((state: RootState) => state.province.list ?? []);

  const ProvinceSingleData = useSelector(
    (state: RootState) => state.province.single ?? ({} as ProvinceModel)
  );

  const statusState = useSelector((state: RootState) => state.province.status ?? "");

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [countryId, setCountryId] = useState("76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7");
  const [no, setNo] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  // const onCloseAlert = () => setShowAlert(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setName("");
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const filteredItems = ProvinceState.filter(
    (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor === Object) {
      setName(data.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = [
    {
      name: "PROVINCE",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "ACTION",
    //   selector: (d: { id: any }) => (
    //     <>
    //       <span
    //         onClick={() => {
    //           onClickEditbutton(d.id);
    //         }}
    //       >
    //         <img src={edit} alt="no image"></img>
    //       </span>
    //       {"    "}
    //       <span
    //         onClick={() => {
    //           if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
    //         }}
    //       >
    //         <img src={hapus} alt="no image"></img>
    //       </span>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusState === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "province digunakan di menu lain",
      });
      setShowAlert(true);
    }
    return () => {
      dispatch(resetStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onEdit = () => {
    dispatch(
      updateData({
        id: ProvinceSingleData.id,
        name: name,
        no: no,
        countryId: countryId,
        isActive,
      } as ProvinceModel)
    );
  };

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  const onClickEditbutton = (id: string) => {
    setName("");
    dispatch(getSingleItem(id));
    setName(ProvinceSingleData.name);
    setShow(true);
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Province</Form.Label>
          </Form.Group>
        </Col>
        {/* <Col>
          <Button
            className="float-right"
            variant="info"
            onClick={handleShow}
            style={{ width: 150 }}
          >
            + Add Province
          </Button>
        </Col> */}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Province</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                id="provinceForm"
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    e.preventDefault();
                    if (ProvinceSingleData.id === undefined) {
                      props.onSubmit(name, no, countryId, isActive);
                      setName("");
                      handleClose();
                    } else {
                      onEdit();
                      setName("");
                      handleClose();
                    }
                  }
                  setValidated(true);
                }}
              >
                <Form.Group className="input-padding">
                  <Form.Label>Province</Form.Label>

                  <Form.Control type="text" onChange={onNameChange} value={name} required />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-info" style={{ width: 100 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="info" form="provinceForm" type="submit" style={{ width: 100 }}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
        </Card>
      </Grid>
    </div>
  );
};

export interface ProvinceFormProps {
  onSubmit: (name: string, no: number, countryId: string, isActive: boolean) => void;
  data: ProvinceModel;
  isEdit: boolean;
}

export default ProvinceTable;
