import React from 'react'
import { Card, Col, Container, Form, Row, Tab, TabContent, Tabs } from 'react-bootstrap'
import ChatCustomer from '../components/ChatCustomer'
import ChatReseller from '../components/ChatReseller'
import ChatStockist from '../components/ChatStockist'
import ChatPage from './ChatPage'

const ChatContainerPage = () => {
  return (
    <Container className="content-body" fluid>
     <Row>
      <Col md={12}>
       <Form.Group>
        <Form.Label className="title">Chat</Form.Label>
       </Form.Group>

   
        <Tabs>
                <Tab eventKey="reseller" title="Reseller">
                    <TabContent>
                     <ChatReseller/>
                    </TabContent>
                </Tab>
                <Tab eventKey="stockist" title="Stockist">
                    <TabContent>
                     <ChatStockist/>
                     {/* <ChatPage/> */}
                    </TabContent>
                </Tab>
                <Tab eventKey="customer" title="Customer">
                    <TabContent>
                     <ChatCustomer/>
                    </TabContent>
                </Tab>
            </Tabs>

      
      </Col>
     </Row>

    </Container>
  )
}

export default ChatContainerPage