import {Grid, Label} from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Image,
  Container,
  Table, Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import noImage from "../../../assets/no-image.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createComplain,
  getItemDetailBySONumber,
  getRequestedSONumber,
  getSalesOrderList,
  getWarehouseList,
} from "../complain.reducer";
import {AdminOptionModel, ItemDetailModal} from "../complain.model";
import { RootState } from "../../../app/store";
import { FaTrash } from "react-icons/fa";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import axios from "axios";
import { endpoint } from "../../../constants";
import { ComplainListModel } from "../complain.model";
import { getPendingNotification } from "../../../global/global.reducer";
import {
  getSingleSalesOrderWithDetail,
  resetSingle,
} from "../../customer/customer.reducer";
import { SalesOrderWithDetailModel } from "../../customer/customer.model";
import AsyncSelect from "react-select/async";
import {ProductOptionModel} from "../../report/laporanKartuStock/laporanKartuStock.model";
import useAsyncProductOption from "../../../hooks/product/useAsyncProductOption";
import useAsyncSalesOrderOption from "../../../hooks/salesOrder/useAsyncSalesOrderOption";
import {SOListDeliveredOptionModel} from "../../../global/global.model";
import {getAdminList} from "../../setting/warehouse/settingWarehouse.reducer";
import Select from "react-select";
import {AdminListModel} from "../../setting/warehouse/settingWarehouse.model";

const ComplainAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const SingleSalesOrderState = useSelector(
    (state: RootState) =>
      state.customer.singleSalesOrderWithDetail ??
      ({} as SalesOrderWithDetailModel)
  );

  const list = useSelector(
    (state: RootState) => state.complain.list ?? ({} as ComplainListModel)
  );

  const salesOrderList = useSelector(
    (state: RootState) => state.complain.salesOrderList ?? []
  );

  const warehouseList = useSelector(
    (state: RootState) => state.complain.warehouseList ?? []
  );

  const itemDetail = useSelector(
    (state: RootState) => state.complain.itemDetail ?? ({} as ItemDetailModal)
  );

  const isLoading = useSelector(
      (state: RootState) => state.complain.isLoading ?? false
  );

  const requestedSONumberList = useSelector(
    (state: RootState) => state.complain.requestedSONumberList ?? []
  );

  const adminList = useSelector(
      (state: RootState) => state.settingWarehouse.adminList ?? []
  );

  const {getListDeliveredOptions} = useAsyncSalesOrderOption();

  const [salesOrderSelected, setSalesOrderSelected] = useState<any>({});
  const [selectedSoId, setSelectedSoId] = useState("");
  const [warehouseSelected, setWarehouseSelected] = useState<any>({});
  const [warehouseListString, setWarehouseListString] = useState("");
  const [complaintProgressDescription, setComplaintProgressDescription] = useState('')
  const [nominalWallet, setNominalWallet] = useState(0)
  const [data, setData] = useState<any>({
    type: 0,
    name: "",
    email: "",
    telephone: "",
    salesOrderId: "",
    description: "",
    photoOfReceipt: "",
    photoOfItem: "",
    videoUnboxing: "",
    adminGudangNominal: 0,
    adminQCNominal: 0,
    adminEkspedisiNominal: 0,
    adminPackingNominal: 0,
    warehouseId: "",
    complaintDetail: [],
    status: 0,
  });
  const [listProduct, setListProduct] = useState<any>([]);
  const [localFiles, setLocalFiles] = useState<any>({
    photoOfReceipt: {
      progress: 100,
    },
    photoOfItem: {
      progress: 100,
    },
    videoUnboxing: {
      progress: 100,
    },
  });
  const [timer, setTimer] = useState(setTimeout(() => {
  }, 1000));

  const [selectedAdminCreated, setSelectedAdminCreated] = useState<AdminOptionModel>({} as AdminOptionModel);
  const [adminListOption, setAdminListOption] = useState<AdminOptionModel[]>([]);

  const resetData = () => {
    setData(
      {
        type: 0,
        name: "",
        email: "",
        telephone: "",
        salesOrderId: "",
        description: "",
        photoOfReceipt: "",
        photoOfItem: "",
        videoUnboxing: "",
        adminGudangNominal: 0,
        adminQCNominal: 0,
        adminEkspedisiNominal: 0,
        adminPackingNominal: 0,
        warehouseId: "",
        complaintDetail: [],
        status: 0,
      }
    )
  }

  useEffect(() => {
    dispatch(getSalesOrderList());
    dispatch(getWarehouseList());
    dispatch(getRequestedSONumber());
    dispatch(resetSingle());
    dispatch(getAdminList());
    resetData();
  }, []);

  useEffect(() => {
    if (
      SingleSalesOrderState &&
      Object.keys(SingleSalesOrderState).length !== 0 &&
      SingleSalesOrderState.constructor == Object
    ) {
      setData({
        ...data,
        ["name"]: SingleSalesOrderState.salesOrder.customerName,
        ["email"]: SingleSalesOrderState.salesOrder.customerEmail,
        ["telephone"]: SingleSalesOrderState.shipment.recipientPhone,
        ["warehouseId"]: SingleSalesOrderState.salesOrder.warehouseList,
      });
    } else {
      resetData();
    }
  }, [SingleSalesOrderState]);

  useEffect(() => {
    
    if (itemDetail?.complaintDetail?.length > 0) {
      setListProduct(itemDetail.complaintDetail);
    }
  }, [itemDetail]);

  useEffect(() => {
      if (adminList.length > 0) {
        const _converted = adminList.map((item: AdminListModel) => {
            return {
                value: item.id,
                label: item.name
            }
        });
        setAdminListOption(_converted);
      }
  }, [adminList]);

  const handleCancel = () => {
    resetData();
    history.goBack();
  };

  const handleSave = () => {
    console.log(requestedSONumberList);
    if (requestedSONumberList.includes(salesOrderSelected.soNumber)) {
      Swal.fire({
        title: "Nomor SO Sudah Dipilih",
        text: "Nomor SO yang sedang dalam proses komplain atau retur tidak dapat dikomplain kenbali",
        icon: "error",
      });
    } else {
      if (selectedAdminCreated.value === undefined) {
        return null;
      }

      let preparedData = {
        ...data,
        salesOrderId: salesOrderSelected.id,
        warehouseId: warehouseSelected.id,
        complaintProgressDescription: complaintProgressDescription,
        nominalWallet: nominalWallet,
        adminId: selectedAdminCreated.value,
        complaintDetail: listProduct.map((item: any) => {
          return {
            variationId: item.variationId,
            quantity: item.quantity,
          };
        }),
      };
      dispatch<any>(createComplain(preparedData))
        .then(unwrapResult)
        .then((res: any) => {
          Swal.fire({
            icon: "success",
            text: "Success",
            showConfirmButton: true,
            timer: 1000,
          });
          dispatch(getPendingNotification());
          history.goBack();
        })
        .catch((err: any) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            text: "Error",
          });
          dispatch(getPendingNotification());
        });
    }
  };

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  // const handleChangeSalesOrder = (e: any) => {
  //
  //   setData({
  //     ...data,
  //     ["name"]: "",
  //     ["email"]: "",
  //     ["telephone"]: "",
  //     ["warehouseId"]: "",
  //   });
  //
  //   let { value } = e.target;
  //   let selected = salesOrderList.filter(
  //     (item: any) => item.soNumber === value
  //   )[0];
  //   setSalesOrderSelected(selected || {});
  //   dispatch(getItemDetailBySONumber(selected?.soNumber));
  //   dispatch(getSingleSalesOrderWithDetail(selected?.id));
  //
  // };

  const handleChangeSalesOrder = (selected: any) => {

    let _selected: SOListDeliveredOptionModel = selected as SOListDeliveredOptionModel;
    setData({
      ...data,
      ["name"]: "",
      ["email"]: "",
      ["telephone"]: "",
      ["warehouseId"]: "",
    });
    //
    // let { value } = e.target;
    // let selected = salesOrderList.filter(
    //     (item: any) => item.soNumber === value
    // )[0];
    setSalesOrderSelected(_selected);
    dispatch(getItemDetailBySONumber(_selected?.soNumber));
    dispatch(getSingleSalesOrderWithDetail(selected?.id));
    
  };

  const handleChangeWarehouse = (e: any) => {
    let { value } = e.target;
    let selected = warehouseList.filter((item: any) => item.name === value)[0];
    setWarehouseSelected(selected);
  };

  const handleRemoveProduct = (variationId: string) => {
    setListProduct(
      listProduct.filter(
        (itemFilter: any) => itemFilter.variationId !== variationId
      )
    );
  };

  const moneyParser = (value: any) => {
    let valueString = value.toString()
    let valueList = valueString.split('')
    let resultList: any = []
    valueList.reverse().map((c: any, i: number) => {
      resultList.push(c)
      if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
        resultList.push('.')
      }
    })
    let result = resultList.reverse().join('')
    return "Rp" + result
  }

  const onChangeFile = async (e: any) => {
    let files = e.target.files;
    if (files) {
      setLocalFiles({
        ...localFiles,
        [e.target.name]: {
          progress: 0,
        },
      });
      let formData = new FormData();
      formData.append("", files[0]);
      try {
        const response = await axios.post(
          endpoint + `/web/api/Upload/UploadFile`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setLocalFiles({
                ...localFiles,
                [e.target.name]: {
                  ...localFiles[e.target.name],
                  progress: percentCompleted,
                },
              });
            },
          }
        );
        console.log(response);
        setData({
          ...data,
          [e.target.name]: response.data.pathReturn,
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const promiseOptions = (inputValue: string) =>
      new Promise<SOListDeliveredOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getListDeliveredOptions(inputValue));
            }, 1000)
        );
      });

  const onAdminCreatedChange = (data: any) => {
    setSelectedAdminCreated(data);
  }

  return (
    <Container fluid>
      <Form.Group className="title">Tambah Pengaduan</Form.Group>
      <Grid>
        <Card>
          <Card.Body>
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Nomor SO
              </Form.Label>
              <Col sm={5}>
                <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                             onChange={handleChangeSalesOrder} />
                {/*<Form.Control*/}
                {/*  placeholder="-"*/}
                {/*  type="text"*/}
                {/*  name="salesOrderId"*/}
                {/*  onChange={handleChangeSalesOrder}*/}
                {/*  list="salesOrderId"*/}
                {/*/>*/}
                {/*<datalist id="salesOrderId">*/}
                {/*  {salesOrderList.map((res: any, i: number) => {*/}
                {/*    return <option key={i}>{res.soNumber}</option>;*/}
                {/*  })}*/}
                {/*</datalist>*/}
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Gudang
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  name="name"
                  value={data.warehouseId}
                  onChange={handleChange}
                  disabled
                />
                {/* <Form.Control
                  placeholder="-"
                  type="text"
                  name="warehouseId"
                  onChange={handleChangeWarehouse}
                  list="warehouseId"
                />
                <datalist id="warehouseId">
                  {warehouseList.map((res: any, i: number) => (
                    <option key={i}>{res.name}</option>
                  ))}
                </datalist> */}
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Nama
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Email
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Telepon
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  name="telephone"
                  value={data.telephone}
                  onChange={handleChange}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Keluhan
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  as="textarea"
                  rows={3}
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Bukti Pembelian
              </Form.Label>
              <Col sm={4}>
                <input
                  type="file"
                  accept="image/*"
                  name="photoOfReceipt"
                  onChange={onChangeFile}
                />
                <br />
                {localFiles.photoOfReceipt.progress < 100 && (
                  <span>Uploading {localFiles.photoOfReceipt.progress}%</span>
                )}
                <br />
                {data.photoOfReceipt && (
                  <Image fluid thumbnail src={endpoint + data.photoOfReceipt} />
                )}
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Foto Barang
              </Form.Label>
              <Col sm={4}>
                <input
                  type="file"
                  accept="image/*"
                  name="photoOfItem"
                  onChange={onChangeFile}
                />
                <br />
                {localFiles.photoOfItem.progress < 100 && (
                  <span>Uploading {localFiles.photoOfItem.progress}%</span>
                )}
                <br />
                {data.photoOfItem && (
                  <Image fluid thumbnail src={endpoint + data.photoOfItem} />
                )}
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Video Unboxing
              </Form.Label>
              <Col sm={4}>
                <input
                  type="file"
                  accept="video/*"
                  name="videoUnboxing"
                  onChange={onChangeFile}
                />
                <br />
                {localFiles.videoUnboxing.progress < 100 && (
                  <span>Uploading {localFiles.videoUnboxing.progress}%</span>
                )}
                <br />
                {data.videoUnboxing && (
                  <iframe
                    title="video unboxing"
                    allowFullScreen
                    style={{ width: "100%", height: "300px" }}
                    src={endpoint + data.videoUnboxing}
                  />
                )}
              </Col>
            </Form.Group>
            <hr />
            <b>Data Karyawan</b>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin
              </Form.Label>
              <Col sm={5}>
                <Select
                    onChange={onAdminCreatedChange}
                    value={selectedAdminCreated}
                    options={adminListOption}
                />
                {
                  selectedAdminCreated.value === undefined && (
                        <Label className="card-subtitle m-1" color="red">
                          wajib diisi.
                        </Label>
                      )
                }
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin Gudang
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  disabled
                  placeholder="-"
                  type="area"
                  value={
                    salesOrderSelected.id
                      ? itemDetail?.adminGudang
                      : ""
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin QC
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  disabled
                  placeholder="-"
                  type="area"
                  value={
                    salesOrderSelected.id ? itemDetail?.adminQC : ""
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin Packing
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  disabled
                  placeholder="-"
                  type="area"
                  value={
                    salesOrderSelected.id
                      ? itemDetail?.adminPacking
                      : ""
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin Ekspedisi
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  disabled
                  placeholder="-"
                  type="area"
                  value={
                    salesOrderSelected.id
                      ? itemDetail?.adminEkspedisi
                      : ""
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <br/>
            <b>Daftar Produk</b>
            <br/><br/>
            {/* <hr /> */}
            <Table>
              <thead>
                <tr>
                  <td>Kode Produk</td>
                  <td>Nama Prduk</td>
                  <td>Varian</td>
                  <td>Qty</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {salesOrderSelected.id &&
                  listProduct?.map((item: any, i: number) => (
                    <tr key={i}>
                      <td>{item?.productCode}</td>
                      <td>{item?.productName}</td>
                      <td>{item?.variantName}</td>
                      <td>{item?.quantity}</td>
                      <td>
                        <div
                          className="retur-table-button"
                          onClick={() => handleRemoveProduct(item.variationId)}
                        >
                          <FaTrash />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <hr />
            <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Keterangan
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      placeholder="-"
                      type="text"
                      value={complaintProgressDescription}
                      onChange={(e: any) => setComplaintProgressDescription(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <hr />
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={3}>
                    Nominal Wallet
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      placeholder="-"
                      type="text"
                      value={moneyParser(nominalWallet)}
                      onChange={(e: any) => setNominalWallet(e.target.value.replace(/[Rp. ]/g, '') || '0')}
                    />
                  </Col>
                </Form.Group>
                <hr/>
            <Row>
              <Form.Group as={Row} className="input-padding m-3">
                <Button
                  className="mr-2"
                  variant="outline-info"
                  style={{ width: 130, height: 45 }}
                  type="submit"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {"     "}
                <Button
                  variant="info"
                  style={{ width: 130, height: 45 }}
                  type="submit"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner animation="border" variant="light" size="sm"/> : "Save"}
                </Button>
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Grid>
    </Container>
  );
};

export default ComplainAdd;
