import axios from "axios";
import { endpoint } from "../../../constants";
import { PointRewardSettingModel } from "./SettingPointReward.model";

export default class SettingPointRewardApi {

    static getPointRewardSettingList() {
        return axios
            .get<any>(endpoint + "​/api/PointSetting/GetList")
            .then((res) => res.data);
    }

    static updatePointRewardSetting(model: PointRewardSettingModel) {
        return axios
            .post<any>(
                endpoint + "/api/PointSetting/Update",
                model
            )
            .then((res) => res.data);
    }

}