import React from 'react'
import ReceiptDeliveryTable from '../components/ReceiptDeliveryTable'

const ReceiptDeliveryTablePage = () => {
    return (
        <div>
            <ReceiptDeliveryTable/>
        </div>
    )
}

export default ReceiptDeliveryTablePage
