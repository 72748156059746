import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Table,
  Nav,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";

import user from "./../../../assets/vector/user.png";
import orders from "./../../../assets/vector/orders.png";
import keep from "./../../../assets/vector/keep.png";
import shipping from "./../../../assets/vector/shipping.png";

import stockist from "./../../../assets/vector/stockist.png";
import reseller from "./../../../assets/vector/reseller.png";
import customer from "./../../../assets/vector/customer.png";

import "./styles.css";
import { getPendingNotification } from "../../../global/global.reducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { endpoint } from "../../../constants";
import { GraphDataRequestData, GraphResponseData } from "../Dashboard.model";

const DashboardPage = () => {
  const dispatch = useDispatch();

  const [totalUser, setTotalUser] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalOrderKeep, setTotalOrderKeep] = useState(0);
  const [totalOrderDropship, setTotalOrderDropship] = useState(0);
  const [totalOrderWalkIn, setTotalOrderWalkIn] = useState(0);
  const [totalShipment, setTotalShipment] = useState(0);
  const [totalComplaintStockist, setTotalComplaintStockist] = useState(0);
  const [totalComplaintReseller, setTotalComplaintReseller] = useState(0);
  const [totalComplaintCustomer, setTotalComplaintCustomer] = useState(0);
  const [totalCustomerRegister, setTotalCustomerRegister] = useState(0);
  const [totalStockistRegister, setTotalStockistRegister] = useState(0);
  const [totalResellerRegister, setTotalResellerRegister] = useState(0);
  const [totalAdminStockistRegister, setTotalAdminStockistRegister] =
    useState(0);

  const [resellerGraphLabels, setResellerGraphLabels] = useState<string[]>([]);
  const [stockistGraphLabels, setStockistGraphLabels] = useState<string[]>([]);
  const [customerGraphLabels, setCustomerGraphLabels] = useState<string[]>([]);

  const [resellerGraphData, setResellerGraphData] = useState<number[]>([]);
  const [stockistGraphData, setStockistGraphData] = useState<number[]>([]);
  const [customerGraphData, setCustomerGraphData] = useState<number[]>([]);

  const [dateType, setDateType] = useState(4); //All, Date, Today, Week, Month, Year
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());

  useEffect(() => {
    getDashboardData();
    getDashboardGraphData({
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
    });
  }, []);

  const getDashboardData = () => {
    axios.get(endpoint + "/api/Report/GetDashboardTotalData").then((res) => {
      setTotalUser(res.data.data.totalUser);
      setTotalOrder(res.data.data.totalOrder);
      setTotalOrderKeep(res.data.data.totalOrderKeep);
      setTotalOrderDropship(res.data.data.totalOrderDropship);
      setTotalOrderWalkIn(res.data.data.totalOrderLangsungBayar);
      setTotalShipment(res.data.data.totalShipment);
      setTotalComplaintStockist(res.data.data.totalComplaintStockist);
      setTotalComplaintReseller(res.data.data.totalComplaintReseller);
      setTotalComplaintCustomer(res.data.data.totalComplaintCustomer);
      setTotalCustomerRegister(res.data.data.totalCustomerRegister);
      setTotalStockistRegister(res.data.data.totalStockistRegister);
      setTotalResellerRegister(res.data.data.totalResellerRegister);
      setTotalAdminStockistRegister(res.data.data.totalAdminStockistRegister);
    });
  };

  const getDashboardGraphData = (requestData: GraphDataRequestData) => {
    setCustomerGraphData([]);
    setCustomerGraphLabels([]);
    setResellerGraphData([]);
    setResellerGraphLabels([]);
    setStockistGraphData([]);
    setStockistGraphLabels([]);

    axios
      .post(endpoint + "/api/Report/GetDashboardGraphData", requestData)
      .then((res) => {
        console.log(res);
        let response: GraphResponseData = res.data.data;

        response.customer.map((item) => {
          setCustomerGraphLabels((prevState) => [...prevState, item.date]);
        });

        response.customer.map((item) => {
          setCustomerGraphData((prevState) => [...prevState, item.grandTotal]);
        });

        response.reseller.map((item) => {
          setResellerGraphLabels((prevState) => [...prevState, item.date]);
        });

        response.reseller.map((item) => {
          setResellerGraphData((prevState) => [...prevState, item.grandTotal]);
        });

        response.stockist.map((item) => {
          setStockistGraphLabels((prevState) => [...prevState, item.date]);
        });

        response.stockist.map((item) => {
          setStockistGraphData((prevState) => [...prevState, item.grandTotal]);
        });
      });
  };

  const doughnut = {
    labels: ["KEEP", "WALK-IN", "DROPSHIP"],
    datasets: [
      {
        label: "# of Votes",
        data: [totalOrderKeep, totalOrderWalkIn, totalOrderDropship],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
      },
    ],
  };

  const data = {
    labels: customerGraphLabels,
    datasets: [
      {
        label: "CUSTOMER",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(235, 143, 52,0.4)",
        borderColor: "rgba(235, 143, 52,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(235, 143, 52,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(235, 143, 52,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: customerGraphData,
      },
      {
        label: "Reseller",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(137, 4, 214,0.5)",
        borderColor: "rgba(137, 4, 214,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(137, 4, 2142,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(137, 4, 214,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: resellerGraphData,
      },

      {
        label: "Stockist",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(38, 107, 255,0.5)",
        borderColor: "rgba(38, 107, 255,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(38, 107, 255,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(38, 107, 255,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: resellerGraphData,
      },
    ],
  };

  return (
    <div style={{ padding: 10 }}>
      <Form.Group>
        <Form.Label className="menu-title">Dashboard</Form.Label>
      </Form.Group>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="widget-number">{totalUser}</div>
                    <div className="widget-title">All User</div>
                  </Col>
                  <Col>
                    <div className="circle-user">
                      <img src={user} alt="Logo" className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="widget-number">{totalOrder}</div>
                    <div className="widget-title">Orders</div>
                  </Col>
                  <Col>
                    <div className="circle-orders">
                      <img src={orders} alt="Logo" className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="widget-number">{totalOrderKeep}</div>
                    <div className="widget-title">keep</div>
                  </Col>
                  <Col>
                    <div className="circle-keep">
                      <img src={keep} alt="Logo" className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="widget-number">{totalShipment}</div>
                    <div className="widget-title">Shipping</div>
                  </Col>
                  <Col>
                    <div className="circle-shipping">
                      <img src={shipping} alt="Logo" className="icon" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col>
            <Card style={{ height: 350 }}>
              <Card.Body>
                <Tabs>
                  <Tab eventKey="register" title="Register">
                    <TabContent>
                      <hr />
                      <Row>
                        <Col sm={2}>
                          <div className="row-circle">
                            <img
                              src={reseller}
                              alt="Logo"
                              className="table-icon-1"
                            />
                          </div>
                        </Col>
                        <Col sm={8}>Reseller</Col>
                        <Col sm={2}>{totalResellerRegister}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col sm={2}>
                          <div className="row-circle">
                            <img
                              src={stockist}
                              alt="Logo"
                              className="table-icon-1"
                            />
                          </div>
                        </Col>
                        <Col sm={8}>Stockist</Col>
                        <Col sm={2}>{totalStockistRegister}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col sm={2}>
                          <div className="row-circle">
                            <img
                              src={customer}
                              alt="Logo"
                              className="table-icon-1"
                            />
                          </div>
                        </Col>
                        <Col sm={8}>Customer</Col>
                        <Col sm={2}>{totalCustomerRegister}</Col>
                      </Row>
                      <hr />
                    </TabContent>
                  </Tab>

                  <Tab eventKey="complaint" title="Data Pengaduan">
                    <TabContent>
                      <hr />
                      <Row>
                        <Col sm={2}>
                          <div className="row-circle">
                            <img
                              src={reseller}
                              alt="Logo"
                              className="table-icon-1"
                            />
                          </div>
                        </Col>
                        <Col sm={8}>Reseller</Col>
                        <Col sm={2}>{totalComplaintReseller}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col sm={2}>
                          <div className="row-circle">
                            <img
                              src={stockist}
                              alt="Logo"
                              className="table-icon-1"
                            />
                          </div>
                        </Col>
                        <Col sm={8}>Stockist</Col>
                        <Col sm={2}>{totalComplaintStockist}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col sm={2}>
                          <div className="row-circle">
                            <img
                              src={customer}
                              alt="Logo"
                              className="table-icon-1"
                            />
                          </div>
                        </Col>
                        <Col sm={8}>Customer</Col>
                        <Col sm={2}>{totalComplaintCustomer}</Col>
                      </Row>
                      <hr />
                    </TabContent>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ height: 350 }}>
              <Card.Body>
                <Card.Title>Sales Order</Card.Title>
                <Card.Text style={{ height: 200 }}>
                  <Doughnut
                    type="doughnut"
                    data={doughnut}
                    height={200}
                    width={50}
                    options={{ maintainAspectRatio: false }}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card style={{ marginTop: 30 }}>
          <Card.Body style={{ margin: 30 }}>
            <Row>
              <Col md={4}>
                <b>Statistik Penjualan</b>
              </Col>
              <Col md={6}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4} style={{ textAlign: "right" }}>
                    Tanggal
                  </Form.Label>

                  {dateType !== 1 ? (
                    <>
                      <Col sm={4}>
                        <Form.Control
                          type="date"
                          value={new Date(startDate)
                            .toISOString()
                            .substr(0, 10)}
                          disabled
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          type="date"
                          value={new Date(endDate).toISOString().substr(0, 10)}
                          disabled
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col sm={4}>
                        <Form.Control
                          type="date"
                          value={new Date(startDate)
                            .toISOString()
                            .substr(0, 10)}
                          onChange={(e) => {
                            setStartDate(
                              new Date(e.target.value).toISOString()
                            );
                            getDashboardGraphData({
                              dateType: dateType,
                              startDate: new Date(e.target.value).toISOString(),
                              endDate: endDate.toString(),
                            });
                          }}
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          type="date"
                          value={new Date(endDate)
                            .toISOString()
                            .substr(0, 10)}
                          onChange={(e) => {
                            setEndDate(new Date(e.target.value).toISOString());
                            getDashboardGraphData({
                              dateType: dateType,
                              startDate: startDate.toString(),
                              endDate: new Date(e.target.value).toISOString(),
                            });
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Form.Group>
              </Col>
              <Col md={2} className="float-right">
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setDateType(parseInt(e.target.value));
                    getDashboardGraphData({
                      dateType: parseInt(e.target.value),
                      startDate: startDate.toString(),
                      endDate: endDate.toString(),
                    });
                  }}
                  value={dateType}
                >
                  <option value={1}>Range Tanggal</option>
                  <option value={2}>Hari ini</option>
                  <option value={3}>Minggu ini</option>
                  <option value={4}>Bulan ini</option>
                  <option value={5}>Tahun ini</option>
                </Form.Control>
              </Col>
            </Row>
            <br />
            <Row>
              <Line type="line" data={data} />
            </Row>
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export default DashboardPage;
