import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Spinner,
  FormCheck,
} from "react-bootstrap";
import "../templateBroadcast.scss";
import { FaSearch, FaRegEdit, FaCircle, FaSmile } from "react-icons/fa";
import { FiMoreVertical, FiTrash, FiX } from "react-icons/fi";
import { MdAttachFile, MdSend } from "react-icons/md";
import { Checkbox, Dropdown, Radio } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBroadcastByPartner,
  getBroadcastList,
  getUserBroadcastAll,
  postBroadcast,
  postImageUpload,
} from "../templateBroadcast.api";
import { RootState } from "../../../../app/store";
import { endpoint } from "../../../../constants";
import Swal from "sweetalert2";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "antd";
import { convertBase64 } from "../../../../components/Library/Base64";

import noImageUser from "../../../../assets/noImageUser.jpeg";
import moment from "moment";

export default function TemplateBroadcast() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { listUser, listBroadcastByPartner, loading } = useSelector(
    (state: RootState) => state.templateBroadcast
  );
  const { Option } = Select;

  const [loadingImage, setloadingImage] = useState<any>(false);

  const [options, setOptions] = useState<any>([]);
  const [recipients, setRecipients] = useState<any>([]);
  const [sendToAllCustomer, setSendToAllCustomer] = useState<any>(false);
  const [sendToAllStockist, setSendToAllStockist] = useState<any>(false);
  const [sendToAllReseller, setSendToAllReseller] = useState<any>(false);
  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [multipleImage, setMultipleImage] = useState<any>([]);

  const [filteredContact, setFilteredContact] = useState<any>([]);

  useEffect(() => {
    //SET users
    dispatch(getUserBroadcastAll());
  }, [listBroadcastByPartner]);

  useEffect(() => {
    setFilteredContact(listUser);
  }, [listUser]);

  useEffect(() => {
    // set opt BROADCAST RECIPIENT
    let opt = listUser.map((res: any) => (
      <Option key={res.id} value={res.fullName} label={res.fullName}>
        {res.fullName}
      </Option>
    ));
    setOptions(opt);
    // console.log(opt)
  }, [listUser]);

  const handleChangeRecipient = (value: any, event: any) => {
    setRecipients(event.map((res: any) => res.key));
    console.log(event);
    // console.log( event.map((res:any)=>(res.key)))
  };

  const messageRef = useRef<any>(null);

  const scrollToBottom = () => {
    messageRef?.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const [broadCast, setBroadCast] = useState(false);
  const [activePerson, setActivePerson] = useState<any>(null);

  useEffect(() => {
    dispatch(getBroadcastByPartner(activePerson?.id));
  }, [activePerson]);

  useEffect(() => {
    scrollToBottom();
  }, [listBroadcastByPartner]);

  const handleChangeChatPerson = (person: any) => {
    setActivePerson(person);
    // setRecipients([person.id])
    setBroadCast(false);
    console.log(person);
  };

  const handleImage = async (e: any) => {
    const dataFile: any = await Array.from(e.target.files);

    let finalData: any = [];
    for (const res of dataFile) {
      setloadingImage(true);

      convertBase64(res, async (result: any) => {
        const imageData = await result.split(",");
        const base64Final = await imageData[1];
        const objBase = {
          file_name: res.name,
          base64url: base64Final,
          imagepath: "",
          location: "string",
        };
        // return finalData.push(objBase)
        dispatch<any>(postImageUpload(objBase))
          .then(unwrapResult)
          .then((res: any) => {
            setloadingImage(true);
            if (res.status === 200) {
              setloadingImage(true);
              finalData.push(res.data.imagepath);
            } else {
              Swal.fire({
                icon: "error",
                text: "Error uploading image!",
              });
            }
          })
          .then(async () => {
            setloadingImage(true);
            setMultipleImage(finalData);
          })
          .then(async () => {
            setloadingImage(false);
          });
      });
    }
  };

  const [rerender, setRerender] = useState<any>(new Date());
  useEffect(() => {
    setInterval(() => setRerender(new Date()), 60000);
  }, []);

  const handleSendMessage = (e: any) => {
    e.preventDefault();
    let dataMultiple = {
      partnerId: recipients,
      sendToAllCustomer: sendToAllCustomer,
      sendToAllStockist: sendToAllStockist,
      sendToAllReseller: sendToAllStockist,
      subject: subject,
      description: description,
      image: multipleImage,
    };
    let dataSingle = {
      partnerId: [activePerson?.id],
      // "sendToAll": false,\"sendToAllCustomer": sendToAllCustomer,
      sendToAllCustomer: false,
      sendToAllStockist: false,
      sendToAllReseller: false,
      subject: subject,
      description: description,
      image: multipleImage,
    };
    console.log(dataSingle);
    console.log(dataMultiple);

    dispatch<any>(postBroadcast(broadCast ? dataMultiple : dataSingle))
      .then(unwrapResult)
      .then((res: any) => {
        console.log(res);
        if (res.status) {
          if (broadCast) {
            setBroadCast(false);
            setRecipients([]);
            setDescription("");
            setMultipleImage([]);
            setSubject("");
            setSendToAllCustomer(false);
            setSendToAllStockist(false);
            setSendToAllReseller(false);
            Swal.fire({
              icon: "success",
              text: "Broadcast berhasil di kirim!",
            });
          } else {
            dispatch(getBroadcastByPartner(activePerson.id));
            setDescription("");
            setMultipleImage([]);
            setSubject("");
            setSendToAllCustomer(false);
            setSendToAllStockist(false);
            setSendToAllReseller(false);
          }
        } else {
          Swal.fire({
            icon: "error",
            text: "Error send a message!",
          });
        }
      })
      .catch((err: any) => {
        console.log(err.message);
        Swal.fire({
          icon: "error",
          text: "Error send a message!",
        });
      });
  };

  const handleChangeFilter = (e: any) => {
    const filtered = listUser.filter((res: any) =>
      res.fullName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredContact(filtered);
  };

  return (
    <Container className="content-body template_broadcast" fluid>
      <Form.Label
        className="page-title mb-3"
        onClick={() => alert(`${multipleImage.length}`)}
      >
        Template Broadcast{" "}
      </Form.Label>
      <div className="boxchat">
        <div className="list">
          <div className="head">
            Template Broadcast{" "}
            <FaRegEdit onClick={() => setBroadCast(!broadCast)} />
          </div>
          <div className="search">
            <FaSearch />
            <input
              type="text"
              placeholder="Search or start new chat"
              onChange={handleChangeFilter}
            />
          </div>
          <div className="box">
            <ul>
              {filteredContact.map((res: any, i: number) => (
                <li
                  key={i}
                  className={activePerson?.id === res.id ? "active-chat" : ""}
                  onClick={() => handleChangeChatPerson(res)}
                >
                  <img
                    src={endpoint + res.selfPhoto}
                    onError={({ currentTarget }: any) => {
                      currentTarget.src = noImageUser;
                    }}
                    style={{ objectFit: "cover" }}
                    alt="profile"
                  />
                  <div className="bio">
                    <p className="name">
                      {res.fullName}
                      <span>
                        {res.lastChatTime &&
                          moment(
                            new Date(res.lastChatTime),
                            "YYYYMMDD"
                          ).fromNow()}
                      </span>
                    </p>
                    {/* <p>{res.id}</p> */}
                    <p className="sub">
                      subject message
                      <span>
                        {res.type === 0
                          ? "Customer"
                          : res.type === 2
                          ? "Seller"
                          : "Stokist"}{" "}
                        <FaCircle />
                      </span>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="chat">
          <div className="head">
            {broadCast ? (
              "Create New Broadcast"
            ) : (
              <>
                {activePerson !== null ? (
                  <div className="prof">
                    <img
                      src={endpoint + activePerson?.selfPhoto}
                      onError={({ currentTarget }: any) => {
                        // currentTarget.onerror = null; // prevents looping
                        currentTarget.src = noImageUser;
                      }}
                      alt="profile"
                      style={{ objectFit: "cover" }}
                    />
                    <div>
                      <p className="name">
                        {activePerson?.fullName}
                        <span>
                          {activePerson?.type === 0
                            ? "Customer"
                            : activePerson?.type === 2
                            ? "Seller"
                            : "Stokist"}{" "}
                          <FaCircle />
                        </span>
                      </p>
                      <p className="sub">subject message </p>
                    </div>
                  </div>
                ) : (
                  "Click contact for chat or click new broadcast!"
                )}
              </>
            )}
            {broadCast ? (
              <FiX onClick={() => setBroadCast(false)} />
            ) : (
              <FiMoreVertical />
            )}
          </div>

          <div className="boxtype">
            <div className="chatcol" ref={messageRef}>
              {broadCast ? (
                <div className="broadform w-100 container">
                  <form>
                    <div className="wrapfield">
                      Recipient
                      <Select
                        mode="multiple"
                        disabled={
                          sendToAllCustomer ||
                          sendToAllReseller ||
                          sendToAllStockist
                        }
                        style={{ width: "100%" }}
                        placeholder="Recipient"
                        onChange={handleChangeRecipient}
                      >
                        {options}
                      </Select>
                    </div>
                    <FormCheck
                      className="mb-3 text-sm"
                      id="checkall"
                      checked={sendToAllCustomer}
                      onChange={(e: any) =>
                        setSendToAllCustomer(e.target.checked)
                      }
                      label="Send to all End Customer"
                    />
                    <FormCheck
                      className="mb-3 text-sm"
                      id="checkall"
                      checked={sendToAllStockist}
                      onChange={(e: any) =>
                        setSendToAllStockist(e.target.checked)
                      }
                      label="Send to all Stockist"
                    />
                    <FormCheck
                      className="mb-3 text-sm"
                      id="checkall"
                      checked={sendToAllReseller}
                      onChange={(e: any) =>
                        setSendToAllReseller(e.target.checked)
                      }
                      label="Send to all Reseller"
                    />
                  </form>
                </div>
              ) : (
                <div className="w-100" style={{ paddingBottom: 90 }}>
                  {listBroadcastByPartner?.map((res: any, i: number) => (
                    <div
                      className="col-md-12 d-flex justify-content-end"
                      key={i}
                    >
                      <div className="wraptext mb-3">
                        <div className="buble text-right">
                          {res.image.map((res: any, i: number) => (
                            <div>
                              <img
                                src={endpoint + res}
                                alt="message-image"
                                width="200"
                                className="imf-fluid mb-2"
                              />
                            </div>
                          ))}
                          <p className="con">
                            <b>{res.subject}</b> &nbsp; <br />
                            {res.description}
                          </p>
                          <p className="time">
                            {moment(
                              new Date(res.createdDate),
                              "YYYYMMDD"
                            ).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {loading ? (
                    <div className="col-md-12 d-flex justify-content-end">
                      <Spinner animation="border" />
                    </div>
                  ) : null}
                </div>
              )}
            </div>

            <div className="chatinput pt-2 px-3 pb-3">
              {multipleImage.length !== 0 ? (
                <div className="wrapimage p-2 w-100 d-flex justify-content-between border align-items-center radius-2">
                  <div
                    className="d-flex align-items-center"
                    style={{ overflowX: "auto" }}
                  >
                    {multipleImage.map((res: any) => (
                      <img
                        className="img-fluid mr-1"
                        src={endpoint + res}
                        alt="uploadedimage"
                        style={{ width: 40, height: 40 }}
                      />
                    ))}
                  </div>

                  <FiTrash
                    className="text-danger"
                    size={20}
                    onClick={() => setMultipleImage([])}
                  />
                </div>
              ) : null}

              <form onSubmit={handleSendMessage}>
                <div className="wrapfield">
                  Subject
                  <input
                    className="broadinp ml-3"
                    type="text"
                    required
                    placeholder="Type subject message here..."
                    onChange={(e: any) => setSubject(e.target.value)}
                    value={subject}
                  />
                </div>
                <div className="wrapinput">
                  <input
                    type="file"
                    name=""
                    accept="image/*"
                    style={{ display: "none" }}
                    id="attach"
                    multiple={true}
                    onChange={handleImage}
                    onClick={(e: any) => (e.target.value = null)}
                  />
                  <FaSmile className="emoji" />
                  <div className="wrapchattext">
                    <input
                      type="text"
                      placeholder="Type something here..."
                      required
                      onChange={(e: any) => setDescription(e.target.value)}
                      value={description}
                    />
                    {loadingImage ? (
                      <span className="mr-2">
                        <Spinner
                          animation="border"
                          variant="warning"
                          size="sm"
                        />
                      </span>
                    ) : (
                      <label htmlFor="attach">
                        <MdAttachFile className="attachfile" />
                      </label>
                    )}
                    {broadCast ? (
                      <button
                        className="sendmessage"
                        type="submit"
                        disabled={loading || loadingImage}
                      >
                        <MdSend />
                      </button>
                    ) : (
                      <button
                        className="sendmessage"
                        type="submit"
                        disabled={
                          loading || loadingImage || activePerson === null
                        }
                      >
                        <MdSend />
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
