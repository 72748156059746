import axios from "axios";
import { endpoint } from "../../../constants";
import {
  FilterModel,
  UpdateMultiplePaidStatusRequest,
  UpdatePaidStatusRequest,
  UpdateStatusModel
} from "./paymentConfirm.model";

export default class Api {
  static getList(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/SalesOrder/GetListPaymentConfirmationWithPaging", model)
      .then((res) => res.data.data.list);
  }

  static getListWithPaging(model: FilterModel) {
    return axios
    .post<any>(endpoint + "/api/PaymentConfirmation/GetListWithPaging", model)
    .then((res) => res.data.data);
}

  static updateStatus(model: UpdateStatusModel) {
    return axios
      .post<any>(endpoint + "/api/PaymentConfirmation/UpdateStatusBySalesOrder", model)
      .then((res) => res.data);
  }

  static changePaidStatus(model: UpdatePaidStatusRequest) {
    return axios
      .post<any>(endpoint + "/api/PaymentConfirmation/ChangePaidStatus", model)
      .then((res) => res.data);
  }

  static changeMultiplePaidStatus(model: UpdateMultiplePaidStatusRequest) {
    return axios
        .post<any>(endpoint + "/api/PaymentConfirmation/ChangePaidStatusMultiple", model)
        .then((res) => res.data);
  }

  static updateMultipleStatus(model: UpdateStatusModel[]) {
    return axios
        .post<any>(endpoint + "/api/PaymentConfirmation/UpdateMultipleStatusBySalesOrder", model)
        .then((res) => res.data);
  }
}
