import React, { Component, HTMLAttributes } from "react";
import "./SidebarMenuTree.css";
import notificationBadge from "../../assets/icons/alert/notificationBadge.svg";

export interface SidebarMenuTreeProps extends HTMLAttributes<HTMLLIElement> {
  title: string;
  faIconClass?: string;
  treeOpen: string;
  setTreeOpen: any;
  isNotificationOn: boolean;
}

export interface SidebarMenuTreeState {}

export default class SidebarMenuTree extends Component<
  SidebarMenuTreeProps,
  SidebarMenuTreeState
> {
  static defaultProps = {
    faIconClass: "fa fa-bars",
  } as SidebarMenuTreeProps;

  state: SidebarMenuTreeState = {
    isOpen: false,
  };

  collapse: HTMLDivElement | undefined = undefined;
  innerCollapse: HTMLDivElement | undefined = undefined;

  componentDidUpdate() {
    if (!this.collapse || !this.innerCollapse) return;

    if (this.props.treeOpen === this.props.title) {
      (
        this.collapse as HTMLDivElement
      ).style.height = `${this.innerCollapse?.clientHeight}px`;
    } else {
      (this.collapse as HTMLDivElement).style.height = "0";
    }
  }

  onRefCollapse = (collapse: HTMLDivElement) => {
    this.collapse = collapse;
  };

  onRefInnerCollapse = (innerCollapse: HTMLDivElement) => {
    this.innerCollapse = innerCollapse;
  };

  handleClickTitle = () => {
    if (this.props.treeOpen === this.props.title) {
      this.props.setTreeOpen("");
    } else {
      this.props.setTreeOpen(this.props.title);
    }
  };

  render() {
    return (
      <li
        className={`sidebar-menu-tree ${
          this.props.treeOpen === this.props.title ? "open" : ""
        } ${this.props.className || ""}`}
        style={this.props.style || {}}
      >
        <p
          className={`${
            this.props.treeOpen === this.props.title ? "active" : ""
          }`}
          onClick={this.handleClickTitle}
        >
          <i
            className={`sidebar-menu-tree__icon ${this.props.faIconClass}`}
          ></i>
          <span className="sidebar-menu-tree__title">{this.props.title}</span>

          <span className="sidebar-menu-tree__caret-container">
            <i className="fa fa-angle-left"></i>
          </span>

          {this.props.isNotificationOn ? (
            // <i className={`fa fa-exclamation-circle sidebar-menu-tree__icon`}></i>

            <svg className="sidebar-menu-tree__icon" width="12" height="12">
              <circle cx="6" cy="6" r="3" fill="red" />
            </svg>
          ) : (
            <></>
          )}

          {/* <i className={`fa fa-bell fa-xs`}></i>    */}
        </p>

        <div
          ref={this.onRefCollapse}
          className={`collapse-menu`}
          style={{ height: "0px" }}
        >
          <div ref={this.onRefInnerCollapse} className={`inner-collapse`}>
            <ul className="sidebar-tree">{this.props.children}</ul>
          </div>
        </div>
      </li>
    );
  }
}
