import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../../../../app/store";
import { getListKeepByProduct } from "../keepPerProduct.reducer";
import KeepByProductTable from "../components/KeepByProductTable";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

const KeepByProductIndex = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListKeepByProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <KeepByProductTable />
    </Container>
  );
};

export default KeepByProductIndex;
