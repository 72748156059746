import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {
  getListPartner,
  getListPaymentMethod,
} from "../../../../global/global.reducer";
import { PartnerOptionModel } from "../paymentIn.model";
import Select from "react-select";
import {
  deleteData,
  getListWithPaging,
  resetStatus,
} from "../paymentIn.reducer";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";
import note from "./../../../../assets/vector/note.png";
import { Link } from "react-router-dom";
import DeleteMessage from "../../../../components/DeleteMessage";
import NumberFormat from "react-number-format";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const PaymentInTable = () => {
  const dispatch = useDispatch();

  const PaymentInListState = useSelector(
    (state: RootState) => state.paymentIn.list ?? []
  );

  const partnerListState = useSelector(
    (state: RootState) => state.global.listPartner ?? []
  );

  const paymentMethodListState = useSelector(
    (state: RootState) => state.global.listPaymentMethod ?? []
  );

  const paymentInStatusState = useSelector(
    (state: RootState) => state.paymentIn.status ?? ""
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const [partnerList, setPartnerList] = useState<PartnerOptionModel[]>([]);

  const [filterPaymentInNumber, setFilterPaymentInNumber] = useState("");
  const [filterPaymentMethodId, setFilterPaymentMethodId] = useState("");
  const [filterPartnerId, setFilterPartnerId] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterStatus, setFilterStatus] = useState(5);

  const [selectedId, setSelectedId] = useState("");

  const [selectedPartner, setSelectedPartner] = useState<PartnerOptionModel>({} as PartnerOptionModel)

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "", 
    title: "",
    content: "",
  });

  const onSelectedPartnerChange = (data: any) => {
    setFilterPartnerId(data.value);
    setSelectedPartner(partnerList.filter((item) => item.value == data.value)[0])
  };

  const onPaymentMethodChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterPaymentMethodId(e.target.value);
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
      setShowAlert(false);
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {

    setFilterPaymentInNumber("")
    setFilterPartnerId("")
    setFilterPaymentMethodId("")
    setFilterEndDate("")
    setFilterStartDate("")
    setFilterStatus(5)
    setSelectedPartner({} as PartnerOptionModel)
   
    dispatch(
      getListWithPaging({
        paymentNumber: "",
        customerId: null,
        paymentMethodId: null,
        startDate: filterStartDate,
        endDate: filterEndDate,
        status: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        paymentNumber: filterPaymentInNumber,
        customerId: filterPartnerId == "" ? null : filterPartnerId,
        paymentMethodId: filterPaymentMethodId == "" ? null : filterPaymentMethodId,
        startDate: filterStartDate,
        endDate: filterEndDate,
        status: filterStatus == 5 ? null : filterStatus,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getListPartner());
    dispatch(getListPaymentMethod());
  }, []);

  useEffect(() => {
    if (partnerListState.length !== 0) {
      setPartnerList([]);

      partnerListState.map((item) => {
        var partnerTypeString =
          item.type == 0
            ? "Customer"
            : item.type == 1
            ? "Stockist"
            : "Reseller";

        setPartnerList((prevState) => [
          ...prevState,
          {
            value: item.id,
            label:
              item.fullName + " - " + item.email + " - " + partnerTypeString,
            type: item.type,
          },
        ]);
      });
    }
  }, [partnerListState]);

  useEffect(() => {
    if (paymentInStatusState == "success-delete") {
      fetchData(0, perPage);
      dispatch(resetStatus());
    }
  }, [paymentInStatusState]);

  const columns = [
    {
      name: "PAYMENT NO.",
      selector: "paymentNumber",
      sortable: true,
    },
    {
      name: "TANGGAL",
      selector: (d: { paymentDate: string }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.paymentDate !== undefined && d.paymentDate.substring(0, 10)}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "CUSTOMER",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "BANK",
      selector: "paymentMethodName",
      sortable: true,
    },
    {
      name: "TOTAL PEMBAYARAN",
      selector: (d: { paymentAmount: any }) => (
        <>
          <NumberFormat
            // value={d.grandTotal}
            value={d.paymentAmount}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { status: number }) => (
        <>
          <p style={{ paddingTop: 15 }}>
            {d.status === 0 && "DRAFT"}
            {d.status === 1 && "COMPLETE"}
            {d.status === 2 && "VOID"}
            {d.status === 3 && "CLOSE"}
            {d.status === 4 && "INVALID"}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id?: string }) => (
        <>
          <Link
            to={{
              pathname: `/payment-in/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"        "}
          <Link
            to={{
              pathname: `/payment-in-allocation-history/${d.id}`,
            }}
          >
            <img src={note}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              setSelectedId(d.id!);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <div>
      <Grid>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Payment Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterPaymentInNumber}
                    onChange={(e) => {
                      setFilterPaymentInNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Tanggal
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    defaultValue={""}
                    value={filterStartDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    defaultValue={""}
                    value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString())
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Customer
                </Form.Label>
                <Col>
                  <Select
                    onChange={onSelectedPartnerChange}
                    value={selectedPartner}
                    options={partnerList}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Bank
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="select"
                    onChange={onPaymentMethodChange}
                    value={filterPaymentMethodId}
                  >
                    <option value="">Select</option>
                    {paymentMethodListState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setFilterStatus(parseInt(e.target.value))
                    }}
                    value={filterStatus}
                  >
                    <option value="5">ALL</option>
                    <option value="0">DRAFT</option>
                    <option value="1">COMPLETE</option>
                    <option value="2">VOID</option>
                    <option value="3">CLOSE</option>
                    <option value="4">INVALID</option>

                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          <br />
          <br />

          <DataTable
            columns={columns}
            data={PaymentInListState}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default PaymentInTable;
