import React, {useEffect, useState} from 'react'
import {Col, Row, Button, Container} from "react-bootstrap";
import UPLOAD_IMAGE from '../../../../assets/vector/upload.png'
import {Link, useParams, useHistory} from 'react-router-dom';

import {FiChevronLeft} from 'react-icons/fi';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {uploadImage, updateFloatingBanner, getSingleFloatingBanner} from '../floatingBanner.api';
import Swal from 'sweetalert2';
import {convertBase64} from '../../../../components/Library/Base64';
import {endpoint} from '../../../../constants'
import {RootState} from '../../../../app/store';
import RichTextEditor from "../../../../components/rich-text/RichTextEditor";
import {FloatingBannerEnum} from "../model";

type FloatingBannerForm = {
    title: string;
    description: string;
    link: string;
    buttonText: string;
    image: string;
    type: number;
}

export default function FloatingBannerForm() {
    const history = useHistory();
    const {id} = useParams<any>();
    const isEdit = id
    const dispatch = useDispatch()
    const {loading} = useSelector((state: RootState) => state.slideShow)

    const {id: userId} = useSelector(
        (state: RootState) => state.user
    );

    const [values, setValues] = useState<FloatingBannerForm>({
        title: '',
        description: '',
        link: '',
        buttonText: '',
        image: '',
        type: 0
    } as FloatingBannerForm)

    useEffect(() => {
        dispatch<any>(getSingleFloatingBanner(isEdit))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res)
                setValues({
                    ...values,
                    ...res
                })
            })
    }, [isEdit])

    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleImage = (e: any) => {
        convertBase64(e.target.files[0], (result: any) => {
            const imageData = result.split(',')
            const base64Final = imageData[1]
            const data = {
                "file_name": e.target.files[0].name,
                "base64url": base64Final,
                "imagepath": "floatingBanner",
                "location": "floatingBanner"
            }
            dispatch<any>(uploadImage(data))
                .then(unwrapResult)
                .then((res: any) => {
                    console.log(res)
                    if (res.status === 200) {
                        setValues({
                            ...values,
                            "image": endpoint + res.data.imagepath
                        })
                        Swal.fire({
                            icon: 'success',
                            text: 'Image uploaded',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: 'Error uploading image!',
                        })
                    }
                }).catch((err: any) => {
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Error uploading image!',
                })
            })
        });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (values.image.length === 0) {
            return Swal.fire({
                icon: 'warning',
                text: 'Image is required!',
            })
        }
        dispatch<any>(updateFloatingBanner({
            id: id,
            title: values.title,
            description: values.description,
            link: values.link,
            buttonText: values.buttonText,
            image: values.image,
            type: values.type,
            userId: userId as string,
            isActive: true
        }))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res)
                if (res.status) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Floating banner updated',
                        showConfirmButton: false,
                        timer: 1500
                    })
                        .then(() => {
                            history.push('/floating-banner')
                        })
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Floating banner cannot updated!',
                    })
                }
            }).catch((err: any) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                text: 'Floating banner cannot updated!',
            })
        })
    }

    const onContentChangeHandler = (val: string) => {
        setValues({
            ...values,
            description: val
        })
    };

    return (
        <Container className="content-body formSlide" fluid>
            <div className="page-title mb-4 title-link">
                <Link to='/floating-banner'>
                    <FiChevronLeft/>
                </Link>
                Update {values.type === FloatingBannerEnum.Popup ? 'Popup' : 'Floating'} Banner
            </div>
            <div className="box">
                <div
                    className="titbox">Update {values.type === FloatingBannerEnum.Popup ? 'Popup' : 'Floating'} Banner
                </div>
                <form onSubmit={handleSubmit}>
                    <Row className='forms'>
                        <Col md={3}><p>Image <br/> <small>*Direkomendasikan gambar dengan aspek rasio 3:1(panoramic)</small></p></Col>
                        <Col md={9}>
                            <input type="file" name="" style={{display: 'none'}} id="bgImage" onChange={handleImage}/>
                            {
                                loading ?
                                    'Loading...'
                                    :
                                    <label htmlFor='bgImage' className="uploadfilebox overflow-hidden">
                                        {
                                            values.image.length !== 0 ?
                                                <img src={values.image} alt="upload" className='img-fluid'/>
                                                :
                                                <>
                                                    <img src={UPLOAD_IMAGE} alt="upload"/>
                                                    Select File
                                                </>
                                        }
                                    </label>
                            }
                        </Col>
                    </Row>
                    {
                        values.type === FloatingBannerEnum.Thumbnail && (
                            <Row className='forms'>
                                <Col md={3}><p>Link</p></Col>
                                <Col md={9}>
                                    <input type="text" placeholder='Link' required name="link" value={values.link}
                                           onChange={handleChange}/>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        values.type === FloatingBannerEnum.Popup && (
                            <>
                                <Row className='forms'>
                                    <Col md={3}><p>Title</p></Col>
                                    <Col md={9}>
                                        <input type="text" placeholder='Title' required name="title" value={values.title}
                                               onChange={handleChange}/>
                                    </Col>
                                </Row>
                                <Row className='forms'>
                                    <Col md={3}><p>Link</p></Col>
                                    <Col md={9}>
                                        <input type="text" placeholder='Link' required name="link" value={values.link}
                                               onChange={handleChange}/>
                                    </Col>
                                </Row>
                                <Row className='forms'>
                                    <Col md={3}><p>Button Text</p></Col>
                                    <Col md={9}>
                                        <input type="text" placeholder='Button Text' required name="buttonText"
                                               value={values.buttonText}
                                               onChange={handleChange}/>
                                    </Col>
                                </Row>
                                <Row className='forms'>
                                    <Col md={3}><p>Description</p></Col>
                                    <Col md={9}>
                                        <RichTextEditor value={values.description} onValueChange={onContentChangeHandler}/>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    <div className="wrap-buttons">
                        <Button type='button' onClick={() => history.push('/floating-banner')}>Cancel</Button>
                        <Button type='submit'>Save</Button>
                    </div>
                </form>
            </div>
        </Container>
    )
}
