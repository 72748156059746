import axios from "axios";
import { endpoint } from "../../constants";
import {CreateEventModel, GetEventTransactionModel, UpdateEventModel, UploadImageModel} from "./event.model";

export default class EventApi {
    static createEvent(args: CreateEventModel) {
        return axios
        .post<any>(endpoint + "/api/Event/Create", args)
        .then((res) => res.data.data);
    }

    static getList() {
        return axios
        .get<any>(endpoint + "/api/Event/GetList")
        .then((res) => res.data.data);
    }

    static getListTransaction(args: GetEventTransactionModel) {
        return axios
            .get<any>(endpoint + "/api/Event/GetListTransaction", {
                params: {
                    partnerId: undefined,
                    eventId: args.eventId,
                    skip: args.skip,
                    take: args.take,
                    isSortByEvent: args.isSortByEvent,
                    isSortByDate: args.isSortByDate,
                    isEventAsc: args.isEventAsc,
                    isDateAsc: args.isDateAsc,
                }
            })
            .then((res) => res.data.data);
    }

    static getSingleItem(id: string) {
        return axios
        .get<any>(endpoint +"/api/Event/GetSingleItem?id=" + id)
        .then((res) => res.data.data);
    }

    static updateEvent(args: UpdateEventModel) {
        return axios
        .post<any>(endpoint + "/api/Event/Update", args)
        .then((res) => res.data.data);
    }

    static deleteEvent(id: string) {
        return axios
        .post<any>(endpoint + "/api/Event/Delete?Id=" + id)
        .then((res) => [
            { response: res.data.data },
        ]);
    }
}
