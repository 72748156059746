import { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import StoreInfoAdmin from "../components/StoreInfoAdmin";
import StoreInfoForm from "../components/StoreInfoForm";
import { handleTab } from "../informationStore.reducer";
import '../informationStore.scss'



function InformationStore() {
    const {activeTab, loading} = useSelector((state:RootState) => state.informationStore)
    const dispatch = useDispatch()
    const [tabs] = useState<any>([
        // 'Admin',
        'Reseller',
        'Stockist',
        'End Customer',
    ])
    
    return (
        <Container className="content-body information-store" fluid>
            <Form.Label className="page-title mb-3">Store Information Settings</Form.Label>
            <div className="box">
                <div className="tabs">
                    {
                        tabs.map((res:any,i:number)=>(
                            <div key={i} className={activeTab === res ? 'childtab active' : 'childtab'} onClick={()=>dispatch( handleTab(res))}>{res}</div>
                        ))
                    }
                </div>
                <StoreInfoForm />
            </div>
        </Container>
    );
}

export default InformationStore;