import "react-dragswitch/dist/index.css";
import AlertMessage from "../../../../components/alertMessage";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { DragSwitch } from "react-dragswitch";
import { Grid } from "semantic-ui-react";
import { ExpeditionModel } from "../Expedition.model";
import { voidTypeAnnotation } from "@babel/types";
import { resetSingle, resetStatus, uploadPhoto } from "../Expedition.reducer";
import upload from "../../../../assets/vector/upload.png";
import Dropzone from "react-dropzone";
import { convertBase64 } from "../../../../components/Library/Base64";
import { endpoint } from "../../../../constants";


const ExpeditionForm = ({
  data = {} as ExpeditionModel,
  isEdit = false,
  ...props
}: ExpeditionFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [fileFormat, setFileFormat] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  // const iconPathState = useSelector(
  //   (state: RootState) => state.expedition.iconPath ?? ""
  // );

  const ExpeditionStatusState = useSelector(
    (state: RootState) => state.expedition.status ?? ""
  );

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onCancelClick = () => {
    dispatch(resetSingle());
    history.replace("/expedition/index");
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (
      ExpeditionStatusState === "success" ||
      ExpeditionStatusState === "success-update"
    ) {
      dispatch(resetStatus());
      dispatch(resetSingle());
      history.replace("/expedition/index");
    } else {
      dispatch(resetStatus());
    }
  }

  // const handleIcon = (files: any) => {
  //   setFileFormat("");
  //   if (files[0] !== undefined) {
  //     setFileFormat(files[0].type.split("/")[1]);
  //     convertBase64(files[0], (result: any) => {
  //       setIcon(result);
  //     });
  //   } else {
  //     console.log("error");
  //   }
  // };

  useEffect(() => {
    if (icon != null && icon != "") {
      dispatch(
        uploadPhoto({
          file_name: name + "." + fileFormat,
          base64url: icon.split(",")[1],
          imagepath: "expedition",
          location: "expedition",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon, fileFormat]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setName(data.name);
      // setIcon(data.icon);
    } else {
    }
  }, [data]);

  useEffect(() => {
    if(ExpeditionStatusState === "success"){
      setAlertProps({
        variant: "success",
        title: "Berhasil Menambahkan Expedisi Baru",
        content: "",
      });
      setShowAlert(true);
    } else if (ExpeditionStatusState === "success-update"){
      setAlertProps({
        variant: "success",
        title: "Berhasil Update Data Expedisi",
        content: "",
      });
      setShowAlert(true);
    }
  }, [ExpeditionStatusState])

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">Add Master Expedition</Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div className="">Add Expedition</div>
            <Form
              noValidate
              // validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(name);
                }
                // setValidated(true);
              }}>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    required
                    value={name}
                    onChange={onNameChange}
                  />
                </Col>
              </Form.Group>
              <hr />

              {/* <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Icon
                </Form.Label>
                <Col sm={4}>
                  <Dropzone
                    onDrop={(acceptedFiles) => handleIcon(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone file-upload-frame w-100">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="text-center file-upload-label">
                            <div> */}
                              {/* {console.log(imageKtp)} */}

                              {/* {(icon !== "" ||
                                Object.keys(data).length !== 0) && (
                                <img
                                  src={
                                    icon !== "" ? icon : endpoint + data.icon
                                  }
                                  className="file-upload-image mb3"
                                />
                              )} */}

                              {/* {icon === "" && Object.keys(data).length === 0 && (
                                <>
                                  <div
                                    className="file-upload-image mb3"
                                    style={{ paddingTop: 50 }}>
                                    <img src={upload} alt="upload" />
                                    <br />
                                    <span style={{ color: "black" }}>
                                      Select File
                                    </span>
                                  </div>
                                </>
                              )} */}
                            {/* </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <br />
                </Col>
              </Form.Group> */}
              <hr />
              <Row>
                <Col sm={6}>
                  <Button
                    className="btn-cancel"
                    variant="light"
                    onClick={onCancelClick}>
                    Cancel
                  </Button>
                  {"  "}

                  <Button
                    variant="info"
                    style={{ width: 135, height: 46 }}
                    type="submit">
                    Save
                  </Button>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Grid>
      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    </div>
  );
};

export interface ExpeditionFormProps {
  onSubmit: (name: string) => void;
  data: ExpeditionModel;
  isEdit: boolean;
}

export default ExpeditionForm;
