import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getList } from '../event.reducer';
import EventTable from '../components/EventTable';

const EventTablePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
   <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <EventTable />
        </Col>
      </Row>
    </Container>
  );
};

export default EventTablePage;
