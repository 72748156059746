import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import PaymentAllocationApi from "./paymentAllocation.api";
import {
  DocumentActionModel,
  FilterModel,
  FilterUnlocatedPaymentModel,
  PaymentAllocationListModel,
  PaymentAllocationModel,
  PaymentAllocationWithDetail,
  UnallocatedInvoiceModel,
  UnallocatedPaymentModel,
} from "./paymentAllocation.model";

export interface PaymentAllocationSlice {
  singleWithDetail? : PaymentAllocationWithDetail;
  list?: PaymentAllocationListModel[];
  totalRow?: number;
  unallocatedPayments?: UnallocatedPaymentModel[];
  totalUnallocatedPaymentsRow?: number;
  unallocatedInvoices? : UnallocatedInvoiceModel[];
  totalUnallocatedInvoicesRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getListWithPaging = createAsyncThunk(
  "PaymentAllocationState/GetListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await PaymentAllocationApi.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAllUnallocatedPayments = createAsyncThunk(
  "PaymentAllocationState/getAllUnallocatedPayments",
  async (model: FilterUnlocatedPaymentModel, { rejectWithValue }) => {
    try {
      return await PaymentAllocationApi.getAllUnallocatedPayment(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAllUnallocatedInvoices = createAsyncThunk(
  "PaymentAllocationState/getAllUnallocatedInnvoices",
  async (model: FilterUnlocatedPaymentModel, { rejectWithValue }) => {
    try {
      return await PaymentAllocationApi.getAllUnallocatedInvoices(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createPaymentAllocation = createAsyncThunk(
  "PaymentAllocationState/createPaymentAllocation",
  async (model: PaymentAllocationModel, { rejectWithValue }) => {
    try {
      return await PaymentAllocationApi.createPaymentAllocation(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const GetSingleWithDetail = createAsyncThunk(
  "PaymentAllocationState/getSingleWithDetail",
  async (id: string, { rejectWithValue }) => {
    try {
      return await PaymentAllocationApi.getSingleItemWithDetail(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const documentAction = createAsyncThunk(
  "PaymentAllocationState/documentAction",
  async (model : DocumentActionModel ,{ rejectWithValue }) => {
    try {
      return await PaymentAllocationApi.docCompleteAction(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const PaymentAllocationSlice = createSlice({
  name: "PaymentAllocationState",
  initialState: {} as PaymentAllocationSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
    resetSingleData: (state) => {
      state.singleWithDetail = {} as PaymentAllocationWithDetail;
    },
    resetPaymentsAndInvoicesList: (state) => {
      state.unallocatedInvoices = [];
      state.unallocatedPayments = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.data;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(GetSingleWithDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GetSingleWithDetail.fulfilled, (state, { payload }) => {
      state.singleWithDetail = payload.data;
      state.isLoading = false;
    });
    builder.addCase(GetSingleWithDetail.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getAllUnallocatedPayments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllUnallocatedPayments.fulfilled,
      (state, { payload }) => {
        state.unallocatedPayments = payload.data;
        state.totalUnallocatedPaymentsRow = payload.total;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getAllUnallocatedPayments.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
    builder.addCase(getAllUnallocatedInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllUnallocatedInvoices.fulfilled,
      (state, { payload }) => {
        state.unallocatedInvoices = payload.data;
        state.totalUnallocatedInvoicesRow = payload.total;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getAllUnallocatedInvoices.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
    builder.addCase(createPaymentAllocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPaymentAllocation.fulfilled, (state, { payload }) => {
      state.status = "success-create-payment-allocation"
      state.isLoading = false;
    });
    builder.addCase(createPaymentAllocation.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed-create-payment-allocation"
    });
    builder.addCase(documentAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(documentAction.fulfilled, (state, { payload }) => {
      state.status = "success-update-payment-allocation"
      state.isLoading = false;
    });
    builder.addCase(documentAction.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed-update-payment-allocation"
    });
  },
});

export const { resetStatus, resetPaymentsAndInvoicesList, resetSingleData } = PaymentAllocationSlice.actions;
export default PaymentAllocationSlice.reducer;
