import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import {
  Card,
  InputGroup,
  FormControl,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../../../constants";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DeleteMessage from "../../../../../components/DeleteMessage";
import hapus from "./../../../../../assets/vector/delete.png";
import { JatuhTempoDropshipModel } from "../jatuhTempoDropship.model";
import edit from "./../../../../../assets/vector/edit.png";
import { RootState } from "../../../../../app/store";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import { getListWithPaging } from "../jatuhTempoDropship.reducer";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

const JatuhTempoDropshipTable = () => {
  const dispatch = useDispatch();

  const listState = useSelector(
    (state: RootState) => state.jatuhTempoDropship.list ?? []
  );

  const totalRowState = useSelector(
    (state: RootState) => state.jatuhTempoDropship.totalRow ?? 0
  );

  const [filterText, setFilterText] = useState("");
  const [list, setList] = useState<JatuhTempoDropshipModel[]>([]);
  const [selectedId, setSelectedId] = useState("");

  const [filterSoNumber, setFilterSoNumber] = useState("")
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [filterStartOrderDate, setFilterStartOrderDate] = useState("");
  const [filterEndOrderDate, setFilterEndOrderDate] = useState("");

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterCustomerName("");
    setFilterProductName("");
    setFilterStartOrderDate("");
    setFilterEndOrderDate("");
    setFilterSoNumber("")
    dispatch(
      getListWithPaging({
        soNumber: "",
        customerName: "",
        productName: "",
        startOrderDate: "",
        endOrderDate: "",
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        soNumber: filterSoNumber,
        customerName: filterCustomerName,
        productName: filterProductName,
        startOrderDate: filterStartOrderDate,
        endOrderDate: filterEndOrderDate,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [list]);

  const columns = [
    {
      name: "SO NUMBER",
      selector: "soNumber",
      sortable: true,
    },
    // {
    //   name: "ID PESANAN",
    //   selector: "orderId",
    //   sortable: true,
    // },
    {
      name: "PEMBELI",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "JENIS PELANGGAN",
      selector: (d: { partnerType: number }) => (
        <p style={{ margin: 10 }}>
          {d.partnerType === 0 && <span>End Customer</span>}
          {d.partnerType === 1 && <span>Stockist</span>}
          {d.partnerType === 2 && <span>Reseller</span>}
          {d.partnerType === 4 && <span>Admin Stockist</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "TANGGAL PESANAN",
      selector: (d: { orderDate: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.orderDate.slice(0, 10)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "TOTAL DISCOUNT",
      selector: (d: { discount: any }) => (
        <>
          <NumberFormat
            value={d.discount}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "TOTAL",
      selector: (d: { grandTotal: any }) => (
        <>
          <NumberFormat
            value={d.grandTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },

    {
      name: "STATUS PEMBAYARAN",
      selector: (d: { paymentStatus: any }) => (
        <p style={{ margin: 10 }}>
          {d.paymentStatus === 0 && (
            <span className={"custom-badge-secondary"}>Unpaid</span>
          )}
          {d.paymentStatus === 1 && (
            <span className={"custom-badge-primary"}>Paid</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { salesOrderId: any }) => (
        <>
          <Link
            to={{
              pathname: `/jatuh-tempo/dropship/edit/${d.salesOrderId}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setSelectedId(d.salesOrderId);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components
  useEffect(() => {
    if (listState.length !== 0) {
        setList(listState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listState]);

  const onDelete = () => setShowAlert(false);

  const onAlertClose = () => setShowAlert(false);

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
           
            <Col>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  SO Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterSoNumber}
                    onChange={(e) => {
                      setFilterSoNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal Pesanan
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={new Date(filterStartDate).toISOString().substr(0, 10)}
                    onChange={(e) =>
                      setFilterStartOrderDate(
                        new Date(e.target.value).toISOString()
                      )
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    // value={filterEndDate.substr(0, 10)}
                    onChange={(e) =>
                      setFilterEndOrderDate(
                        new Date(e.target.value).toISOString()
                      )
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Product Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterProductName}
                    onChange={(e) => {
                      setFilterProductName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Customer Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterCustomerName}
                    onChange={(e) => {
                      setFilterCustomerName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

          </Row>

          <Row>
            <Col md={4}>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          <hr />

          <DataTable
            columns={columns}
            data={list}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={list}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default JatuhTempoDropshipTable;
