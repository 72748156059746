import "./styles.css";
import { Card, Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import AlertMessage from "../../../components/alertMessage";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetStatus } from "../profile.reducer";
import { ProfileModel } from "../profile.model";
import { RootState } from "../../../app/store";
import { Grid } from "semantic-ui-react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SettingProfileForm = ({ data = {} as ProfileModel, ...props }: ProfileFormProps) => {
  const dispatch = useDispatch();

  const createStatus = useSelector((state: RootState) => state.profile.status ?? "");

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pincode, setPincode] = useState("");
  //password toggle
  const [passwordShown, setPasswordShown] = useState(false);
  //form validation
  const [validated, setValidated] = useState(false);
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => setShowAlert(false);

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onEmailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  const onPincodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPincode(e.target.value);
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "password salah / data belum terisi",
      });
      setShowAlert(true);
    }
    return () => {
      dispatch(resetStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    setName(data.name);
    setEmail(data.email);
    setUsername(data.username);
    setPincode(data.pincode);
    setPassword(data.password);
    setConfirmPassword(data.confirmPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "password salah / data belum terisi",
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const refreshPage = () => {
    window.location.reload();
  };

  const togglePasswordInvisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div className="profile-setting-subtitle">Profile Settings</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const status = true;
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(name, username, email, pincode, password, confirmPassword);
                  // refreshPage();

                  // history.replace("/setting-profile");
                  if (status) {
                    setAlertProps({
                      variant: "success",
                      title: "Berhasil",
                      content: "Berhasil Mengupdate Profile",
                    });
                    setShowAlert(true);
                  }
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Username
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    onChange={onUsernameChange}
                    value={username}
                    required
                    placeholder="name"
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Email
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={onEmailChange}
                    value={email}
                    required
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Password
                </Form.Label>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Control
                      type={passwordShown ? "text" : "password"}
                      onChange={onPasswordChange}
                      value={password}
                      required
                      placeholder="Password"
                    />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text
                        style={{
                          backgroundColor: "#fff",
                          color: "#F5B95D",
                          border: 0,
                        }}
                        onClick={togglePasswordInvisibility}
                      >
                        {passwordShown ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Confirmation Password
                </Form.Label>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Control
                      type={passwordShown ? "text" : "password"}
                      onChange={onConfirmPasswordChange}
                      value={confirmPassword}
                      required
                      placeholder="Password"
                    />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text
                        style={{
                          backgroundColor: "#fff",
                          color: "#F5B95D",
                          border: 0,
                        }}
                        onClick={togglePasswordInvisibility}
                      >
                        {passwordShown ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  PIN
                </Form.Label>
                <Col sm={4}>
                  <InputGroup>
                    <Form.Control
                      type={passwordShown ? "number" : "password"}
                      onChange={onPincodeChange}
                      value={pincode}
                      required
                      placeholder="PIN"
                    />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text
                        style={{
                          backgroundColor: "#fff",
                          color: "#F5B95D",
                          border: 0,
                        }}
                        onClick={togglePasswordInvisibility}
                      >
                        {passwordShown ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                  <Form.Label className="card-subtitle">
                    Empty if you are not change password
                  </Form.Label>
                </Col>
              </Form.Group>
              <hr />
              <Button className="btn-save-profile" variant="info" type="submit">
                Save
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export interface ProfileFormProps {
  onSubmit: (
    name: string,
    username: string,
    email: string,
    pincode: string,
    password: string,
    confirmPassword: string
  ) => void;
  data?: ProfileModel;
}

export default SettingProfileForm;
