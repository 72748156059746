import {
  Card,
  InputGroup,
  FormControl,
  Form,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import hapus from "./../../../../../assets/vector/delete.png";
import edit from "./../../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import axios from "axios";
import DeleteMessage from "../../../../../components/DeleteMessage";
import { SalesOrderModel } from "../dropship.model";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../../../constants";
import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { getUnpaidDropship } from "../../../salesOrder/salesOrder.reducer";

/*
 * created by : dikembe mutombo
 * created date : 14/oct/2021
 */

const DropshipTable = () => {
  const dispatch = useDispatch();

  const UnpaidDropshipListState = useSelector(
    (state: RootState) => state.salesOrder.unpaidDroposhipList ?? []
  );

  const totalRowState = useSelector(
    (state: RootState) => state.salesOrder.totalRowUnpaidDropship ?? 0
  );

  const [filterText, setFilterText] = useState("");
  const [list, setList] = useState<SalesOrderModel[]>([]);
  const [selectedId, setSelectedId] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterProductCode, setFilterProductCode] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterSoNumber, setFilterSoNumber] = useState("")

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterCustomerName("")
    setFilterProductCode("")
    setFilterProductName("")
    setFilterStartDate("")
    setFilterEndDate("")
    setFilterSoNumber("")

    dispatch(
      getUnpaidDropship({
        customerName: "",
        productCode: "",
        productName: "",
        startDate: "",
        endDate: "",
        soNumber: "",
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getUnpaidDropship({
        customerName: filterCustomerName,
        productCode: filterProductCode,
        productName: filterProductName,
        startDate: filterStartDate,
        endDate: filterEndDate,
        soNumber: filterSoNumber,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [UnpaidDropshipListState]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  const columns = [
    {
      name: "SO Number",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (d: { orderDate: any }) => (
        <>
          <p style={{ paddingTop: 15 }}>{d.orderDate.slice(0, 10)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Nama Pelanggan",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "Jenis Pelanggan",
      selector: (d: { partnerType: number }) => (
        <p style={{ margin: 10 }}>
          {d.partnerType === 0 && <span>End Customer</span>}
          {d.partnerType === 1 && <span>Stockist</span>}
          {d.partnerType === 2 && <span>Reseller</span>}
          {d.partnerType === 4 && <span>Admin Stockist</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Kode",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "Produk",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Varian",
      selector: "variationName",
      sortable: true,
    },

    {
      name: "qty",
      selector: "quantity",
      sortable: true,
    },
    // {
    //   name: "Harga",
    //   selector: (d: { price: any }) => (
    //     <>
    //       <NumberFormat
    //         value={d.price}
    //         displayType="text"
    //         thousandSeparator={true}
    //         prefix="Rp. "
    //       />
    //     </>
    //   ),
    //   sortable: true,
    // },
    {
      name: "SubTotal",
      selector: (d: { subTotal: any }) => (
        <>
          <NumberFormat
            value={d.subTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "Grand Total",
      selector: (d: { grandTotal: any }) => (
        <>
          <NumberFormat
            value={d.grandTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },
    // {
    //   name: "Total",
    //   selector: (d: { grandTotal: any }) => (
    //     <>
    //       <NumberFormat
    //         value={d.grandTotal}
    //         displayType="text"
    //         thousandSeparator={true}
    //         prefix="Rp. "
    //       />
    //     </>
    //   ),
    //   sortable: true,
    // },

    {
      name: "ACTION",
      selector: (d: { salesOrderId: any }) => (
        <>
          {/* <Link
            to={{
              pathname: `/order-detail/${d.salesOrderId}`,
            }}
          >
            <img src={edit}></img>
          </Link> */}

          <Link
            to={{
              pathname: `/customer-so-detail/${d.salesOrderId}`,
            }}>
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components

  useEffect(() => {
    axios
      .get<any>(endpoint + "/api/SalesOrder/GetListByStatusOrder?statusOrder=4")
      .then((res) => setList(res.data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = () => setShowAlert(false);

  const onAlertClose = () => setShowAlert(false);

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Customer Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterCustomerName}
                    onChange={(e) => {
                      setFilterCustomerName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Tanggal Order
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    value={filterStartDate}
                    onChange={(e) =>
                      setFilterStartDate(new Date(e.target.value).toISOString().substring(0,10))
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    value={new Date(filterEndDate).toString()}
                    onChange={(e) =>
                      setFilterEndDate(new Date(e.target.value).toISOString().substring(0,10))
                    }
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Product Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterProductName}
                    onChange={(e) => {
                      setFilterProductName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Product Code
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterProductCode}
                    onChange={(e) => {
                      setFilterProductCode(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  SO Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterSoNumber}
                    onChange={(e) => {
                      setFilterSoNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            </Row>

          <Row>
            <Col md={4}>

            <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>
              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>

             
            </Col>
          </Row>


          {/* <InputGroup className="mb-3 padding-filter " style={{ width: 330 }}>
            <InputGroup.Prepend className="search-input">
              <InputGroup.Text
                style={{
                  backgroundColor: "#fff",
                  color: "#F5B95D",
                  border: 0,
                }}
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              style={{ borderLeft: 0 }}
              placeholder="Search"
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              value={filterText}
            />
          </InputGroup> */}

          <hr />

          <DataTable
            columns={columns}
            data={UnpaidDropshipListState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={list}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            selectableRows
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default DropshipTable;
