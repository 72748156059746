export type UpdateFloatingBannerRequest = {
    id: string;
    title: string;
    description: string;
    link: string;
    buttonText: string;
    image: string;
    type: FloatingBannerEnum;
    userId: string;
    isActive: boolean;
}

export type FloatingBannerModel = {
    id: string;
    title: string;
    description: string;
    link: string;
    buttonText: string;
    image: string;
    type: FloatingBannerEnum;
    isActive: boolean;
}

export enum FloatingBannerEnum
{
    Popup,
    Thumbnail
}
