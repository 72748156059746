import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import {
  Card,
  InputGroup,
  FormControl,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DeleteMessage from "../../../../../components/DeleteMessage";
import hapus from "./../../../../../assets/vector/delete.png";
import edit from "./../../../../../assets/vector/edit.png";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import { KeepByProductModel } from "../keepPerProduct.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { getKeepPerProduct } from "../keepPerProduct.reducer";
import {MAX_PAGING_OPTIONS} from "../../../../../constants";

/*
 * created by : dikembe mutombo
 * created date : 11/oct/2021
 */

const KeepByProductTable = () => {
  const dispatch = useDispatch();

  // const listState = useSelector(
  //   (state: RootState) => state.keepByProduct.list ?? []
  // );

  const KeepPerProductList = useSelector(
    (state: RootState) => state.keepByProduct.keepPerProductList ?? []
  );

  const totalRowState = useSelector(
    (state: RootState) => state.keepByProduct.totalRowKeepProduct ?? 0
  );

  const [filterText, setFilterText] = useState("");
  const [list, setList] = useState<KeepByProductModel[]>([]);
  const [selectedId, setSelectedId] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  //pagination
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filterProductName, setFilterProductName] = useState("");
  const [filterVariantName, setFilterVariantName] = useState("");
  const [filterSoNumber, setFilterSoNumber] = useState("")

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {

    setFilterProductName("")
    setFilterVariantName("")
    setFilterSoNumber("")

    dispatch(
      getKeepPerProduct({
        productName: "",
        variantName: "",
        soNumber: "",
        skip: 0,
        take: perPage,
      })
    );

  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getKeepPerProduct({
        productName: filterProductName,
        variantName: filterVariantName,
        soNumber: filterSoNumber,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [KeepPerProductList]);

  useEffect(() => {
    fetchData(0, perPage);
  }, []);

  const columns = [
    {
      name: "Product Name",
      selector: "productName",
      sortable: true,
    },
    {
      name: "variation Name",
      selector: "variationName",
      sortable: true,
    },
    // {
    //   name: "SO Number",
    //   selector: "soNumber",
    //   sortable: true,
    // },
    {
      name: "Order",
      selector: "order",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { variationId: any; productName: any }) => (
        <>
          <Link
            to={{
              pathname: `/keep-per-product/detail/${d.variationId}/${d.productName}`,
            }}
          >
            <img src={edit}></img>
          </Link>
        </>
      ),
    },
  ];
  // end of datatable components

  // useEffect(() => {
  //   setList(listState);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [listState]);

  const onDelete = () => setShowAlert(false);

  const onAlertClose = () => setShowAlert(false);

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Product Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterProductName}
                    onChange={(e) => {
                      setFilterProductName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Variant Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterVariantName}
                    onChange={(e) => {
                      setFilterVariantName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
          <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  SO Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterSoNumber}
                    onChange={(e) => {
                      setFilterSoNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          {/* <InputGroup className="mb-3 padding-filter " style={{ width: 330 }}>
            <InputGroup.Prepend className="search-input">
              <InputGroup.Text
                style={{
                  backgroundColor: "#fff",
                  color: "#F5B95D",
                  border: 0,
                }}
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              style={{ borderLeft: 0 }}
              placeholder="Search"
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              value={filterText}
            />
          </InputGroup> */}

          <hr />

          <DataTable
            columns={columns}
            data={KeepPerProductList}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={list}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            selectableRows
            customStyles={TableCustomStyles}
          /> */}
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      ></DeleteMessage>
    </div>
  );
};

export default KeepByProductTable;
