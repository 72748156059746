import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {SubscriberModel, SubscriberRequestModel} from "./subscriber.model";
import { ApiErrorResponse } from "../..";
import SubscriberApi from "./subscriber.api";

export interface SubscriberSlice {
  list?: SubscriberModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const createData = createAsyncThunk(
  "subscriber/create",
  async (Model: SubscriberRequestModel, { rejectWithValue }) => {
    try {
      return await SubscriberApi.createData(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "subscriber/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await SubscriberApi.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "subscriber/update",
  async (model: SubscriberRequestModel, { rejectWithValue }) => {
    try {
      return await SubscriberApi.updateData(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "subscriber/deleteCity",
  async (id: string, { rejectWithValue }) => {
    try {
      return await SubscriberApi.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const subscriberSlice = createSlice({
  name: "SubscriberState",
  initialState: {} as SubscriberSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "pending";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
      state.status = "pending";
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
        state.status = "success";
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
        state.status = "failed";
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.status = "pending";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const { resetStatus } = subscriberSlice.actions;
export default subscriberSlice.reducer;
