import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import edit from "./../../../assets/vector/edit.png";
import hapus from "./../../../assets/vector/delete.png";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import SettingMembershipTable from "../components/SettingMembershipTable";

const SettingMembershipTablePage = () => {

  return (
    <div>
      <SettingMembershipTable/>
    </div>
  );
};

export default SettingMembershipTablePage;
