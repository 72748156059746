import React, {useEffect, useState} from 'react';
import {Container, Form} from "react-bootstrap";
import {AdditionalPageSettingEnum} from "../models";
import {getSingleAdditionalPageSetting} from "../footerSetting.reducer";
import {useDispatch} from "react-redux";
import FooterSettingForm from "../components/FooterSettingForm";

type TabModel = {
    id: string,
    name: string,
    pageTitle: string,
    type: AdditionalPageSettingEnum
}

const FooterSettingPage = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<TabModel>({
        id: 'aboutCompany', name: 'About Company', pageTitle: 'Footer About Company Setting', type: AdditionalPageSettingEnum.CompanyInfo,
    })
    const [tabs] = useState<TabModel[]>([
        {id: 'aboutCompany', name: 'About Company', pageTitle: 'Footer About Company Setting', type: AdditionalPageSettingEnum.CompanyInfo},
        {id: 'service', name: 'Information', pageTitle: 'Footer Information Setting', type: AdditionalPageSettingEnum.Service},
        {id: 'return', name: 'Return', pageTitle: 'Footer Return Setting', type: AdditionalPageSettingEnum.Return},
        {id: 'getInTouch', name: 'Get In Touch', pageTitle: 'Footer Get In Touch Setting', type: AdditionalPageSettingEnum.GetInTouch},
        {id: 'findUs', name: 'Follow Us', pageTitle: 'Footer Follow Us Setting', type: AdditionalPageSettingEnum.FindUs},
    ]);

    useEffect(() => {
        dispatch(getSingleAdditionalPageSetting(activeTab.type))
    }, [activeTab]);

    const onTabClick = (tab: TabModel) => {
        setActiveTab(tab);
    }

    return (
        <Container className="content-body information-store" fluid>
            <Form.Label className="page-title mb-3" column={"sm"}>Web Settings - Footer</Form.Label>
            <div className="box">
                <div className="tabs">
                    {
                        tabs.map((res: TabModel)=>(
                            <div key={res.id} className={activeTab.id === res.id ? 'childtab active' : 'childtab'} onClick={()=> onTabClick(res)}>
                                {res.name}
                            </div>
                        ))
                    }
                </div>
                <div>
                    <FooterSettingForm pageTitle={activeTab.pageTitle} type={activeTab.type} />
                </div>
            </div>
        </Container>
    );
};

export default FooterSettingPage;
