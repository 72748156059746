import SupplierTable from "../components/SupplierTable";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { RootState } from "../../../../app/store";
import { getList } from "../supplier.reducer";
import { useEffect } from "react";

const SupplierTablePage = () => {
  const dispatch = useDispatch();

  const State = useSelector((state: RootState) => state.supplier.name);

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <SupplierTable />
        </Col>
      </Row>
    </Container>
  );
};

export default SupplierTablePage;
