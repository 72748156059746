import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
  Container,
} from "react-bootstrap";
import SettingWarehousePartner from "../components/SettingWarehousePartner";
import SettingWarehouseAdmin from "../components/SettingWarehouseAdmin";
import SettingWarehouseEndCustomer from "../components/SettingWarehouseEndCustomer";
import SettingWarehouseStockist from "../components/SettingWarehouseStockist";

const SettingWarehousePage = () => {
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label className="title">Setting Gudang</Form.Label>
              </Form.Group>

              <Card>
                <Card.Body>
                  <Tabs>
                    <Tab eventKey="reseller" title="Reseller">
                      <TabContent>
                        <SettingWarehousePartner />
                      </TabContent>
                    </Tab>

                    <Tab eventKey="stockist" title="Stockist">
                      <TabContent>
                        <SettingWarehouseStockist />
                      </TabContent>
                    </Tab>

                    <Tab eventKey="customer" title="End Customer">
                      <TabContent>
                        <SettingWarehouseEndCustomer />
                      </TabContent>
                    </Tab>

                    <Tab eventKey="admin" title="Admin">
                      <TabContent>
                        {/* <SettingFaqForm onSubmit={onSubmit} partnerType={0} /> */}
                        <SettingWarehouseAdmin />
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingWarehousePage;
