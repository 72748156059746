import React, { useEffect, useState } from 'react'
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { getAllProductFilterAsset, postAssetProductReport } from '../AssetReport.api';
import Pagination from "react-js-pagination";
import { setByProductValues } from '../AssetReport.reducer';
import { RootState } from '../../../../app/store';

export default function AssetProductReport() {

    const dispatch = useDispatch()

    const { listProductFilterAsset } = useSelector((state: RootState) => state.assetReport)

    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)

    const initialValues = {
        filterProduct: "",
        skip: 0,
        take: "5",
        page: 1,
    }

    const [values, setValues] = useState({
        filterProduct: "",
        skip: 0,
        take: "5",
        page: 1,
    })

    useEffect(() => {
        dispatch(getAllProductFilterAsset())
    }, [])

    useEffect(() => {
        dispatch(setByProductValues(values))
        getData()
    }, [values.page, values.take, values.skip])

    const handlePerPage = (e: any) => {
        setValues({
            ...values,
            take: e.target.value,
            skip: 0,
            page: 1
        })
    }

    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
        dispatch(setByProductValues({
            ...values,
            [e.target.name]: e.target.value,
        }))
    }

    const handleReset = () => {
        setValues(initialValues)
    }

    const handlePagination = (page: any) => {
        setValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        })
        dispatch(setByProductValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        }))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setValues({
            ...values,
            skip: 0,
            page: 1,
        })
        getData()
    }

    const getData = () => {
        const data = {
            "filterProduct": values.filterProduct,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        dispatch<any>(postAssetProductReport(data))
            .then(unwrapResult)
            .then((res: any) => {
                setList(res.data.list)
                setTotal(res.data.total)
            })
            .catch((err: any) => console.log(err))
    }

    const moneyParser = (value: any) => {
        let valueString = value.toString()
        let valueList = valueString.split('')
        let resultList: any = []
        valueList.reverse().map((c: any, i: number) => {
            resultList.push(c)
            if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
                resultList.push('.')
            }
        })
        let result = resultList.reverse().join('')
        return "Rp" + result + ",00"
    }

    return (
        <>
            <div className="head w-100">
                <form onSubmit={handleSubmit}>
                    <div className="row w-100">
                        <div className="col-md-2 mb-3 ">
                            <label htmlFor="title">Nama Produk</label>
                        </div>
                        <div className="col-md-4 mb-3">
                            <input className='custext' name="filterProduct" list='filterProduct' value={values.filterProduct} onChange={handleChange} />
                            <datalist id="filterProduct">
                                {listProductFilterAsset.map((res: any, i: number) =>
                                    <option key={i} value={res.name} />
                                )}
                            </datalist>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="wrapbutfil col-md-12">
                            <Button className='addor' type='button' onClick={handleReset}>Reset</Button>
                            <Button className='addorrev' type='submit'>Filter</Button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="con">
                <div className="filter d-flex justify-content-between align-items-center">
                    Showing {list.length} of {total} results
                    <div className="result d-flex justify-content-between align-items-center">
                        <span>Results per page:</span>
                        <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                </div>
                <table className="table">
                    <thead className="">
                        <tr>
                            <th scope="col">No</th>
                            <th scope="col">Nama Produk</th>
                            <th scope="col">Jumlah QTY</th>
                            <th scope="col">Asset / Nominal Reseller</th>
                            <th scope="col">Asset / Nominal Stockist</th>
                            <th scope="col">Asset / Nominal End Customer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.map((res: any, i: number) => (
                                <>
                                    <tr key={i} style={{ fontWeight: "bold" }}>
                                        <td>
                                            {
                                                values.skip === 0 ?
                                                    i + 1
                                                    :
                                                    i + 1 + (values.page - 1) * parseInt(values.take)
                                            }
                                        </td>
                                        <td>{res.productName}</td>
                                        <td>{res.totalQty}</td>
                                        <td>{moneyParser(res.totalAssetReseller)}</td>
                                        <td>{moneyParser(res.totalAssetStockist)}</td>
                                        <td>{moneyParser(res.totalAssetEndCustomer)}</td>
                                    </tr>
                                    {res.variant.map((v: any, i: number) => (
                                        <tr key={i}>
                                            <td></td>
                                            <td>- {v.variantName}</td>
                                            <td>{v.qty}</td>
                                            <td>{moneyParser(v.totalReseller)}</td>
                                            <td>{moneyParser(v.totalStockist)}</td>
                                            <td>{moneyParser(v.totalEndCustomer)}</td>
                                        </tr>
                                    ))}
                                </>
                            ))
                        }

                    </tbody>
                </table>
                <div className="d-flex justify-content-end">
                    <Pagination
                        activePage={values.page}
                        itemClass='item'
                        itemsCountPerPage={parseInt(values.take)}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={handlePagination}
                        prevPageText='⟨ PREVIOUS'
                        itemClassPrev='prev'
                        itemClassNext='next'
                        nextPageText='NEXT ⟩'
                        hideFirstLastPages
                    />
                </div>

            </div>
        </>
    )
}
