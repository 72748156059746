import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { PaymentConfirmationSlice } from "../../master/paymentConfirmation/paymentConfirmation.reducer";
import PaymentInApi from "./paymentIn.api";
import { FilterModel, PaymentInAllocationHistoryModel, PaymentInModel } from "./paymentIn.model";

export interface PaymentInSlice {
  list?: PaymentInModel[];
  single?: PaymentInModel;
  allocationHistoryList? : PaymentInAllocationHistoryModel[];
  totalRow?: number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getListWithPaging = createAsyncThunk(
  "PaymentInState/GetListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await PaymentInApi.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "PaymentInState/GetSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await PaymentInApi.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getAllocationHistory = createAsyncThunk(
  "PaymentInState/getAllocationHistory",
  async (id: string, { rejectWithValue }) => {
    try {
      return await PaymentInApi.getAllocationHistory(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createData = createAsyncThunk(
  "PaymentInState/create",
  async (model: PaymentInModel, { rejectWithValue }) => {
    try {
      return await PaymentInApi.create(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "PaymentInState/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      return await PaymentInApi.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "PaymentInState/update",
  async (model: PaymentInModel, { rejectWithValue }) => {
    try {
      return await PaymentInApi.update(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const PaymentInSlice = createSlice({
  name: "PaymentInState",
  initialState: {} as PaymentInSlice,
  reducers: {
    resetSingle: (state) => {
      state.single = {} as PaymentInModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-create";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed-create";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-delete";
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-update";
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.single = payload;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getAllocationHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllocationHistory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.allocationHistoryList = payload;
    });
    builder.addCase(getAllocationHistory.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const { resetStatus, resetSingle } = PaymentInSlice.actions;
export default PaymentInSlice.reducer;
