import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import SettingPointRewardForm from "../components/SettingPointRewardForm";
import AlertMessage from "../../../../components/alertMessage";
import { RootState } from "../../../../app/store";
import { getPointRewardSettingList, resetSingle, updatePointRewardSetting } from "../SettingPointReward.reducer";
import {PartnerTypeEnum, PointRewardSettingModel} from "../SettingPointReward.model";

const SettingPointRewardPage = () => {
  const dispatch = useDispatch();

  const PointRewardSettingStatusState = useSelector(
    (state: RootState) => state.settingPointReward.status ?? ""
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    if(PointRewardSettingStatusState === "success"){
      dispatch(resetSingle())
    }
  };

  const onUpdate = (
    id: string,
    partnerType: number,
    isPointReward: boolean,
    pointType : number,
    shoppingAmountToPoint: number,
    qty: number,
    point: number,
    pointToShoppingAmount: number
  ) => {
    console.log("Ready to Dispatch")
    dispatch(
      updatePointRewardSetting({
        id,
        partnerType,
        isPointReward,
        pointType,
        shoppingAmountToPoint,
        qty,
        point,
        pointToShoppingAmount
      } as PointRewardSettingModel)
    );
  };

  useEffect(() => {
    if(PointRewardSettingStatusState === "success"){
      dispatch(getPointRewardSettingList())
      setAlertProps({
        variant: "success",
        title: "Berhasil Meng-update Setting Point Reward",
        content: "",
      });
      setShowAlert(true);
    }
  }, [PointRewardSettingStatusState])

  useEffect(() => {
    dispatch(getPointRewardSettingList())
  }, [])

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label className="title">Point and Reward</Form.Label>
              </Form.Group>

              <Card>
                <Card.Body>
                  <Tabs>
                    <Tab eventKey="reseller" title="Reseller">
                      <TabContent>
                        {" "}
                        <SettingPointRewardForm type={PartnerTypeEnum.Reseller} onUpdate={onUpdate}></SettingPointRewardForm>{" "}
                      </TabContent>
                    </Tab>

                    <Tab eventKey="stockist" title="Stockist">
                      <TabContent>
                        {" "}
                        <SettingPointRewardForm type={PartnerTypeEnum.Stockist} onUpdate={onUpdate}></SettingPointRewardForm>
                      </TabContent>
                    </Tab>

                    <Tab eventKey="customer" title="End Customer">
                      <TabContent>
                        {" "}
                        <SettingPointRewardForm type={PartnerTypeEnum.Customer} onUpdate={onUpdate}></SettingPointRewardForm>
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>S
            </Col>
          </Row>
        </Col>
      </Row>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    </Container>
  );
};

export default SettingPointRewardPage;
