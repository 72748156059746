import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import {
  createData,
  getCustomerSingleItem,
  getResellerSingleItem,
  getStockistSingleItem,
  updateData,
} from "../paymentConfirmation.reducer";
import { PaymentConfirmationModel } from "../paymentConfirmation.model";
import EndCustomerForm from "../components/EndCustomerForm";
import ResellerForm from "../components/ResellerForm";
import StockistForm from "../components/StockistForm";

const PaymentConfirmationPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleCustomerDataState = useSelector(
    (state: RootState) =>
      state.paymentConfirmation.singleCustomer ??
      ({} as PaymentConfirmationModel)
  );

  const singleStockistDataState = useSelector(
    (state: RootState) =>
      state.paymentConfirmation.singleStockist ??
      ({} as PaymentConfirmationModel)
  );

  const singleResellerrDataState = useSelector(
    (state: RootState) =>
      state.paymentConfirmation.singleReseller ??
      ({} as PaymentConfirmationModel)
  );

  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [isResellerEdit, setIsResellerEdit] = useState(false);
  const [isStockistEdit, setIsStockistEdit] = useState(false);

  useEffect(() => {
    if (
      singleCustomerDataState &&
      Object.keys(singleCustomerDataState).length !== 0 &&
      singleCustomerDataState.constructor === Object
    ) {
      setIsCustomerEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleCustomerDataState]);

  useEffect(() => {
    if (
      singleResellerrDataState &&
      Object.keys(singleResellerrDataState).length !== 0 &&
      singleResellerrDataState.constructor === Object
    ) {
      setIsResellerEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleCustomerDataState]);

  useEffect(() => {
    if (
      singleStockistDataState &&
      Object.keys(singleStockistDataState).length !== 0 &&
      singleStockistDataState.constructor === Object
    ) {
      setIsStockistEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStockistDataState]);

  useEffect(() => {
    dispatch(getCustomerSingleItem());
    dispatch(getStockistSingleItem());
    dispatch(getResellerSingleItem());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmitCustomer = (
    type: number,
    //isEnable: boolean,
    isEnableEmailSeller: boolean,
    emailSeller: string,
    emailSubjectSeller: string,
    emailContentSeller: string,
    isEnableEmailBuyer: boolean,
    emailBuyer: string,
    emailSubjectBuyer: string,
    emailContentBuyer: string,
    textLinkOrderNotification: string,
    urlOrderNotification: string,
    notificationOrder: string,
    //isEnableTransferWarningBox: boolean,
    transferAlertFormNotification: string,
    isEnableRedirect: boolean,
    redirectURL: string,
    successNotification: string,
    errorInvoiceIDNotification: string,
    notificationEmailErrorForm: string,
    notificationFormFileTypeError: string,
    captchaErrorNotification: string,
    isEnableCaptcha: boolean,
    reCaptchaSiteKey: string,
    reCaptchaSecretKey: string,
    bankOptionsFormSetting: string,
    orderIDFormLabel: string,
    nameLabel: string,
    emailLabel: string,
    bankLabel: string,
    labelPaymentDate: string,
    valueTransferLabel: string,
    proffOfPaymentRequired: boolean,
    recordLabels: string
  ) => {
    if (isCustomerEdit) {
      dispatch(
        updateData({
          id: singleCustomerDataState.id,
          type,
          isEnableEmailSeller,
          emailSeller,
          emailSubjectSeller,
          emailContentSeller,
          isEnableEmailBuyer,
          emailBuyer,
          emailSubjectBuyer,
          emailContentBuyer,
          textLinkOrderNotification,
          urlOrderNotification,
          notificationOrder,
          //isEnableTransferWarningBox: boolean,
          transferAlertFormNotification,
          isEnableRedirect,
          redirectURL,
          successNotification,
          errorInvoiceIDNotification,
          notificationEmailErrorForm,
          notificationFormFileTypeError,
          captchaErrorNotification,
          isEnableCaptcha,
          reCaptchaSiteKey,
          reCaptchaSecretKey,
          bankOptionsFormSetting,
          orderIDFormLabel,
          nameLabel,
          emailLabel,
          bankLabel,
          labelPaymentDate,
          valueTransferLabel,
          proffOfPaymentRequired,
          recordLabels,
        } as PaymentConfirmationModel)
      );
    } else {
      dispatch(
        createData({
          type,
          isEnableEmailSeller,
          emailSeller,
          emailSubjectSeller,
          emailContentSeller,
          isEnableEmailBuyer,
          emailBuyer,
          emailSubjectBuyer,
          emailContentBuyer,
          textLinkOrderNotification,
          urlOrderNotification,
          notificationOrder,
          //isEnableTransferWarningBox: boolean,
          transferAlertFormNotification,
          isEnableRedirect,
          redirectURL,
          successNotification,
          errorInvoiceIDNotification,
          notificationEmailErrorForm,
          notificationFormFileTypeError,
          captchaErrorNotification,
          isEnableCaptcha,
          reCaptchaSiteKey,
          reCaptchaSecretKey,
          bankOptionsFormSetting,
          orderIDFormLabel,
          nameLabel,
          emailLabel,
          bankLabel,
          labelPaymentDate,
          valueTransferLabel,
          proffOfPaymentRequired,
          recordLabels,
        } as PaymentConfirmationModel)
      );
    }
  };

  const onSubmitReseller = (
    type: number,
    //isEnable: boolean,
    isEnableEmailSeller: boolean,
    emailSeller: string,
    emailSubjectSeller: string,
    emailContentSeller: string,
    isEnableEmailBuyer: boolean,
    emailBuyer: string,
    emailSubjectBuyer: string,
    emailContentBuyer: string,
    textLinkOrderNotification: string,
    urlOrderNotification: string,
    notificationOrder: string,
    //isEnableTransferWarningBox: boolean,
    transferAlertFormNotification: string,
    isEnableRedirect: boolean,
    redirectURL: string,
    successNotification: string,
    errorInvoiceIDNotification: string,
    notificationEmailErrorForm: string,
    notificationFormFileTypeError: string,
    captchaErrorNotification: string,
    isEnableCaptcha: boolean,
    reCaptchaSiteKey: string,
    reCaptchaSecretKey: string,
    bankOptionsFormSetting: string,
    orderIDFormLabel: string,
    nameLabel: string,
    emailLabel: string,
    bankLabel: string,
    labelPaymentDate: string,
    valueTransferLabel: string,
    proffOfPaymentRequired: boolean,
    recordLabels: string
  ) => {
    if (isResellerEdit) {
      dispatch(
        updateData({
          id: singleResellerrDataState.id,
          type,
          isEnableEmailSeller,
          emailSeller,
          emailSubjectSeller,
          emailContentSeller,
          isEnableEmailBuyer,
          emailBuyer,
          emailSubjectBuyer,
          emailContentBuyer,
          textLinkOrderNotification,
          urlOrderNotification,
          notificationOrder,
          //isEnableTransferWarningBox: boolean,
          transferAlertFormNotification,
          isEnableRedirect,
          redirectURL,
          successNotification,
          errorInvoiceIDNotification,
          notificationEmailErrorForm,
          notificationFormFileTypeError,
          captchaErrorNotification,
          isEnableCaptcha,
          reCaptchaSiteKey,
          reCaptchaSecretKey,
          bankOptionsFormSetting,
          orderIDFormLabel,
          nameLabel,
          emailLabel,
          bankLabel,
          labelPaymentDate,
          valueTransferLabel,
          proffOfPaymentRequired,
          recordLabels,
        } as PaymentConfirmationModel)
      );
    } else {
      dispatch(
        createData({
          type,
          isEnableEmailSeller,
          emailSeller,
          emailSubjectSeller,
          emailContentSeller,
          isEnableEmailBuyer,
          emailBuyer,
          emailSubjectBuyer,
          emailContentBuyer,
          textLinkOrderNotification,
          urlOrderNotification,
          notificationOrder,
          //isEnableTransferWarningBox: boolean,
          transferAlertFormNotification,
          isEnableRedirect,
          redirectURL,
          successNotification,
          errorInvoiceIDNotification,
          notificationEmailErrorForm,
          notificationFormFileTypeError,
          captchaErrorNotification,
          isEnableCaptcha,
          reCaptchaSiteKey,
          reCaptchaSecretKey,
          bankOptionsFormSetting,
          orderIDFormLabel,
          nameLabel,
          emailLabel,
          bankLabel,
          labelPaymentDate,
          valueTransferLabel,
          proffOfPaymentRequired,
          recordLabels,
        } as PaymentConfirmationModel)
      );
    }
  };

  const onSubmitStockist = (
    type: number,
    //isEnable: boolean,
    isEnableEmailSeller: boolean,
    emailSeller: string,
    emailSubjectSeller: string,
    emailContentSeller: string,
    isEnableEmailBuyer: boolean,
    emailBuyer: string,
    emailSubjectBuyer: string,
    emailContentBuyer: string,
    textLinkOrderNotification: string,
    urlOrderNotification: string,
    notificationOrder: string,
    //isEnableTransferWarningBox: boolean,
    transferAlertFormNotification: string,
    isEnableRedirect: boolean,
    redirectURL: string,
    successNotification: string,
    errorInvoiceIDNotification: string,
    notificationEmailErrorForm: string,
    notificationFormFileTypeError: string,
    captchaErrorNotification: string,
    isEnableCaptcha: boolean,
    reCaptchaSiteKey: string,
    reCaptchaSecretKey: string,
    bankOptionsFormSetting: string,
    orderIDFormLabel: string,
    nameLabel: string,
    emailLabel: string,
    bankLabel: string,
    labelPaymentDate: string,
    valueTransferLabel: string,
    proffOfPaymentRequired: boolean,
    recordLabels: string
  ) => {
    if (isStockistEdit) {
      dispatch(
        updateData({
          id: singleStockistDataState.id,
          type,
          isEnableEmailSeller,
          emailSeller,
          emailSubjectSeller,
          emailContentSeller,
          isEnableEmailBuyer,
          emailBuyer,
          emailSubjectBuyer,
          emailContentBuyer,
          textLinkOrderNotification,
          urlOrderNotification,
          notificationOrder,
          //isEnableTransferWarningBox: boolean,
          transferAlertFormNotification,
          isEnableRedirect,
          redirectURL,
          successNotification,
          errorInvoiceIDNotification,
          notificationEmailErrorForm,
          notificationFormFileTypeError,
          captchaErrorNotification,
          isEnableCaptcha,
          reCaptchaSiteKey,
          reCaptchaSecretKey,
          bankOptionsFormSetting,
          orderIDFormLabel,
          nameLabel,
          emailLabel,
          bankLabel,
          labelPaymentDate,
          valueTransferLabel,
          proffOfPaymentRequired,
          recordLabels,
        } as PaymentConfirmationModel)
      );
    } else {
      dispatch(
        createData({
          type,
          isEnableEmailSeller,
          emailSeller,
          emailSubjectSeller,
          emailContentSeller,
          isEnableEmailBuyer,
          emailBuyer,
          emailSubjectBuyer,
          emailContentBuyer,
          textLinkOrderNotification,
          urlOrderNotification,
          notificationOrder,
          //isEnableTransferWarningBox: boolean,
          transferAlertFormNotification,
          isEnableRedirect,
          redirectURL,
          successNotification,
          errorInvoiceIDNotification,
          notificationEmailErrorForm,
          notificationFormFileTypeError,
          captchaErrorNotification,
          isEnableCaptcha,
          reCaptchaSiteKey,
          reCaptchaSecretKey,
          bankOptionsFormSetting,
          orderIDFormLabel,
          nameLabel,
          emailLabel,
          bankLabel,
          labelPaymentDate,
          valueTransferLabel,
          proffOfPaymentRequired,
          recordLabels,
        } as PaymentConfirmationModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row style={{ margin: 0 }}>
        <Col md={12}>
          <Tabs
            style={{
              backgroundColor: "white",
              padding: 10,
              border: "1px solid rgba(0,0,0,.125)",
              color: "black",
              borderRadius: ".50rem",
            }}
            variant="pills"
            defaultActiveKey="customer"
            className="mb-3"
          >
            <Tab eventKey="customer" title="End Customer">
              <EndCustomerForm
                onSubmit={onSubmitCustomer}
                data={singleCustomerDataState}
                isEdit={isCustomerEdit}
              />
            </Tab>

            <Tab eventKey="reseller" title="Reseller">
              <ResellerForm
                onSubmit={onSubmitReseller}
                data={singleResellerrDataState}
                isEdit={isResellerEdit}
              />
            </Tab>
            <Tab eventKey="stockist" title="Stockist">
              <StockistForm
                onSubmit={onSubmitStockist}
                data={singleStockistDataState}
                isEdit={isStockistEdit}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentConfirmationPage;
