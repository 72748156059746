import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StockOpnameForm from "../components/StockOpnameForm";
import { StockOpnameModel, SingleStockOpnameModel } from "../stockOpname.model";
import { RootState } from "../../../../app/store";
import { getSingleDataStockOpname } from "../stockOpname.reducer";

const StockOpnamePage = () => {

    const dispatch = useDispatch();

    let { id } = useParams<{ id: string }>();

    const singleDataState = useSelector(
        (state: RootState) =>
            state.stockOpname.single ?? ({} as SingleStockOpnameModel)
    );

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (
            singleDataState &&
            Object.keys(singleDataState).length !== 0 &&
            singleDataState.constructor === Object
        ) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleDataState]);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleDataStockOpname(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    
    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <StockOpnameForm
                                data={singleDataState}
                                isEdit={isEdit}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default StockOpnamePage
