import '../AssetReport.scss'
import { Container, Button } from "react-bootstrap";
import { FiPrinter } from 'react-icons/fi';
import { FaFileExport } from 'react-icons/fa';
import AssetProductReport from '../components/AssetProductReport'
import AssetCategoryReport from '../components/AssetCategoryReport'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { postExportAssetCategory, postExportAssetProduct } from '../AssetReport.api';
import { unwrapResult } from '@reduxjs/toolkit';

export default function AssetReport() {

    const dispatch = useDispatch()

    const { byProductValues, byCategoryValues } = useSelector((state: RootState) => state.assetReport)

    const [activeTab, setActiveTab] = useState(0)

    const handleExportExcel = () => {
        if (activeTab === 0) {
            const data = {
                filterProduct: byProductValues.filterProduct,
                skip: byProductValues.skip,
                take: parseInt(byProductValues.take)
            }
            dispatch<any>(postExportAssetProduct(data))
                .then(unwrapResult)
                .then((res: any) => {
                    const url = URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Laporan Aset by Product.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err: any) => console.log(err))
        }
        else if (activeTab === 1) {
            const data = {
                categoryName: byCategoryValues.categoryName,
                skip: byCategoryValues.skip,
                take: parseInt(byCategoryValues.take)
            }
            dispatch<any>(postExportAssetCategory(data))
                .then(unwrapResult)
                .then((res: any) => {
                    const url = URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Laporan Aset by Category.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err: any) => console.log(err))
        }
    }

    const handlePrint = () => {
        if (activeTab === 0) {
            let params =  "?filterProduct=" + byProductValues.filterProduct +
                "&skip=" + byProductValues.skip +
                "&take=" + byProductValues.take
            const newWindow = window.open('/print/assetproductreport' + params)
            if (newWindow) newWindow.opener = null
        }
        else if (activeTab === 1) {
            let params = "?categoryName=" + byCategoryValues.categoryName +
                "&skip=" + byCategoryValues.skip +
                "&take=" + byCategoryValues.take
            const newWindow = window.open('/print/assetcategoryreport' + params)
            if (newWindow) newWindow.opener = null
        }
    }

    return (
        <Container className="content-body asset-report" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                Laporan Aset
                <div>
                    <Button className='addor' onClick={handleExportExcel}><FaFileExport /> Export to Excel</Button>
                    <Button className='addor' onClick={handlePrint}><FiPrinter /> Print</Button>
                </div>
            </div>
            <div className="box">
                <div className="tabs">
                    <div className={activeTab === 0 ? 'childtab active' : 'childtab'} onClick={() => setActiveTab(0)}>By Product</div>
                    <div className={activeTab === 1 ? 'childtab active' : 'childtab'} onClick={() => setActiveTab(1)}>By Category</div>
                </div>
                {activeTab === 0
                    ? (
                        <AssetProductReport />
                    )
                    : (
                        activeTab === 1
                            ? (
                                <AssetCategoryReport />
                            )
                            : null
                    )
                }

            </div>
        </Container>
    )
}
