import axios from "axios";
import { endpoint } from "../../../constants";
import { SalesOrderModel } from "./jatuhTempoDropship/jatuhTempoDropship.model";
import { FilterModel, ShipmentModel } from "./model";

export default class Api {
  static getSingleShipment(id: string) {
    return axios
      .get<any>(endpoint + "/api/Shipment/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static updateShipment(model: ShipmentModel) {
    return axios.post<any>(endpoint + "/api/shipment/update", model).then((res) => res.data);
  }

  static updateSalesOrder(model: SalesOrderModel) {
    return axios.post<any>(endpoint + "/api/SalesOrder/Update", model).then((res) => res.data);
  }

  static getListKeepUnpaid(model: FilterModel) {
    return axios.post<any>(endpoint + "/api/SalesOrder/GetListKeepUnpaid", model).then((res) => res.data.data);
  }
}
