import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { AllStockReportModel, FilterModel, KartuStockModel, SummaryDataModel, VariantListModel } from "./laporanKartuStock.model";
import Api from "./laporanKartuStock.api";
import { StaticRouter } from "react-router-dom";

export interface LaporanKartuStockSlice{ 
  allStockReport? : AllStockReportModel[];
 list? : KartuStockModel[];
 summaryData? : SummaryDataModel;
 totalRow? : number;
 isLoading?: boolean;
 error?: ApiErrorResponse<any>;
 status?: string;
}

export const getLaporanKartuStock = createAsyncThunk(
 'getLaporanKartuStockState/getLaporanKartuStock',
 async ( model : FilterModel, {rejectWithValue}) => {
     try {
         return await Api.getListWithPaging(model);
     } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
     }
 }
);

export const exportToExcel = createAsyncThunk(
  'ExpeditionReportExportToExcelState/ExpeditionReportExportToExcel',
  async ( model : FilterModel, {rejectWithValue}) => {
      try {
          return await Api.exportToExcel(model);
      } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
      }
  }
);

const LaporanKartuStockSlice = createSlice({
 name: "LaporanKartuStockState",
 initialState: {} as LaporanKartuStockSlice,
 reducers: {
   resetStatus: (state) => {
     state.status = "";
   },
   resetList: (state) => {
     state.list = [] as  KartuStockModel[];
     state.summaryData = {} as SummaryDataModel;
     state.allStockReport = [] as AllStockReportModel[];
   }
 },
 extraReducers: (builder) => {
   builder.addCase(getLaporanKartuStock.pending, (state) => {
     state.isLoading = true;
   });
   builder.addCase(getLaporanKartuStock.fulfilled, (state, { payload }) => {
    state.allStockReport = payload;
     state.list = payload.list;
     state.summaryData = payload.summary;
     state.totalRow = payload.total;
     state.isLoading = false;
   });
   builder.addCase(getLaporanKartuStock.rejected, (state, { payload }) => {
     state.error = payload as ApiErrorResponse<any>;
     state.isLoading = false;
   });
}
});

export const { resetStatus,resetList } = LaporanKartuStockSlice.actions;
export default LaporanKartuStockSlice.reducer;