import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getSlideShow = createAsyncThunk('get/getSlideShow', async () => {
    const response = await axios.get(`${endpoint}/api/SlideShow/GetList`)
    console.log(response)
    return response.data
})

export const getSlideShowDetail = createAsyncThunk('get/getSlideShowDetail', async (id: string) => {
    try {
        const response = await axios.get(`${endpoint}/api/SlideShow/GetSingleItem`, {
            params: {
                id: id
            }
        })
        // console.log(response.data.data, 'detail')
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postSlideShow = createAsyncThunk('post/slideShow', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/SlideShow/Create`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postImageUpload = createAsyncThunk('upload/imagesConvert', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/web/api/Upload/UploadImage`, body)
        return response
    }
    catch (error: any) {
        return error.response
    }
})

export const updateSlideShow = createAsyncThunk('api/update/slideShow', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/SlideShow/Update`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const deleteSlideShow = createAsyncThunk('delete/slideShow', async (id: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/SlideShow/Delete`, {}, {
            params: {
                id: id
            }
        })
        console.log(response)
        return response
    }
    catch (error: any) {
        return error.response
    }
})