import React, { useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    Form,
    Card,
    Tabs,
    Tab,
    TabContent,
  } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import SettingStockForm from '../components/SettingStockForm';
import { RootState } from "../../../../app/store";
import { getStockSettingList, resetSingle, updateStockSetting } from '../SettingStock.reducer';
import { StockSettingModel } from '../SettingStock.model';
import AlertMessage from '../../../../components/alertMessage';

const SettingStock = () => {

  const dispatch = useDispatch();

  const StockSettingStatusState = useSelector(
    (state: RootState) => state.settingStock.status ?? ""
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });


  const onUpdate = ( 
    id  : string, 
    type : number, 
    settingType : number , 
    limitStockSetting : string) => {
      dispatch(updateStockSetting({
        id,
        type,
        settingType,
        limitStockSetting
      } as StockSettingModel
      ))
    }

    const onAlertClose = () =>{
      setShowAlert(false)
      if(StockSettingStatusState === "success"){
        dispatch(resetSingle())
      }
    }

  useEffect(() => {
    if(StockSettingStatusState === "success"){
      dispatch(getStockSettingList())
      setAlertProps({
        variant: "success",
        title: "Berhasil Meng-update Setting Stock",
        content: "",
      });
      setShowAlert(true);
    }
  }, [StockSettingStatusState])

  useEffect(() => {
    dispatch(getStockSettingList())
  }, [])

    return (
        <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label className="title">Stock</Form.Label>
              </Form.Group>

              <Card>
                <Card.Body>
                  <Tabs>
                    <Tab eventKey="reseller" title="Reseller">
                      <TabContent>
                          <SettingStockForm type={1} onUpdate={onUpdate}></SettingStockForm>
                      </TabContent>
                    </Tab>

                    <Tab eventKey="stockist" title="Stockist">
                      <TabContent>
                      <SettingStockForm type={0} onUpdate={onUpdate}></SettingStockForm>
                      </TabContent>
                    </Tab>

                    <Tab eventKey="customer" title="End Customer">
                      <TabContent>
                      <SettingStockForm type={2} onUpdate={onUpdate}></SettingStockForm>
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}></AlertMessage>
    
    </Container>
    )
}

export default SettingStock
