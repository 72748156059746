import axios from "axios";
import { endpoint } from "../../constants";
import { FilterModel, PartnerRegisterModel, PartnerRequest, PartnerStore, RejectionModel, UploadImageModel } from "./registerStockist.model";

export default class RegisterStockistApi {

    static getStockistList() {
        return axios
            .get<any>(endpoint + "/api/Partner/GetListByType?type=1")
            .then((res) => res.data.data);
    }

    static getCustomerListWithPaging(model: FilterModel) {
        return axios
          .post<any>(endpoint + "/api/partner/GetListWithFilter", model)
          .then((res) => res.data.data);
      }

    static getSingleStockistData(partnerId: string) {
        return axios
        .get<any>(endpoint + "/api/partner/GetSingleItem?Id=" + partnerId)
        .then((res) => res.data);
    }

    static registerReseller(model: PartnerRegisterModel) {
        return axios
            .post<any>(endpoint + "/api/Partner/Register", model)
            .then((res) => res.data.data);
    }

    static uploadImage(model: UploadImageModel) {
        return axios
            .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
            .then((res) => res.data);
    }

    static getProvince() {
        return axios
            .get<any>(endpoint + "/api/Address/GetProvinceByCountry?Id=76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7")
            .then((res) => res.data.data);
    }

    static getCity(provinceId: string) {
        return axios
            .get<any>(endpoint + "/api/Address/GetCityByProvince?Id=" + provinceId)
            .then((res) => res.data.data);
    }

    static getDistrict(cityId: string) {
        return axios
            .get<any>(endpoint + "/api/Address/GetDistrictByCity?Id=" + cityId)
            .then((res) => res.data.data);
    }

    static getPartnerStoreList(partnerId: string) {
        return axios
            .get<any>(endpoint + "/api/PartnerStore/GetListByPartner=" + partnerId)
            .then((res) => res.data.data);
    }

    static createNewPartnerStore(partnerId: string) {
        return axios
            .get<any>(endpoint + "/api/PartnerStore/Create=" + partnerId)
            .then((res) => res.data.data);
    }

    static deletePartnerStore(storeId: string) {
        return axios
            .get<any>(endpoint + "/api/PartnerStore/Delete?Id=" + storeId)
            .then((res) => res.data.data);
    }

    static updatePartnerStore(model: PartnerStore) {
        return axios.post<any>(endpoint + "/api/PartnerStore/Update", model)
            .then((res) => res.data.data);
    }

    static getStoreTypeEnumList() {
        return axios
            .get<any>(endpoint + "/api/PartnerStore/GetStoreTypeEnumList")
            .then((res) => res.data.data);
    }

    static getSocialMediaEnumList() {
        return axios
            .get<any>(endpoint + "/api/PartnerStore/GetSocialMediaEnumList")
            .then((res) => res.data.data);
    }

    static approveStockist(partnerId: string) {
        return axios
            .post<any>(endpoint + "/api/Partner/Approve?PartnerId=" + partnerId)
            .then((res) => [
                { response: res.data.data },
            ]);
    }

    static rejectStockist(rejectionModel : RejectionModel) {
        return axios
            .post<any>(endpoint + "/api/Partner/Reject?PartnerId=" + rejectionModel.id + "&reason=" + rejectionModel.reason)
            .then((res) => [
                { response: res.data.data },
            ]);
    }

    static deleteStockist(partnerId: string) {
        return axios
            .post<any>(endpoint + "/api/Partner/Delete?Id=" + partnerId)
            .then((res) => [
                { response: res.data.data },
            ]);
    }

    static updatePartner(model: PartnerRequest) {
        return axios.post<any>(endpoint + "/api/Partner/Update", model)
            .then((res) => res.data.data);
    }

    static createPartnerStore(model: PartnerStore) {
        return axios.post<any>(endpoint + "/api/PartnerStore/Create", model)
            .then((res) => res.data.data);
    }

    static getPartnerStoreByPartnerId(partnerId: string) {
        return axios
            .get<any>(endpoint + "/api/PartnerStore/GetListByPartner?partnerId=" + partnerId)
            .then((res) => res.data.data);
    }

}

