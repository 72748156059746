import { Card, Form, Row, Col, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import hapus from "./../../../../assets/vector/delete.png";
import { ChangeEvent, useEffect, useState } from "react";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { DistrictModel } from "../district.model";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import {
  deleteData,
  getCity,
  getList,
  getProvince,
  getSingleItem,
  updateData,
} from "../district.reducer";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const DistrictTable = ({
  data = {} as DistrictModel,
  isEdit = false,
  ...props
}: DistrictFormProps) => {
  const dispatch = useDispatch();

  const DistrictState = useSelector((state: RootState) => state.district.list ?? []);

  const provinceState = useSelector((state: RootState) => state.district.listProvince ?? []);

  const cityState = useSelector((state: RootState) => state.district.listCity ?? []);

  const dateChangesState = useSelector((state: RootState) => state.district.dateChanges ?? "");

  const districtSingleData = useSelector(
    (state: RootState) => state.district.single ?? ({} as DistrictModel)
  );

  useEffect(() => {
    dispatch(getProvince());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // datatable components
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [no, setNo] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onProvinceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProvince(e.target.value);

    dispatch(getCity(e.target.value));
  };

  const onCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
  };

  const filteredItems = DistrictState.filter(
    (item) =>
      (item.provinceName && item.provinceName.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.cityName && item.cityName.toLowerCase().includes(filterText.toLowerCase()))
  );

  const columns = [
    {
      name: "PROVINCE",
      selector: "provinceName",
      sortable: true,
    },
    {
      name: "CITY",
      selector: "cityName",
      sortable: true,
    },
    {
      name: "NAME",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "ACTION",
    //   selector: (d: { id: any }) => (
    //     <>
    //       <span
    //         onClick={() => {
    //           setShow(true);
    //           onClickEditbutton(d.id);
    //         }}
    //       >
    //         <img src={edit}></img>
    //       </span>
    //       {"    "}
    //       <span
    //         onClick={() => {
    //           if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
    //         }}
    //       >
    //         <img src={hapus}></img>
    //       </span>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setName(data.name);
      setProvince(data.provinceId);
      dispatch(getCity(data.provinceId));
      setCity(data.cityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (dateChangesState !== "") {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateChangesState]);

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateChangesState]);

  const onEdit = () => {
    dispatch(
      updateData({
        id: districtSingleData.id,
        name: name,
        no: no,
        cityId: city,
        isActive,
      } as DistrictModel)
    );
  };

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  const onClickEditbutton = (id: string) => {
    dispatch(getSingleItem(id));
  };

  const onCloseAlert = () => setShowAlert(false);

  const handleClose = () => {
    setCity("");
    setProvince("");
    setName("");
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master District</Form.Label>
          </Form.Group>
        </Col>
        {/* <Col>
          <Button
            className="float-right"
            variant="info"
            onClick={handleShow}
            style={{ width: 150 }}
          >
            + Add District
          </Button>
        </Col> */}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={4}>
              <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add District</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                id="districtForm"
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    e.preventDefault();
                    if (districtSingleData.id === undefined) {
                      props.onSubmit(no, name, city, isActive);
                      setName("");
                      setCity("");
                      setProvince("");
                    } else {
                      onEdit();
                    }
                  }
                  setValidated(true);
                }}
              >
                <Form.Group className="input-padding">
                  <Form.Label>Province</Form.Label>
                  <Form.Control as="select" onChange={onProvinceChange} value={province}>
                    <option>select</option>
                    {provinceState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>City</Form.Label>
                  <Form.Control as="select" onChange={onCityChange} value={city}>
                    <option>select</option>
                    {cityState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="input-padding">
                  <Form.Label>District Name</Form.Label>

                  <Form.Control type="text" onChange={onNameChange} value={name} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-info" style={{ width: 100 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="info" form="districtForm" type="submit" style={{ width: 100 }}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </Grid>
    </div>
  );
};

export interface DistrictFormProps {
  onSubmit: (no: number, cityId: string, name: string, isActive: boolean) => void;
  data: DistrictModel;
  isEdit: boolean;
}

export default DistrictTable;
