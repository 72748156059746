import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getAllCustomerFilterAccountReceivable = createAsyncThunk('get/customer/All', async () => {
    const response = await axios.get(`${endpoint}/api/Partner/GetList`)
    return response.data
})

export const getAllProductFilterAccountReceivable = createAsyncThunk('get/product/All', async () => {
    const response = await axios.get(`${endpoint}/api/Product/GetList`)
    return response.data
})

export const postExportAccountReceivable = createAsyncThunk('post/postExportAccountReceivable', async (body:any) => {
    try {
        const response = await axios({
            url : `${endpoint}/api/Report/ReportPiutangExportToExcel`, 
            data: body,
            method:'post',
            responseType: 'blob', 
        })
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})

export const postAccountReceivableReport= createAsyncThunk('post/postAccountReceivableReport', async (body:any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportPiutang`, body)
        return response.data
    } 
    catch (error:any) {
        return error.response
    }
})