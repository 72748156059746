import "react-dragswitch/dist/index.css";
import AlertMessage from "../../../../components/alertMessage";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { resetSingle, resetStatus } from "../bank.reducer";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { DragSwitch } from "react-dragswitch";
import { BankModel } from "../bank.model";
import { Grid } from "semantic-ui-react";

const BankForm = ({ data = {} as BankModel, isEdit = false, ...props }: BankFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const createStatus = useSelector((state: RootState) => state.bank.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.bank.error ?? "");

  let history = useHistory();
  const [name, setName] = useState("");
  // const [accountNumber, setAccountNumber] = useState("");
  // const [accountName, setAccountName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  // const onAccountNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setAccountNumber(e.target.value);
  // };

  // const onAccountNameChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
  //   setAccountName(e.target.value);
  // };

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setName(data.name);
      // setAccountNumber(data.accountNumber);
      // setAccountName(data.accountName);
      setIsActive(data.isActive);
    } else {
      setName("");
      // setAccountNumber("");
      // setAccountName("");
      setIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      //history.replace("/bank/index");
    } else {
      dispatch(resetStatus());
    }
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">Add Master Bank</Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div className="">Add Master Bank</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(name, isActive);
                  setName("");
                  // setAccountName("");
                  // setAccountNumber("");
                  history.replace("/bank/index");
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" required onChange={onNameChange} value={name} />
                </Col>
              </Form.Group>
              <hr />
              {/* <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Account Number
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    required
                    onChange={onAccountNumberChange}
                    value={accountNumber}
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Account Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    required
                    onChange={onAccountNameChange}
                    value={accountName}
                  />
                </Col>
              </Form.Group>
              <hr /> */}
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Active
                </Form.Label>
                <Col sm={4}>
                  <DragSwitch
                    checked={isActive}
                    onChange={(e) => {
                      setIsActive(e);
                    }}
                  />
                  <br />
                  <Form.Label style={{ paddingTop: 10 }} className="card-subtitle">
                    *Bank Activation Status
                  </Form.Label>
                </Col>
              </Form.Group>
              <hr />
              <Row>
                <Col sm={6}>
                  <Link to={{ pathname: `/bank/index` }}>
                    <Button className="btn-cancel" variant="light">
                      Cancel
                    </Button>
                    {"  "}
                  </Link>
                  <Button variant="info" style={{ width: 135, height: 46 }} type="submit">
                    Save
                  </Button>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
    </div>
  );
};

export interface BankFormProps {
  onSubmit: (name: string, isActive: boolean) => void;
  data: BankModel;
  isEdit: boolean;
}

export default BankForm;
