import { Container, Form } from "react-bootstrap";
import PesananRekapTable from "../components/pesananRekapTable";

/*
 * created by : dikembe mutombo
 * created date : 28/oct/2021
 */

const PesananRekapIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan Rekap</Form.Label>
      </Form.Group>
      <PesananRekapTable />
    </Container>
  );
};

export default PesananRekapIndex;
