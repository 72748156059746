export interface KeepBelumLunasModel {
    id: string;
    salesOrderId: string;
    partnerId: string;
    orderId: string;
    salesOrderDetailId: string;
    customerName: string;
    variationId: string;
    variationName: string;
    productId: string;
    productName: string;
    productCode: string;
    quantity: number;
    orderDate: string;
    subTotal: number;
    grandTotal: number;
    qtyTotal: number;
    totalTax: number;
    adminFee: number;
    note: string;
    discountId: string;
    soNumber: string;
    statusOrder: number;
    productType: number;
    discount: number;
    price: number;
    contractStockistApproval: number;
    partnerType: number;
    paymentStatus: number;
    shippingStatus: number;
    inventorySKU: string;
    limitApprovalStatus: number;
}

export enum PartnerTypeEnum
{
    Customer,
    Stockist,
    Reseller,
    Admin,
    AdminStockist,
    None
}

export interface TemporaryShipmentDetail {
    id: string;
    variationId: string;
    variationName: string;
    productName: string;
    productCode: string;
    customProductAttributeList: string;
    note: null;
    price: number;
    subtotal: number;
    qty: number;
    temporaryShipmentId: string;
}

export interface TemporaryShipmentModel {
    id: string;
    senderName: string | null;
    recipientName: string | null;
    recipientAddress: string | null;
    recipientPostalCode: string | null;
    recipientPhone: string | null;
    recipientAddressId: string;
    recipientProvinceId: string;
    recipientCityId: string;
    recipientDistrictId: string;
    paymentMethodId: string;
    statusOrder: number;
    expeditionId: string;
    service: string | null;
    shipmentCost: number;
    productName: string | null;
    inventorySKU: string;
    discountId: string | null;
    partnerId: string;
    partnerName: string;
    partnerType: number;
    contractDiscount: number;
    contractStockistDiscount: number;
    contractStockistFreeShippingDiscount: number;
    note: string | null;
    resiNumber: string | null;
    walletPoint: number;
    productCount: string | null;
    createdDate: string;
    cashlessCourier: string | null;
    cashlessAddress: string | null;
    cashlessExpedition: string | null;
    temporaryShipmentDetail: TemporaryShipmentDetail[];
    rajaOngkirResult: string | null;
    barcodeImage: string | null;
}

export interface TemporaryShipmentRequest {
    partnerTypeEnum: PartnerTypeEnum | null | undefined;
    partnerName: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    productSKU: string | undefined;
    skip: number;
    take: number;
}

