import { Link, useHistory, useParams } from "react-router-dom";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { getBankList, resetSingle, resetStatus } from "../supplier.reducer";
import AlertMessage from "../../../../components/alertMessage";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { SupplierModel } from "../supplier.model";
import { DragSwitch } from "react-dragswitch";
import { Grid } from "semantic-ui-react";
import Select from "antd/lib/select";


const SupplierForm = ({
  data = {} as SupplierModel,
  isEdit = false,
  ...props
}: SupplierFormProps) => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  const createStatus = useSelector((state: RootState) => state.supplier.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.supplier.error ?? "");

  const bankListState = useSelector((state:RootState)=> state.supplier.bankList ?? [])

  let history = useHistory();
  const [name, setName] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [supplierCode, setSupplierCode] = useState("");
  const [address, setAddress] = useState("");
  const [bankId, setBankId] = useState("");
  const [bank, setBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onSupplierCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSupplierCode(e.target.value);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onPhoneNumberChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTelephoneNumber(e.target.value);
  };

  const onAddressChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAddress(e.target.value);
  };

  const onBankChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBank(e.target.value);
  };

  const handleBankIdChange = (e: any) => {
    setBankId(e);
  };

  const onaccountNumberChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAccountNumber(e.target.value);
  };

  useEffect(() => {
    dispatch(getBankList());
  }, [])
  

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setSupplierCode(data.supplierCode);
      setName(data.name);
      setTelephoneNumber(data.telephoneNumber);
      setAddress(data.address);
      setBank(data.bank);
      setAccountNumber(data.accountNumber);
      setIsActive(data.isActive);
    } else {
      setSupplierCode("");
      setName("");
      setTelephoneNumber("");
      setAddress("");
      setBank("");
      setAccountNumber("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/supplier/index");
    } else {
      dispatch(resetStatus());
    }
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">
          {isEdit ? "Edit Data Supplier" : "Add Data Supplier"}
        </Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div>{isEdit ? "Edit Data Supplier" : "Add Data Supplier"}</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(
                    supplierCode,
                    name,
                    bankId,
                    accountNumber,
                    telephoneNumber,
                    address,
                    isActive
                  );
                }
                setValidated(true);
              }}
            >
              <hr />
              {isEdit === false ? (
                <></>
              ) : (
                <>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Supplier Code
                    </Form.Label>
                    <Col sm={5}>
                      <Form.Control
                        type="text"
                        required
                        onChange={onSupplierCodeChange}
                        value={supplierCode}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              )}
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Supplier Name
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onNameChange} value={name} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Bank
                </Form.Label>
                <Col sm={5}>
                  {/* <Form.Control type="text" required onChange={onBankChange} value={bank} /> */}

                  <Select
                    value={bankId}
                    onChange={handleBankIdChange}
                    style={{ width: "100%" }}
                  >
                    {bankListState.map((item) => (
                      <Select.Option value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>

                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Account Number
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    required
                    onChange={onaccountNumberChange}
                    value={accountNumber}
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Telephone
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    type="text"
                    required
                    onChange={onPhoneNumberChange}
                    value={telephoneNumber}
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Address
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    onChange={onAddressChange}
                    value={address}
                  />
                </Col>
              </Form.Group>
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}

              <div className="btn-footer-group">
                <Link to={{ pathname: `/supplier/index` }}>
                  <Button className="btn-cancel" variant="light">
                    Cancel
                  </Button>
                  {"  "}
                </Link>
                <Button variant="info" style={{ width: 130, height: 45 }} type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
    </div>
  );
};

export interface SupplierFormProps {
  onSubmit: (
    name: string,
    bankId: string,
    accountNumber: string,
    telephoneNumber: string,
    address: string,
    supplierCode: string,
    isActive: boolean
  ) => void;
  data: SupplierModel;
  isEdit: boolean;
}

export default SupplierForm;
