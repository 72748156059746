
export const transactionStatus = [
    {
        value: 'null',
        label: 'Select'
    },
    {
        value: '0',
        label: 'Menunggu Pembayaran'
    },
    {
        value: '1',
        label: 'Pembayaran Terkonfirmasi'
    },
    {
        value: '2',
        label: 'Pembayaran Diterima'
    },
    {
        value: '3',
        label: 'Dalam Proses'
    },
    {
        value: '4',
        label: 'Dikirim'
    },
    {
        value: '5',
        label: 'Diterima'
    },
    {
        value: '6',
        label: 'Terkirim'
    },
    {
        value: '11',
        label: 'Komplain'
    },
    {
        value: '12',
        label: 'Dibatalkan'
    },
    {
        value: '13',
        label: 'Selesai'
    },
    {
        value: '14',
        label: 'Ditolak'
    },
    {
        value: '15',
        label: 'Complain Process'
    },
    {
        value: '16',
        label: 'Complain Reject'
    },
    {
        value: '17',
        label: 'Complain Complete'
    },
    {
        value: '18',
        label: 'Retur Process'
    },
    {
        value: '19',
        label: 'Retur Complete"'
    },
    {
        value: '20',
        label: 'Retur Reject'
    },
]