import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel, InventoryReceiveHistoryModel } from "./inventoryReceive.model";

export default class Api {
  static getList() {
    return axios
      .get<any>(endpoint + "/api/HistoryInventoryReceive/GetList")
      .then((res) => res.data.data);
  }

  static getListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/HistoryInventoryReceive/GetListWithPaging", model)
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(
        endpoint + "/api/HistoryInventoryReceive/GetSingleItem?id=" + id
      )
      .then((res) => res.data.data);
  }

  static updateData(model: InventoryReceiveHistoryModel) {
    return axios
      .post<any>(endpoint + "/api/HistoryInventoryReceive/update", model)
      .then((res) => res.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/InventoryReceive/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static getInventoryMoveDetailList(id: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/InventoryMoveDetail/GetListByMaster?inventoryMoveId=" +
          id
      )
      .then((res) => res.data.data);
  }
}
