import React, { useEffect, useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
// import uploadImage from '../../../../assets/vector/upload.png'

import { FiChevronLeft } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Switch from "react-switch";
import { getMembershipTransDetail } from "../membership.api";
import moment from "moment";
import { endpoint } from "../../../constants";

export default function ViewMembership() {
  const dispatch = useDispatch();
  const { id } = useParams<any>();

  const [values, setValues] = useState<any>({
    id: "",
    partnerId: "",
    partnerName: "",
    partnerAddress: "",
    membershipId: "",
    membershipTitle: "",
    periodType: 0,
    status: 0,
    periodStart: null,
    periodEnd: null,
    createdDate: "",
    confirmationPaymentDate: null,
    paymentMethodId: "",
    feeIDR: 0,
    feePercentage: 0,
    minimumPurchase: 0,
    feeUpTo: 0,
    price: 0,
    membershipDescription: "",
    isActive: true,
  });

  useEffect(() => {
    dispatch<any>(getMembershipTransDetail(id))
      .then(unwrapResult)
      .then((res: any) => {
        console.log(res);
        setValues({
          ...values,
          ...res,
        });
      });
    // .catch((error:any)={
    //     Swal
    // })
  }, []);

  return (
    <Container className="content-body formMembership " fluid>
      <div className="page-title mb-4 title-link">
        <Link to="/membership">
          <FiChevronLeft />
        </Link>
        View Register Membership
      </div>
      <div className="box">
        <div className="titbox">View Register Membership</div>
        <Row className="forms">
          <Col md={3}>
            <p>Nama</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.partnerName}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Alamat</p>{" "}
          </Col>
          <Col md={9}>
            <textarea
              className="px-2"
              rows={3}
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.partnerAddress}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Judul Membership</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.membershipTitle}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Biaya Membership</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.feeIDR}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Minimal Pembelian</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.minimumPurchase}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Tanggal Berlaku</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="-"
              readOnly
              value={
                values.confirmationPaymentDate === null
                  ? undefined
                  : moment(values.confirmationPaymentDate).format(
                      "DD/MMM/YYYY - HH:SS"
                    )
              }
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Deskripsi</p>{" "}
          </Col>
          <Col md={9}>
            <textarea
              className="px-2"
              rows={3}
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.membershipDescription}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Tanggal Register</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="-"
              readOnly
              value={
                values.periodStart === null
                  ? undefined
                  : moment(values.periodStart).format("DD/MMM/YYYY - HH:SS")
              }
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Tanggal Pembayaran</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="-"
              readOnly
              value={
                values.confirmationPaymentDate === null
                  ? undefined
                  : moment(values.confirmationPaymentDate).format(
                      "DD/MMM/YYYY - HH:SS"
                    )
              }
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Nomor Rekening</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.noRek}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Transfer Atas Nama</p>{" "}
          </Col>
          <Col md={9}>
            <input
              type="text"
              name="infoStore"
              placeholder="Title"
              readOnly
              value={values.name}
            />
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Bukti Transfer</p>{" "}
          </Col>
          <Col md={9}>
            {values.image === null ? (
              <>-</>
            ) : (
              <>
                <img
                  className="w-50 img-fluid"
                  src={endpoint + values.image}
                  alt="bukti-transfer"
                />
              </>
            )}
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Status Proses</p>{" "}
          </Col>
          <Col md={9}>
            {values.status === 0
              ? "Waiting for Payment"
              : values.status === 1
              ? "Waiting for Confirmation"
              : values.status === 2
              ? "Approved"
              : values.status === 3
              ? "Rejected"
              : values.status === 4
              ? "Expired"
              : null}
          </Col>
        </Row>
        <Row className="forms">
          <Col md={3}>
            <p>Status</p>{" "}
          </Col>
          <Col md={9}>{values.isActive ? "Aktif" : "Tidak Aktif"}</Col>
        </Row>
      </div>
    </Container>
  );
}
