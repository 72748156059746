import moment from "moment";

export const TZ_OFFSET = (new Date()).getTimezoneOffset() * 60000;

export function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d as any);
}

export const dateSetTimeToNow = (date: string | undefined) => {
    // Membuat objek Date dari tanggal dan waktu yang diberikan
    let dateAndTime = date !== undefined ? new Date(date) : new Date();

// Mendapatkan jam, menit, detik, dan milidetik saat ini
    let timeNow = new Date();

// Mengatur jam, menit, detik, dan milidetik objek tanggalDanWaktu ke jam sekarang
    dateAndTime.setHours(timeNow.getHours(), timeNow.getMinutes(), timeNow.getSeconds(), timeNow.getMilliseconds());

    return moment(dateAndTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
}
