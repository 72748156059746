
import React from 'react';
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { FaPlay } from "react-icons/fa";
import { FaSearch } from 'react-icons/fa';

import './historyChat.css';

const HistoryChat = () => {
  return (
      <Row>
        
        <div className="msger">
            <div style={{height:400}}>
                <div className="sider">
                    <header className="sider-header">
                        <div className="sider-header-title">Customer message</div>
                    </header>
                    <div className="sider-searchbox">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className="sider-search-input ">
                            <InputGroup.Text style={{backgroundColor: '#fff', color: '#F5B95D', border:0}}><FaSearch /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                            style={{borderLeft: 0, borderRight:0,
                                height: 64,
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: 12,
                                lineHeight: 16,
                                color: '#A0A0A0' }}
                            placeholder="Search or start new chat"
                            />
                        </InputGroup>
                    </div>
                    <div className="sider-chat-list" >
                        <div className="sider-chat-item">  
                            <Row>
                                <Col md={2}>
                                    <div className="msg-img"></div>
                                </Col>
                                <Col md={6}>
                                    <span className="msg-user-name">Jaylon Taylor</span>
                           
                                    <span className="msg-user-sub float-left"> subject message</span>
                                </Col>
                                <Col md={4} style={{paddingTop:10}}>
                                    <span className="msg-user-status float-right"> 10:07 AM</span>
                                    <span className="msg-user-status float-right"> <span className="dot"></span> Stockist</span>
                                </Col>
                            </Row>    
                            <hr/>  
                            <Row>
                                <Col md={2}>
                                    <div className="msg-img"></div>
                                </Col>
                                <Col md={6}>
                                    <span className="msg-user-name">Jaylon Taylor</span>
                           
                                    <span className="msg-user-sub float-left"> subject message</span>
                                </Col>
                                <Col md={4} style={{paddingTop:10}}>
                                    <span className="msg-user-status float-right"> 10:07 AM</span>
                                    <span className="msg-user-status float-right"> <span className="dot"></span> Stockist</span>
                                </Col>
                            </Row>    
                            <hr/>  
                            
                            <Row>
                                <Col md={2}>
                                    <div className="msg-img"></div>
                                </Col>
                                <Col md={6}>
                                    <span className="msg-user-name">Jaylon Taylor</span>
                           
                                    <span className="msg-user-sub float-left"> subject message</span>
                                </Col>
                                <Col md={4} style={{paddingTop:10}}>
                                    <span className="msg-user-status float-right"> 10:07 AM</span>
                                    <span className="msg-user-status float-right"> <span className="dot"></span> Stockist</span>
                                </Col>
                            </Row>    
                            <hr/>  
                            <Row>
                                <Col md={2}>
                                    <div className="msg-img"></div>
                                </Col>
                                <Col md={6}>
                                    <span className="msg-user-name">Jaylon Taylor</span>
                           
                                    <span className="msg-user-sub float-left"> subject message</span>
                                </Col>
                                <Col md={4} style={{paddingTop:10}}>
                                    <span className="msg-user-status float-right"> 10:07 AM</span>
                                    <span className="msg-user-status float-right"> <span className="dot"></span> Stockist</span>
                                </Col>
                            </Row>    
                            <hr/>  
                            <Row>
                                <Col md={2}>
                                    <div className="msg-img"></div>
                                </Col>
                                <Col md={6}>
                                    <span className="msg-user-name">Jaylon Taylor</span>
                           
                                    <span className="msg-user-sub float-left"> subject message</span>
                                </Col>
                                <Col md={4} style={{paddingTop:10}}>
                                    <span className="msg-user-status float-right"> 10:07 AM</span>
                                    <span className="msg-user-status float-right"> <span className="dot"></span> Stockist</span>
                                </Col>
                            </Row>    
                            <hr/>   
                            <Row>
                                <Col md={2}>
                                    <div className="msg-img"></div>
                                </Col>
                                <Col md={6}>
                                    <span className="msg-user-name">Jaylon Taylor</span>
                           
                                    <span className="msg-user-sub float-left"> subject message</span>
                                </Col>
                                <Col md={4} style={{paddingTop:10}}>
                                    <span className="msg-user-status float-right"> 10:07 AM</span>
                                    <span className="msg-user-status float-right"> <span className="dot"></span> Stockist</span>
                                </Col>
                            </Row>    
                            <hr/>     

                        </div>
                    </div>
                </div>    
                <div className="msger-content">
                    <header className="msger-header">
                    <div className="msger-header-title ">
                        
                    <Row>
                        <Col md={2}>
                            <div className="msg-img"></div>
                        </Col>
                        <Col md={8}>
                            <span className="msg-user-name">Jaylon Taylor</span>
                            <span className="msg-user-status"> <span className="dot"></span> Stockist</span>
                            <div className="msg-user-sub"> subject message</div>
                        </Col>
                        <Col md={2}>
                        </Col>
                    </Row>
                    </div>
                    <div className="msger-header-options">
                    <span><i className="fas fa-cog"></i></span>
                    </div>
                </header>

                <main className="msger-chat">
                    <div className="msg left-msg">
                        <div className="msg-bubble">
                            <div className="msg-info">
                            <div className="msg-info-name">BOT</div>
                            <div className="msg-info-time">12:45</div>
                            </div>

                            <div className="msg-text">
                                Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
                            </div>
                        </div>
                    </div>

                    <div className="msg right-msg">
                        <div className="msg-bubble">
                            <div className="msg-info">
                            <div className="msg-info-name">Sajad</div>
                            <div className="msg-info-time">12:46</div>
                            </div>

                            <div className="msg-text">
                            You can change your name in JS section!
                            </div>
                        </div>
                    </div>

                    <div className="msg right-msg">
                        <div className="msg-bubble">
                            <div className="msg-info">
                            <div className="msg-info-name">Sajad</div>
                            <div className="msg-info-time">12:46</div>
                            </div>

                            <div className="msg-text">
                            You can change your name in JS section!
                            </div>
                        </div>
                    </div>

                    <div className="msg right-msg">
                        <div className="msg-bubble">
                            <div className="msg-info">
                            <div className="msg-info-name">Sajad</div>
                            <div className="msg-info-time">12:46</div>
                            </div>

                            <div className="msg-text">
                            You can change your name in JS section!
                            </div>
                        </div>
                    </div>

                    <div className="msg left-msg">
                        <div className="msg-bubble">
                            <div className="msg-info">
                            <div className="msg-info-name">BOT</div>
                            <div className="msg-info-time">12:45</div>
                            </div>

                            <div className="msg-text">
                                Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
                            </div>
                        </div>
                    </div>


                    <InputGroup className="mb-3">   
                        <FormControl style={{borderRight:0}}
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            />

                        <InputGroup.Prepend style={{borderLeft:0}}>
                            <InputGroup.Text style={{backgroundColor: '#fff', borderLeft:0, padding:3, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                                <button className='msger-send-btn'><FaPlay/></button>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                    </InputGroup>
                </main>
                </div>
            </div>
        
        </div>
    </Row>
  )
};

export default HistoryChat;
