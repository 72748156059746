import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, Button, Col, Row, InputGroup } from 'react-bootstrap';
import logo from './../../../assets/logo.png';
import loginPanel from './../../../assets/login-panel.png';
import { useDispatch, useSelector } from 'react-redux';
import { logUserIn } from '../user.reducer';
import { LoginCredential } from '../model';
import './style.css';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { RootState } from '../../../app/store';

const LoginForm = () => {

  const errorMessage = useSelector(
    (state: RootState) => state.user.error ?? ""
  );

  const statusState = useSelector(
    (state: RootState) => state.user.status ?? ""
  );
  
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      dispatch(
        logUserIn({
          username,
          password,
        } as LoginCredential)
      );
    }
    setValidated(true);
  };

  useEffect(() => {
    if (statusState === "failed"){
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      
      if (message !== undefined){
        setMessage(message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const togglePasswordInvisibility = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  return (
    <Form style={{overflow: "hidden"}} onSubmit={handleSubmit}>
      <Row>
        <Col className="background-login">
          <div className="center-image" >
            <img src={loginPanel} alt="image" style={{ width:"60vh" }}></img>   
          </div> 
        </Col>
        <Col>
          <div style={{paddingRight:120, paddingLeft:120, paddingTop:50}}>
            <img src={logo} alt="Logo" className="logo-img" />

              <Form.Group>
                <Form.Label className="title-text">Log In</Form.Label>
              </Form.Group> 

              <Form.Group>
                <Form.Label className="subtitle-text">Enter your Email and password to login</Form.Label>
              </Form.Group>  

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  className="label-margin" 
                  type="email" 
                  placeholder="Enter email" 
                  required
                  onChange={onUsernameChange}/>
              </Form.Group>

              <Form.Group className="input-padding">
                <Form.Label>Password</Form.Label>

                <InputGroup>
                    <Form.Control 
                      type={passwordShown ? "text" : "password"}
                      onChange={onPasswordChange}
                      value={password}
                      required
                      placeholder="Password" />
                    <InputGroup.Prepend className="toggle-eye-icon">
                      <InputGroup.Text 
                        style={{
                          backgroundColor: '#fff', 
                          color: '#F5B95D', 
                          border:0}}
                        onClick={togglePasswordInvisibility}    
                      >
                          {
                            passwordShown ? <FaEye /> : <FaEyeSlash/>
                          }
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
              </Form.Group>  

              {statusState === "failed" ? 
                <Form.Label style={{color:"red"}}>*{message}</Form.Label>: <></>             
              }

              <Button className="btn-login primary-btn" type="submit" block>
                Submit
              </Button>

              <Form.Group>
                <Link to={{pathname: `/forgot-password`}}>
                  <Form.Label className="form-margin">Forgot Password ?</Form.Label>
                </Link>
              </Form.Group>  

          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
