import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Usertable from "../components/UserTable";
import { RootState } from "../../../app/store";
import { getUserList } from "../user.reducers";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const UserTablePage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector((state: RootState) => state.account.name);

  useEffect(() => {
    // dispatch(getUserList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master User</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Link
            to={{
              pathname: "/create-user", 
            }}
          >
            <Button variant="info" className="float-right">
              + Add Data User
            </Button>
          </Link>
        </Col>
      </Row>
      <Usertable />
    </Container>
  );
};

export default UserTablePage;
