import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import edit from "./../../../../assets/vector/edit.png";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  exportToExcel,
  getStockOpnamelistWithPaging,
  getWarehouse,
} from "../stockOpname.reducer";
import { getKeyThenIncreaseKey } from "antd/lib/message";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const StockOpnameTable = () => {
  const dispatch = useDispatch();

  const stockOpnameStatus = useSelector(
    (state: RootState) => state.stockOpname.status ?? ""
  );

  const stockOpnameList = useSelector(
    (state: RootState) => state.stockOpname.list ?? []
  );

  const totalRowState = useSelector(
    (state: RootState) => state.stockOpname.totalRow ?? 0
  );

  const warehouseListState = useSelector(
    (state: RootState) => state.stockOpname.warehouseList ?? []
  );

  const ExportLinkState = useSelector(
    (state: RootState) => state.stockOpname.exportResponse ?? ""
  );

  const [stockOpnameNumber, setStockOpnameNumber] = useState("");
  const [note, setNote] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [loading, setLoading] = useState(false);

  const onWarehouseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWarehouseId(e.target.value);
  };

  const onExportClick = () => {
    dispatch(
      exportToExcel({
        stockOpnameNumber: "",
        warehouseId: "",
        status: "",
        startDate: "",
        endDate: "",
        note: "",
        skip: 0,
        take: 100,
      })
    );
  };

  const getList = (page: number, perPage: number) => {
    setLoading(true);
    dispatch(
      getStockOpnamelistWithPaging({
        stockOpnameNumber: stockOpnameNumber,
        warehouseId: warehouseId,
        status: "",
        startDate: startDate,
        endDate: endDate,
        note: note,
        skip: page,
        take: perPage,
      })
    );
  };

  const onFilterClick = () => {
    getList(0, take);
  };

  const onResetClick = () => {
    setStockOpnameNumber("");
    setWarehouseId("");
    setStatus("");
    setStartDate("");
    setEndDate("");
    setNote("");

    dispatch(
      getStockOpnamelistWithPaging({
        stockOpnameNumber: "",
        warehouseId: "",
        status: "",
        startDate: "",
        endDate: "",
        note: "",
        skip: 0,
        take: take,
      })
    );
  };

  const handlePageChange = (page: number) => {
    console.log(page);
    setLoading(true);
    getList((page - 1) * take, take);
  };

  const handlePerRowChange = async (newPerPage: number, page: number) => {
    setTake(newPerPage);
    setSkip(page);
    getList(page, newPerPage);
  };

  const filteredItems = stockOpnameList;

  useEffect(() => {
    setLoading(false);
  }, [stockOpnameList]);

  const columns = [
    {
      name: "NO. STOCK OPNAME",
      selector: "stockOpnameNumber",
      sortable: true,
    },
    {
      name: "DATE",
      selector: (d: { createdDate: string }) => (
        <span>{d.createdDate.substr(0, 10)}</span>
      ),
      sortable: true,
    },
    {
      name: "WAREHOUSE",
      selector: "warehouseName",
      sortable: true,
    },
    {
      name: "CREATE BY",
      selector: "name",
      sortable: true,
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    // {
    //   name: "STATUS",
    //   selector: (d: { status: number }) => (
    //     <p style={{ margin: 10 }}>
    //       {d.status === 0 && <span>IN</span>}
    //       {d.status === 1 && <span>OUT</span>}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-stock-opname/${d.id}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          {/* <span
                    onClick={() => {
                        if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                            onDelete(d.id);
                    }}
                >
                    <img src={hapus}></img>
                </span> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getWarehouse());
    getList(0, take);
  }, []);

  useEffect(() => {
    if (ExportLinkState !== "") {
      window.open(ExportLinkState, "_blank");
    }
  }, [ExportLinkState]);

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Stock Opname</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          {/* <Button
            className="float-right"
            style={{ width: 130, marginLeft: 10 }}
            variant="outline-info"
          >
            Print
          </Button>{" "} */}
          <Button
            className="float-right"
            style={{ width: 160, marginLeft: 10 }}
            variant="outline-info"
            onClick={onExportClick}
          >
            Export to excel
          </Button>{" "}
          <Link
            className="float-right ml-2"
            to={{ pathname: "/create-stock-opname" }}
          >
            <Button variant="info" style={{ width: 200 }}>
              + Add Stock Opname
            </Button>
          </Link>
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Stock Opname Number</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    placeholder="Stock Opname Number"
                    type="text"
                    value={stockOpnameNumber}
                    onChange={(e) => {
                      setStockOpnameNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Date</Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="Date"
                    value={startDate.substr(0, 10)}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  ></Form.Control>
                </Col>
                <Col sm={1}>-</Col>
                <Col sm={4}>
                  <Form.Control
                    type="Date"
                    value={endDate.substr(0, 10)}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  ></Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Warehouse</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="select"
                    onChange={onWarehouseChange}
                    value={warehouseId}
                  >
                    <option>Pilih Warehouse</option>
                    {warehouseListState.map((Item) => {
                      return (
                        <option value={Item.id}>
                          {Item.code} - {Item.name} - {Item.city}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>

                
              </Form.Group>
            </Col>

            <Col md={6}>
                  <Form.Group as={Row} className="input-padding m-1">
                    <Form.Label column>Note</Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        placeholder="Note"
                        type="text"
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>

            {/* <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column>Status</Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}>
                    <option value={""}>All</option>
                    <option value={"0"}>IN</option>
                    <option value={"1"}>OUT</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col> */}
          </Row>

          <Row>
            <Col>
              <Button
                style={{ width: 130, margin: 10 }}
                variant="outline-info"
                onClick={onResetClick}
              >
                Reset
              </Button>{" "}
              <Button
                style={{ width: 160, margin: 10 }}
                variant="info"
                onClick={onFilterClick}
              >
                Filter
              </Button>{" "}
            </Col>
          </Row>

          <DataTable
            noHeader
            customStyles={TableCustomStyles}
            columns={columns}
            data={filteredItems}
            pagination
            progressPending={loading}
            paginationServer
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRowState}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>
    </div>
  );
};

export default StockOpnameTable;
