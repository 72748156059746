import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import ReceiptDeliveryApi from "./receiptDelivery.api";
import {
  Delivery,
  DeliveryReceiptFilter,
  PickUpDateBatchUpdateRequest,
  UserModel,
} from "./receiptDelivery.model";

export interface ReceiptDeliverySlice {
  list?: Delivery[];
  single?: Delivery;
  userAdminGudangList?: UserModel[];
  userAdminQCList?: UserModel[];
  userAdminPackingList?: UserModel[];
  userAdminEkspedisiList?: UserModel[];
  totalRow?: number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getDeliveryReceiptListWithPaging = createAsyncThunk(
  "getDeliveryReceiptListWithPagingState/getDeliveryReceiptListWithPaging",
  async (model: DeliveryReceiptFilter, { rejectWithValue }) => {
    try {
      return await ReceiptDeliveryApi.getListWithPagination(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getUserAdminGudangList = createAsyncThunk(
  "getUserAdminGudangListState/getUserAdminGudangList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await ReceiptDeliveryApi.getUserByRole(
        "4e19a9ae-498a-4969-9374-cd605d9cbf82"
      );
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getUserAdminQCList = createAsyncThunk(
  "getUserAdminQCListState/getUserAdminQCList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await ReceiptDeliveryApi.getUserByRole(
        "763fe347-99b6-4fa0-bc59-6eadf52ce476"
      );
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getUserAdminPackingList = createAsyncThunk(
  "getUserAdminPackingListState/getUserAdminPackingList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await ReceiptDeliveryApi.getUserByRole(
        "9b1d1fa3-6fb3-454f-adc9-e2128aa053ca"
      );
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getUserAdminEkspedisiList = createAsyncThunk(
  "getUserAdminEkspedisiListState/getUserAdminEkspedisiList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await ReceiptDeliveryApi.getUserByRole(
        "398e1f1e-04cb-4ef7-ab8c-66c78246cc42"
      );
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateDeliveryReceipt = createAsyncThunk(
  "updateDeliveryReceiptState/updateDeliveryReceipt",
  async (model: Delivery, { rejectWithValue }) => {
    try {
      return await ReceiptDeliveryApi.updateDeliveryReceipt(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updatePickUpDateBatch = createAsyncThunk(
  "updatePickUpDateBatchState/updatePickUpDateBatch",
  async (model: PickUpDateBatchUpdateRequest, { rejectWithValue }) => {
    try {
      return await ReceiptDeliveryApi.updatePickupDateBatch(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const ReceiptDeliverySlice = createSlice({
  name: "ReceiptDeliveryState",
  initialState: {} as ReceiptDeliverySlice,
  reducers: {
    resetSingle: (state) => {
      state.single = {} as Delivery;
      state.status = "";
    },
    resetStatus: (state) => {
      state.status = "";
    },
    resetTotalRow: (state) => {
      state.totalRow = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateDeliveryReceipt.pending, (state) => {
      state.isLoading = true;
      state.status = "loading";
    });
    builder.addCase(updateDeliveryReceipt.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.isLoading = false;
    });
    builder.addCase(updateDeliveryReceipt.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });

    builder.addCase(updatePickUpDateBatch.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      });
      builder.addCase(updatePickUpDateBatch.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isLoading = false;
      });
      builder.addCase(updatePickUpDateBatch.rejected, (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
        state.status = "failed";
      });

    builder.addCase(getDeliveryReceiptListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getDeliveryReceiptListWithPaging.fulfilled,
      (state, { payload }) => {
        state.list = payload.list;
        state.totalRow = payload.total;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getDeliveryReceiptListWithPaging.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );

    builder.addCase(getUserAdminGudangList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserAdminGudangList.fulfilled, (state, { payload }) => {
      state.userAdminGudangList = payload;
      state.isLoading = false;
    });
    builder.addCase(getUserAdminGudangList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getUserAdminQCList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserAdminQCList.fulfilled, (state, { payload }) => {
      state.userAdminQCList = payload;
      state.isLoading = false;
    });
    builder.addCase(getUserAdminQCList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getUserAdminPackingList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserAdminPackingList.fulfilled, (state, { payload }) => {
      state.userAdminPackingList = payload;
      state.isLoading = false;
    });
    builder.addCase(getUserAdminPackingList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getUserAdminEkspedisiList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getUserAdminEkspedisiList.fulfilled,
      (state, { payload }) => {
        state.userAdminEkspedisiList = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getUserAdminEkspedisiList.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
  },
});

export const { resetSingle, resetStatus } = ReceiptDeliverySlice.actions;
export default ReceiptDeliverySlice.reducer;
