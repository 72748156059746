import React, { Component, HTMLAttributes } from 'react';
import './Layout.css';

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  isCompact?: boolean;
}

export default class Layout extends Component<LayoutProps> {
  state = {};

  render() {
    return <div className="admin-layout">{this.props.children}</div>;
  }
}
