import { Container, Form } from "react-bootstrap";
import PaymentConfirmTable from "../components/PaymentConfirmForm";

/*
 * created by : dikembe mutombo
 * created date : 28/oct/2021
 */

const PaymentConfirmIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Payment Confirmation</Form.Label>
      </Form.Group>
      <PaymentConfirmTable />
    </Container>
  );
};

export default PaymentConfirmIndex;
