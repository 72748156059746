import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import edit from "./../../../../assets/vector/edit.png";
import hapus from "./../../../../assets/vector/delete.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../../app/store";
import { deleteData } from "../voucher.reducer";
import DeleteMessage from "../../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";
import {VoucherModel} from "../voucher.model";

const VoucherTable = () => {
  const dispatch = useDispatch();

  const [resetPaginationToggle] = useState(false);
  const [selectedId, setSelectedId] = useState("")

  const [filterText, setFilterText] = useState("");
  const [filterType, setFilterType] = useState<string>('null');
  const [filterStatus, setFilterStatus] = useState<string>('null');
  const [filterHidden, setFilterHidden] = useState<string>('null');

  const [filteredItems, setFilteredItems] = useState<VoucherModel[]>([]);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const listVoucherState = useSelector(
    (state: RootState) => state.voucher.list ?? []
  );

  useEffect(() => {
    setFilteredItems(listVoucherState);
  }, [listVoucherState]);

  const columns = [
    {
      name: "CODE",
      selector: "code",
      sortable: true,
    },
    {
      name: "DISCOUNT NAME",
      selector: "name",
      sortable: true,
    },
    {
      name: "TYPE",
      selector: "type",
      sortable: true,
      format: (d: any) => (
          <p style={{margin: 10}}>
            {d.type === 0 && (
                <span className={"custom-badge-secondary"}>Percentage</span>
            )}
            {d.type === 1 && (
                <span className={"custom-badge-secondary"}>Fix Amount</span>
            )}
            {d.type === 2 && (
                <span className={"custom-badge-secondary"}>Free Shipping</span>
            )}
            {d.type === 3 && (
                <span className={"custom-badge-secondary"}>Buy X Get Y</span>
            )}
            {d.type === 4 && (
                <span className={"custom-badge-secondary"}>Cash Back</span>
            )}

          </p>
      ),
    },
    {
      name: "STATUS",
      selector: "isActive",
      format: (d: any) => (
            <p style={{margin: 10}}>
                {d.isActive && (
                    <span className={"custom-badge-success"}>Active</span>
                )}
                {!d.isActive && (
                    <span className={"custom-badge-secondary"}>Inactive</span>
                )}
            </p>
        ),
      sortable: true,
    },
    {
      name: "HIDDEN",
      selector: "isHidden",
      format: (d: any) => (
            <p style={{margin: 10}}>
                {d.isHidden && (
                    <span className={"custom-badge-warning"}>Yes</span>
                )}
                {!d.isHidden && (
                    <span className={"custom-badge-secondary"}>No</span>
                )}
            </p>
        ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-voucher/${d.id}`,
            }}
          >
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onDelete(d.id);
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteData(id));
    }
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteData(selectedId));
      setShowAlert(false);
    }
  };

  const onFilterClick = () => {

    // filter by filterText, filterType, filterStatus, filterHidden
    let filteredData = listVoucherState.filter((item) => {
        let result = true;
        if (filterText !== "") {
            result = result && item.name.toLowerCase().includes(filterText.toLowerCase());
        }
        if (filterType !== 'null') {
            result = result && item.type === parseInt(filterType);
        }
        if (filterStatus !== 'null') {
            result = result && item.isActive === (filterStatus === 'true');
        }
        if (filterHidden !== 'null') {
            result = result && item.isHidden === (filterHidden === 'true');
        }
        return result;
    });
    setFilteredItems(filteredData);
  };

  const onFilterResetClick = () => {
    setFilterText("");
    setFilterType('null');
    setFilterStatus('null');
    setFilterHidden('null');
    setFilteredItems(listVoucherState);
  }

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">
              Master Voucher Discount
            </Form.Label>
          </Form.Group>
        </Col>
        <Link
          to={{
            pathname: "/voucher/create",
          }}
        >
          <Button
            className="button-create-customer float-right"
            style={{ width: 160 }}
          >
            + Add Voucher
          </Button>
          {"  "}
        </Link>
        <Button
          className="float-right"
          style={{ width: 130, marginLeft: 10 }}
          variant="outline-info"
        >
          Print
        </Button>{" "}
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="outline-info"
        >
          Export to excel
        </Button>{" "}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row className={'mb-2'}>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                Voucher Name
                </Form.Label>
                <Col>
                  <Form.Control
                      placeholder="Voucher Name"
                      type="text"
                      value={filterText}
                      onChange={(e) => {
                        setFilterText(e.target.value);
                      }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Type
                </Form.Label>
                <Col>
                  <Form.Control
                      as="select"
                      value={filterType}
                      onChange={(e) => {
                        setFilterType(e.target.value);
                      }}
                  >
                    <option value={'null'}>All</option>
                    <option value={'0'}>Percentage</option>
                    <option value={'1'}>Fixed Amount</option>
                    <option value={'2'}>Free Shipping</option>
                    <option value={'3'}>Buy X get Y</option>
                    <option value={'4'}>Cash Back</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                      as="select"
                      value={filterStatus}
                      onChange={(e) => {
                        setFilterStatus(e.target.value);
                      }}
                  >
                    <option value={'null'}>All</option>
                    <option value={'true'}>Active</option>
                    <option value={'false'}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={3}>
                  Hidden
                </Form.Label>
                <Col>
                  <Form.Control
                      as="select"
                      value={filterHidden}
                      onChange={(e) => {
                        setFilterHidden(e.target.value);
                      }}
                  >
                    <option value={'null'}>All</option>
                    <option value={'true'}>Yes</option>
                    <option value={'false'}>No</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col md={12} className={'d-flex align-items-center justify-content-end'}>
              <Row className={'mx-1'}>
                <Col md={12}>
                  <Button
                      variant="outline-info"
                      style={{width: 130, height: 45, marginLeft: 10}}
                      type="submit"
                      onClick={onFilterResetClick}
                  >
                    Reset Filter
                  </Button>
                  <Button
                      variant="info"
                      style={{width: 130, height: 45, marginLeft: 10}}
                      type="submit"
                      onClick={onFilterClick}
                  >
                    Filter
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default VoucherTable;
