import { useEffect, useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import "../product.css";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import upload from "./../../../../assets/vector/upload.png";
import { convertBase64 } from "../../../../components/Library/Base64";
import { resetImageGallery, setImageGalleryTempForm, uploadImage } from "../product.reducer";
import { RootState } from "../../../../app/store";
import { ImageGalleryModel, UploadImageModel } from "../product.model";
import { FaTimes } from "react-icons/fa";
import { endpoint } from "../../../../constants";
import axios from "axios";
import { useParams } from "react-router";

const ProductImageGallery = ({ isEdit = false, ...props }: ImageGalleryProps) => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();

  let imageState = useSelector(
    (state: RootState) => state.product.uploadImage ?? ({} as UploadImageModel)
  );

  let multiImageState = useSelector(
    (state: RootState) => state.product.imagePathList ?? []
  );

  let imageGallerySingleItemState = useSelector(
    (state: RootState) => state.product.singleImageGallery ?? ({} as ImageGalleryModel)
  );

  const [imageGallery, setImageGallery] = useState<Array<string>>([]);
  const [onHoverIndex, setOnHoverIndex] = useState(-1);

  useEffect(() => {
    if (imageState && Object.keys(imageState).length !== 0 && imageState.constructor === Object) {
      setImageGallery((prevState) => [...prevState, imageState.imagepath]);
    }
    // else {
    //   // dispatch(resetImageGallery())
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageState]);
  

  useEffect(() => {
    if (multiImageState.length !== 0 ) {

      multiImageState.map((image)=>{
        setImageGallery((prevState)=>[
          ...prevState,
          image
        ])
      })

      setImageGallery((prevState) => [...prevState, imageState.imagepath]);
    }
    // else {
    //   setImageGallery([])
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiImageState]);

  useEffect(() => {
    dispatch(setImageGalleryTempForm(imageGallery));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageGallery]);

  useEffect(() => {
    if (isEdit === true) {
      axios
        .get<any>(endpoint + "/api/ProductGallery/GetSingleByProduct?productId=" + id)
        .then((res) => {
          if (res.data.data.path !== null) {
            setImageGallery(res.data.data.path);
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (
      imageGallerySingleItemState &&
      Object.keys(imageGallerySingleItemState).length !== 0 &&
      imageGallerySingleItemState.constructor === Object
    ) {
      setImageGallery(imageGallerySingleItemState.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageGallerySingleItemState]);

  const handleImage = (files: any) => {

    console.log(files)

    // if (files[0] !== undefined) {

    //   convertBase64(files[0], (result: any) => {
    //     let fileFormat = files[0].type.split("/")[1];
    //     dispatch(
    //       uploadImage({
    //         file_name: "file_name." + fileFormat,
    //         base64url: result.split(",")[1],
    //         imagepath: "product",
    //         location: "product",
    //       })
    //     );
    //   });

    // } else {
    //   console.log("error");
    // }



    //upload multiple image
    files.map((file : any)=>{

      convertBase64(file, (result: any) => {
        let fileFormat = file.type.split("/")[1];
        dispatch(
          uploadImage({
            file_name: "file_name." + fileFormat,
            base64url: result.split(",")[1],
            imagepath: "product",
            location: "product",
          })
        );
      });
    

    })



  };

  const deleteImage = (index: number) => {
    const imgGallery = [...imageGallery];
    imgGallery.splice(index, 1);
    setImageGallery(imgGallery);
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      <Card>
        <Card.Body>
          <Form.Group as={Row}>
            <Form.Label
              style={{
                fontWeight: 610,
                fontSize: 17,
                paddingTop: 0,
                paddingBottom: 0,
              }}
              column
              sm={12}
            >
              Images Gallery
            </Form.Label>
          </Form.Group>
          <hr />
          <Form>
            <section>
              <Row>
                <Col sm={4} className="pb-3">
                  <Dropzone onDrop={(acceptedFiles) => handleImage(acceptedFiles)}>
                    {({ getRootProps, getInputProps, }) => (
                      <div className="dropzone file-upload-frame-gallery w-100">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="text-center file-upload-label-gallery">
                            <div>
                              <div className="file-upload-image-gallery mb3">
                                <img src={upload} alt="upload" />
                                <br />
                                <span style={{ color: "black" }}>Select File</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </Col>
                {imageGallery.map((Item, index) => (
                  <Col
                    className="pr-2 pl-2"
                    onMouseLeave={() => {
                      setOnHoverIndex(-1);
                    }}
                    onMouseOver={() => {
                      setOnHoverIndex(index);
                    }}
                    sm={4}
                  >
                    <img
                      src={endpoint + Item}
                      className="file-upload-image-gallery"
                    />
                    <button
                      type="button"
                      onClick={() => deleteImage(index)}
                      className={`d-${onHoverIndex === index ? "block" : "none"} delete-image-btn`}
                    >
                      <FaTimes />
                    </button>
                  </Col>
                ))}
              </Row>
            </section>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export interface ImageGalleryProps {
  isEdit: Boolean;
}

export default ProductImageGallery;
