import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { createData, getList } from "../city.reducer";
import { RootState } from "../../../../app/store";
import CityTable from "../components/cityTable";
import { useEffect, useState } from "react";
import { CityModel } from "../city.model";

const CityTablePage = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const State = useSelector((state: RootState) => state.city.name);

  const singleDataState = useSelector((state: RootState) => state.city.single ?? ({} as CityModel));

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  const onSubmit = (no: number, name: string, provinceId: string) => {
    dispatch(
      createData({
        no,
        name,
        provinceId,
      } as CityModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <CityTable data={singleDataState} onSubmit={onSubmit} isEdit={isEdit} />
    </Container>
  );
};

export default CityTablePage;
