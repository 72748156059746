
import { Container, Form } from "react-bootstrap";
import LaporanEkspedisiTable from '../components/LaporanEkspedisiTable'

const LaporanEkspedisiPage = () => {
  return (
    <Container className="content-body" fluid> 
     <LaporanEkspedisiTable/>
    </Container>
  )
}

export default LaporanEkspedisiPage