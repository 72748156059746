import { Container, Form } from "react-bootstrap";
import PesananDropshipTable from "../components/pesananDropshipTable";

/*
 * created by : dikembe mutombo
 * created date : 28/oct/2021
 */

const PesananDropshipIndex = () => {
  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <Form.Label className="page-title">Pesanan Dropship</Form.Label>
      </Form.Group>
      <PesananDropshipTable />
    </Container>
  );
};

export default PesananDropshipIndex;
