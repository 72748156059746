import {AppDispatch} from "../../app/store";
import {useDispatch} from "react-redux";
import {getPartnerDropdown, getPartnerWithTypeDropdown} from "../../global/global.reducer";
import {unwrapResult} from "@reduxjs/toolkit";
import {PartnerOptionModel} from "../../features/salesOrder/salesOrder/salesOrder.model";

const useAsyncPartnerWithTypeOption = () => {
    const dispatch : AppDispatch = useDispatch();

    const getPartnerWithTypeOptions = async (inputValue: string, type: number) => {
        const resultAction = await dispatch(getPartnerWithTypeDropdown({
            partnerName: inputValue,
            type: type
        }));

        const originalPromiseResult = unwrapResult(resultAction);
        let _option = originalPromiseResult?.map((item: any) => {
            return {
                value: item.id,
                label: item.fullName,
                type: item.type,
                id: item.id,
            }
        });
        return _option as PartnerOptionModel[];
    };

    return {getPartnerWithTypeOptions} as const;
};

export default useAsyncPartnerWithTypeOption;
