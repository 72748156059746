import { createWarehouse, getSingleItem, updateWarehouse } from "../warehouse.reducer";
import WarehouseForm from "../components/WarehouseForm";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { WarehouseModel } from "../warehouse.model";
import { RootState } from "../../../../app/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const WarehousePage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.warehouse.single ?? ({} as WarehouseModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    code: string,
    name: string,
    phoneNumber: string,
    address: string,
    provinceId: string,
    cityId: string,
    districtId: string,
    isActive: boolean
  ) => {
    if (isEdit) {
      dispatch(
        updateWarehouse({
          id,
          code,
          name,
          phoneNumber,
          address,
          provinceId,
          cityId,
          districtId,
          isActive,
        } as WarehouseModel)
      );
    } else {
      dispatch(
        createWarehouse({
          code,
          name,
          phoneNumber,
          address,
          provinceId,
          cityId,
          districtId,
        } as WarehouseModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <WarehouseForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WarehousePage;
