import {
  getConfigureTermSingleItem,
  getParentList,
  updateConfigureTerms,
} from "../attribute.reducer";
import ConfigureTermsEditForm from "../components/configureTermsEditForm";
import { ConfigureTermsModel } from "../attribute.model";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { RootState } from "../../../../app/store";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const ConfigureTermsEditPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.attribute.configureTermSingle ?? ({} as ConfigureTermsModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
      if (singleDataState.productAttributeId !== undefined) {
        dispatch(getParentList(singleDataState.productAttributeId));
      }
    } else {
      setIsEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined && id !== "") {
      dispatch(getConfigureTermSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    name: string,
    slug: string,
    parentId: string,
    productAttributeId: string,
    description: string,
    isActive: boolean,
    color: string,
  ) => {

    let newParentId = ""

    if(parentId===""){
      newParentId = "00000000-0000-0000-0000-000000000000"
    }

    dispatch(
      updateConfigureTerms({
        id,
        name,
        slug,
        parentId : newParentId,
        description,
        productAttributeId,
        isActive,
        color
      } as ConfigureTermsModel)
    );
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <ConfigureTermsEditForm
                onSubmit={onSubmit}
                data={singleDataState}
                //   isEdit={isEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfigureTermsEditPage;
