import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import ExpeditionForm from '../components/ExpeditionForm'
import { ExpeditionModel } from "../Expedition.model";
import { createExpedition, getSingleExpedition, updateExpedition } from "../Expedition.reducer";

const ExpeditionPage = () => {

    const dispatch = useDispatch();

    let { id } = useParams<{ id: string }>();

    const singleDataState = useSelector(
        (state: RootState) => state.expedition.single ?? ({} as ExpeditionModel)
    )

    const [isEdit, setIsEdit] = useState(false);


    const onSubmit = (
        name: string,
        // icon: string
    ) => {
        if(!isEdit){
            dispatch(
                createExpedition({
                    name,
                    // icon
                } as ExpeditionModel)
            )
        } else {
            dispatch(
                updateExpedition({
                    id,
                    name,
                    // icon
                } as ExpeditionModel)
            )
        }
    }

    useEffect(() => {
        if (
            singleDataState &&
            Object.keys(singleDataState).length !== 0 &&
            singleDataState.constructor === Object
        ) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [singleDataState])

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleExpedition(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div>
            <ExpeditionForm data={singleDataState} isEdit={isEdit} onSubmit={onSubmit}/>
        </div>
    )
}

export default ExpeditionPage
