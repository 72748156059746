import {
  Card,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { ElementPropModel } from "../../../../components/DataTable/models";
import {
  deleteWarehouse,
  getCity,
  getList,
  getListWithPaging,
  resetStatus,
} from "../warehouse.reducer";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import AlertMessage from "../../../../components/alertMessage";
import { getListCity } from "../../../../global/global.reducer";
import DeleteMessage from "../../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const WarehouseTable = () => {
  const dispatch = useDispatch();

  //alert
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteAlertProps, setDeleteAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const warehouseState = useSelector(
    (state: RootState) => state.warehouse.list ?? []
  );

  const warehouseStatusState = useSelector(
    (state: RootState) => state.warehouse.status ?? ""
  );

  const totalRowState = useSelector(
    (state: RootState) => state.warehouse.totalRow ?? 0
  );

  const cityListState = useSelector(
    (state: RootState) => state.global.listCity ?? []
  );

  const [selectedId, setSelectedId] = useState("");

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const [filterCode, setFilterCode] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [filterCityId, setFilterCityId] = useState<string | null>(null);
  const [filterTempCityId, setFilterTempCityId] = useState("");
  const [filterIsActive, setFilterIsActive] = useState<boolean | null>(null);
  const [filterTempIsActive, setFilterTempIsActive] = useState(2);

  // const filteredItems = userState.filter(
  //   (item) =>
  //     (item.code &&
  //       item.code.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.name &&
  //       item.name.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.phoneNumber &&
  //       item.phoneNumber.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.city && item.city.toLowerCase().includes(filterText.toLowerCase()))
  // );

  const onCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterCityId(e.target.value);
    setFilterTempCityId(e.target.value);
  };

  const onStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "0") {
      setFilterIsActive(true);
      setFilterTempIsActive(0);
    } else if (e.target.value === "1") {
      setFilterIsActive(false);
      setFilterTempIsActive(1);
    } else {
      setFilterIsActive(null);
      setFilterTempIsActive(2);
    }
  };

  const onAlertClose = () => {
    setShowAlert(false);
  };

  const onDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const onFilterClick = () => {
    fetchData(0, perPage);
  };

  const onFilterResetClick = () => {
    setFilterCode("");
    setFilterName("");
    setFilterPhoneNumber("");
    setFilterTempCityId("");
    setFilterCityId(null);
    setFilterIsActive(null);
    setFilterTempIsActive(2);

    dispatch(
      getListWithPaging({
        code: "",
        name: "",
        phoneNumber: "",
        cityId: null,
        isActive: null,
        skip: 0,
        take: perPage,
      })
    );
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page: any) => {
    fetchData((page - 1) * perPage, perPage);
  };

  const fetchData = (page: number, perPage: number) => {
    setLoading(true);

    dispatch(
      getListWithPaging({
        code: filterCode,
        name: filterName,
        phoneNumber: filterPhoneNumber,
        cityId: filterCityId,
        isActive: filterIsActive,
        skip: page,
        take: perPage,
      })
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [warehouseState]);

  useEffect(() => {
    fetchData(0, perPage);
    dispatch(getListCity());
  }, []);

  useEffect(() => {
    if (warehouseStatusState === "success-delete") {
      fetchData(0, perPage);
      dispatch(resetStatus());
    }
  }, [warehouseStatusState]);

  const columns = [
    {
      name: "CODE",
      selector: "code",
      sortable: true,
    },
    {
      name: "NAME",
      selector: "name",
      sortable: true,
    },
    {
      name: "CITY",
      selector: "city",
      sortable: true,
    },
    {
      name: "TELEPHONE",
      selector: "phoneNumber",
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && (
            <span className={"custom-badge-success"}>Active</span>
          )}
          {d.isActive === false && (
            <span className={"custom-badge-secondary"}>Inactive</span>
          )}
        </p>
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-warehouse/${d.id}`,
            }}
          >
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
              //   onDelete(d.id);

              setSelectedId(d.id);
              setDeleteAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowDeleteAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  // end of datatable components
  // useEffect(() => {
  //   dispatch(getList());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onDelete = (id: string) => {
    if (id !== undefined) {
      if (
        id !== "e81c0699-0578-4d8e-af2f-72b3fd13f548" &&
        id !== "1c7a11f2-df77-4d94-ab24-2f1d2ba788e9"
      ) {
        dispatch(deleteWarehouse(id));
      } else {
        setAlertProps({
          variant: "failed",
          title: "Akses Ditolak",
          content: "Anda tidak dapat menghapus GUDANG UTAMA dan GUDANG REJECT.",
        });
        setShowAlert(true);
      }
    }
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      if (
        selectedId !== "e81c0699-0578-4d8e-af2f-72b3fd13f548" &&
        selectedId !== "1c7a11f2-df77-4d94-ab24-2f1d2ba788e9"
      ) {
        dispatch(deleteWarehouse(selectedId));
        setShowDeleteAlert(false);
      } else {
        setAlertProps({
          variant: "failed",
          title: "Akses Ditolak",
          content: "Anda tidak dapat menghapus GUDANG UTAMA dan GUDANG REJECT.",
        });
        setShowAlert(true);
        setShowDeleteAlert(false);
      }
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Warehouse</Form.Label>
          </Form.Group>
        </Col>
        <Link
          to={{
            pathname: "/Warehouse/create",
          }}
        >
          <Button
            className="button-create-customer float-right"
            style={{ width: 160 }}
          >
            + Add Warehouse
          </Button>
          {"  "}
        </Link>
        <Button
          className="float-right"
          style={{ width: 130, marginLeft: 10 }}
          variant="outline-info"
        >
          Print
        </Button>{" "}
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="outline-info"
        >
          Export to excel
        </Button>{" "}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Code
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterCode}
                    onChange={(e) => {
                      setFilterCode(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Name
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Phone Number
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Search"
                    type="text"
                    value={filterPhoneNumber}
                    onChange={(e) => {
                      setFilterPhoneNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  City
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onCityChange}
                    value={filterTempCityId}
                  >
                    <option value="">Select</option>
                    {cityListState.map((Item) => {
                      return <option value={Item.id}>{Item.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group as={Row} className="input-padding m-1">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    onChange={onStatusChange}
                    value={filterTempIsActive}
                  >
                    <option value={2}>All</option>
                    <option value={0}>Active</option>
                    <option value={1}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="outline-info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterResetClick}
              >
                Reset Filter
              </Button>

              <Button
                variant="info"
                style={{ width: 130, height: 45, marginLeft: 10 }}
                type="submit"
                onClick={onFilterClick}
              >
                Filter
              </Button>
            </Col>
          </Row>

          {/* <Row>
            <Col md={4}>
              <InputGroup
                className="mb-3 padding-filter"
                style={{ width: 330 }}
              >
                <InputGroup.Prepend className="search-input">
                  <InputGroup.Text
                    style={{
                      backgroundColor: "#fff",
                      color: "#F5B95D",
                      border: 0,
                    }}
                  >
                    <FaSearch />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  placeholder="Search for an (ID, From, etc)"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  value={filterText}
                />
              </InputGroup>
            </Col>
          </Row> */}

          <hr />

          <DataTable
            columns={columns}
            data={warehouseState}
            pagination
            paginationServer
            progressPending={loading}
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            paginationTotalRows={totalRowState}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />

          {/* <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            selectableRows
            customStyles={TableCustomStyles}
          />*/}
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />

      <DeleteMessage
        {...deleteAlertProps}
        show={showDeleteAlert}
        onAlertCloseAction={onDeleteAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default WarehouseTable;
