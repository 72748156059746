import { createSlice } from '@reduxjs/toolkit'
import { getAutoCancelKeepList } from './autoCancelKeep.api'
import {AutoCancelKeepModel} from "./models";
// import { getMembership } from './orderDataReport'


export interface AutoCancelKeepState {
  loading: boolean,
  list: AutoCancelKeepModel[],
  total: number
}
const initialState: AutoCancelKeepState = {
  loading: false,
  list: [],
  total: 0
}

export const autoCancelKeep = createSlice({
  name: 'autoCancelKeepSlice',
  initialState,
  reducers: {
    // increment: (state) => {
    // state.value += 1
  },
  extraReducers: (builder) => {
    // post data filter
    builder.addCase(getAutoCancelKeepList.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAutoCancelKeepList.fulfilled, (state, { payload }) => {
      // console.log(payload)
      const { list, total } = payload
      state.list = list ?? []
      state.total = total ?? 0
      state.loading = false
    })
    builder.addCase(getAutoCancelKeepList.rejected, (state, action) => {
      state.loading = false
    })
    //post
    // builder.addCase(postExportOrderData.pending, (state, action) => {
    //   state.loading = true
    // })
    // builder.addCase(postExportOrderData.fulfilled, (state, action) => {
    //   state.loading = false
    // })
    // builder.addCase(postExportOrderData.rejected, (state, action) => {
    //   state.loading = false
    // })

  },
})

export default autoCancelKeep.reducer