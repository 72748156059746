 import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../app/store";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { getComplainList } from "../complain.reducer";
import { ComplainListModel } from "../complain.model";
import moment from "moment";
import { BsEyeFill } from "react-icons/bs";
import { MdNote } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { getPendingNotification } from "../../../global/global.reducer";
 import {MAX_PAGING_OPTIONS} from "../../../constants";

const ComplainTable = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  const list = useSelector(
    (state: RootState) => state.complain.list ?? ({} as ComplainListModel)
  );

  const { status } = useSelector((state: RootState) => state.complain)

  const initialValue = {
    soNumber: "",
    name: "",
    startDate: "",
    endDate: "",
    status: 0,
    progressStatus: 0,
    skip: 0,
    take: 10
  }

  const [filterData, setFilterData] = useState<any>(initialValue)

  useEffect(() => {
    if (JSON.stringify(filterData) === JSON.stringify(initialValue)) {
      dispatch(getComplainList(filterData))
    }
  }, [filterData])

  // useEffect(() => {
  //   dispatch(getPendingNotification())
  // }, [])
  

  const changeFilter = (e: any) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.name === 'status' || e.target.name === 'progressStatus' ? parseInt(e.target.value) : e.target.value
    })
  }

  const handleResetFilter = () => {
    setFilterData(initialValue)
  }

  const handleAdd = () => {
    history.push('/complain/add')
  }

  const columns = [
    {
      name: "NOMOR PESANAN",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "TANGGAL Pengaduan",
      selector: (d: any) => (
        <span>{moment(new Date(d.createdDate)).format('DD/MM/YYYY HH:mm')}</span>
      ),
      sortable: true,
    },
    {
      name: "NAMA",
      selector: "name",
      sortable: true,
    },
    {
      name: "ADMIN",
      selector: "adminName",
      sortable: true,
      format: (row: any) => row.adminName ?? '-'
    },
    {
      name: "JENIS PELANGGAN",
      selector: (d: any) => {
        let enums = ["Customer", "Stockist", "Reseller", "Admin"]
        return (
          <span>{enums[d.type]}</span>
        )
      },
      sortable: true,
    },
    {
      name: "TELEPON",
      selector: "telephone",
      sortable: true,
    },
    {
      name: "STATUS & TANGGAL PROSES",
      selector: (d: any) => (
        d.status === 0
          ? (
            <>
              <span>request</span><br />
              <span>{moment(new Date(d.createdDate)).format('DD/MM/YYYY HH:mm')}</span>
            </>
          )
          : d.status === 1
            ? (
              <>
                <span>aprove</span><br />
                <span>{moment(new Date(d.approveDate)).format('DD/MM/YYYY HH:mm')}</span>
              </>
            )
            : d.status === 2
              ? (
                <>
                  <span>reject</span><br />
                  <span>{moment(new Date(d.rejectDate)).format('DD/MM/YYYY HH:mm')}</span>
                </>
              )
              : null
      ),
      sortable: true,
    },
    {
      name: "KELUHAN",
      selector: "description",
      sortable: true,
    },
    {
      name: "STATUS PROSES",
      selector: (d: any) => (
        d.progressStatus === 0
          ? (
            <span>Request</span>
          )
          : d.progressStatus === 1
            ? (
              <span>Wallet (discount)</span>
            )
            : d.progressStatus === 2
              ? (
                <span>Retur barang sama</span>
              )
              : d.progressStatus === 3
                ? (
                  <span>Retur barang baru</span>
                )
                : d.progressStatus === 4
                  ? (
                    <span>Refund</span>
                  )
                  : null
      ),
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: any) => (
        <div className="complain-button">
          <Link to={{ pathname: "/complain-detail/" + d.id }}>
            <BsEyeFill />
          </Link>
          {d.status === 0 && (<Link to={{ pathname: "/complain-detail/" + d.id + "?aproval=true" }}>
            <Button className="approval-button" size="sm">Approval</Button>
          </Link>)}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Pengaduan</Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Button
            className="float-right"
            style={{ width: 130, marginLeft: 10 }}
            variant="outline-info">
            Print
          </Button>{" "}
          <Button
            className="float-right"
            style={{ width: 160, marginLeft: 10 }}
            variant="outline-info">
            Export to excel
          </Button>{" "}
          <Button
            className="float-right"
            style={{ width: 160, marginLeft: 10 }}
            variant="outline-info"
            onClick={handleAdd}>
            Add Complain
          </Button>{" "}
        </Col>
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Row>
            <Col sm={5}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Nomor Pesanan
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Nomor Pesanan"
                    type="text"
                    name="soNumber"
                    value={filterData.soNumber}
                    onChange={changeFilter}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Tanggal Pengaduan
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Tanggal Pengaduan"
                    type="date"
                  // value={filterText}
                  // onChange={(e) => {
                  //   setFilterText(e.target.value);
                  // }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Nama
                </Form.Label>
                <Col>
                  <Form.Control
                    placeholder="Nama"
                    type="text"
                    name="name"
                    value={filterData.name}
                    onChange={changeFilter}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    name="status"
                    value={filterData.status}
                    onChange={changeFilter}
                  >
                    <option value={0}>Request</option>
                    <option value={1}>Approve</option>
                    <option value={2}>Reject</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group as={Row} className="input-padding m-3">
                <Form.Label column sm={4}>
                  Status Proses
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    name="progressStatus"
                    value={filterData.progressStatus}
                    onChange={changeFilter}
                  >
                    <option value={0}>Request</option>
                    <option value={1}>Wallet (discount)</option>
                    <option value={2}>Retur barang sama</option>
                    <option value={3}>Retur barang baru</option>
                    <option value={4}>Refund</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group as={Row} className="input-padding m-3">
                <Button
                  className="mr-2"
                  variant="outline-info"
                  style={{ width: 130, height: 45 }}
                  type="submit"
                  onClick={handleResetFilter}
                >
                  Reset
                </Button>
                {"     "}
                <Button
                  variant="info"
                  style={{ width: 130, height: 45 }}
                  type="submit"
                  onClick={() => dispatch(getComplainList(filterData))}
                >
                  Filter
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <DataTable
              columns={columns}
              data={list.list}
              pagination
              noHeader
              customStyles={TableCustomStyles}
              paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
            />
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export default ComplainTable;
