import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { Card, Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import { deleteConfigureTerms, getConfiguretermsList, resetStatus } from "../attribute.reducer";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

const ConfiguretermsTable = () => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const userState = useSelector((state: RootState) => state.attribute.configureTermsList ?? []);
  const attributeTermStatus = useSelector((state: RootState) => state.attribute.status ?? "")

  const filteredItems = userState.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.slug && item.slug.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.parentId && item.parentId.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.description && item.description.toLowerCase().includes(filterText.toLowerCase()))
  );

  const columns = [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    // },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "slug",
    //   selector: "slug",
    //   sortable: true,
    // },
    {
      name: "parent",
      selector: "parentName",
      sortable: true,
    },
    {
      name: "description",
      selector: "description",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/attribute/configure-terms-edit/${d.id}`,
            }}
          >
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteConfigureTerms(id));
    }
  };

  useEffect(() => {
    if(attributeTermStatus === "success-delete-configure-term"){
      dispatch(getConfiguretermsList(id))
      dispatch(resetStatus())
    }
    
  }, [attributeTermStatus])

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <div style={{ margin: "auto", padding: 10 }}>Configure Terms</div>
              </Col>
              <Col>
                <Link
                  to={{
                    pathname: `/attribute/configure-terms-create/${id}`,
                  }}
                >
                  <Button className="float-right" variant="info">
                    Create
                  </Button>
                </Link>
              </Col>
            </Row>
            <hr />
            <InputGroup className="mb-3 padding-filter" style={{ width: 300 }}>
              <InputGroup.Prepend className="search-input" style={{ padding: 0 }}>
                <InputGroup.Text
                  style={{
                    backgroundColor: "#fff",
                    color: "#F5B95D",
                    border: 0,
                  }}
                >
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                style={{ borderLeft: 0 }}
                placeholder="Search for an (ID, From, etc)"
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
                value={filterText}
              />
            </InputGroup>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              noHeader
              customStyles={TableCustomStyles}
            />
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export default ConfiguretermsTable;
