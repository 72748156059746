import axios from "axios";
import { endpoint } from "../../../constants";
import { ApplicationLinkModel } from "./SettingApplicationLink.model";

export default class SettingApplicationLinkApi {
  static getApplicationLinkSetting() {
    return axios
      .get<any>(endpoint + "​/api/WebsitePageSetting/GetApplicationLink")
      .then((res) => res.data);
  }

  static updateApplicationLinkSetting(model: ApplicationLinkModel) {
    return axios
      .post<any>(endpoint + "/api/WebsitePageSetting/UpdateLink", model)
      .then((res) => res.data);
  }
}
