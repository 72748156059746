import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getInformationStoreByType, postInformationStore, updateInformationStore } from './informationStore.api'


export interface InformationStoreState {
  activeTab : string,
  loading : boolean
}
const initialState: InformationStoreState = {
  activeTab : 'Reseller',
  loading : false,
}

export const storeInformationSlice = createSlice({
    name: 'informationStore',
    initialState,
    reducers: {
        handleTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload
        },
        // decrement: (state) => {
        // state.value -= 1
        // },
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        // state.value += action.payload
        // },
    },
    extraReducers : (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getInformationStoreByType.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getInformationStoreByType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getInformationStoreByType.rejected, (state, action) => {
            state.loading = false
        })
        // //post
        builder.addCase(postInformationStore.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(postInformationStore.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postInformationStore.rejected, (state, action) => {
            state.loading = false
        })
        // //update
        builder.addCase(updateInformationStore.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(updateInformationStore.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateInformationStore.rejected, (state, action) => {
            state.loading = false
        })
        // //delete
        // builder.addCase(deleteCompany.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(deleteCompany.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(deleteCompany.rejected, (state, action) => {
        //     state.loading = false
        // })
    },
})

// Action creators are generated for each case reducer function
export const { handleTab} = storeInformationSlice.actions

export default storeInformationSlice.reducer