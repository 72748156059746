import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'
export const getLaporanPerfomaStaff = createAsyncThunk('post/reportPerformaStaff', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportPerformaStaff`, body)
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

