import axios from "axios";
import { endpoint } from "../../../constants";
import { UploadImageModel } from "../../registerReseller/registerReseller.model";
import { FilterModel, PaymentMethodModel } from "./paymentMethod.model";

export default class PaymentMethodApi {
    static createData(model: PaymentMethodModel) {
        return axios
        .post<any>(endpoint + "/api/paymentMethod/Create", model)
        .then((res) => res.data.data);
    }

    static getList() {
        return axios
        .get<any>(endpoint + "/api/paymentMethod/GetList")
        .then((res) => res.data.data);
    }

    static getListWithPaging(model: FilterModel) {
        return axios
        .post<any>(endpoint + "/api/paymentMethod/GetListWithPaging", model)
        .then((res) => res.data.data);
    }

    static getBankList() {
        return axios
        .get<any>(endpoint + "/api/bank/GetList")
        .then((res) => res.data.data);
    }

    static getSingleItem(id: string) {
        return axios
        .get<any>(endpoint +"/api/paymentMethod/GetSingleItem?id=" + id)
        .then((res) => res.data.data);
    }

    static updateData(model: PaymentMethodModel) {
        return axios
        .post<any>(endpoint + "/api/paymentMethod/update", model)
        .then((res) => res.data.data);
    }

    static deleteData(id: string) {
        return axios
        .post<any>(endpoint + "/api/paymentMethod/Delete?Id=" + id)
        .then((res) => [
            { response: res.data.data },
        ]);
    }

    static getRoleList() {
        return axios
        .get<any>(endpoint + "/api/User/GetAllRoleList")
        .then((res) => res.data);
    }

    static uploadImage(model: UploadImageModel) {
        return axios
            .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
            .then((res) => res);
        }

        
}
