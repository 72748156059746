import React, { Component, HTMLAttributes } from 'react';
import './UserPanel.css';

export interface UserPanelProps extends HTMLAttributes<HTMLDivElement> {
  name?: string;
  role?: string;
  imgUrl?: string;
}

// ttps://react.semantic-ui.com/images/wireframe/square-image.png

export default class UserPanel extends Component<UserPanelProps> {
  static defaultProps = {
    imgUrl: '',
  } as UserPanelProps;

  render() {
    const { name, role, imgUrl } = this.props;

    return (
      <div>
       <div className={`user-panel ${this.props.className}`} style={this.props.style}>
         <div className="user-panel__avatar">
           {/* <img src={imgUrl} alt="User" /> */}
         </div>
         <div className="user-panel__info">
           {/* <p>{name || 'Potalava'}</p> */}
           {/* <p className="user-panel__info__online-status">{role || 'Admin'}</p> */}
         </div>
       </div>
       </div>
    );
  }
}
