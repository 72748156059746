import {
  ContractApprovalRequestModel,
  ContractCategoryModel,
  ContractModel,
} from "../contract.model";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import AlertMessage from "../../../../components/alertMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { RootState } from "../../../../app/store";
import { DragSwitch } from "react-dragswitch";

import {
  contractApprovalRequest,
  getAdminList,
  getAdminStockistListByStokist,
  resetStatus,
} from "../contract.reducer";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import moment from "moment";
import { TimePicker } from "antd";
import { DatePicker } from "antd";
import DateRangePicker from "react-bootstrap-daterangepicker";

/*
 * @created by : dikembe mutombo
 * @created date : 05/oct/2021
 */

const ContractForm = ({
  data = {} as ContractModel,
  isEdit = false,
  ...props
}: ContractProps) => {
  const dispatch = useDispatch();

  let history = useHistory();

  let keepRef = useRef<any>(null);

  let dropshipRef = useRef<any>(null);

  const status = useSelector((state: RootState) => state.contract.status ?? "");

  const errorMessage = useSelector(
    (state: RootState) => state.contract.error ?? ""
  );

  const contractCategoryState = useSelector(
    (state: RootState) => state.contract.listContractCategory ?? []
  );

  const userState = useSelector(
    (state: RootState) => state.global.listUser ?? []
  );

  const adminStokistListState = useSelector(
    (state: RootState) => state.contract.adminStocksitList ?? []
  );

  const adminListState = useSelector(
    (state: RootState) => state.contract.adminList ?? []
  );

  const partnerState = useSelector(
    (state: RootState) => state.global.listPartnerByType ?? []
  );

  const productCategoryState = useSelector(
    (state: RootState) => state.global.listProductCategory ?? []
  );

  const [adminStockistId, setAdminStockistId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [endDateContract, setEndDateContract] = useState(new Date());
  const [endDateContractType, setEndDateContractType] = useState(0);
  const [amountAdminStockist, setAmountAdminStockist] = useState(0);
  const [maximumKeep, setMaximumKeep] = useState(0);
  const [startDateMaximumKeep, setStartDateMaximumKeep] = useState(new Date());
  const [endDateMaximumKeep, setEndDateMaximumKeep] = useState(new Date());
  const [maximumDropship, setmaximumDropship] = useState(0);
  const [startDateMaximumDropShip, setStartDateMaximumDropShip] = useState(
    new Date()
  );
  const [endDateMaximumDropShip, setEndDateMaximumDropShip] = useState(
    new Date()
  );
  const [isFreeShipping, setIsFreeShipping] = useState(false);
  const [freeShippingAmount, setFreeShippingAmount] = useState(0);
  const [isPurchaseItem, setIsPurchaseItem] = useState(false);
  const [purchaseItemQty, setPurchaseItemQty] = useState(0);
  const [purchaseItemAmount, setpurchaseItemAmount] = useState(0);
  const [purchaseItemDiscount, setPurchaseItemDiscount] = useState(0);
  const [isMultipleVariant, setIsMultipleVariant] = useState(false);
  const [productCategoryList, setProductCategoryList] = useState<Array<string>>(
    []
  );

  const [unlimitedKeep, setUnlimitedKeep] = useState(false);
  const [unlimitedHutang, setUnlimitedHutang] = useState(false);
  const [maximumHutang, setMaximumHutang] = useState(0);
  const [startMaximumHutangDate, setStartMaximumHutangDate] = useState<string|null>(null);
  const [endMaximumHutangDate, setEndMaximumHutangDate] = useState<string|null>(null);

  const [startTimeKeep, setStartTimeKeep] = useState("00:00");
  const [endTimeKeep, setEndTimeKeep] = useState("00:00");

  const [isActive, setIsActive] = useState(false);

  const [productCategory, setProductCategory] = useState({
    contractStockistId: "",
    productCategoryList: productCategoryList,
  });
  const [isApprove, setIsApprove] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  //set initial data
  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor === Object) {
      setPartnerId(data.partnerId);
      setAdminStockistId(data.adminStockistId);
      setAmountAdminStockist(data.amountAdminStockist);
      setMaximumKeep(data.maximumKeep);
      setStartDateMaximumKeep(data.startDateMaximumKeep);
      setmaximumDropship(data.maximumDropship);
      setIsFreeShipping(data.isFreeShipping);
      setPurchaseItemQty(data.purchaseItemQty);
      setFreeShippingAmount(data.freeShippingAmount);
      setIsPurchaseItem(data.isPurchaseItem);
      setPurchaseItemDiscount(data.purchaseItemDiscount);
      setpurchaseItemAmount(data.purchaseItemAmount);
      setUnlimitedKeep(data.isUnlimitKeep);
      setUnlimitedHutang(data.isUnlimitHutang);
      setMaximumHutang(data.maximumHutang);

      setStartTimeKeep(data.keepStartTime);
      setEndTimeKeep(data.keepEndTime);

      setStartMaximumHutangDate(data.hutangStartTime);
      setEndMaximumHutangDate(data.hutangEndTime);

      setIsActive(data.isActive);
      setEndDateContractType(data.endDateContractType);

      dispatch(getAdminStockistListByStokist(data.partnerId));

      // keepRef.current.setStartDate(
      //   moment(data.startDateMaximumKeep).format("MM/DD/YYYY")
      // );
      // keepRef.current.setEndDate(
      //   moment(data.endDateMaximumKeep).format("MM/DD/YYYY")
      // );

      // dropshipRef.current.setStartDate(
      //   moment(data.startDateMaximumDropShip).format("MM/DD/YYYY")
      // );
      // dropshipRef.current.setEndDate(
      //   moment(data.endDateMaximumDropShip).format("MM/DD/YYYY")
      // );
    } else {
      setAdminStockistId("");
      setAmountAdminStockist(0);
      setMaximumKeep(0);
      setmaximumDropship(0);
      setIsFreeShipping(false);
      setFreeShippingAmount(0);
      setIsPurchaseItem(false);
      setPurchaseItemDiscount(0);
      setPurchaseItemQty(0);
      setpurchaseItemAmount(0);
      setMaximumHutang(0);
      setStartTimeKeep("00:00");
      setEndTimeKeep("00:00");
      setStartMaximumHutangDate(null);
      setEndMaximumHutangDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);



  //alert
  useEffect(() => {
    if (status === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "",
      });
      setShowAlert(true);
    } else if (status === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = message.title;

      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: xhr,
      });
      setShowAlert(true);
    } else if (status === "success-approval-change") {
      setAlertProps({
        variant: "success",
        title: "Update data berhasil.",
        content: "",
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  //initial data category
  useEffect(() => {
    if (contractCategoryState.length !== 0) {
      let arr: string[] = [];
      contractCategoryState.map((item) => {
        arr.push(item.productCategoryId);
      });
      setProductCategoryList(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractCategoryState]);

  useEffect(() => {
    dispatch(getAdminList());
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    if (status === "success") {
      dispatch(resetStatus());
      history.replace("/contract-setting/index");
    } else if (status === "success-approval-change") {
      dispatch(resetStatus());
      history.replace("/contract-setting/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onMaxDropshipDateChange = (event: any) => {
    setStartDateMaximumDropShip(new Date(event.target.value.split("-")[0]));
    setEndDateMaximumDropShip(new Date(event.target.value.split("-")[1]));
  };

  const onMaxKeepDateChange = (event: any) => {
    setStartDateMaximumKeep(new Date(event.target.value.split("-")[0]));
    setEndDateMaximumKeep(new Date(event.target.value.split("-")[1]));
  };

  const onCheckCategory = (item: string) => {
    let newObj = productCategory;
    let newArr = [];
    if (!productCategoryList.includes(item)) {
      newArr = [...productCategoryList, item];
    } else {
      newArr = productCategoryList.filter((a) => a !== item);
    }
    newObj.productCategoryList = newArr;
    setProductCategoryList(newArr);
    setProductCategory(newObj);
  };

  const stringToBoolean = (value: string) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    // return value;
  };

  const onStockistChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    console.log("Stockist ChangeD !! --> " + e.target.value);
    setPartnerId(e.target.value);
    dispatch(getAdminStockistListByStokist(e.target.value));
  };

  const onApprovalChangeClick = (type: number) => {
    dispatch(
      contractApprovalRequest({
        id: data.id,
        status: type,
      } as ContractApprovalRequestModel)
    );
  };

  const onStartTimeKeepChange = (time: any, timeString: string) => {
    console.log(timeString)
    setStartTimeKeep(timeString);
  };

  const onEndTimeKeepChange = (time: any, timeString: string) => {
    setEndTimeKeep(timeString);
  };

  const onStartMaxHutangChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      console.log(value._d.toISOString());
      setStartMaximumHutangDate(date.toISOString());
    }
  };

  const onEndMaxHutangChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      console.log(value._d.toISOString());
      setEndMaximumHutangDate(date.toISOString());
    }
  };

  return (
    <div>
      {isEdit && data.status === 0 ? (
        <>
          <Row>
            <Col md={12}>
              <Button
                className="float-right"
                style={{ width: 160, marginLeft: 10 }}
                variant="danger"
                onClick={() => {
                  if (window.confirm("Reject Reseller ini?"))
                    onApprovalChangeClick(2);
                }}
              >
                Reject
              </Button>

              <Button
                className="float-right"
                style={{ width: 160, marginLeft: 10 }}
                variant="success"
                onClick={() => {
                  if (window.confirm("Approve data Reseller ini?"))
                    onApprovalChangeClick(1);
                }}
              >
                Approve
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}

      <Grid textAlign="center" style={{ marginTop: 10 }}>
        <Card>
          <Card.Body>
            <div>Contract Settings</div>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                props.onSubmit(
                  adminStockistId,
                  amountAdminStockist,
                  maximumKeep,
                  startDateMaximumKeep,
                  endDateMaximumKeep,
                  maximumDropship,
                  startDateMaximumDropShip,
                  endDateMaximumDropShip,
                  isFreeShipping,
                  freeShippingAmount,
                  isPurchaseItem,
                  purchaseItemQty,
                  purchaseItemDiscount,
                  isMultipleVariant,
                  isApprove,
                  productCategory,
                  partnerId,
                  endDateContract,
                  endDateContractType,
                  purchaseItemAmount,
                  unlimitedKeep,
                  unlimitedHutang,
                  maximumHutang,
                  startTimeKeep,
                  endTimeKeep,
                  isActive,
                  startMaximumHutangDate,
                  endMaximumHutangDate
                );
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Stockist
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={onStockistChange}
                    value={partnerId}
                  >
                    <option>select</option>
                    {partnerState.map((Item) => {
                      return <option value={Item.id}>{Item.fullName}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Admin EMORY
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setAdminStockistId(e.target.value);
                    }}
                    value={adminStockistId}
                  >
                    <option>select</option>
                    {adminListState.map((Item) => {
                      return (
                        <option value={Item.id}>
                          {Item.name} ({Item.roleName})
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Total Admin Stockist
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="number"
                    onChange={(e) =>
                      setAmountAdminStockist(parseInt(e.target.value))
                    }
                    value={amountAdminStockist}
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tanggal Berakhir
                </Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label="Tanggal Berakhir"
                    name="endDate"
                    type="radio"
                    checked={endDateContractType === 0}
                    value={0}
                    onChange={() => setEndDateContractType(0)}
                  />
                  <Form.Control
                    style={{ marginTop: 5 }}
                    disabled={endDateContractType === 1 ? true : false}
                    type="date"
                    onChange={(e) =>
                      setEndDateContract(new Date(e.target.value))
                    }
                  />
                  <br />
                  <Form.Check
                    type="radio"
                    name="endDate"
                    label="Unlimited"
                    checked={endDateContractType === 1}
                    value={1}
                    onChange={() => setEndDateContractType(1)}
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Maximal Keep
                </Form.Label>

                <Col sm={4}>
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    className="form-control"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={maximumKeep}
                    onValueChange={({ floatValue }) => {
                      let val = floatValue ?? 0;
                      setMaximumKeep(val);
                    }}
                  />
                </Col>

                <Form.Label column sm={1}>
                  Waktu Berlaku
                </Form.Label>

                {/* {
                  unlimitedKeep?
                  <>  
                  <Col sm={3}>
                  <DateRangePicker ref={keepRef} onEvent={onMaxKeepDateChange}>
                    <input type="text" className="form-control" disabled/>
                  </DateRangePicker>
                </Col>
                  </> : 
                  <>
                  <Col sm={3}>
                  <DateRangePicker ref={keepRef} onEvent={onMaxKeepDateChange}>
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                </Col>
                </>

                } */}

                {unlimitedKeep ? (
                  <>
                    <Col sm={1}>
                      <TimePicker
                        value={moment(startTimeKeep, "HH:MM")}
                        onChange={onStartTimeKeepChange}
                        defaultOpenValue={moment("00:00", "HH:MM")}
                        format={"HH:MM"}
                        disabled
                      />{" "}
                      -
                    </Col>
                    <Col sm={1}>
                      <TimePicker
                        value={moment(endTimeKeep, "HH:MM")}
                        onChange={onEndTimeKeepChange}
                        defaultOpenValue={moment("00:00", "HH:MM")}
                        format={"HH:MM"}
                        disabled
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col sm={1}>
                      <TimePicker
                        value={moment(startTimeKeep, "HH:mm")}
                        onChange={onStartTimeKeepChange}
                        defaultValue={moment("00:00", "HH:mm")}
                        // defaultOpenValue={moment("00:00", "HH:mm")}
                        format={"HH:mm"}
                      />
                    </Col>
                    <Col sm={1}>
                      <TimePicker
                        value={moment(endTimeKeep, "HH:mm")}
                        onChange={onEndTimeKeepChange}
                        defaultValue={moment("00:00", "HH:mm")}
                        // defaultOpenValue={moment("00:00", "HH:mm")}
                        format={"HH:mm"}
                      />
                    </Col>
                  </>
                )}
              </Form.Group>

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}></Form.Label>
                <Col sm={4}>
                  {/* <Form.Check
                    label="Unlimited Keep"
                    name="unlimitedMaxKeep"
                    type="radio"
                    checked={unlimitedKeep === true}
                    // value={unlimitedKeep}
                    // onChange={() => setUnlimitedKeep(stringToBoolean(e.target.value))}
                  /> */}

                  <Form.Check
                    label="Unlimited Waktu Keep"
                    name="unlimitedMaxKeep"
                    checked={unlimitedKeep}
                    onChange={(e) => {
                      setUnlimitedKeep(e.target.checked);
                    }}
                    type="checkbox"
                  />
                </Col>
              </Form.Group>

              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Maximal Hutang
                </Form.Label>
                <Col sm={4}>
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    className="form-control"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={maximumHutang}
                    onValueChange={({ floatValue }) => {
                      let val = floatValue ?? 0;
                      setMaximumHutang(val);
                    }}
                  />
                </Col>
                <Form.Label column sm={1}>
                  Tanggal Berlaku
                </Form.Label>

                {unlimitedHutang ? (
                  <>
                    <Col sm={2}>
                      <DatePicker disabled />
                    </Col>
                    <Col sm={2}>
                      <DatePicker disabled />
                    </Col>
                  </>
                ) : (
                  <>
                   <Col sm={2}>
                  {/* <DateRangePicker
                    ref={dropshipRef}
                    onEvent={onMaxDropshipDateChange}
                  >
                    <input type="text" className="form-control" />
                  </DateRangePicker> */}

                  <DatePicker
                    // defaultValue={moment(new Date(), "YYYY-MM-DD HH:mm")}
                    // defaultValue={moment("", "")}
                    value={
                      startMaximumHutangDate === null ? null :
                      moment(startMaximumHutangDate, "YYYY-MM-DD HH:mm")
                      // startMaximumHutangDate === null ||
                      // startMaximumHutangDate === undefined ||
                      // startMaximumHutangDate === ""
                      //   ? moment(new Date(), "DD-MM-YYYY HH:mm")
                      //   : moment(startMaximumHutangDate, "DD-MM-YYYY HH:mm")
                    }
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onStartMaxHutangChange}
                  />
                </Col>

                <Col sm={2}>
                  <DatePicker
                    // defaultValue={moment(new Date(), "YYYY-MM-DD HH:mm")}
                    // defaultValue={moment("", "")}
                    value={
                      endMaximumHutangDate === null ? null :
                      moment(endMaximumHutangDate, "YYYY-MM-DD HH:mm")
                      // endMaximumHutangDate === null ||
                      // endMaximumHutangDate === undefined ||
                      // endMaximumHutangDate === ""
                      //   ? moment(new Date(), "DD-MM-YYYY HH:mm")
                      //   : moment(endMaximumHutangDate, "DD-MM-YYYY HH:mm")
                    }
                    style={{ width: "100%" }}
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onEndMaxHutangChange}
                  />
                </Col>
                  </>
                )}

               
              </Form.Group>

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}></Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label="Unlimited Hutang"
                    name="unlimitedMaxHutang"
                    checked={unlimitedHutang}
                    onChange={(e) => {
                      setUnlimitedHutang(e.target.checked);
                    }}
                    type="checkbox"
                  />
                </Col>
              </Form.Group>

              {/* <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Category
                </Form.Label>
                <Col sm={2}>
                  {productCategoryState.map((item, index) => {
                    if (
                      contractCategoryState?.some(
                        (i) => i.productCategoryId === item.id
                      )
                    ) {
                      return (
                        <Form.Check
                          label={item.name}
                          name="group1"
                          type="checkbox"
                          value={item.id}
                          onClick={() => onCheckCategory(item.id)}
                          defaultChecked
                        />
                      );
                    } else {
                      return (
                        <Form.Check
                          label={item.name}
                          name="group1"
                          type="checkbox"
                          value={item.id}
                          onClick={() => onCheckCategory(item.id)}
                        />
                      );
                    }
                  })}
                </Col>
              </Form.Group> */}
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Reward
                </Form.Label>
                <Col sm={4}>
                  <Form.Check
                    label="Free shipping"
                    name="reward"
                    checked={isFreeShipping}
                    onChange={(e) => {
                      setIsFreeShipping(e.target.checked);
                    }}
                    type="checkbox"
                  />
                  <br />
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    className="form-control"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={freeShippingAmount}
                    disabled={isFreeShipping === true ? false : true}
                    onValueChange={({ floatValue }) => {
                      let val = floatValue ?? 0;
                      setFreeShippingAmount(val);
                    }}
                  />
                  <br />
                  <Form.Check
                    label="Pembelian Barang"
                    name="reward"
                    type="checkbox"
                    checked={isPurchaseItem}
                    onChange={(e) => {
                      setIsPurchaseItem(e.target.checked);
                    }}
                  />
                  <br />
                  {/* <Form.Control
                    onChange={(e) => {
                      setPurchaseItemQty(parseInt(e.target.value));
                    }}
                    value={purchaseItemAmount}
                    type="number"
                    disabled={isPurchaseItem === true ? false : true}
                  /> */}

                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    className="form-control"
                    inputMode="numeric"
                    prefix="Rp. "
                    value={purchaseItemAmount}
                    disabled={isPurchaseItem === true ? false : true}
                    onValueChange={({ floatValue }) => {
                      let val = floatValue ?? 0;
                      setpurchaseItemAmount(val);
                    }}
                  />

                  <Form.Group className="input-padding">
                    <Form.Label />
                    <NumberFormat
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      className="form-control"
                      inputMode="numeric"
                      prefix="Rp. "
                      value={purchaseItemDiscount}
                      disabled={isPurchaseItem === true ? false : true}
                      onValueChange={({ floatValue }) => {
                        let val = floatValue ?? 0;
                        setPurchaseItemDiscount(val);
                      }}
                    />

                    <span className="small-note-text">* potongan sebesar</span>
                  </Form.Group>
                </Col>
              </Form.Group>

              <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Is Active
                </Form.Label>
                <Col sm={9}>
                  <DragSwitch
                  
                    checked={isActive}
                    onChange={(e) => {
                      setIsActive(e);
                    }}
                  />
                </Col>
              </Form.Group>

              <hr />
              <div>
                <Button
                  className="btn"
                  style={{ width: 125, height: 40 }}
                  variant="info"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      />
    </div>
  );
};

export interface ContractProps {
  onSubmit: (
    adminStockistId: string,
    amountAdminStockist: number,
    maximumKeep: number,
    startDateMaximumKeep: Date,
    endDateMaximumKeep: Date,
    maximumDropship: number,
    startDateMaximumDropShip: Date,
    endDateMaximumDropShip: Date,
    isFreeShipping: boolean,
    freeShippingAmount: number,
    isPurchaseItem: boolean,
    purchaseItemQty: number,
    purchaseItemDiscount: number,
    isMultipleVariant: boolean,
    isApprove: boolean,
    ContractCategory: ContractCategoryModel,
    partnerId: string,
    endDateContract: Date,
    endDateContractType: number,
    purchaseItemAmount: number,
    isUnlimitKeep: boolean,
    isUnlimitHutang: boolean,
    maximumHutang: number,
    startTimeKeep: string,
    endTimeKeep: string,
    isActive: boolean,
    hutangStartTime: string | null,
    hutangEndTime: string | null,
  ) => void;
  data: ContractModel;
  isEdit: boolean;
}

export default ContractForm;
