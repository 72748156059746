import React, {useEffect, useState} from 'react';
import {Container, Form} from "react-bootstrap";
import PageSettingForm from "../components/PageSettingForm";
import {AdditionalPageSettingEnum} from "../../footerSetting/models";
import {getSingleAdditionalPageSetting} from "../../footerSetting/footerSetting.reducer";
import {useDispatch} from "react-redux";

type TabModel = {
    id: string,
    name: string,
    pageTitle: string,
    type: AdditionalPageSettingEnum
}

const WebpageSettingPage = () => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState<TabModel>({
        id: 'aboutUs', name: 'About Us', pageTitle: 'Page - About Us', type: AdditionalPageSettingEnum.AboutUs,
    });

    const [tabs] = useState<TabModel[]>([
        {id: 'aboutUs', name: 'About Us', pageTitle: 'Page - About Us', type: AdditionalPageSettingEnum.AboutUs},
        {id: 'contactUs', name: 'Contact Us', pageTitle: 'Page - Contact Us', type: AdditionalPageSettingEnum.ContactUs},
        {id: 'address', name: 'Address', pageTitle: 'Page - Address', type: AdditionalPageSettingEnum.Address},
        {id: 'returnPolicy', name: 'Return Policy', pageTitle: 'Page - Return Policy', type: AdditionalPageSettingEnum.ReturnPolicy},
        {id: 'sizeGuide', name: 'Size Guide', pageTitle: 'Page - Size Guide', type: AdditionalPageSettingEnum.SizeGuide},
        {id: 'siteMap', name: 'Site Map', pageTitle: 'Page - Site Map', type: AdditionalPageSettingEnum.SiteMap},
        {id: 'status', name: 'Status', pageTitle: 'Page - Status', type: AdditionalPageSettingEnum.Status},
        {id: 'help', name: 'Help', pageTitle: 'Page - Help', type: AdditionalPageSettingEnum.Help},
        {id: 'privacy', name: 'Privacy', pageTitle: 'Page - Privacy', type: AdditionalPageSettingEnum.Privacy},
        {id: 'termAndCondition', name: 'Term And Condition', pageTitle: 'Page - Term And Condition', type: AdditionalPageSettingEnum.TermAndCondition},
    ]);

    useEffect(() => {
        dispatch(getSingleAdditionalPageSetting(activeTab.type))
    }, [activeTab]);

    const onTabClick = (tab: TabModel) => {
        setActiveTab(tab);
    }

    return (
        <Container className="content-body information-store" fluid>
            <Form.Label className="page-title mb-3" column={"sm"}>Web Settings - Page Setting</Form.Label>
            <div className="box">
                <div className="tabs">
                    {
                        tabs.map((res: TabModel)=>(
                            <div key={res.id} className={activeTab.id === res.id ? 'childtab active' : 'childtab'} onClick={() => onTabClick(res)}>
                                {res.name}
                            </div>
                        ))
                    }
                </div>
                <div>
                    {
                        activeTab && <PageSettingForm pageTitle={activeTab.pageTitle} type={activeTab.type} />
                    }

                </div>
            </div>
        </Container>
    );
};

export default WebpageSettingPage;
