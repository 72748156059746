import "../../pesananDalamProses.style.css";
import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { SalesOrderModel } from "../keepPerProduct.model";
import { endpoint } from "../../../../../constants";
import { Link, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import hapus from "./../../../../../assets/vector/delete.png";
import edit from "./../../../../../assets/vector/edit.png";

import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import axios from "axios";
import DeleteMessage from "../../../../../components/DeleteMessage";

const KeepPerProductDetail = () => {
  let { id } = useParams<{ id: string }>();

  let { productName } = useParams<{ productName: string }>();

  let history = useHistory();

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [list, setList] = useState<SalesOrderModel[]>([]);
  const [selectedId, setSelectedId] = useState("");
  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const columns = [
    {
      name: "Nama Pelanggan",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "Kode produk",
      selector: "productCode",
      sortable: true,
    },
    {
      name: "Product Name",
      selector: "productName",
      sortable: true,
    },
    {
      name: "SO Number",
      selector: "soNumber",
      sortable: true,
    },
    {
      name: "order Date",
      selector: (d: { orderDate: any }) => (
        <>
          <p>{d.orderDate.slice(0, 10)}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "varian",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "qty",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "sell Price",
      selector: (d: { grandTotal: any }) => (
        <>
          <NumberFormat
            value={d.grandTotal}
            displayType="text"
            thousandSeparator={true}
            prefix="Rp. "
          />
        </>
      ),
      sortable: true,
    },

    {
      name: "ACTION",
      selector: (d: { partnerId: any; customerName: any }) => (
        <>
          <Link
            to={{
              pathname: `/keep-belum-lunas-detail/${d.partnerId}/${d.customerName}`,
            }}
          >
            <img src={edit}></img>
          </Link>
          {"    "}
          <span
            onClick={() => {
              setSelectedId(d.partnerId);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus}></img>
          </span>
        </>
      ),
    },
  ];
  // end of datatable components

  useEffect(() => {
    axios
      .get<any>(endpoint + "​/api/SalesOrder/GetListByVariant?variantId=" + id)
      .then((res) => setList(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickTambah = () => {
    history.replace("/sales-order/index");
  };

  const onDelete = () => setShowAlert(false);

  const onAlertClose = () => setShowAlert(false);

  return (
    <Container className="content-body" fluid>
      <div onClick={() => history.goBack()} className="form-title">
        <FaChevronLeft />
        {"   "}
        Pesanan Keep Per Product
      </div>
      <Card>
        <br />
        <h5 style={{ paddingLeft: 20 }}>
          Semua Pesanan Keep Dari Product {id.slice(0, 5).toUpperCase()} ({productName})
        </h5>
        <hr />
        <Row>
          <Col md={6}>
            {/* <Button className="button-export" variant="primary">
              Nota keep
            </Button>{" "}
            <Button className="button-export" variant="success">
              Nota Dropship
            </Button>{" "} */}
            <Button className="button-export" variant="outline-info" onClick={onClickTambah}>
              + Tambah
            </Button>{" "}
          </Col>
          {/* <Col md={{ span: 4, offset: 2 }}>
            <Button
              style={{ float: "right", marginRight: 20 }}
              className="button-export"
              variant="outline-info"
            >
              Export to excel
            </Button>{" "}
            <Button style={{ float: "right" }} className="button-export" variant="outline-info">
              Print
            </Button>{" "}
          </Col> */}
        </Row>
        <hr />
        <DataTable
          columns={columns}
          data={list}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          noHeader
          customStyles={TableCustomStyles}
        />
      </Card>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      />
    </Container>
  );
};

export default KeepPerProductDetail;
