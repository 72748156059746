import { useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getListPaymentMethod, getListProduct } from "../../../../global/global.reducer";
import ScanPesananForm from "../components/scanPesananForm";

/*
 * created by : dikembe mutombo
 * created date : 26/oct/2021
 */

const ScanPesananIndex = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  useEffect(() => {
    dispatch(getListPaymentMethod());
    dispatch(getListProduct());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Form.Group>
        <div onClick={() => history.goBack()} className="form-title">
          <FaChevronLeft />
          {"            "}
          Scan Pesanan
        </div>
      </Form.Group>
      <ScanPesananForm />
    </Container>
  );
};

export default ScanPesananIndex;
