import React, { Component, CSSProperties, ReactNode } from 'react';
import logo from '../../assets/logo.png';

export interface SiderProps extends React.HTMLAttributes<HTMLDivElement> {
  logo?: ReactNode;
  logoWrapperStyle?: CSSProperties;
  logoWrapperClass?: string;
  isCompact?: boolean;
  brandName?: ReactNode;
  showBrand?: boolean;
  onLogoBrandClick?: () => void;
}

const defaultBG = 'rgb(255, 255, 255)';
const defaultLogoBGColor = '#fff';
const defaultFontColor = '#fff';

const defaultSiderStyle: CSSProperties = {
  backgroundColor: defaultBG,
};

const defaultLogoStyle: CSSProperties = {
  backgroundColor: defaultLogoBGColor,
  font: defaultFontColor,
};

export default class Sider extends Component<SiderProps> {
  static defaultProps = {
    logo: <img src={logo} alt="brand" className="admin-default-logo" />,
    brandName: <span>Potalava</span>,
    showBrand: false,
    isCompact: false,
  } as SiderProps;

  siderRef: HTMLDivElement | undefined;

  componentDidMount() {
    this.attachClassBody();
    this.attachEvent();
  }

  componentDidUpdate() {
    this.attachClassBody();
    this.attachEvent();
  }

  attachClassBody = () => {
    if (this.props.isCompact) {
      document.body.classList.add('sider-compact');
    } else {
      document.body.classList.remove('sider-compact');
    }
  };

  attachEvent = () => {
    let sider = this.siderRef as HTMLDivElement;
    sider.removeEventListener('mouseenter', this.mouseInBodyEvent);
    sider.removeEventListener('mouseleave', this.mouseOutBodyEvent);

    if (this.props.isCompact) {
      sider.addEventListener('mouseenter', this.mouseInBodyEvent);
      sider.addEventListener('mouseleave', this.mouseOutBodyEvent);
    }
  };

  mouseInBodyEvent = () => {
    document.body.classList.remove('sider-compact');
  };

  mouseOutBodyEvent = () => {
    document.body.classList.add('sider-compact');
  };

  onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (this.props.onLogoBrandClick) this.props.onLogoBrandClick();
  };

  onRefSider = (ref: HTMLDivElement) => {
    this.siderRef = ref;
  };

  render() {
    return (
      <aside
        ref={this.onRefSider}
        className={`admin-sider`}
        style={{ ...defaultSiderStyle, ...(this.props.style || {}) }}
      >
        <a
          href="/"
          onClick={this.onClick}
          className={`admin-sider__logo-wrapper ${this.props.logoWrapperClass}`}
          style={{ ...defaultLogoStyle, ...this.props.logoWrapperStyle }}
        >
          {this.props.logo}

          {this.props.showBrand && (
            <div className="admin-sider__brand-wrapper">{this.props.brandName}</div>
          )}
        </a>

        {this.props.children}
      </aside>
    );
  }
}
