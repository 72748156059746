import axios from "axios";
import { endpoint } from "../../../constants";
import { CollectionTagModel, UploadImageModel } from "./collectionTag.model";

export default class CollectionTagApi {
    static createData(model: CollectionTagModel) {
        return axios
        .post<any>(endpoint + "/api/ProductCollectionTag/Create", model)
        .then((res) => res.data.data);
    }

    static getList() {
        return axios
        .get<any>(endpoint + "/api/ProductCollectionTag/GetList")
        .then((res) => res.data.data);
    }

    static getSingleItem(id: string) {
        return axios
        .get<any>(endpoint +"/api/ProductCollectionTag/GetSingleItem?id=" + id)
        .then((res) => res.data.data);
    }

    static updateData(model: CollectionTagModel) {
        return axios
        .post<any>(endpoint + "/api/ProductCollectionTag/update", model)
        .then((res) => res.data.data);
    }

    static deleteData(id: string) {
        return axios
        .post<any>(endpoint + "/api/ProductCollectionTag/Delete?Id=" + id)
        .then((res) => [
            { response: res.data.data },
        ]);
    }

    static uploadImage(model: UploadImageModel) {
        return axios
        .post<any>(endpoint + "/web/api/Upload/UploadImage", model)
        .then((res) => res);
    }

}
