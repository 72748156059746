import { createSlice } from '@reduxjs/toolkit'
import {
    confirmPayment, getMembershipTier,
    getMembershipTierTransaction, rejectPayment,
} from './membershipTier.api'
import {MembershipTier, MembershipTierTransaction} from "./membershipTier.types";


export interface MembershipTierState {
    membershipTiers: MembershipTier[],
    memberships: MembershipTierTransaction[],
    total: number,
    isGetMembershipTierLoading: boolean,
    isGetMembershipLoading: boolean,
    isConfirmPaymentLoading: boolean,
    isRejectPaymentLoading: boolean,
    confirmStatus: 'pending' | 'success' | 'failed',
    rejectStatus: 'pending' | 'success' | 'failed',
}
const initialState: MembershipTierState = {
    membershipTiers: [],
    memberships: [],
    total: 0,
    isGetMembershipTierLoading: false,
    isGetMembershipLoading: false,
    isConfirmPaymentLoading: false,
    isRejectPaymentLoading: false,
    confirmStatus: 'pending',
    rejectStatus: 'pending',
}

export const membershipTierSlice = createSlice({
    name: 'membershipTier',
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.confirmStatus = 'pending';
            state.rejectStatus = 'pending';
        }
    },
    extraReducers: (builder) => {
        // get membership tier
        builder.addCase(getMembershipTier.pending, (state, action) => {
            state.isGetMembershipTierLoading = true;
        })
        builder.addCase(getMembershipTier.fulfilled, (state, action) => {
            state.isGetMembershipTierLoading = false;
            state.membershipTiers = action.payload?.data?.map((item: MembershipTier )=> ({
                id: item.id,
                title: item.title,
                name: item.title,
                description: item.description,
                icon: item.icon,
                price: item.price,
                pointMultiplier: item.pointMultiplier,
                isActive: item.isActive
            }))
        })
        builder.addCase(getMembershipTier.rejected, (state, action) => {
            state.isGetMembershipTierLoading = false;
        })
        // get membership tier transaction
        builder.addCase(getMembershipTierTransaction.pending, (state, action) => {
            state.isGetMembershipLoading = true;
        })
        builder.addCase(getMembershipTierTransaction.fulfilled, (state, action) => {
            state.isGetMembershipLoading = false;
            state.memberships = action.payload?.data?.list ?? [];
            state.total = action.payload?.data?.total ?? 0;
        })
        builder.addCase(getMembershipTierTransaction.rejected, (state, action) => {
            state.isGetMembershipLoading = false;
        })
        builder.addCase(confirmPayment.pending, (state, action) => {
            state.isConfirmPaymentLoading = true;
            state.confirmStatus = 'pending';
        })
        builder.addCase(confirmPayment.fulfilled, (state, action) => {
            state.isConfirmPaymentLoading = false;
            state.confirmStatus = 'success';
        })
        builder.addCase(confirmPayment.rejected, (state, action) => {
            state.isConfirmPaymentLoading = false;
            state.confirmStatus = 'failed';
        })
        builder.addCase(rejectPayment.pending, (state, action) => {
            state.isRejectPaymentLoading = true;
            state.rejectStatus = 'pending';
        })
        builder.addCase(rejectPayment.fulfilled, (state, action) => {
            state.isRejectPaymentLoading = false;
            state.rejectStatus = 'success';
        })
        builder.addCase(rejectPayment.rejected, (state, action) => {
            state.isRejectPaymentLoading = false;
            state.rejectStatus = 'failed';
        })
    },
})

export const { resetStatus } = membershipTierSlice.actions;
export default membershipTierSlice.reducer;