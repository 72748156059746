import React from 'react'
import { Container, Form } from "react-bootstrap";
import PointTable from '../components/PointTable';

const PointPage = () => {
  return (
    <Container className="content-body" fluid>
      <PointTable/>
    </Container>
  )
}

export default PointPage