import { Card, Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { deleteAttribute, getAttributeList } from "../attribute.reducer";
import setting from "./../../../../assets/vector/setting.png";
import hapus from "./../../../../assets/vector/delete.png";
import edit from "./../../../../assets/vector/edit.png";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DeleteMessage from "../../../../components/DeleteMessage";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const AttributeTable = () => {
  const dispatch = useDispatch();

  const userState = useSelector((state: RootState) => state.attribute.list ?? []);

  const [filterText, setFilterText] = useState("");
  const [isActive, setIsactive] = useState("true");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedId, setSelectedId] = useState("")

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const filteredItems = userState.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.slug && item.slug.toLowerCase().includes(filterText.toLowerCase()))
  );


  const onAlertClose = () => {
    setShowAlert(false);
  };


  const columns = [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    // },
    {
      name: "NAME",
      selector: "name",
      sortable: true,
    },
    // {
    //   name: "SLUG",
    //   selector: "slug",
    //   sortable: true,
    // },
    // {
    //   name: "TYPE",
    //   selector: (d: { type: number }) => (
    //     <p style={{ margin: 10 }}>{d.type === 0 && <span>text</span>}</p>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: "ORDER BY",
    //   // selector: "",
    //   selector: (d: { defaultShortOrder: number }) => (
    //     <p style={{ margin: 10 }}>
    //       {d.defaultShortOrder === 0 && <span>Custom Ordering</span>}
    //       {d.defaultShortOrder === 1 && <span>Name</span>}
    //       {d.defaultShortOrder === 2 && <span>Numeric</span>}
    //       {d.defaultShortOrder === 3 && <span>Terms ID</span>}
    //     </p>
    //   ),
    //   sortable: true,
    // },
    {
      name: "STATUS",
      selector: (d: { isActive: boolean }) => (
        <p style={{ margin: 10 }}>
          {d.isActive === true && <span className={"custom-badge-success"}>Active</span>}
          {d.isActive === false && <span className={"custom-badge-secondary"}>Inactive</span>}
        </p>
      ),
      sortable: true,
    },
    {
      name: "TERMS",
      selector: "terms",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/edit-attribute/${d.id}`,
            }}
          >
            <img src={edit} style={{ paddingRight: 10 }}></img>
          </Link>
          {"        "}
          <span
            onClick={() => {
              // if (window.confirm("Apakah kamu yakin akan menghapus item ini?")) onDelete(d.id);
            
              setSelectedId(d.id);
              setAlertProps({
                variant: "delete",
                title: "Menghapus Data",
                content: "Apakah kamu yakin akan menghapus data ini?",
              });
              setShowAlert(true);
            }}
          >
            <img src={hapus} style={{ paddingRight: 10 }}></img>
          </span>
        </>
      ),
    },
  ];

  const onDelete = (id: string) => {
    if (id !== undefined) {
      dispatch(deleteAttribute(id));
    }
  };

  const onDeleteData = () => {
    if (selectedId !== undefined) {
      dispatch(deleteAttribute(selectedId));
      setShowAlert(false);
    }
  };

  useEffect(() => {
    if (isActive === "true") {
      dispatch(getAttributeList(true));
    } else {
      dispatch(getAttributeList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="page-title">Master Attribute</Form.Label>
          </Form.Group>
        </Col>
        <Link
          to={{
            pathname: "/attribute/create",
          }}
        >
          <Button className="float-right" variant="info" style={{ width: 160 }}>
            + Add Attribute
          </Button>
          {"  "}
        </Link>
        <Button
          className="float-right"
          style={{ width: 130, marginLeft: 10 }}
          variant="outline-info"
        >
          Print
        </Button>{" "}
        <Button
          className="float-right"
          style={{ width: 160, marginLeft: 10 }}
          variant="outline-info"
        >
          Export to excel
        </Button>{" "}
      </Row>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group className="padding-filter ">
                  <Col md={4}>
                    <Form.Control
                      as="select"
                      style={{ height: 35 }}
                      onChange={(e) => {
                        setIsactive(e.target.value);
                      }}
                    >
                      <option value={"true"}>Active</option>
                      <option value={"false"}>Inactive</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <InputGroup className="mb-3 padding-filter" style={{ width: 330 }}>
                  <InputGroup.Prepend className="search-input">
                    <InputGroup.Text
                      style={{ backgroundColor: "#fff", color: "#F5B95D", border: 0 }}
                    >
                      <FaSearch />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    style={{ borderLeft: 0 }}
                    placeholder="Search for an (ID, From, etc)"
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    value={filterText}
                  />
                </InputGroup>
              </Col>
            </Row>

            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              noHeader
              customStyles={TableCustomStyles}
              paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
            />
          </Card.Body>
        </Card>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDeleteData}
      ></DeleteMessage>
    </div>
  );
};

export default AttributeTable;
