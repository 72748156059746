import axios from "axios";
import { endpoint } from "../../../constants";
import { FilterModel, PaymentInModel } from "./paymentIn.model";

export default class PaymentInApi {
  static getListWithPaging(model: FilterModel) {
    return axios
      .post<any>(
        endpoint + "/api/PaymentIn/GetListWithFilter",
        model
      )
      .then((res) => res.data.data);
  }

  static create(model: PaymentInModel) {
    return axios
      .post<any>(
        endpoint + "/api/PaymentIn/Create",
        model
      )
      .then((res) => res.data.data);
  }

  static update(model: PaymentInModel) {
    return axios
      .post<any>(
        endpoint + "/api/PaymentIn/Update",
        model
      )
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(
        endpoint + "/api/PaymentIn/GetSingleItem?id=" + id
      )
      .then((res) => res.data.data);
  }

  static getAllocationHistory(id: string) {
    return axios
      .post<any>(
        endpoint + "/api/PaymentIn/GetListPaymentAllocationDetail?paymentId=" + id
      )
      .then((res) => res.data.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/PaymentIn/Delete?Id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

}
