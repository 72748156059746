import { getConfiguretermsList, resetSingle, resetStatus } from "../attribute.reducer";
import ConfigureTermsIndex from "../containers/ConfigureTermsIndex";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import AlertMessage from "../../../../components/alertMessage";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AttributeModel } from "../attribute.model";
import { RootState } from "../../../../app/store";
import { DragSwitch } from "react-dragswitch";
import { Grid } from "semantic-ui-react";

const AttributeForm = ({
  data = {} as AttributeModel,
  isEdit = false,
  ...props
}: AttributeFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const createStatus = useSelector((state: RootState) => state.attribute.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.attribute.error ?? "");

  

  let history = useHistory();
  const [attributeId, setAttributeId] = useState("")
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isEnableArchive, setIsEnableArchive] = useState(false);
  const [type, setType] = useState(0);
  const [defaultShortOrder, setDefaultShortOrder] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onSlugChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSlug(e.target.value);
  };

  const onTypeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setType(parseInt(e.target.value));
  };

  const onDefaultShortOrderChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDefaultShortOrder(parseInt(e.target.value));
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/attribute/index");
    } else {
      dispatch(resetStatus());
    }
  };

  useEffect(() => {
    if(id !== undefined && id !== ""){
      console.log(id)
      dispatch(getConfiguretermsList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success" && isEdit) {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
      // dispatch(resetSingle())
      // dispatch(resetStatus())
      // history.goBack();
    } else if(createStatus === "success"){
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    }else if (createStatus === "failed") {
      // let arr = JSON.stringify(errorMessage);
      // let parse = JSON.parse(arr);
      // let message = parse.data.message.toString();
      // setAlertProps({
      //   variant: "failed",
      //   title: "Gagal",
      //   content: "Gagal menyimpan data Attribute",
      // });
      // setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setAttributeId(data.id)
      setName(data.name);
      setSlug(data.slug);
      setIsEnableArchive(data.isEnableArchive);
      setType(data.type);
      setDefaultShortOrder(data.defaultShortOrder);
    } else {
      setAttributeId("")
      setName("");
      setSlug("");
      setIsEnableArchive(false);
      setType(0);
      setDefaultShortOrder(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">
          {isEdit ? "Edit Data Attribute" : "Add Data Attribute"}
        </Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ paddingBottom: 30 }}>
        <Card>
          <Card.Body>
            <div>{isEdit ? "Edit Data Attribute" : "Add Data Attribute"}</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();
                  props.onSubmit(name, slug, isEnableArchive, type, defaultShortOrder, isActive);
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" required onChange={onNameChange} value={name} />
                </Col>
              </Form.Group>

              {/* <hr />

              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Slug
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" required onChange={onSlugChange} value={slug} />
                  <Form.Label style={{ paddingTop: 10 }} className="card-subtitle">
                    * filled with lowercase
                  </Form.Label>
                </Col>
              </Form.Group> */}


              {/* <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Enable Archieves
                </Form.Label>
                <Col sm={4}>
                  <DragSwitch
                    checked={isEnableArchive}
                    onChange={(e) => {
                      setIsEnableArchive(e);
                    }}
                  />
                  <br />
                  <Form.Label style={{ paddingTop: 10 }} className="card-subtitle">
                    * enable if you want this attribute to have product archieves in your store
                  </Form.Label>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Type
                </Form.Label>
                <Col sm={4}>
                  <Form.Control as="select" onChange={onTypeChange} value={type}>
                    <option value={0}>Text</option>
                  </Form.Control>
                </Col>
              </Form.Group> */}


              {/* <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Default Sort Order
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    onChange={onDefaultShortOrderChange}
                    value={defaultShortOrder}
                  >
                    <option value={0}>Custom Ordering</option>
                    <option value={1}>Name</option>
                    <option value={2}>Numeric</option>
                    <option value={3}>Terms Id</option>
                  </Form.Control>
                </Col>
              </Form.Group> */}

              
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}
              <Row>
                <Col sm={6}>
                  <Link to={{ pathname: `/attribute/index` }}>
                    <Button className="btn-cancel" variant="light">
                      Cancel
                    </Button>
                    {"  "}
                  </Link>
                  <Button variant="info" style={{ width: 130, height: 45 }} type="submit">
                    Save
                  </Button>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>

        <AlertMessage {...alertProps} show={showAlert} onAlertCloseAction={onAlertClose} />
      </Grid>

      <div>{isEdit ? <ConfigureTermsIndex /> : ""}</div>
    </div>
  );
};

export interface AttributeFormProps {
  onSubmit: (
    name: string,
    slug: string,
    isEnableArchive: boolean,
    type: number,
    defaultShortOrder: number,
    isActive: boolean
  ) => void;
  data: AttributeModel;
  isEdit: boolean;
}

export default AttributeForm;
