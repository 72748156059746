import axios from "axios";
import { endpoint } from "../../constants";
import { FilterModel } from "./point.model";

export default class PointApi {

 static getPointRequestListWithPaging(model: FilterModel) {
  return axios
    .post<any>(endpoint + "/api/WalletRequest/GetListPointWithFilter", model)
    .then((res) => res.data.data);
}}