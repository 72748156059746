import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'



export const postOrderCost = createAsyncThunk('post/postOrderCost', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/Report/ReportOrderCost`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const postExportDailyOrderCost = createAsyncThunk('post/postExportDailyOrderCost', async (body: any) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Report/ReportOrderCostExportToExcel`,
            data: body,
            method: 'post',
            responseType: 'blob',
        })
        console.log(response)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})