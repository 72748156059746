import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Tabs,
  Tab,
  TabContent,
} from "react-bootstrap";
import { RootState } from "../../../../app/store";
import SettingExpeditionForm from "../components/SettingExpeditionResellerForm";
import SettingExpeditionResellerForm from "../components/SettingExpeditionResellerForm";
import SettingExpeditionStockistForm from "../components/SettingExpeditionStockistForm";
import SettingExpeditionEndCustomerForm from "../components/SettingExpeditionEndCustomerForm";

const SettingExpeditionsPage = () => {
  
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label className="title">Delivery</Form.Label>
              </Form.Group>
              <Card>
                <Card.Body>
                  <Tabs>
                    <Tab eventKey="reseller" title="Reseller">
                      <TabContent>
                        <SettingExpeditionResellerForm />
                      </TabContent>
                    </Tab>

                    <Tab eventKey="stockist" title="Stockist">
                    <TabContent>
                        <SettingExpeditionStockistForm />
                      </TabContent>
                    </Tab>

                    <Tab eventKey="customer" title="End Customer">
                    <TabContent>
                        <SettingExpeditionEndCustomerForm />
                      </TabContent>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingExpeditionsPage;
