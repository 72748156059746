import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../app/store';
import Form from '../components/Form';
import { sendEmail } from '../forgotPassword.reducer';
import { ResetPasswordModel } from '../models';


const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.forgotPassword.email ?? ({} as ResetPasswordModel)
  );

  const onSubmit = (email: string) => { 
      dispatch(
        sendEmail(
          email
        )
      );
  };

  return (
    <div>
        <Form 
        onSubmit={onSubmit}
        data={singleDataState}/>
    </div>
  );
};

export default ForgotPasswordPage;
