import axios from "axios";
import { UserModel, checkPinModel, CreateRoleModel, FilterModel } from "./models";
import { endpoint } from "../../constants";

export default class UserApi {
  static createUser(createUser: UserModel) {
    return axios
      .post<any>(endpoint + "/api/User/Register", createUser)
      .then((res) => res.data);
  }

   static getUserList() {
     return axios
       .get<any>(endpoint + "/api/User/GetAll")
       .then((res) => res.data.data);
   }
   
   static getUserListWithPaging(model: FilterModel) {
    return axios
      .post<any>(endpoint + "/api/user/GetAllAdminWithPaging", model)
      .then((res) => res.data.data);
  }
  static getSingleItem(professionalTypeId: string) {
    return axios
       .get<any>(
         endpoint +
           "/api/User/GetSingleItem?id=" +
           professionalTypeId
       )
       .then((res) => res.data.data);
   }

   static updateUser(professionalType: UserModel) {
     return axios
       .post<any>(endpoint + "/api/user/updateUser", professionalType)
       .then((res) => res.data);
   }

   static checkPin(checkPinModel: checkPinModel) {
    return axios
      .post<any>(endpoint + "/api/User/checkPin", checkPinModel)
      .then((res) => res.data);
  }

  static getRole() {
    return axios
      .get<any>(endpoint + "​/api/User/GetAllRoleList")
      .then((res) => res.data);
  }

  static assignRole(Model: CreateRoleModel) {
    return axios
      .post<any>(endpoint + "/api/User/AssignRoleToUser", Model)
      .then((res) => res.data.data);
  }
  static assignMultiRole(Model: CreateRoleModel) {
        return axios
            .post<any>(endpoint + "/api/User/AssignMultiRoleToUser", Model)
            .then((res) => res.data.data);
    }

//   static deleteProfessionalType(professionalType: ProfessionalTypeModel) {
//     return axios
//       .post<any>(endpoint + "/api/ProfessionalType/Delete", professionalType)
//       .then((res) => [
//         { response: res.data.data },
//         { id: professionalType.id },
//       ]);
//   }
}
