import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { convertBase64 } from "../../../../components/Library/Base64";
import Dropzone from "react-dropzone";
import upload from "./../../../../assets/vector/upload.png";
import { DragSwitch } from "react-dragswitch";
import { TreeSelect } from "antd";
import { CollectionTagModel } from "../collectionTag.model";
import { reset, resetStatus } from "../collectionTag.reducer";

const CollectionTagForm = ({
  data = {} as CollectionTagModel,
  isEdit = false,
  ...props
}: CollectionTagFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  let createStatus = useSelector((state: RootState) => state.collectionTag.status ?? "");

  const errorMessage = useSelector((state: RootState) => state.collectionTag.error ?? "");

  let history = useHistory();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [keyword, setKeyword] = useState("");
  const [banner, setBanner] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [fileFormat, setFileFormat] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [validated, setValidated] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(reset());
      dispatch(resetStatus());
      history.replace("/collection-tag/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onKeywordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setKeyword(e.target.value);
  };

  const onBannerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBanner(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: "",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      // let arr = JSON.stringify(errorMessage);
      // let parse = JSON.parse(arr);
      // let message = parse.data.title.toString()
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: "Data Tidak Terisi / Foto Kosong",
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setName(data.name);
      setDescription(data.description);
      setKeyword(data.keyword);
      setIsActive(data.isActive);
      //console.log("banner", data.banner)
    } else {
      setName("");
      setDescription("");
      setBanner("");
      setKeyword("");
      setIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleImage = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setBanner(result);
        console.log("result", result);
      });
    } else {
      console.log("error");
    }
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="title">
          {isEdit ? "Edit Data Collection Tag" : "Add Data Collection Tag"}
        </Form.Label>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <div>{isEdit ? "Edit Data Collection Tag" : "Add Data Collection Tag"}</div>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                const form = e.currentTarget;
                if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  e.preventDefault();

                  props.onSubmit(
                    name,
                    keyword,
                    description,
                    banner,
                    fileFormat,
                    data.banner,
                    isActive
                  );
                }
                setValidated(true);
              }}
            >
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onNameChange} value={name} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Keyword
                </Form.Label>
                <Col sm={5}>
                  <Form.Control type="text" required onChange={onKeywordChange} value={keyword} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Description
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    onChange={onDescriptionChange}
                    value={description}
                  />
                </Col>
              </Form.Group>

              {/* <hr /> */}

              {/* <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Banner
                </Form.Label>
                <Col sm={2}>
                  <Dropzone onDrop={(acceptedFiles) => handleImage(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone file-upload-frame w-100">
                        <div className="dz-message needsclick" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="text-center file-upload-label">
                            <div>
                              {(banner !== "" || Object.keys(data).length !== 0) && (
                                <img
                                  src={
                                    banner !== ""
                                      ? banner
                                      : "https://sandbox-api.emorystyle.com" + data.banner
                                  }
                                  className="file-upload-image mb3"
                                />
                              )}

                              {banner === "" && Object.keys(data).length === 0 && (
                                <>
                                  <div className="file-upload-image mb3" style={{ paddingTop: 50 }}>
                                    <img src={upload} alt="upload" />
                                    <br />
                                    <span style={{ color: "black" }}>Select File</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </Col>
              </Form.Group> */}
              {isEdit ? (
                <>
                  <hr />
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={3}>
                      Active Status
                    </Form.Label>
                    <Col sm={4}>
                      <DragSwitch
                        checked={isActive}
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                      />
                      <br />
                    </Col>
                  </Form.Group>
                  <hr />
                </>
              ) : (
                <></>
              )}
              <div className="btn-footer-group">
                <Link to={{ pathname: `/collection-tag/index` }}>
                  <Button className="btn-cancel" variant="light">
                    Cancel
                  </Button>
                  {"  "}
                </Link>
                <Button variant="info" style={{ width: 130, height: 45 }} type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>
    </div>
  );
};

export interface CollectionTagFormProps {
  onSubmit: (
    name: string,
    banner: string,
    description: string,
    keyword: string,
    fileFormat: string,
    imagePath: string,
    isActive: boolean
  ) => void;
  data: CollectionTagModel;
  isEdit: boolean;
}

export default CollectionTagForm;
