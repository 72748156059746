import { createData, getSingleItem, updateData } from "../supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import SupplierForm from "../components/SupplierForm";
import { RootState } from "../../../../app/store";
import { SupplierModel } from "../supplier.model";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const SupplierPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.supplier.single ?? ({} as SupplierModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    supplierCode: string,
    name: string,
    bankId: string,
    accountNumber: string,
    telephoneNumber: string,
    address: string,
    isActive: boolean
  ) => {
    if (isEdit) {
      dispatch(
        updateData({
          id,
          supplierCode,
          name,
          bankId,
          accountNumber,
          telephoneNumber,
          address,
          isActive,
        } as SupplierModel)
      );
    } else {
      dispatch(
        createData({
          supplierCode,
          name,
          bankId,
          accountNumber,
          telephoneNumber,
          address,
        } as SupplierModel)
      );
    }
  };
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <SupplierForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SupplierPage;
