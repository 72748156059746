import { Form, Row, Col } from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";

import { Input } from "antd";
import { useDispatch } from "react-redux";
import { setSimpleProductShippingTempForm } from "../../product.reducer";
import { ProductModel } from "../../product.model";

import NumberFormat from "react-number-format";
import { valueEventAriaMessage } from "react-select/src/accessibility";

const ShippingProduct = ({
  data = {} as ProductModel,
  productType = 0,
  ...props
}: TabsTypeProps) => {
  const dispatch = useDispatch();

  const [shippingWeight, setShippingWeight] = useState(0);
  const [shippingDimensionsLength, setShippingDimensionsLength] = useState(0);
  const [shippingDimensionsWidth, setShippingDimensionsWidth] = useState(0);
  const [shippingDimensionsHeight, setShippingDimensionsHeight] = useState(0);
  const [shippingClass, setShippingClass] = useState(0);

  const onShippingWeightChange = (value: any) => {
    if (value === null || value === undefined || value === "") {
      setShippingWeight(0);
    } else {
      setShippingWeight(parseFloat(value));
    }
  };

  const onShippingDimensionsLengthChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setShippingDimensionsLength(parseInt(e.target.value));
  };

  const onShippingDimensionsWidthChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setShippingDimensionsWidth(parseInt(e.target.value));
  };

  const onShippingDimensionsHeightChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setShippingDimensionsHeight(parseInt(e.target.value));
  };

  const onShippingClassChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShippingClass(parseInt(e.target.value));
  };

  useEffect(() => {
    dispatch(
      setSimpleProductShippingTempForm({
        shippingWeight,
        shippingDimensionsLength,
        shippingDimensionsWidth,
        shippingDimensionsHeight,
        shippingClass,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingWeight]);

  useEffect(() => {
    dispatch(
      setSimpleProductShippingTempForm({
        shippingWeight,
        shippingDimensionsLength,
        shippingDimensionsWidth,
        shippingDimensionsHeight,
        shippingClass,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDimensionsLength]);

  useEffect(() => {
    dispatch(
      setSimpleProductShippingTempForm({
        shippingWeight,
        shippingDimensionsLength,
        shippingDimensionsWidth,
        shippingDimensionsHeight,
        shippingClass,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDimensionsWidth]);

  useEffect(() => {
    dispatch(
      setSimpleProductShippingTempForm({
        shippingWeight,
        shippingDimensionsLength,
        shippingDimensionsWidth,
        shippingDimensionsHeight,
        shippingClass,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDimensionsHeight]);

  useEffect(() => {
    dispatch(
      setSimpleProductShippingTempForm({
        shippingWeight,
        shippingDimensionsLength,
        shippingDimensionsWidth,
        shippingDimensionsHeight,
        shippingClass,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingClass]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setShippingWeight(data.shippingWeight);
      setShippingDimensionsLength(data.shippingDimensionsLength);
      setShippingDimensionsWidth(data.shippingDimensionsWidth);
      setShippingDimensionsHeight(data.shippingDimensionsHeight);
      setShippingClass(data.shippingClass);
    } else {
      setShippingWeight(0);
      setShippingDimensionsLength(0);
      setShippingDimensionsWidth(0);
      setShippingDimensionsHeight(0);
      setShippingClass(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <hr />
      <Form>
        <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            Weight (Kg)
          </Form.Label>
          <Col sm={8}>
            <NumberFormat
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix=" Kg"
              className="form-control"
              inputMode="text"
              value={shippingWeight}
              onValueChange={({ floatValue }) => {
                let val = floatValue ?? 0;
                onShippingWeightChange(val.toString());
              }}
            />

            {/* <Input
              suffix="Kg"
              type="text"
              required
              onChange={onShippingWeightChange}
              value={shippingWeight}
            /> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="input-padding">
          <Form.Label column sm={4}>
            Dimentions
          </Form.Label>
          <Col sm={8}>
            <Row>
              <Col sm={4}>
                <Input
                  title="Length"
                  suffix="cm"
                  type="number"
                  placeholder="Length"
                  required
                  onChange={onShippingDimensionsLengthChange}
                  value={shippingDimensionsLength}
                />
              </Col>
              <Col sm={4}>
                <Input
                  title="Weight"
                  suffix="cm"
                  placeholder="Weight"
                  type="number"
                  required
                  onChange={onShippingDimensionsWidthChange}
                  value={shippingDimensionsWidth}
                />
              </Col>
              <Col sm={4}>
                <Input
                  title="Height"
                  suffix="cm"
                  type="number"
                  placeholder="Height"
                  required
                  onChange={onShippingDimensionsHeightChange}
                  value={shippingDimensionsHeight}
                />
              </Col>
            </Row>
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} className="input-padding">
            <Form.Label column sm={4}>
              Shipping Class
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={onShippingClassChange}
                value={shippingClass}
              >
                <option value={0}>Flate Rate</option>
                <option value={1}>No Sipping Class</option>
              </Form.Control>
            </Col>
          </Form.Group> */}
      </Form>
    </div>
  );
};

export interface TabsTypeProps {
  data: ProductModel;
  productType: number;
}

export default ShippingProduct;
