
import React, { useEffect, useState, useRef } from 'react'
import '../laporanPelunasan.scss'
import { Container, Form, Button } from "react-bootstrap";
import { FiPrinter } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
// import { deleteSlideShow, getSlideShow } from '../slideShow.api';
// import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { unwrapResult } from '@reduxjs/toolkit';
import { FaFileExport } from 'react-icons/fa';
// import { postExportDailyOrderCost, postOrderCost } from '../dailyMonthlyOrder.api';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { postExportPelunasan, postLaporanPelunasan } from '../laporanPelunasan.api';
import { DatePicker, Space } from 'antd';
import ReactToPrint from 'react-to-print';




export default function LaporanPelunasan() {
    // const history = useHistory()
    const dispatch = useDispatch()
    const { RangePicker } = DatePicker;
    const tableRef = useRef(null);

    // const {listSlideShow} = useSelector((state:RootState) => state.slideShow)

    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)

    const initialValues = {
        "partnerType": 'null',
        "reportType": '1',
        "filterMonthly": moment(new Date()).format('YYYY-MM'),
        "customerName": '',
        "soNumber": '',
        "startDate": '',
        "endDate": '',
        "skip": 0,
        "take": '10',
        page: 1
    }

    const [values, setValues] = useState({
        "partnerType": 'null',
        "reportType": '1',
        "filterMonthly": moment(new Date()).format('YYYY-MM'),
        "customerName": '',
        "soNumber": '',
        "startDate": '',
        "endDate": '',
        "skip": 0,
        "take": '10',
        page: 1
    })

    useEffect(() => {
        getData()
    }, [values.page, values.take, values.skip])

    const handleRangePicker = (event: any, picker: any) => {
        setValues({
            ...values,
            startDate: picker[0],
            endDate: picker[1],
        })
    }


    const handleReset = () => {
        setValues(initialValues)
    }

    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
        console.log(e.target.name)
        console.log(e.target.value)
    }

    const handlePerPage = (e: any) => {
        setValues({
            ...values,
            take: e.target.value,
            skip: 0,
            page: 1
        })
    }


    const handlePagination = (page: any) => {
        setValues({
            ...values,
            skip: parseInt(values.take) * (page - 1),
            page: page
        })
    }

    const getData = () => {
        const data = {
            "partnerType": values.partnerType === 'null' ? null : parseInt(values.partnerType),
            "reportType": parseInt(values.reportType),
            "filterMonthly": new Date(values.filterMonthly).toISOString(),
            "customerName": values.customerName,
            "soNumber": values.soNumber,
            "startDate": values.startDate === "" ? new Date().toISOString() : new Date(values.startDate).toISOString(),
            "endDate": values.endDate === "" ? new Date().toISOString() : new Date(values.endDate).toISOString(),
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        console.log(data)
        dispatch<any>(postLaporanPelunasan(data))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res.data)
                setList(res.data.list)
                setTotal(res.data.total)
            })
            .catch((err: any) => console.log(err))
    }

    const handleExportExcel = (e: any) => {
        const data = {
            "partnerType": values.partnerType === 'null' ? null : parseInt(values.partnerType),
            "reportType": parseInt(values.reportType),
            "filterMonthly": new Date(values.filterMonthly).toISOString(),
            "startDate": values.startDate === "" ? new Date().toISOString() : new Date(values.startDate).toISOString(),
            "endDate": values.endDate === "" ? new Date().toISOString() : new Date(values.endDate).toISOString(),
            "customerName": values.customerName,
            "soNumber": values.soNumber,
            "skip": values.skip,
            "take": parseInt(values.take)
        }
        console.log(data)
        dispatch<any>(postExportPelunasan(data))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res)
                const url = URL.createObjectURL(new Blob([res]));
                console.log(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Laporan Pelunasan - ${values.reportType === '0' ? 'harian' : 'bulanan'} ${moment(new Date(values.filterMonthly)).format('DD-MMM-YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err: any) => console.log(err))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setValues({
            ...values,
            "skip": 0,
            page: 1,
        })
        getData()
    }


    return (
        <Container className="content-body laporanPelunasan" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                Laporan Pelunasan Pembayaran
                <div>
                    <Button className='addor' onClick={handleExportExcel}><FaFileExport /> Export to Excel</Button>
                    <ReactToPrint
                        trigger={() => <Button className='addor'><FiPrinter /> Print</Button>}
                        content={() => tableRef.current}
                    />
                </div>
            </div>
            <div className="box">
                <div className="head w-100">
                    <form onSubmit={handleSubmit}>
                        <div className="row w-100">
                            <div className="col-md-6">
                                <div className="d-flex">
                                    <div className=" col-md-4 mb-3 ">
                                        <label htmlFor="title">Jenis Pembeli</label>
                                    </div>
                                    <div className=" mb-3">
                                        <div className='wrap-radios'>
                                            <Form.Check
                                                className='radiocustom  w-100'
                                                style={{ marginBottom: 13 }}
                                                inline
                                                type='radio'
                                                id='user4'
                                                label='Semua Pelanggan'
                                                name='partnerType'
                                                checked={values.partnerType === 'null'}
                                                value={'null'}
                                                onClick={handleChange}
                                            />

                                            <Form.Check
                                                className='radiocustom  w-100'
                                                style={{ marginBottom: 13 }}
                                                inline
                                                type='radio'
                                                id='user4'
                                                label='Reseller'
                                                name='partnerType'
                                                checked={values.partnerType === '2'}
                                                value={'2'}
                                                onClick={handleChange}
                                            />

                                            <Form.Check
                                                className='radiocustom  w-100'
                                                style={{ marginBottom: 13 }}
                                                inline
                                                type='radio'
                                                id='user4'
                                                label='Stockist'
                                                name='partnerType'
                                                checked={values.partnerType === '1'}
                                                value={'1'}
                                                onClick={handleChange}
                                            />

                                            <Form.Check
                                                className='radiocustom  w-100'
                                                style={{ marginBottom: 13 }}
                                                inline
                                                type='radio'
                                                id='user4'
                                                label='Admin Stockist'
                                                name='partnerType'
                                                checked={values.partnerType === '4'}
                                                value={'4'}
                                                onClick={handleChange}
                                            />

                                            <Form.Check
                                                className='radiocustom  w-100'
                                                style={{ marginBottom: 13 }}
                                                inline
                                                type='radio'
                                                id='user4'
                                                label='End Customer'
                                                name='partnerType'
                                                checked={values.partnerType === '0'}
                                                value={'0'}
                                                onClick={handleChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex">
                                    <div className="col-md-4 ">
                                        <label htmlFor="title">Tanggal Pesanan</label>
                                    </div>
                                    <div className="">
                                        <div className="d-flex align-items-center mb-3 justify-content-between">
                                            <Form.Check
                                                className='radiocustom m-0 '
                                                style={{ marginBottom: 13 }}
                                                inline
                                                type='radio'
                                                id='user4'
                                                label='Harian'
                                                name='reportType'
                                                checked={values.reportType === '0'}
                                                value={'0'}
                                                onClick={handleChange}
                                            />
                                            <Space direction="vertical" size={12}>
                                                <RangePicker
                                                    className='custext'
                                                    onChange={handleRangePicker}
                                                    value={values.startDate !== "" && values.endDate !== "" ? [moment(values.startDate, 'YYYY-MM-DD'), moment(values.endDate, 'YYYY-MM-DD')] : undefined}
                                                    format="YYYY-MM-DD"
                                                />
                                            </Space>
                                        </div>
                                        <div className="d-flex align-items-center mb-3 justify-content-between">
                                            <Form.Check
                                                className='radiocustom m-0 '
                                                style={{ marginBottom: 13 }}
                                                inline
                                                type='radio'
                                                id='user4'
                                                label='Bulanan'
                                                name='reportType'
                                                checked={values.reportType === '1'}
                                                value={'1'}
                                                onClick={handleChange}
                                            />
                                            <input className='custext' type="month" name='filterMonthly' value={values.filterMonthly} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <div className="wrap-inp">
                                        <label htmlFor="title">SO Number</label>
                                        <input className='custext' type="text" placeholder='SO Number' name="soNumber" value={values.soNumber} onChange={handleChange} />
                                    </div>
                                    <div className="wrap-inp">
                                        <label htmlFor="title">Nama Customer</label>
                                        <input className='custext' type="text" placeholder='Nama Customer' value={values.customerName} name="customerName" onChange={handleChange} id="" />
                                    </div>
                                </div>
                            </div>


                            <div className="wrapbutfil col-md-12">
                                <Button className='addor' onClick={handleReset}>Reset</Button>
                                <Button className='addorrev' type='submit'>Filter</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="con">
                    <div className="filter d-flex justify-content-between align-items-center">
                        Showing {list.length} of {total} results
                        <div className="result d-flex justify-content-between align-items-center">
                            <span>Results per page:</span>
                            <select className="custom-select sel" value={values.take} name='take' onChange={handlePerPage}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ overflowX: 'scroll' }}>
                        <table className="table" ref={tableRef}>
                            <thead className="">
                                <tr>
                                    {/* <th scope="col"><input type="checkbox" name="" id="" /></th> */}
                                    <th scope="col">No</th>
                                    <th scope="col">SO Number</th>
                                    <th scope="col">Nama Pembeli</th>
                                    <th scope="col">Jenis Pembeli</th>
                                    <th scope="col">Tanggal Pembayaran</th>
                                    <th scope="col">Metode Pembayaran</th>
                                    {/* <th scope="col">Total modal</th> */}
                                    <th scope="col">Subtotal</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Discount Voucher</th>
                                    <th scope="col">Discount Membership</th>
                                    <th scope="col">Discount Kontrak Stockist</th>
                                    <th scope="col">Discount Stockist Ongkir </th>
                                    <th scope="col">Discount Satuan/Serian </th>
                                    <th scope="col">biaya pengiriman</th>
                                    <th scope="col">Total Penjualan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((res: any, i: number) => (
                                        <tr key={i}>
                                            {/* <th scope="row"><input type="checkbox" name="" id="" /></th> */}
                                            <td>
                                                {
                                                    values.skip === 0 ?
                                                        i + 1
                                                        :
                                                        i + 1 + (values.page - 1) * parseInt(values.take)
                                                }
                                            </td>
                                            <td>{res.soNumber}</td>
                                            <td>{res.customerName}</td>
                                            <td>
                                                {
                                                    res.type === 1 ? 'Stockist' :
                                                        res.type === 2 ? 'Reseller' :
                                                            res.type === 3 ? 'Admin' :
                                                                res.type === 4 ? 'Admin Stockist' :
                                                                    res.type === 0 ? 'End Customer' : null
                                                }
                                            </td>
                                            <td>{moment(res.orderDate).format('DD/MM/YYYY')}</td>
                                            <td>{res.paymentMethod}</td>
                                            {/* <td>{res.totalAsset}</td> */}
                                            <td>{res.subTotal}</td>
                                            <td>{res.discount}</td>

                                            <td>{res.membershipDiscount}</td>
                                            <td>{res.contractDiscount}</td>
                                            <td>{res.contractStockistDiscount}</td>
                                            <td>{res.contractStockistFreeShippingDiscount}</td>
                                            <td>{res.shippingDiscount}</td>

                                            <td>Rp{res.shipmentCost}</td>
                                            <td>{res.grandTotal}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Pagination
                            activePage={values.page}
                            itemClass='item'
                            itemsCountPerPage={parseInt(values.take)}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePagination}
                            prevPageText='⟨ PREVIOUS'
                            itemClassPrev='prev'
                            itemClassNext='next'
                            nextPageText='NEXT ⟩'
                            hideFirstLastPages
                        />
                    </div>
                </div>
            </div>
        </Container>
    )
}
