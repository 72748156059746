import {getDestination, getListDeliveredWithFilter} from "../../global/global.reducer";
import {AppDispatch} from "../../app/store";
import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {DomesticDestinationOption} from "../../global/global.model";


const useAsyncAddressDestinationOption = () => {
    const dispatch : AppDispatch = useDispatch();

    const getAddressDestination = async (inputValue: string) => {

        const resultAction = await dispatch(getDestination(inputValue));

        const originalPromiseResult = unwrapResult(resultAction);
        let _option = originalPromiseResult?.map((item: any) => {
            return {
                value: item.id,
                label: item.label,
                province_name: item.province_name,
                city_name: item.city_name,
                district_name: item.district_name,
                zip_code: item.zip_code,
                id: item.id,
            }
        });
        return _option as DomesticDestinationOption[];
    };

    return {getAddressDestination} as const;
};

export default useAsyncAddressDestinationOption;
