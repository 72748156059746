import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceBySONumber,
  getSalesOrderDetailList,
  getSalesOrderList,
  resetSingle,
} from "../Invoice.reducer";
import { InvoiceModel } from "../Invoice.model";
import { useHistory } from "react-router-dom";

const InvoicePage = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const SingleInvoiceState = useSelector(
    (state: RootState) => state.invoice.single ?? ({} as InvoiceModel)
  );

  const SalesOrderDetailListState = useSelector(
    (state: RootState) => state.invoice.salesOrderDetailList ?? []
  );

  const salesOrderList = useSelector(
    (state: RootState) => state.invoice.salesOrderList ?? []
  );

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [soNumber, setSoNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [dateReceived, setDateReceived] = useState("");

  const [adminGudangName, setAdminGudangName] = useState("");
  const [adminPackingName, setAdminPackingName] = useState("");
  const [adminQCName, setAdminQCName] = useState("");
  const [adminExpeditionName, setAdminExpeditionName] = useState("");

  const [shippmentCost, setShippmentCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);

  const [deliverName, setDeliverName] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientPostalCode, setRecipientPostalCode] = useState("");
  const [expedition, setExpedition] = useState("");
  const [weight, setWeight] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [resiNumber, setResiNumber] = useState("");

  const onSONumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSoNumber(e.target.value);
  };

  const onConfirmClick = () => {
    dispatch(getInvoiceBySONumber(soNumber));
  };

  const onResetClick = () => {
    setSoNumber("")
    setCustomerName("");
    setDateReceived("");
    setAdminGudangName("");
    setAdminQCName("");
    setAdminPackingName("");
    setAdminExpeditionName("");
    setDeliverName("");
    setRecipientName("");
    setRecipientPhoneNumber("");
    setRecipientAddress("");
    setRecipientPostalCode("");
    setExpedition("");
    setWeight(0);
    setReceiptNumber("");
    setShippmentCost(0);
    setDiscount(0);
    setTotal(0);
    dispatch(resetSingle())
  };

  useEffect(() => {
    dispatch(getSalesOrderList())
  }, [])

  useEffect(() => {
    if (
      SingleInvoiceState &&
      SingleInvoiceState.constructor == Object &&
      SingleInvoiceState.id !== undefined
    ) {
      setCustomerName(SingleInvoiceState.customerName);
      setDateReceived(
        SingleInvoiceState.invoiceDate.toString().substring(0, 10)
      );
      setAdminGudangName(SingleInvoiceState.adminGudang);
      setAdminQCName(SingleInvoiceState.qc);
      setAdminPackingName(SingleInvoiceState.packing);
      setAdminExpeditionName(SingleInvoiceState.expedition);
      setDeliverName(SingleInvoiceState.customerName);
      setRecipientName(SingleInvoiceState.customerName);
      setRecipientPhoneNumber(SingleInvoiceState.recipientPhone);
      setRecipientAddress(SingleInvoiceState.recipientAddress);
      setRecipientPostalCode(SingleInvoiceState.postalCode);
      setExpedition(SingleInvoiceState.expeditionName);
      setWeight(SingleInvoiceState.weight);
      setReceiptNumber(SingleInvoiceState.resiNumber);
      setShippmentCost(SingleInvoiceState.shipmentCost);
      setDiscount(SingleInvoiceState.discount);
      setTotal(SingleInvoiceState.total);
      setResiNumber(SingleInvoiceState.resiNumber);

      dispatch(getSalesOrderDetailList(SingleInvoiceState.salesOrderId));
    }
  }, [SingleInvoiceState]);

  const list1 = [
    {
      name: "-",
      quantity: "0",
      price: "0",
      subtotal: "0",
    },
    {
      name: "-",
      quantity: "0",
      price: "0",
      subtotal: "0",
    },
  ];

  const list2 = [
    {
      customerName: customerName,
      recipientName: recipientName,
      recipientPhone: recipientPhoneNumber,
      recipientAddress: recipientAddress,
      recipientPostalCode: recipientPostalCode,
      expedition: expedition,
      weight: weight,
      receipt: receiptNumber,
      resiNumber: resiNumber
    },
  ];

  const columns1 = [
    {
      name: "ITEM NAME",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIANT",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "QTY",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "PRICE",
      selector: "regulerPrice",
      sortable: true,
    },
    {
      name: "SUBTOTAL",
      selector: (d: { quantity: number; regulerPrice: number }) => (
        <>
          <span> Rp. {d.quantity * d.regulerPrice},- </span>
        </>
      ),
    },
  ];

  const columns2 = [
    {
      name: "FROM",
      selector: "customerName",
      sortable: true,
    },
    {
      name: "TO",
      selector: "recipientName",
      sortable: true,
    },
    {
      name: "PHONE NUMBER",
      selector: "recipientPhone",
      sortable: true,
    },
    {
      name: "ADDRESS",
      selector: "recipientAddress",
      sortable: true,
    },
    {
      name: "POSTAL CODE",
      selector: "recipientPostalCode",
      sortable: true,
    },
    {
      name: "EKSPEDISI",
      selector: "expedition",
      sortable: true,
    },
    {
      name: "BERAT",
      selector: "weight",
      sortable: true,
    },
    {
      name: "RESI",
      selector: "resiNumber",
      sortable: true,
    },
  ];

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Button
                className="float-right"
                style={{ width: 160, marginLeft: 10 }}
                variant="outline-info"
                type="submit">
                Back
                {/* onClick={history.goBack()} */}
              </Button>{" "}
              <a href={window.location.origin + "/invoice/print/" + SingleInvoiceState.salesOrderId} target="_blank">
                <Button
                  className="float-right"
                  style={{ width: 160, marginLeft: 10 }}
                  variant="outline-info"
                  type="submit"
                  disabled={SingleInvoiceState.soNumber ? false : true}>
                  Print
                </Button>
              </a>{" "}
              <Form.Group>
                <Form.Label className="title">Invoice Penjualan</Form.Label>
              </Form.Group>
              <Grid>
                <Card>
                  <Card.Body>
                    <b>Data Customer</b>
                    <hr />
                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        SO Number
                      </Form.Label>
                      <Col sm={5}>
                        <Form.Control
                          placeholder="-"
                          type="text"
                          name="salesOrderId"
                          onChange={onSONumberChange}
                          list='salesOrderId'
                          value={soNumber}
                        />
                        <datalist id="salesOrderId">
                          {salesOrderList.map((res: any, i: number) =>
                            <option key={i}>{res.soNumber}</option>
                          )}
                        </datalist>
                      </Col>
                      <Col>
                        <Button
                          style={{ width: 160, marginLeft: 10 }}
                          variant="info"
                          type="submit"
                          onClick={onConfirmClick}>
                          Confirm
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          style={{ width: 120, marginLeft: 4 }}
                          variant="outline-info"
                          type="submit"
                          onClick={onResetClick}>
                          Reset
                        </Button>
                      </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        Customer Name
                      </Form.Label>

                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          disabled
                          // onChange={onInternalUseNumberChange}
                          value={customerName}></Form.Control>
                      </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        Date Received
                      </Form.Label>

                      <Col sm={5}>
                        <Form.Control
                          type="date"
                          placeholder=""
                          required
                          disabled
                          // onChange={onInternalUseNumberChange}
                          value={dateReceived}></Form.Control>
                      </Col>
                    </Form.Group>

                    <hr />
                  </Card.Body>
                </Card>
              </Grid>
              <br />
              <Grid>
                <Card>
                  <Card.Body>
                    <b>Data Karyawan</b>
                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        Nama Admin Gudang
                      </Form.Label>

                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          // onChange={onInternalUseNumberChange}
                          value={adminGudangName}
                          disabled></Form.Control>
                      </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        Nama Admin QC
                      </Form.Label>

                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          // onChange={onInternalUseNumberChange}
                          value={adminQCName}
                          disabled></Form.Control>
                      </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        Nama Admin Packing
                      </Form.Label>

                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          // onChange={onInternalUseNumberChange}
                          value={adminPackingName}
                          disabled></Form.Control>
                      </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={3}>
                        Nama Admin Ekspedisi
                      </Form.Label>

                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          // onChange={onInternalUseNumberChange}
                          value={adminExpeditionName}
                          disabled></Form.Control>
                      </Col>
                    </Form.Group>

                    <hr />
                  </Card.Body>
                </Card>
              </Grid>
              <br />
              <Grid>
                <Card>
                  <Card.Body>
                    <b>Data Belanja</b>
                    <hr />
                    <br />
                    <DataTable
                      columns={columns1}
                      data={SalesOrderDetailListState}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle}
                      noHeader
                      customStyles={TableCustomStyles}
                    />

                    <br />

                    <div>
                      <Row>
                        <Col md={3}>
                          <b>ONGKOS KIRIM</b>
                        </Col>
                        <Col md={3}>
                          <b>Rp. {shippmentCost},-</b>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <b>POTONGAN</b>
                        </Col>
                        <Col md={3}>
                          <b>Rp. {discount},-</b>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <b>TOTAL</b>
                        </Col>
                        <Col md={3}>
                          <b>Rp. {total},-</b>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Grid>
              <br />
              <Grid>
                <Card>
                  <Card.Body>
                    <b>Data Shipping</b>
                    <hr />
                    <DataTable
                      columns={columns2}
                      data={list2}
                      noHeader
                      customStyles={TableCustomStyles}
                    />
                  </Card.Body>
                </Card>

                <br />
              </Grid>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default InvoicePage;
