import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import ContractForm from "../components/contractForm";
import {
  getListPartnerByType,
  getListProductCategory,
  getListUser,
} from "../../../../global/global.reducer";
import {
  createData,
  getListContractCategory,
  getListContractDiscountSatuan,
  getListContractDiscountSerian,
  getSingleContract,
  updateData,
} from "../contract.reducer";
import { ContractCategoryModel, ContractModel, formValuesModel } from "../contract.model";
import { RootState } from "../../../../app/store";
import { FaChevronLeft } from "react-icons/fa";
import ContractSatuanTable from "../components/contractSatuanTable";
import ContractSerianTable from "../components/contractSerianTable";

/*
 * created by : dikembe mutombo
 * created date : 05 oct 2021
 */

const ContractPage = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.contract.single ?? ({} as ContractModel)
  );

  const [isEdit, setIsEdit] = useState(false);
  const [satuan, setSatuan] = useState<formValuesModel[]>([]);
  const [serian, setSerian] = useState<formValuesModel[]>([]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleContract(id));
      dispatch(getListContractCategory(id));
      dispatch(getListContractDiscountSatuan(id));
      dispatch(getListContractDiscountSerian(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(getListUser());
    dispatch(getListProductCategory(id));
    dispatch(getListPartnerByType(1));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSatuanSubmit = (form: formValuesModel[]) => {
    setSatuan(form);
  };

  const onSerianSubmit = (form: formValuesModel[]) => {
    setSerian(form);
  };

  const onSubmit = (
    adminStockistId: string,
    amountAdminStockist: number,
    maximumKeep: number,
    startDateMaximumKeep: Date,
    endDateMaximumKeep: Date,
    maximumDropship: number,
    startDateMaximumDropShip: Date,
    endDateMaximumDropShip: Date,
    isFreeShipping: boolean,
    freeShippingAmount: number,
    isPurchaseItem: boolean,
    purchaseItemQty: number,
    purchaseItemDiscount: number,
    isMultipleVariant: boolean,
    isApprove: boolean,
    ContractCategory: ContractCategoryModel,
    partnerId: string,
    endDateContract: Date,
    endDateContractType: number,
    purchaseItemAmount : number,
    isUnlimitKeep:boolean,
    isUnlimitHutang:boolean,
    maximumHutang: number,
    keepStartTime : string,
    keepEndTime : string,
    isActive: boolean,
    hutangStartTime: string|null,
    hutangEndTime: string|null,
  ) => {
    let contractDiscountList = [...satuan, ...serian];

    if (isEdit) {
      dispatch(
        updateData({
          id,
          adminStockistId,
          amountAdminStockist,
          maximumKeep,
          startDateMaximumKeep,
          endDateMaximumKeep,
          maximumDropship,
          startDateMaximumDropShip,
          endDateMaximumDropShip,
          isFreeShipping,
          freeShippingAmount,
          isPurchaseItem,
          purchaseItemQty,
          purchaseItemDiscount,
          isMultipleVariant,
          isApprove,
          ContractCategory,
          partnerId,
          endDateContract,
          endDateContractType,
          contractDiscount: contractDiscountList,
          isUnlimitKeep,
          isUnlimitHutang,
          purchaseItemAmount,
          isActive,
          maximumHutang,
          keepStartTime,
          keepEndTime,
          hutangStartTime,
          hutangEndTime
        } as ContractModel)
      );
    } else {
      dispatch(
        createData({
          adminStockistId,
          amountAdminStockist,
          maximumKeep,
          startDateMaximumKeep,
          endDateMaximumKeep,
          maximumDropship,
          startDateMaximumDropShip,
          endDateMaximumDropShip,
          isFreeShipping,
          freeShippingAmount,
          isPurchaseItem,
          purchaseItemQty,
          purchaseItemDiscount,
          isMultipleVariant,
          isApprove,
          ContractCategory,
          partnerId,
          endDateContract,
          endDateContractType,
          contractDiscount: contractDiscountList,
          isUnlimitKeep,
          isUnlimitHutang,
          purchaseItemAmount,
          maximumHutang,
          keepStartTime,
          keepEndTime,
          hutangStartTime,
          hutangEndTime
        } as ContractModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <div onClick={() => history.goBack()} className="form-title">
        <FaChevronLeft />
        {"   "}
        {isEdit ? "Edit Contract" : "Add Contract"}
      </div>
      <div>
        <ContractForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
      </div>
      <br />

      <Tabs className="tabs-style" variant="pills" defaultActiveKey="satuan">
        <Tab eventKey="satuan" title="Satuan">
          <br />
          <ContractSatuanTable isEdit={isEdit} onSubmitData={onSatuanSubmit} />
        </Tab>
        <Tab eventKey="serian" title="Serian">
          <br />
          <ContractSerianTable isEdit={isEdit} onSubmitData={onSerianSubmit} />
        </Tab>
      </Tabs>
      <br />
    </Container>
  );
};

export default ContractPage;
