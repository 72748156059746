import { ApiErrorResponse } from "../../..";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FaqModel } from "./settingFaq.model";
import SettingFaqApi from "./settingFaq.api";

export interface SettingFaqSlice {
    singleFaq : FaqModel;
    faqList : FaqModel[];
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getFaqList = createAsyncThunk(
    'FaqListState/FaqList',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await SettingFaqApi.getFaqList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getFaqListByPartnerType = createAsyncThunk(
    'FaqListByPartnerTypeState/FaqListByPartnerType',
    async (partnerType : number, { rejectWithValue }) => {
        try {
            return await SettingFaqApi.getFaqListByPartner(partnerType);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const createFaq = createAsyncThunk(
    'CreateFaqState/CreateFaq',
    async (model: FaqModel, { rejectWithValue }) => {
        try {
            return await SettingFaqApi.createFaq(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updateFaq = createAsyncThunk(
    'UpdateFaqState/UpdateFaq',
    async (model: FaqModel, { rejectWithValue }) => {
        try {
            return await SettingFaqApi.updateFaq(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteFaq = createAsyncThunk(
    'DeleteFaqState/DeleteFaq',
    async (faqId : string, { rejectWithValue }) => {
        try {
            return await SettingFaqApi.deleteFaq(faqId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

const FaqSlice = createSlice({
    name : 'FaqSlice',
    initialState: {} as SettingFaqSlice,
    reducers : {
        resetSingle: (state) => {
            state.singleFaq = {} as FaqModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        }
    },
    extraReducers: (builder) =>{
        builder.addCase(getFaqList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getFaqList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.faqList = payload.data;
        });
        builder.addCase(getFaqList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(createFaq.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(createFaq.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(createFaq.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(deleteFaq.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(deleteFaq.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-delete";
        });
        builder.addCase(deleteFaq.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(updateFaq.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(updateFaq.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success-update";
        });
        builder.addCase(updateFaq.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed-update";
        });
    }
})

export const { resetSingle, resetStatus } = FaqSlice.actions;
export default FaqSlice.reducer;