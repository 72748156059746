import React from 'react';
import { Grid } from 'semantic-ui-react';
import Form from '../components/Form';


const EmailConfirmationPage = () => {
  return (
    <div>
        <Form/>
    </div>
  );
};

export default EmailConfirmationPage;
