import KeepBelumLunasTable from "../components/KeepBelumLunasTable";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../../../../app/store";
import { getKeepBelumLunasList } from "../keepBelumLunas.reducer";
import { getPendingNotification } from "../../../../../global/global.reducer";

/*
 * created by : dikembe mutombo
 * created date : 05/oct/2021
 */

const KeepBelumLunasIndex = () => {
  const dispatch = useDispatch();

  const statusState = useSelector((state: RootState) => state.account.name);

  useEffect(() => {
    //dispatch(getKeepBelumLunasList());
    dispatch(getPendingNotification())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  return (
    <Container className="content-body" fluid>
      <KeepBelumLunasTable />
    </Container>
  );
};

export default KeepBelumLunasIndex;
