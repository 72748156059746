import { createData, getSingleItem, updateData } from "../bank.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { RootState } from "../../../../app/store";
import BankForm from "../components/bankForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BankModel } from "../bank.model";

const BankPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector((state: RootState) => state.bank.single ?? ({} as BankModel));

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    name: string,
    isActive: boolean
  ) => {
    if (isEdit) {
      dispatch(
        updateData({
          id,
          name,
          isActive,
        } as BankModel)
      );
    } else {
      dispatch(
        createData({
          name,
        } as BankModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <BankForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BankPage;
