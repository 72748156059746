import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LSK_USER_SLICE } from "../../constants";
import { ApiErrorResponse } from "../..";
import { CheckTokenModel, LoginCredential } from "./model";
import UserApi from "./user.api";

export interface UserSlice {
  id?: string;
  name?: string;
  username?: string;
  token?: string;
  tokenJWT? : string;
  role?: string;
  roleId?: string;
  tokenStatus?: boolean;
  status?: string;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
}

// - IF EXISTS ON LOCAL STORAGE -
// getting initial slice from local storage
const initialSlice = localStorage.getItem(LSK_USER_SLICE)
  ? (JSON.parse(localStorage.getItem(LSK_USER_SLICE) as string) as UserSlice)
  : {};

export const logUserIn = createAsyncThunk(
  "userLoginState/logUserIn",
  async (loginCredential: LoginCredential, { rejectWithValue }) => {
    try {
      return await UserApi.login(loginCredential);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const checkTokenStatus = createAsyncThunk(
  "checkTokenStatusState/checkTokenStatus",
  async (model : CheckTokenModel, { rejectWithValue }) => {
    try {
      return await UserApi.checkUserToken(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const userSlice = createSlice({
  name: "userLoginState",
  initialState: initialSlice,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    name: (state, action) => {
      state.name = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetStatus: (state, action) => {
      state.status = "";
    },
    logoutUser: (state) => {
      state.username = "";
      state.id = "";
      state.token = "";
      state.tokenJWT = "";
      state.role = "";
      state.roleId = "";
      state.isLoading = false;
      state.status = "";
      state.tokenStatus = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logUserIn.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(logUserIn.fulfilled, (state, { payload }) => {
      state.username = payload.username;
      state.id = payload.id;
      state.token = payload.token;
      state.tokenJWT = payload.token;
      state.role = payload.role;
      state.roleId = payload.roleId;
      state.isLoading = false;
      state.status = "login";
      state.tokenStatus = true;
    });

    builder.addCase(logUserIn.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(checkTokenStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkTokenStatus.fulfilled, (state, { payload }) => {
      state.tokenStatus = payload;
      state.status = "success-check-token";
      state.isLoading = false;
    });
    builder.addCase(checkTokenStatus.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setUsername, setToken, setId, setLoading, setRole, logoutUser } =
  userSlice.actions;

export default userSlice.reducer;
