import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { DragSwitch } from "react-dragswitch";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../../app/store";
import {  getResellerSettingExpedition, getStockistSettingExpedition, resetStatus, updateSettingExpedition } from "../SettingExpedition.reducer";

const SettingExpeditionStockistForm = () => {
    const dispatch = useDispatch();

    const SettingStockistExpeditionListState = useSelector(
      (state: RootState) => state.settingExpedition.stockistExpeditionList ?? []
    );
  
    const SettingExpeditionStatusState = useSelector(
        (state: RootState) => state.settingExpedition.status ?? ""
      );

    const handleToggleChange = (settingExpeditionId :string , settingIsActive : boolean) => {
      dispatch(updateSettingExpedition({
        expeditionSettingId : settingExpeditionId,
        isActive : settingIsActive
      }))
    }
  
    useEffect(() => {
      dispatch(getStockistSettingExpedition());
    }, []);

    useEffect(() => {
        if(SettingExpeditionStatusState==="success-update"){
            dispatch(getStockistSettingExpedition());
            dispatch(resetStatus())
        }
    }, [SettingExpeditionStatusState])
  
    return (
      <div>
        <Form>
          <Grid>
            <br />
            <br />
  
            {SettingStockistExpeditionListState.map((item, index) => (
              <>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={2}>
                    {item.expeditionName}
                  </Form.Label>
                  <Col sm={10}>
                    <DragSwitch
                      checked={item.isActive}
                      onChange={(e) => {
                        handleToggleChange(item.id, !item.isActive);
                      }}
                    />
                  </Col>
                </Form.Group>
                <hr />
              </>
            ))}
  
            {/* <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                JNE
              </Form.Label>
              <Col sm={9}>
                <DragSwitch
                  checked={isActive}
                  onChange={(e) => {
                    setIsActive(e);
                  }}
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                TiKi
              </Form.Label>
              <Col sm={9}>
                <DragSwitch
                  checked={isActive2}
                  onChange={(e) => {
                    setIsActive2(e);
                  }}
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Si Cepat
              </Form.Label>
              <Col sm={9}>
                <DragSwitch
                  checked={isActive3}
                  onChange={(e) => {
                    setIsActive3(e);
                  }}
                />
              </Col>
            </Form.Group>
            <hr /> */}
          </Grid>
        </Form>
      </div>
    );
  };
export default SettingExpeditionStockistForm
