import { endpoint } from "../../../constants";
import { CityModel } from "./city.model";
import axios from "axios";

export default class CityApi {
  static createData(model: CityModel) {
    return axios.post<any>(endpoint + "/api/City/Create", model).then((res) => res.data.data);
  }

  static getList() {
    return axios.get<any>(endpoint + "/api/City/GetListCity").then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/City/GetSingleCity?id=" + id)
      .then((res) => res.data.data);
  }

  static updateData(model: CityModel) {
    return axios.post<any>(endpoint + "/api/City/update", model).then((res) => res.data.data);
  }

  static deleteData(id: string) {
    return axios
      .post<any>(endpoint + "/api/City/Delete?id=" + id)
      .then((res) => [{ response: res.data.data }]);
  }

  static getProvince() {
    return axios
      .get<any>(
        endpoint + "/api/Address/GetProvinceByCountry?Id=76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7"
      )
      .then((res) => res.data.data);
  }
}
