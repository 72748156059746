import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";

import { EventModel, CreateEventModel } from "../event.model";
import { createEvent, getSingleItem, updateEvent } from "../event.reducer";
import EventForm from "../components/EventForm";

const EventPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.event.single ?? ({} as EventModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
     if (id !== undefined) {
       dispatch(getSingleItem(id));
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

  const onSubmit = (form: CreateEventModel) => {
    if (isEdit) {
      dispatch(updateEvent({...form, id: id}));
    } else {
      dispatch(createEvent(form));
    }
  };
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <EventForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
               />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default EventPage;
