import { Card, Form, Row } from "react-bootstrap";
import { Tree } from "antd";
import "../product.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useEffect, useState } from "react";
import {setProductCategoriesTempForm, setProductMembershipTempForm} from "../product.reducer";
import { ProductModel } from "../product.model";

const ProductionCategoriesForm = ({
  data = {} as ProductModel,
  isEdit = false,
  ...props
}: TabsTypeProps) => {
  const dispatch = useDispatch();

  const membershipTiers = useSelector(
    (state: RootState) => state.membershipTier.membershipTiers ?? []
  );

  const [productMembershipTempArray, setProductMembershipTempArray] = useState<
    Array<string>
  >([]);

  // const onCheck = (checkedKeys: any, info: any) => {
  //   console.log("onCheck", checkedKeys.checked);
  //   let array = checkedKeys.checked;
  //   setProductMembershipTempArray(array);
  // };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      setProductMembershipTempArray(data.membershipTierList?.split(",") ?? []);
    }

    // else {
    //   setProductCategoriesTempArray([]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(setProductMembershipTempForm(productMembershipTempArray));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productMembershipTempArray]);

  const onCheck = (item: string) => {
    let newArr = [];
    if (!productMembershipTempArray?.includes(item)) {
      newArr = [...productMembershipTempArray, item];
    } else {
      newArr = productMembershipTempArray?.filter((a) => a !== item);
    }
    setProductMembershipTempArray([item]);
    // dispatch(setSelectedRoleList(newArr.toString()));
  };

  console.log("productMembershipTempArray", productMembershipTempArray);

  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Group as={Row}>
            <Form.Label
              style={{ fontWeight: 610, fontSize: 17, paddingTop: 0 }}
              column
              sm={12}
            >
              Product Membership
            </Form.Label>
          </Form.Group>
          <hr />
          <Form>
            <section>
              {membershipTiers.map((item) => {
                return (
                    <Form.Check
                        label={item.name}
                        name="group1"
                        type="radio"
                        value={item.id.toString()}
                        onClick={() => onCheck(item.id.toString())}
                        checked={
                          productMembershipTempArray?.some((val) => val === item.id.toString())
                        }

                        // defaultChecked
                    />
                );
              })}
              {/*<Tree*/}
              {/*  checkedKeys={productMembershipTempArray}*/}
              {/*  checkable*/}
              {/*  checkStrictly={true}*/}
              {/*  onCheck={onCheck}*/}
              {/*  treeData={membershipTiers}*/}
              {/*/>*/}
            </section>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export interface TabsTypeProps {
  data: ProductModel;
  isEdit: boolean;
}

export default ProductionCategoriesForm;
