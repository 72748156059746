export const moneyParser = (value: any) => {
    let valueString = value.toString()
    let valueList = valueString.split('')
    let resultList: any = []
    valueList.reverse().map((c: any, i: number) => {
        resultList.push(c)
        if ((i + 1) % 3 === 0 && (i + 1) !== valueList.length) {
            resultList.push('.')
        }
    })
    let result = resultList.reverse().join('')
    return "Rp" + result + ",00"
}