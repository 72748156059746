import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import { CreateInternalUseModel, InternalUseModel, UpdateInternalUseModel } from "../internalUse.model";
import InternalUseCreate from "../components/InternalUseCreate";
import { createInternalUse, getInternalUseSingleItem, updateInternalUse } from "../internalUse.reducer";

const InternalUsePage = () => {

    const dispatch = useDispatch();

    let { id } = useParams<{ id: string }>();

    const singleDataState = useSelector(
        (state: RootState) =>
            state.internalUse.single ?? ({} as InternalUseModel)
    );

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (
            singleDataState &&
            Object.keys(singleDataState).length !== 0 &&
            singleDataState.constructor === Object
        ) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleDataState]);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getInternalUseSingleItem(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSubmit = (
        internalUseDate: string,
        status: number,
        internalUserNumber: string,
        note: string,
        warehouseId: string,
        isActive: boolean) => {
            if(isEdit){
                dispatch(
                    updateInternalUse({
                        id,
                        internalUseDate,
                        status,
                        internalUserNumber,
                        note,
                        warehouseId,
                        isActive
                    } as CreateInternalUseModel)
                )
            } else {
                dispatch(
                    createInternalUse({
                        internalUseDate,
                        status,
                        internalUserNumber,
                        note,
                        warehouseId
                    } as CreateInternalUseModel)
                )
            }
            

    };

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <InternalUseCreate
                                onSubmit={onSubmit}
                                data={singleDataState}
                                isEdit={isEdit} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>



    )
}

export default InternalUsePage
