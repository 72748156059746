import { ApiErrorResponse } from "../../..";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationLinkModel } from "./SettingApplicationLink.model";
import SettingApplicationLinkApi from "./SettingApplicationLink.api";

export interface SettingApplicationLinkSlice{
    singleApplicationLinkSetting: ApplicationLinkModel;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getApplicationLinkSetting = createAsyncThunk(
    'getApplicationLinkSettingState/ApplicationLinkSetting',
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await SettingApplicationLinkApi.getApplicationLinkSetting();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateApplicationLinkSetting = createAsyncThunk(
    'updateApplicationLinkSettingState/UpdateApplicationLinkSetting',
    async (model: ApplicationLinkModel, { rejectWithValue }) => {
        try {
            return await SettingApplicationLinkApi.updateApplicationLinkSetting(model);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const SettingApplicationLinkSlice = createSlice({
    name: 'SettingApplicationLink',
    initialState: {} as SettingApplicationLinkSlice,
    reducers : {
        resetSingle: (state) => {
            state.singleApplicationLinkSetting = {} as ApplicationLinkModel
            state.status = ""
        },
        resetStatus: (state) => {
            state.status = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getApplicationLinkSetting.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getApplicationLinkSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.singleApplicationLinkSetting = payload.data;
        });
        builder.addCase(getApplicationLinkSetting.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateApplicationLinkSetting.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(updateApplicationLinkSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(updateApplicationLinkSetting.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
    }
})

export const { resetSingle, resetStatus } = SettingApplicationLinkSlice.actions;
export default SettingApplicationLinkSlice.reducer;

