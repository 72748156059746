import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import Api from "./productCategory.api";
import { CreateProductCategoryModel, FilterModel, ParentListModel, ProductCategoryModel } from "./productCategory.model";

export interface ProductCategorySlice {
  name?: string;
  list?: ProductCategoryModel[];
  // parentList?: ProductCategoryModel[];
  single?: ProductCategoryModel;
  totalRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  dateModified?: string;
  parentList?: ParentListModel[];
}

export const createData = createAsyncThunk(
  "ProductCategoryCreateState/ProductCategoryCreate",
  async (Model: CreateProductCategoryModel, { rejectWithValue }) => {
    try {
      //return await Api.createData(Model);
      if (Model.UploadImageModel.file_name !== "file_name.") {
        const response = await Api.uploadImage(Model.UploadImageModel);
        if (response.status === 200) {
          let ProductCategoryModelData = Model.ProductCategoryData;
          ProductCategoryModelData.image = response.data.imagepath;
          return await Api.createData(ProductCategoryModelData);
        }
      } else {
        let ProductCategoryModelData = Model.ProductCategoryData;
        return await Api.createData(ProductCategoryModelData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "ProductCategorygetListState/ProductCategoryGetList",
  async (isActive: boolean, { rejectWithValue }) => {
    try {
      return await Api.getList(isActive);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWithPaging = createAsyncThunk(
  "ProductCategorygetListWithPagingState/ProductCategoryGetListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "ProductCategorySingleItemState/ProductCategorySingleItem",
  async (customerId: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(customerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "ProductCategoryState/ProductCategoryUpdate",
  async (model: ProductCategoryModel, { rejectWithValue }) => {
    try {
      return await Api.updateData(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateImage = createAsyncThunk(
  "ProductCategoryState/ProductCategoryUpdate",
  async (Model: CreateProductCategoryModel, { rejectWithValue }) => {
    try {
      const response = await Api.uploadImage(Model.UploadImageModel);
      if (response.status === 200) {
        let ProductCategoryModelData = Model.ProductCategoryData;
        ProductCategoryModelData.image = response.data.imagepath;
        return await Api.updateData(ProductCategoryModelData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "ProductCategoryState/ProductCategorydelete",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListParentForDropDown = createAsyncThunk(
  "ProductCategorygetParentListState/ProductCategoryGetParentList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getParentList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const ProductCategorySlice = createSlice({
  name: "ProductCategoryState",
  initialState: {} as ProductCategorySlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    reset: (state) => {
      state.single = {} as ProductCategoryModel;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status = "success";
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.dateModified = new Date().toISOString();
      state.status = "success";
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = "failed";
    });

    builder.addCase(getListParentForDropDown.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListParentForDropDown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.parentList = payload;
    });
    builder.addCase(getListParentForDropDown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const { setFilter, reset, resetStatus } = ProductCategorySlice.actions;
export default ProductCategorySlice.reducer;
