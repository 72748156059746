import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";
import { ApiErrorResponse } from "../..";
import WalletApi from "./wallet.api";
import { ChangeWalletStatusModel, CreateRequestWalletModel, FilterModel, WalletModel } from "./wallet.model";

export interface WalletSlice {
 list? : WalletModel[];
 single? : WalletModel;
 totalRow? : number;
 isLoading?: boolean;
 error?: ApiErrorResponse<any>;
 status?: string;
}

export const getWalletRequestListWithPaging = createAsyncThunk(
 "getWalletRequestListWithPagingState/getWalletRequestListWithPaging",
 async (model: FilterModel, { rejectWithValue }) => {
   try {
     return await WalletApi.getWalletRequestListWithPaging(model);
   } catch (e) {
     return rejectWithValue(e as ApiErrorResponse<any>);
   }
 }
);

export const createWalletRequest = createAsyncThunk(
  "createWalletRequestState/createWalletRequest",
  async (model: CreateRequestWalletModel, { rejectWithValue }) => {
    try {
      return await WalletApi.requestWallet(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

 export const changeWalletStatus = createAsyncThunk(
  "changeWalletStatusState/changeWalletStatus",
  async (model: ChangeWalletStatusModel, { rejectWithValue }) => {
    try {
      return await WalletApi.changeStatusWallet(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

 export const updateWalletRequest = createAsyncThunk(
  "updateWalletRequestState/updateWalletRequest",
  async (model: WalletModel, { rejectWithValue }) => {
    try {
      return await WalletApi.updateWallet(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
 );

export const deleteWallet = createAsyncThunk(
 "deletteWalletState/deleteWallet",
 async (id: string, { rejectWithValue }) => {
   try {
     return await WalletApi.deleteWallet(id);
   } catch (e) {
     return rejectWithValue(e as ApiErrorResponse<any>);
   }
 }
);

const WalletSlice = createSlice({
  name: "WalletState",
  initialState: {} as WalletSlice,
  reducers : {
    resetSingle: (state) => {
      state.single = {} as WalletModel;
      state.status = "";
    },
    resetStatus: (state) => {
      state.status = "";
    }
  },
extraReducers: (builder) => {
  builder.addCase(getWalletRequestListWithPaging.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getWalletRequestListWithPaging.fulfilled, (state, {payload})=> {
    state.list = payload.list;
    state.totalRow = payload.total;
    state.isLoading = false;
  });
  builder.addCase(getWalletRequestListWithPaging.rejected, (state, {payload}) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
  });
  builder.addCase(createWalletRequest.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(createWalletRequest.fulfilled, (state, {payload})=> {
    state.status = "success"
    state.isLoading = false;
  });
  builder.addCase(createWalletRequest.rejected, (state, {payload}) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed"
  });
  builder.addCase(updateWalletRequest.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(updateWalletRequest.fulfilled, (state, {payload})=> {
    state.status = "success-update"
    state.isLoading = false;
  });
  builder.addCase(updateWalletRequest.rejected, (state, {payload}) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed-update"
  });
  builder.addCase(changeWalletStatus.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(changeWalletStatus.fulfilled, (state, {payload})=> {
    state.status = "success-change-status"
    state.isLoading = false;
  });
  builder.addCase(changeWalletStatus.rejected, (state, {payload}) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed-change-status"
  });
  builder.addCase(deleteWallet.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(deleteWallet.fulfilled, (state, {payload})=> {
    state.status = "success-delete"
    state.isLoading = false;
  });
  builder.addCase(deleteWallet.rejected, (state, {payload}) => {
    state.error = payload as ApiErrorResponse<any>;
    state.isLoading = false;
    state.status = "failed-delete"
  });
},
})

export const {resetSingle,resetStatus} = WalletSlice.actions;
export default WalletSlice.reducer;