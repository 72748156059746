import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { endpoint } from '../../../constants'

export const getHowToOrder = createAsyncThunk('get/howToOrder', async () => {
    const response = await axios.get(`${endpoint}/api/WebsitePageSetting/GetList`)
    return response.data
})

export const getHowToOrderDetail = createAsyncThunk('get/howToOrderDetail', async (id: string) => {
    try {
        const response = await axios.get(`${endpoint}/api/WebsitePageSetting/GetSingleItem`, {
            params: {
                id: id
            }
        })
        console.log(response.data.data, 'detail')
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

export const createHowToOrder = createAsyncThunk('api/post/howToOrder', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/WebsitePageSetting/Create`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})
export const updateHowToOrder = createAsyncThunk('api/update/howToOrder', async (body: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/WebsitePageSetting/Update`, body)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})

export const deleteHowToOrder = createAsyncThunk('delete/howToOrder', async (id: any) => {
    try {
        const response = await axios.post(`${endpoint}/api/WebsitePageSetting/Delete`, {}, {
            params: {
                id: id
            }
        })
        console.log(response)
        return response
    }
    catch (error: any) {
        return error.response
    }
})