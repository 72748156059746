import { createSlice } from '@reduxjs/toolkit'
import { postImageUpload } from '../../master/templateBroadcast/templateBroadcast.api'
import { getFormatNota, getFormatNotaDetail, updateFormatNota } from './formatNota.api'


export interface FormatNotaState {
  loading : boolean,
  loadingImage : boolean,
  listFormatNota : any,
}
const initialState: FormatNotaState = {
  loading : false,
  loadingImage : false,
  listFormatNota : []
}

export const formatNota = createSlice({
    name: 'format nota',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        //Get
        builder.addCase(getFormatNota.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getFormatNota.fulfilled, (state, action) => {
            state.loading = false
            console.log(action.payload.data)
            state.listFormatNota = action.payload.data
        })
        builder.addCase(getFormatNota.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(postImageUpload.pending, (state, action) => {
          state.loadingImage = true
        })
        builder.addCase(postImageUpload.fulfilled, (state, action) => {
            state.loadingImage = false
        })
        builder.addCase(postImageUpload.rejected, (state, action) => {
            state.loadingImage = false
        })
        // //post
        // builder.addCase(postImageUpload.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(postImageUpload.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(postImageUpload.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //delete
        // builder.addCase(deleteSlideShow.pending, (state, action) => {
        //   state.loading = true
        // })
        // builder.addCase(deleteSlideShow.fulfilled, (state, action) => {
        //     state.loading = false
        // })
        // builder.addCase(deleteSlideShow.rejected, (state, action) => {
        //     state.loading = false
        // })
        // //detail
        builder.addCase(getFormatNotaDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getFormatNotaDetail.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getFormatNotaDetail.rejected, (state, action) => {
            state.loading = false
        })
        //update
        builder.addCase(updateFormatNota.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateFormatNota.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateFormatNota.rejected, (state, action) => {
            state.loading = false
        })
    },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default formatNota.reducer