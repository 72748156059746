import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { ApiErrorResponse } from "../..";
import ConfirmPasswordApi from './ConfirmPassword.api';
import { ConfirmPasswordModel } from './ConfirmPasswordModel';

export interface ChangePasswordSlice {
    password?: string;
    confirmPassword?: StaticRange;
    data?: ConfirmPasswordModel;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const changePassword = createAsyncThunk(
    'createProfessionalTypeState/createProfessionalType',
    async (changePasswordModel: ConfirmPasswordModel, {rejectWithValue}) => {
        try {
            return await ConfirmPasswordApi.changePassword(changePasswordModel);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);


const ChangePasswordSlice = createSlice({
    name: 'createProfessionalTypeState',
    initialState: {} as ChangePasswordSlice,
    reducers: {
        resetSingle: (state) => {
            state.data = {} as ConfirmPasswordModel
        },
        resetStatus: (state) => {
            state.status = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changePassword.pending, (state) => {
          state.isLoading = true;
          state.status = "";
        });
        builder.addCase(changePassword.fulfilled, (state, { payload }) => {
          state.password = payload
          state.isLoading = false;
          state.status = "success";
        });
        builder.addCase(changePassword.rejected, (state, { payload }) => {
          state.error = payload as ApiErrorResponse<any>;
          state.isLoading = false;
          state.status = "failed";
        });
      },
})

export const { resetSingle,resetStatus } = ChangePasswordSlice.actions;
export default ChangePasswordSlice.reducer;