import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import UOMTable from '../components/UOMTable';
import { getList } from '../UOM.reducer';

const UOMTablePage = () => {
  const dispatch = useDispatch();
  const State = useSelector(
    (state: RootState) => state.UOM.name
  );
  const listDataState = useSelector(
    (state: RootState) => state.UOM.list
  );

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [State]);

  return (
   <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <UOMTable />
        </Col>
      </Row>
    </Container>
  );
};

export default UOMTablePage;
