import { CreateAttribute, getAttributeSingleItem, updateAttribute } from "../attribute.reducer";
import AttributeForm from "../components/atrributeForm";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { AttributeModel } from "../attribute.model";
import { RootState } from "../../../../app/store";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const AttributePage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.attribute.single ?? ({} as AttributeModel)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getAttributeSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (
    name: string,
    slug: string,
    isEnableArchive: boolean,
    type: number,
    defaultShortOrder: number,
    isActive: boolean
  ) => {
    if (isEdit) {
      dispatch(
        updateAttribute({
          id,
          name,
          slug,
          isEnableArchive,
          type,
          defaultShortOrder,
          isActive,
        } as AttributeModel)
      );
    } else {
      dispatch(
        CreateAttribute({
          name,
          slug,
          isEnableArchive,
          type,
          defaultShortOrder,
        } as AttributeModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <AttributeForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AttributePage;
