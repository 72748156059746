import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { SalesOrderModel } from "./jatuhTempoDropship/jatuhTempoDropship.model";
import { KeepBelumLunasModel } from "./keepBelumLunas/keepBelumLunas.model";
import { FilterModel, ShipmentModel } from "./model";
import Api from "./pesananDalamProses.api";

export interface PesananDalamProsesSlice {
  single?: ShipmentModel;
  list?: KeepBelumLunasModel[];
  totalRow? : number;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  modifiedDate?: string;
}

export const getSingleShipment = createAsyncThunk(
  "pesananDalamProses/getSingle",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleShipment(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListKeepUnpaid = createAsyncThunk(
  "getListKeepUnpaidState/getListKeepUnpaid",
  async (Model: FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getListKeepUnpaid(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateShipmentData = createAsyncThunk(
  "SalesOrderState/updateShipment",
  async (Model: ShipmentModel, { rejectWithValue }) => {
    try {
      return await Api.updateShipment(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateSalesOrder = createAsyncThunk(
  "SalesOrderState/updateSalesOrder",
  async (Model: SalesOrderModel, { rejectWithValue }) => {
    try {
      return await Api.updateSalesOrder(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const PesananDalamProsesSlice = createSlice({
  name: "pesananDalamProsesState",
  initialState: {} as PesananDalamProsesSlice,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleShipment.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleShipment.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleShipment.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListKeepUnpaid.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getListKeepUnpaid.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListKeepUnpaid.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(updateShipmentData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(updateShipmentData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success";
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(updateShipmentData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export const { resetStatus } = PesananDalamProsesSlice.actions;
export default PesananDalamProsesSlice.reducer;
