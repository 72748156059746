import {AdditionalPageSetting, AdditionalPageSettingEnum} from "./models";
import {ApiErrorResponse} from "../../../index";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import FooterSettingApi from "./footerSetting.api";


export interface FooterSettingSlice {
    singleData : AdditionalPageSetting;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
}

export const getSingleAdditionalPageSetting = createAsyncThunk(
    'webSetting/getSingleAdditionalPageSetting',
    async (type: AdditionalPageSettingEnum, { rejectWithValue }) => {
        try {
            return await FooterSettingApi.getSingleAdditionalPageSetting(type);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    });

export const updateAdditionalPageSetting = createAsyncThunk(
    'webSetting/updateAdditionalPageSetting',
    async (args: AdditionalPageSetting, { rejectWithValue }) => {
        try {
            return await FooterSettingApi.updateAdditionalPageSetting(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    });

const FooterSettingSlice = createSlice({
    name : 'FooterSettingSlice',
    initialState : {} as FooterSettingSlice,
    reducers : {
        resetSingle: (state) => {
            state.singleData = {} as AdditionalPageSetting
        },
        resetStatus : (state) => {
            state.status = "pending";
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getSingleAdditionalPageSetting.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSingleAdditionalPageSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.singleData = payload.data;
        });
        builder.addCase(getSingleAdditionalPageSetting.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.singleData = {} as AdditionalPageSetting;
        });
        builder.addCase(updateAdditionalPageSetting.pending, (state) => {
            state.isLoading = true;
            state.status = "pending";
        });
        builder.addCase(updateAdditionalPageSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(updateAdditionalPageSetting.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "error";
        });
    }
});

export const { resetSingle, resetStatus } = FooterSettingSlice.actions;
export default FooterSettingSlice.reducer;