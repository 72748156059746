import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Col, Form, Row, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { RootState } from "../../../app/store";
import { ShipmentModel, ThirdPartyTypeEnum } from "../customer.model";
import { getShipmentBySalesOrder, updateCustomerShipment } from "../customer.reducer";

const CustomerEditShipment = () => {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();


  const [shipmentId, setShipmentId] = useState("")
  const [soNumber, setSoNumber] = useState("-");
  const [customerName, setCustomerName] = useState("-");
  const [deliveryName, setDeliveryName] = useState("-");
  const [recipientName, setRecipientName] = useState("-");
  const [recipientPhone, setRecipientPhone] = useState("-");

  const [recipientAddress, setRecipientAddress] = useState("-");
  const [recipientProvinceId, setRecipientProvinceId] = useState("");
  const [recipientCityId, setRecipientCityId] = useState("");
  const [recipientDistrictId, setRecipientDistrictId] = useState("");
  const [recipientPostalCode, setRecipientPostalCode] = useState("");

  const [thirdPartyId, setThirdPartyId] = useState("");
  const [expeditionId, setExpeditionId] = useState("");
  const [expeditionReceipt, setExpeditionReceipt] = useState("");
  const [rateType, setRateType] = useState(0);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [weight, setWeight] = useState(0);
  const [isFreeShipingCost, setIsFreeShipingCost] = useState(false);
  const [shipingCost, setShipingCost] = useState(0);
  const [adminFee, setAdminFee] = useState(0);

  const [thirdParty, setThirdParty] = useState(ThirdPartyTypeEnum.None);



  const shipmentState = useSelector(
    (state: RootState) => state.customer.singleShipment ?? ({} as ShipmentModel)
  );

  const onrecipientNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRecipientName(e.target.value)
  }

  const onRecipientProvinceChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRecipientProvinceId(e.target.value);
  };
  const onRecipientCityChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRecipientCityId(e.target.value);
  };

  const onRecipientDistrictChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRecipientDistrictId(e.target.value);
  };

  const onThirdPartyChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setThirdPartyId(e.target.value);
  };

  const onExpeditionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setExpeditionId(e.target.value);
  };

  const onRateTypeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    // setRateType(e.target.value)
  };

  const onSave = () => {
    dispatch(updateCustomerShipment(
      {
        id : shipmentId,
        deliveryName: deliveryName,
        shippingAddress: recipientAddress,
        recipientName: recipientName
      } as ShipmentModel
    ))
  }

  const onCancelClick = () => {
    history.replace("/customer-so-detail/" + id)
  }

  useEffect(() => {
    dispatch(getShipmentBySalesOrder(id));
  }, []);

  useEffect(() => {
    if (
      shipmentState &&
      Object.keys(shipmentState).length !== 0 &&
      shipmentState.constructor == Object
    ) {
      setShipmentId(shipmentState.id)
      setCustomerName(shipmentState.deliveryName);
      setDeliveryName(shipmentState.deliveryName);
    }
  }, [shipmentState]);

  return (
    <div>
      <Button
        className="float-right"
        style={{ width: 160, marginLeft: 10 }}
        variant="info"
        type="submit"
        onClick={onSave}
        >
        Save
      </Button>
      <Button
        className="float-right"
        style={{ width: 130, marginLeft: 10 }}
        variant="outline-info"
        onClick={() => onCancelClick()}
      >
        Cancel
      </Button>{" "}
      <Form.Group>
        <Form.Label className="title">Shipment</Form.Label>
      </Form.Group>
      <Grid textAlign="center">
        <Card>
          <Card.Body>
            <div>
              <b>Edit Shipment</b>
            </div>
            <hr />

            <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  SO Number
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={soNumber} disabled />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Customer
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={customerName} disabled />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Dikirim Dari
                </Form.Label>
                <Col sm="5">
                <Form.Control
                    as="select"
                    // onChange={onRecipientCityChange}
                    // value={recipientCityId}
                    >
                    <option>select</option>
                    {/* {provinceState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })} */}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Kepada
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={recipientName} onChange={onrecipientNameChange} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Telepon Penerima
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={recipientPhone} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Alamat Penerima
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={recipientAddress}
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Provinsi
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    onChange={onRecipientProvinceChange}
                    value={recipientProvinceId}>
                    <option>select</option>
                    {/* {provinceState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })} */}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Kota
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    onChange={onRecipientCityChange}
                    value={recipientCityId}>
                    <option>select</option>
                    {/* {provinceState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })} */}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Kecamatan
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    onChange={onRecipientDistrictChange}
                    value={recipientDistrictId}>
                    <option>select</option>
                    {/* {provinceState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })} */}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Kode Pos
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={recipientPostalCode} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Third Party
                </Form.Label>
                <Col sm={5}>
                <Form.Control
                as="select"
                onChange={(e) => {
                  setThirdParty(parseInt(e.target.value));
                }}
                value={thirdParty}>
                <option value={ThirdPartyTypeEnum.None}>None</option>
                <option value={ThirdPartyTypeEnum.Lazada}>Lazada</option>
                <option value={ThirdPartyTypeEnum.Tokopedia}>Tokopedia</option>
                <option value={ThirdPartyTypeEnum.Shopee}>Shopee</option>
                <option value={ThirdPartyTypeEnum.Blibli}>Blibli</option>
                <option value={ThirdPartyTypeEnum.BukaLapak}>Bukalapak</option>
                <option value={ThirdPartyTypeEnum.JDid}>JDid</option>
                <option value={ThirdPartyTypeEnum.Orami}>Orami</option>
                <option value={ThirdPartyTypeEnum.Other}>Other</option>
              </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Ekspedisi
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    onChange={onExpeditionChange}
                    value={expeditionId}>
                    <option>select</option>
                    {/* {provinceState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })} */}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  No. Resi
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={expeditionReceipt} />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Tipe Tarif
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    as="select"
                    onChange={onRateTypeChange}
                    value={rateType}>
                    <option>select</option>
                    {/* {provinceState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })} */}
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Total Belanja
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={total} disabled />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Berat
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={weight} disabled />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Total Ongkos Kirim
                </Form.Label>
                <Col sm={5}>
                  <Form className="align-left">
                    <Form.Row>
                      <Col>
                        <Form.Check
                          label="Ongkos Kirim Manual"
                          type="radio"
                          // checked={gender === 0}
                          value={0}
                          // onClick={() => setGender(0)}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          // checked={gender === 1}
                          value={1}
                          // onClick={() => setGender(1)}
                          label="Gratis ongkos Kirim"
                        />
                      </Col>
                    </Form.Row>
                  </Form>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3"></Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={shipingCost} disabled />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Total Pembayaran
                </Form.Label>
                <Col sm="5">
                  <Form.Control type="text" value={grandTotal} disabled />
                </Col>
              </Form.Group>
              <hr />
            </Form>
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export default CustomerEditShipment;
