import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import hapus from "./../../../../assets/vector/delete.png";

import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { RootState } from "../../../../app/store";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {
  InventoryMoveDetailModel,
  ProductOptionModel,
  VariantModel,
} from "../inventoryMove.model";
import DeleteMessage from "../../../../components/DeleteMessage";
import {
  createDetailData,
  deleteInventoryMoveDetail,
  getListVariationByProduct,
  getProductListByWarehouse, resetListVariationByProduct,
} from "../inventoryMove.reducer";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import useAsyncProductOption from "../../../../hooks/product/useAsyncProductOption";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const ProductTableComponent = ({
  isEdit = false,
  inventoryMoveId = "",
  isApproved = false,
  ...props
}: InventoryMoveDetailProps) => {
  const dispatch = useDispatch();

  const productListState = useSelector(
    (state: RootState) => state.global.listProduct ?? []
  );

  const UOMListState = useSelector(
    (state: RootState) => state.global.listUOM ?? []
  );

  const listInventoryMoveDetailState = useSelector(
    (state: RootState) => state.inventoryMove.listInventoryMoveDetail ?? []
  );

  const selectedWarehouseIdState = useSelector(
    (state: RootState) => state.inventoryMove.selectedWarehouseId ?? ""
  );

  const productListByWarehouseState = useSelector(
    (state: RootState) => state.inventoryMove.productListByWarehouse ?? []
  );

  const variationState = useSelector(
    (state: RootState) => state.inventoryMove.listVariationByProduct ?? []
  );

  const [productList, setProductList] = useState<ProductOptionModel[]>([]);

  // datatable components
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [inventoryMoveDetail, setInventoryMove] = useState<
    InventoryMoveDetailModel[]
  >([]);
  const [uomId, setUom] = useState("");
  const [uomName, setUomName] = useState("");

  const [productId, setProduct] = useState("");
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productSku, setProductSku] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [note, setNote] = useState("");
  const [variationName, setVariationName] = useState("");
  const [warehouseStockValidation, setWarehouseStockValidation] = useState(0);

  const [varianId, setVarianId] = useState("");
  const [selectedProductVariantList, setSelectedProductVariantList] = useState<
    VariantModel[]
  >([]);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [timer, setTimer] = useState(setTimeout(() => {
  }, 1000));

  const {getProductOptions} = useAsyncProductOption();

  const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProduct(e.target.value);
    setProductName(e.target.value.split("&")[1]);
    setProductCode(e.target.value.split("&")[2]);

    console.log(e.target.value);
    // setSelectedProductVariantList(
    //   productListByWarehouseState.filter((item) => item.id === e.target.value.split("&")[0])[0].variantList ?? []
    // );
  };

  const onSelectedProductChange = (product: any) => {
    setVarianId("");
    dispatch(resetListVariationByProduct());
    setProduct(product.id);
    setProductSku(product.sku);
    setProductName(product.productName);
    dispatch(getListVariationByProduct(product.id));
  };

  const onProductVariantChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVarianId(e.target.value.split("&")[0]);
    setVariationName(e.target.value.split("&")[1]);
    setWarehouseStockValidation(parseInt(e.target.value.split("&")[7]));
    console.log(e.target.value.split("&")[6]);
  };

  const onUOMChange = (e: ChangeEvent<HTMLInputElement>) => {
    // setUom(e.target.value);
    setUomName(e.target.value.split("&")[1]);
  };

  const onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const onQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.value === undefined) {
      setQuantity(0);
    } else {
      setQuantity(parseInt(e.target.value));
    }
  };

  useEffect(() => {
    if (selectedWarehouseIdState !== "") {
      dispatch(getProductListByWarehouse(selectedWarehouseIdState));
    }
  }, [selectedWarehouseIdState]);

  useEffect(() => {
    if (productListByWarehouseState.length !== 0) {
      setProductList([]);
      productListByWarehouseState.map((product) => {
        setProductList((prevState) => [
          ...prevState,
          {
            id: product.id,
            sku: product.inventorySKU,
            productName: product.name,
            label: product.inventorySKU + " - " + product.name,
            uom: product.uom,
          },
        ]);
      });
    }
  }, [productListByWarehouseState]);

  const columns = [
    {
      name: "SKU",
      selector: "productSku",
      sortable: true,
    },
    {
      name: "PRODUCT NAME",
      selector: "productName",
      sortable: true,
    },
    {
      name: "VARIAN",
      selector: "variationName",
      sortable: true,
    },
    {
      name: "Quantity",
      selector: "quantity",
      sortable: true,
    },

    {
      name: "UOM",
      selector: () => (
        <>
          <span>PCS</span>
        </>
      ),
    },
    {
      name: "NOTE",
      selector: "note",
      sortable: true,
    },
    {
      name: "ACTION",
      selector: (d: { id: any }, index: number) => (
        <>
          {!isApproved ? (
            <>
              <span
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedId(d.id);
                  setAlertProps({
                    variant: "delete",
                    title: "Menghapus Data",
                    content: "Apakah kamu yakin akan menghapus data ini?",
                  });
                  setShowAlert(true);
                }}
              >
                <img src={hapus} style={{ paddingRight: 10 }}></img>
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setInventoryMove(listInventoryMoveDetailState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInventoryMoveDetailState]);

  console.log(inventoryMoveDetail);

  useEffect(() => {
    props.onSubmitData(inventoryMoveDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryMoveDetail]);

  const onSaveBtnClick = () => {
    if (!isEdit) {
      setInventoryMove((prevState) => [
        ...prevState,
        {
          productId: productId.split("&")[0],
          variantId: varianId,
          productCode,
          productName,
          quantity,
          uomId: null,
          uomName,
          note,
          variationName,
          productSku,
        } as unknown as InventoryMoveDetailModel,
      ]);
    } else {
      dispatch(
        createDetailData({
          inventoryMoveId: inventoryMoveId,
          listDetail: [
            {
              id: selectedId,
              productId: productId.split("&")[0],
              variantId: varianId,
              productCode: productCode,
              productName: productName,
              quantity: quantity,
              uomId: null,
              uomName: uomName,
              note: note,
              variationName: variationName,
              productSku: productSku,
            },
          ],
        })
      );
    }

    setShow(false);

    setProduct("");
    setVarianId("");
    setQuantity(0);
    setNote("");
    setVariationName("");
    dispatch(resetListVariationByProduct());
  };

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const onCloseClicked = () => {
    setVarianId("");
    setQuantity(0);
    dispatch(resetListVariationByProduct());
    setShow(false);
  }

  const onAlertClose = () => setShowAlert(false);

  const onDelete = () => {
    if (!isEdit) {
      let newList = [...inventoryMoveDetail];
      newList.splice(selectedIndex, 1);
      setInventoryMove(newList);
      setShowAlert(false);
    } else {
      dispatch(deleteInventoryMoveDetail(selectedId));
      setShowAlert(false);
    }
  };

  const promiseOptions = (inputValue: string) =>
      new Promise<ProductOptionModel[]>((resolve) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
              resolve(getProductOptions(inputValue));
            }, 1000)
        );
      });
console.log("warehouseStockValidation", warehouseStockValidation);
  return (
    <div>
      <Grid textAlign="center">
        <Card>
          {!isApproved ? (
            <>
              <Row>
                <Col>
                  <Button
                    style={{ width: 135, height: 46, margin: 10 }}
                    variant="info"
                    type="button"
                    onClick={handleShow}
                  >
                    Add product
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          <DataTable
            columns={columns}
            data={inventoryMoveDetail}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
          />
        </Card>

        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={onCloseClicked}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="input-padding">
                <Form.Label>Product</Form.Label>
                {/* <Form.Control as="select" onChange={onProductChange} value={productId}>
                  <option>select</option>
                  {productListByWarehouseState.map((Item) => {
                    return <option value={Item.id + "&" + Item.name + "&" + Item.code}>{Item.name}</option>;
                  })}
                </Form.Control> */}

                {/*<Select*/}
                {/*  // defaultValue={selectedPartnerId}*/}
                {/*  onChange={onSelectedProductChange}*/}
                {/*  options={productList}*/}
                {/*/>*/}

                <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                             onChange={onSelectedProductChange} />
              </Form.Group>

              <Form.Group className="input-padding">
                <Form.Label>Varian</Form.Label>
                <Form.Control
                  as="select"
                  onChange={onProductVariantChange}
                  // value={varianId}
                >
                  <option>Pilih Varian</option>

                  {variationState.map((Item) => {
                    let variationName = "";

                    if (Item.customProductAttributeList !== null) {
                      if (Item.customProductAttributeList.length === 1) {
                        variationName += Item.customProductAttributeList[0].name;
                      } else if (Item.customProductAttributeList.length === 2) {
                        variationName +=
                            Item.customProductAttributeList[0].name +
                            "-" +
                            Item.customProductAttributeList[1].name;
                      } else if (Item.customProductAttributeList.length === 3) {
                        variationName +=
                            Item.customProductAttributeList[0].name +
                            "-" +
                            Item.customProductAttributeList[1].name +
                            "-" +
                            Item.customProductAttributeList[2].name;
                      }
                    }

                    let tempStock = Item.warehouseStockList.find(
                      (itemStock) =>
                        itemStock.warehouseId === selectedWarehouseIdState
                    );
                    let stockByWarehouse = 0;

                    if (tempStock !== undefined) {
                      stockByWarehouse = tempStock.quantity;
                    }

                    return (
                      <option
                        value={
                          Item.id +
                          "&" +
                          variationName +
                          "&" +
                          Item.regulerPriceEndCustomer +
                          "&" +
                          Item.regulerPriceStockist +
                          "&" +
                          Item.regulerPriceReseller +
                          "&" +
                          Item.stockQty +
                          "&" +
                          Item.weight +
                          "&" +
                          stockByWarehouse
                        }
                      >
                        {Item.customProductAttributeList?.length >= 1
                          ? Item.customProductAttributeList[0].name
                          : ""}
                        {Item.customProductAttributeList?.length >= 2
                          ? " - " + Item.customProductAttributeList[1].name
                          : ""}
                        {Item.customProductAttributeList?.length == 3
                          ? " - " + Item.customProductAttributeList[2].name
                          : ""}

                        {" (" + stockByWarehouse + ")"}
                      </option>
                    );
                  })}
                </Form.Control>
                {
                    warehouseStockValidation === 0 && (
                        <Form.Label><small className="text-danger">kuantitas tidak tersedia untuk varian di warehouse ini</small></Form.Label>
                    )
                }
              </Form.Group>

              <Form.Group className="input-padding">
                <Form.Label>UOM</Form.Label>
                {/* <Form.Control as="select" onChange={onUOMChange} value={uomId}>
                  <option>select</option>
                  {UOMListState.map((Item) => {
                    return <option value={Item.id + "&" + Item.code}>{Item.code}</option>;
                  })}
                </Form.Control> */}
                <Form.Control type="text" disabled placeholder="PCS" />
              </Form.Group>

              <Form.Group className="input-padding">
                <Form.Label>Quantity</Form.Label>

                <Form.Control
                  type="number"
                  onChange={onQuantityChange}
                  value={quantity}
                  min={0}
                  max={warehouseStockValidation}
                />
                {
                  quantity > warehouseStockValidation && (
                        <Form.Label><small className="text-danger">kuantitas melebihi stok yang tersedia di warehouse</small></Form.Label>
                    )
                }
              </Form.Group>

              <Form.Group className="input-padding">
                <Form.Label>Note</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={onNoteChange}
                  value={note}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-info"
              style={{ width: 100 }}
              onClick={onCloseClicked}
            >
              Cancel
            </Button>

            {varianId !== "" && varianId !== null &&  (quantity !== 0 && warehouseStockValidation >= quantity) ? (
              <>
                {" "}
                <Button
                  variant="info"
                  type="submit"
                  style={{ width: 100 }}
                  onClick={onSaveBtnClick}
                >
                  Save
                </Button>
              </>
            ) : (
              <></>
            )}
          </Modal.Footer>
        </Modal>
      </Grid>

      <DeleteMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
        onDeleteAction={onDelete}
      />
    </div>
  );
};

export interface InventoryMoveDetailProps {
  onSubmitData: (inventoryMoveDetail: InventoryMoveDetailModel[]) => void;
  isEdit: boolean;
  inventoryMoveId: string;
  isApproved: boolean;
}

export default ProductTableComponent;
