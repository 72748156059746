import React from 'react';
import ComplainTable from '../components/ComplainTable';

const ComplainsTablePage = () => {
  return (
  <div>
      <ComplainTable/>
  </div>
  );
};

export default ComplainsTablePage;
