import axios from "axios";
import { DistrictModel } from "./district.model";
import { endpoint } from "../../../constants";

export default class DistrictApi {
  static createData(model: DistrictModel) {
    return axios
      .post<any>(
        endpoint + "/api/District/Create",
        model
      )
      .then((res) => res.data.data);
  }

   static getList() {
     return axios
       .get<any>(endpoint + "/api/City/GetListDistrict")
       .then((res) => res.data.data);
   }

  static getSingleItem(id: string) {
    return axios
       .get<any>(
         endpoint + "/api/City/GetSingleDistrict?id=" +
           id
       )
       .then((res) => res.data.data);
   }

   static updateData(model: DistrictModel) {
     return axios
       .post<any>(endpoint + "/api/District/update", model)
       .then((res) => res.data.data);
   }

   static deleteData(id: string) {
     return axios
       .post<any>(endpoint + "/api/District/Delete?id=" + id)
       .then((res) => [
         { response: res.data.data },
       ]);
   }

  static getProvince() {
    return axios
      .get<any>(endpoint + "/api/Address/GetProvinceByCountry?Id=76cb1ec3-2aab-499e-a2a2-ae2ce01e40e7")
      .then((res) => res.data.data);
  }

  static getCity(provinceId: string) {
    return axios
      .get<any>(endpoint + "/api/Address/GetCityByProvince?Id=" + provinceId)
      .then((res) => res.data.data);
  }


}
