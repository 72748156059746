import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Image,
  ButtonGroup,
  ToggleButton,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplainDetail,
  getListProduct,
  getListVariationByProduct,
  updateComplain,
} from "../complain.reducer";
import { RootState } from "../../../app/store";
import { ComplainModel, ProductModel, VariantModel } from "../complain.model";
import moment from "moment";
import DataTable from "react-data-table-component";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { FaTrash } from "react-icons/fa";
import { endpoint } from "../../../constants";
import NumberFormat from "react-number-format";


const ComplainDetail = ({ readOnly = false }: ComplainDetailProps) => {
  console.log(readOnly);
  const history = useHistory();
  const dispatch = useDispatch();

  const single = useSelector(
    (state: RootState) => state.complain.single ?? ({} as ComplainModel)
  );

  const listProduct: ProductModel[] = useSelector(
    (state: RootState) => state.complain.listProduct ?? []
  );

  const listVariationByProduct: VariantModel[] = useSelector(
    (state: RootState) => state.complain.listVariationByProduct ?? []
  );

  const [status, setStatus] = useState(0);
  const [progressStatus, setProgressStatus] = useState(0);
  const [description, setDescription] = useState("");
  const [complaintProgressDescription, setComplaintProgressDescription] =
    useState("");
  const [nominalWallet, setNominalWallet] = useState(0);
  const [adminGudangNominal, setAdminGudangNominal] = useState(0);
  const [adminQCNominal, setAdminQCNominal] = useState(0);
  const [adminEkspedisiNominal, setAdminEkspedisiNominal] = useState(0);
  const [adminPackingNominal, setAdminPackingNominal] = useState(0);
  const [productListSelected, setProductListSelected] = useState<any>([]);
  const [statusModalAddProduct, setStastusModalAddProduct] = useState(false);
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [selectedVariantName, setSelectedVariantName] = useState("");
  const [selectedProductQty, setSelectedProductQty] = useState(0);

  useEffect(() => {
    getDetail();
    dispatch(getListProduct());
  }, []);

  useEffect(() => {
    if (single.id) {
      console.log(single)
      setStatus(single.status);
      setProgressStatus(single.progressStatus);
      setDescription(single.description);
      setComplaintProgressDescription(single.complaintProgressDescription);
      setNominalWallet(single.nominalWallet);
      setAdminGudangNominal(single.adminGudangNominal);
      setAdminQCNominal(single.adminQCNominal);
      setAdminEkspedisiNominal(single.adminEkspedisiNominal);
      setAdminPackingNominal(single.adminPackingNominal);
      setProductListSelected(single.complaintDetail);
    }
  }, [single]);

  const getDetail = () => {
    let pathname = window.location.pathname;
    let id = pathname.split("/")[2];
    dispatch(getComplainDetail(id));
  };

  const handleDeleteList = (id: string) => {
    setProductListSelected(
      productListSelected.filter((item: any) => item.id !== id)
    );
  };

  const handleChangeQtyList = (e: any, data: any) => {
    let newProductListSelected: any = [];
    productListSelected.map((item: any) => {
      let newItem = JSON.parse(JSON.stringify(item));
      if (newItem.id === data.id) {
        newItem.quantity = e.target.value;
      }
      newProductListSelected.push(newItem);
    });
    setProductListSelected(newProductListSelected);
  };

  const onProductChange = (e: any) => {
    let selected = listProduct.filter(
      (item: any) => item.id === e.target.value
    )[0];
    if (selected) {
      setSelectedVariantId("");
      setSelectedProductCode(selected.productCode);
      setSelectedProductName(selected.name);
      dispatch(getListVariationByProduct(e.target.value));
    }
  };

  const onVariationChange = (e: any) => {
    let selected = listVariationByProduct.filter(
      (item: any) => item.id === e.target.value
    )[0];
    if (selected) {
      setSelectedVariantId(e.target.value);
      setSelectedVariantName(selected.name);
    }
  };

  const handleAddProduct = () => {
    let data = {
      variationId: selectedVariantId,
      quantity: selectedProductQty,
      productCode: selectedProductCode,
      productName: selectedProductName,
      variantName: selectedVariantName,
    };
    setProductListSelected([...productListSelected, data]);
    setStastusModalAddProduct(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = () => {
    dispatch(
      updateComplain({
        id: single.id,
        type: single.type,
        typeName: single.typeName,
        name: single.name,
        email: single.email,
        telephone: single.telephone,
        salesOrderId: single.salesOrderId,
        description: single.description,
        complaintProgressDescription: complaintProgressDescription,
        photoOfReceipt: single.photoOfReceipt,
        photoOfItem: single.photoOfItem,
        videoUnboxing: single.videoUnboxing,
        progressStatus: progressStatus,
        status: status,
        recipientDate: single.recipientDate,
        rejectDate: single.rejectDate,
        approveDate: single.approveDate,
        changeToWalletDate: single.changeToWalletDate,
        nominalWallet: nominalWallet,
        adminGudangNominal: adminGudangNominal,
        adminQCNominal: adminQCNominal,
        adminEkspedisiNominal: adminEkspedisiNominal,
        adminPackingNominal: adminPackingNominal,
        complaintDetail: productListSelected,
      })
    );
    history.goBack();
  };

  const moneyParser = (value: any) => {
    let valueString = value.toString();
    let valueList = valueString.split("");
    let resultList: any = [];
    valueList.reverse().map((c: any, i: number) => {
      resultList.push(c);
      if ((i + 1) % 3 === 0 && i + 1 !== valueList.length) {
        resultList.push(".");
      }
    });
    let result = resultList.reverse().join("");
    return "Rp" + result;
  };

  return (
    <div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={statusModalAddProduct}
        onHide={() => setStastusModalAddProduct(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Product</Form.Label>
              <Form.Control as="select" onChange={onProductChange}>
                <option>select</option>
                {listProduct.map((Item: any) => {
                  return <option value={Item.id}>{Item.name}</option>;
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Variant</Form.Label>
              <Form.Control as="select" onChange={onVariationChange}>
                <option>select</option>
                {listVariationByProduct.map((item: any) => (
                  <option value={item.id}>
                    {item.customProductAttributeList.length >= 1
                      ? item.customProductAttributeList[0].name
                      : ""}
                    {item.customProductAttributeList.length >= 2
                      ? " - " + item.customProductAttributeList[1].name
                      : ""}
                    {item.customProductAttributeList.length == 3
                      ? " - " + item.customProductAttributeList[2].name
                      : ""}
                    {" (" + item.stockQty + ")"}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Qty</Form.Label>
              <Form.Control
                type="number"
                value={selectedProductQty}
                onChange={(e) =>
                  setSelectedProductQty(parseInt(e.target.value))
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-info"
            style={{ width: 100 }}
            onClick={() => setStastusModalAddProduct(false)}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            type="submit"
            style={{ width: 100 }}
            onClick={handleAddProduct}
            disabled={selectedVariantId === "" || readOnly}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Form.Group className="title">Detail Pengaduan</Form.Group>
      <Grid>
        <Card>
          <Card.Body>
            <b>Detail Pengaduan</b>
            <span className="float-right">
              {status === 1 ? "Aproved" : status === 2 ? "Rejected" : null}
            </span>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Nama
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  value={single.name}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Email
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  value={single.email}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Telepon
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  value={single.telephone}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                No. Pesanan
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  value={single.soNumber}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Tanggal Terima Pesanan
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="text"
                  value={
                    single.recipientDate !== "-"
                      ? moment(new Date(single.recipientDate)).format(
                          "DD/MM/YYYY HH:mm"
                        )
                      : single.recipientDate
                  }
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Keluhan
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="area"
                  value={description}
                  disabled
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Bukti Pembelian
              </Form.Label>
              <Col sm={4}>
                {single.photoOfReceipt !== "" && (
                  <Image
                    fluid
                    thumbnail
                    src={endpoint + single.photoOfReceipt}
                  />
                )}
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Foto Barang
              </Form.Label>
              <Col sm={4}>
                {single.photoOfItem !== "" && (
                  <Image fluid thumbnail src={endpoint + single.photoOfItem} />
                )}
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Video Unboxing
              </Form.Label>
              <Col sm={4}>
                {single.videoUnboxing !== "" && (
                  <iframe
                    title="video unboxing"
                    allowFullScreen
                    style={{ width: "100%", height: "300px" }}
                    src={endpoint + single.videoUnboxing}
                  />
                )}
              </Col>
            </Form.Group>
            <hr />
            <b>Data Karyawan</b>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin Gudang
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="area"
                  value={single.adminGudang}
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Nominal"
                  type="text"
                  value={moneyParser(adminGudangNominal)}
                  onChange={(e) =>
                    setAdminGudangNominal(
                      parseInt(e.target.value.replace(/[Rp. ]/g, "") || "0")
                    )
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin QC
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="area"
                  value={single.adminQC}
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Nominal"
                  type="text"
                  value={moneyParser(adminQCNominal)}
                  onChange={(e) =>
                    setAdminQCNominal(
                      parseInt(e.target.value.replace(/[Rp. ]/g, "") || "0")
                    )
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin Packing
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="area"
                  value={single.adminPacking}
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Nominal"
                  type="text"
                  value={moneyParser(adminPackingNominal)}
                  onChange={(e) =>
                    setAdminPackingNominal(
                      parseInt(e.target.value.replace(/[Rp. ]/g, "") || "0")
                    )
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="input-padding">
              <Form.Label column sm={3}>
                Admin Ekspedisi
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  placeholder="-"
                  type="area"
                  value={single.adminEkspedisi}
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Nominal"
                  type="text"
                  value={moneyParser(adminEkspedisiNominal)}
                  onChange={(e) =>
                    setAdminEkspedisiNominal(
                      parseInt(e.target.value.replace(/[Rp. ]/g, "") || "0")
                    )
                  }
                />
              </Col>
            </Form.Group>
            <hr />
            <b>Proses Pengaduan</b>
            <hr />
            {new URLSearchParams(window.location.search).get("aproval") ? (
              <ButtonGroup>
                {["Request", "Aprove", "Reject"].map((radio, i) => {
                  if (i !== 0) {
                    return (
                      <ToggleButton
                        className="toggle-button"
                        key={i}
                        type="radio"
                        variant="outline-primary"
                        name="radio"
                        value={i}
                        checked={status === i}
                        onChange={(e) => single.status === 0 && setStatus(i)}
                        disabled={single.status !== 0 && i !== status}
                      >
                        {radio}
                      </ToggleButton>
                    );
                  } else {
                    return null;
                  }
                })}
              </ButtonGroup>
            ) : (
              <>
                <Row>
                  <ButtonGroup>
                    {[
                      {
                        id: 1,
                        label: "Wallet (discount)",
                        color: "primary",
                      },
                      {
                        id: 2,
                        label: "Retur barang sama",
                        color: "primary",
                      },
                      {
                        id: 3,
                        label: "Retur barang baru",
                        color: "primary",
                      },
                      // {
                      //   id: 4,
                      //   label: 'Refund',
                      //   color: 'primary'
                      // }
                    ].map((radio) => {
                      return (
                        <ToggleButton
                          className="toggle-button"
                          key={radio.id}
                          type="radio"
                          variant={"outline-" + radio.color}
                          name="radio"
                          value={radio.id}
                          checked={progressStatus === radio.id}
                          onChange={(e) => setProgressStatus(radio.id)}
                        >
                          {radio.label}
                        </ToggleButton>
                      );
                    })}
                  </ButtonGroup>
                </Row>
                <hr />
              </>
            )}
            <hr />
            {/* {progressStatus === 1 && ( */}
            <>
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Keterangan
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    placeholder="-"
                    type="text"
                    value={complaintProgressDescription}
                    onChange={(e: any) =>
                      setComplaintProgressDescription(e.target.value)
                    }
                  />
                </Col>
              </Form.Group>
              <hr />
              <Form.Group as={Row} className="input-padding">
                <Form.Label column sm={3}>
                  Nominal Wallet
                </Form.Label>
                <Col sm={5}>
                  <Form.Control
                    placeholder="-"
                    type="text"
                    value={moneyParser(nominalWallet)}
                    onChange={(e: any) =>
                      setNominalWallet(
                        e.target.value.replace(/[Rp. ]/g, "") || "0"
                      )
                    }
                  />
                </Col>
              </Form.Group>
            </>
            {/* )} */}
            <hr />
            <Grid>
              <Card>
                <Card.Body>
                  {progressStatus === 0 && (
                    <Button
                      variant="info"
                      type="submit"
                      onClick={() => setStastusModalAddProduct(true)}
                    >
                      Tambah Produk
                    </Button>
                  )}
                  <DataTable
                    noHeader
                    customStyles={TableCustomStyles}
                    columns={[
                      {
                        name: "KODE PRODUK",
                        selector: "productCode",
                        sortable: true,
                      },
                      {
                        name: "PRODUCT NAME",
                        selector: "productName",
                        sortable: true,
                      },
                      {
                        name: "COLOR/VARIANT",
                        selector: "variantName",
                        sortable: true,
                      },
                      {
                        name: "PRICE",
                        selector: (d: any) => (
                          <NumberFormat
                            value={d.price}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="Rp. "
                          />
                        ),
                        sortable: true,
                      },
                      {
                        name: "QTY",
                        selector: (d: any) => (
                          <Form.Control
                            type="number"
                            placeholder="-"
                            value={d.quantity}
                            onChange={(e) => handleChangeQtyList(e, d)}
                          ></Form.Control>
                        ),
                        sortable: true,
                      },
                      {
                        name: "SUBTOTAL",
                        selector: (d: any) => (
                          <NumberFormat
                            value={d.price*d.quantity}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="Rp. "
                          />
                        ),
                        sortable: true,
                      },
                      {
                        name: "ACTION",
                        selector: (d: any) => (
                          <div className="retur-table-button">
                            {progressStatus === 0 && (
                              <FaTrash onClick={() => handleDeleteList(d.id)} />
                            )}
                          </div>
                        ),
                        sortable: true,
                      },
                    ]}
                    data={productListSelected}
                    pagination
                    paginationServer
                  />
                </Card.Body>
              </Card>
            </Grid>
            <hr />
            <Row>
              <Form.Group as={Row} className="input-padding m-3">
                <Button
                  className="mr-2"
                  variant="outline-info"
                  style={{ width: 130, height: 45 }}
                  type="submit"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {"     "}
                {!readOnly && (
                  <Button
                    variant="info"
                    style={{ width: 130, height: 45 }}
                    type="submit"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Grid>
    </div>
  );
};

export interface ComplainDetailProps {
  readOnly?: boolean;
}
export default ComplainDetail;
